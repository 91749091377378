import React, { useEffect } from "react"
import styled from "styled-components"
import { getUsersExcelData } from "services/dataValidation"
import { getUserID } from "utils/helper"
import Item from "./tableItem"
const Table = (props) => {
  useEffect(() => {
    getFiles()
  }, [])

  function getFiles() {
    const user = getUserID()
    getUsersExcelData({
      user_id: user,
    })
      .then(function (response) {})
      .catch((error) => {
        console.log("error", error)
      })
  }

  const files = props.files ? props.files : []
  const items = []
  for (let i = 0; i < files.length; i++) {
    items.push(
      <Item
        name={files[i].file_name}
        created_at={files[i].created_at}
        download_url={files[i].download_url}
        id={files[i].download_url}
        key={i}></Item>
    )
  }
  return (
    <Wrapper>
      <WrapperTitle>
        <HeadingFiles>DATA MANIPULATOR HISTORY</HeadingFiles>
      </WrapperTitle>
      <div className='scroll-in-mobile dowloads'>{items}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 20px;
`
const HeadingFiles = styled.h2`
  font-weight: bold;
  color: #15342e;
  margin-left: 13px;
  font-family: roboto;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.7;
  padding-top: 14px;
`

export default Table
