import React,{useState,useEffect} from 'react';
import styled from "styled-components"
import ManageCustomFields from './ManageCustomFields';
import ManageTags from './ManageTags';

function Setting() {
  const [tabs,setTabs]=useState('manageTags');
  const handleManageTabs={
    manageTags:<ManageTags/>,
    manageCustomFields:<ManageCustomFields/>
  }

  return <>
        <Container>
        <div className='MainDownloadTabs mb-20 mt-16'>
                    <ul>
                        <li className={tabs==='manageTags'?'active':null} onClick={()=>setTabs('manageTags')}>
                        Manage Tags
                        </li>{" "}
                        <li className={tabs==='manageCustomFields'?'active':null} onClick={()=>setTabs('manageCustomFields')}>
                        {" "}
                        Manage Custom Fields
                        </li>
                    </ul>
          </div>
        {handleManageTabs[tabs]}
        </Container>
        </>
}
const Container = styled.div`
  width:100%
`

export default Setting;
