import React, { useState } from "react"
import ReactTooltip from "react-tooltip"
import styled from "styled-components"
import TagModal from "components/manipulator-tag-modal"
import Item from "./tableItem"

const Table = (props) => {
  const files = props.files ? props.files : undefined
  const { refetch, counter } = props
  const { TShow, setTShow, currentPage, recordsPerPage, totalRecords } = props
  const [TagModalData, setTagModalData] = useState(0)
  const [selectedRecord, setselectedRecord] = useState(0)

  const { isLoading, closeModals, setisLoading, totalfiles } = props
  const items = []
  if (files) {
    for (let i = 0; i < files.length; i++) {
      const Owner1 = files[i].owner_1
      const Owner2 = files[i].owner_2
      items.push(
        <Item
          index={i}
          files={files}
          totalfiles={totalfiles}
          totalRecords={totalRecords}
          currentPage={currentPage}
          recordsPerPage={recordsPerPage}
          setTShow={setTShow}
          counter={counter}
          setTagModalData={setTagModalData}
          setselectedRecord={setselectedRecord}
          filterData={props.filterData}
          PresetValue={props.PresetValue}
          id={files[i].id}
          applyFilter={props.applyFilter}
          Owner1={Owner1}
          Owner2={Owner2}
          mailing_address={files[i].mailing_address}
          mailing_city={files[i].mailing_city}
          mailing_state={files[i].mailing_state}
          mailing_zip={files[i].mailing_zip}
          addreses={files[i].addreses}
          phone_number={files[i].phone_number}
          tag={files[i].tag}
          emails={files[i]?.emails}
          custom_fields={files[i]?.custom_fields}
          times_called={files[i].times_called}
          file={files[i]}
          filteredRelativeCounts={files[i].filtered_relative_counts}
          customFieldCount={files[i].custom_field_count}
          relativeCounts={files[i].relative_counts}
          closeModals={closeModals}
          setisLoading={setisLoading}
          isLoading={isLoading}
          key={i}></Item>
      )
    }
  }

  function checkAll() {
    if (document.querySelector("input[id=Checkbox]").checked) {
      const listCheckBox = document.getElementsByClassName("checkboxContacts")
      for (let i = 0; i < listCheckBox.length; i++) {
        listCheckBox[i].checked = true
      }

      const record_ids = []
      document.querySelectorAll("input[id=fileIds").value = record_ids.join("")
      document.querySelector(
        ".SelectAllMain.row .hidden-action"
      ).style.display = "block"
    } else {
      const listCheckBox = document.getElementsByClassName("checkboxContacts")
      for (let i = 0; i < listCheckBox.length; i++) {
        listCheckBox[i].checked = false
      }
      document.querySelector("input[id=fileIds").value = ""
      document.querySelector(
        ".SelectAllMain.row .hidden-action"
      ).style.display = "none"
    }
  }
  const [showItem, setShowItem] = useState("flex")
  function showHiddenColumn() {
    const listData = document.querySelectorAll(".row.contacts_hidden_data")
    for (let i = 0; i < listData.length; i++) {
      listData[i].style.display = showItem
    }
    setShowItem(showItem === "flex" ? "none" : "flex")
  }

  return (
    <>
      <Wrapper>
        <div className='scroll-in-mobile contact-table contacts'>
          <div className='SelectAllMain row'>
            <div className='hidden-action'>
              <div className='flex'>
                <span className=' add_tags text-gray-500 text-xs'>
                  <p
                    className='font-bold pr-5 cursor-pointer'
                    onClick={() => props.addTags()}>
                    + Add Tag
                  </p>
                </span>
                <span className=' add_tags'>
                  <p
                    className='font-bold col text-gray-500 text-xs cursor-pointer'
                    onClick={() => props.removeTags()}>
                    × Remove Tag
                  </p>{" "}
                </span>
              </div>
            </div>
          </div>
          <div className='row reports_listing'>
            <input type='hidden' name='fileIds' id='fileIds' value='' />
            <div className='listing_heading owner1'>
              {" "}
              <input type='Checkbox' id='Checkbox' onClick={checkAll} />{" "}
              <span> Owner 1 </span>{" "}
            </div>
            <div className='listing_heading owner2'>
              {" "}
              <span> Owner 2 </span>{" "}
            </div>
            <div className='listing_heading addreses'>
              {" "}
              <span> Mailing Address </span>{" "}
            </div>
            <div className='listing_heading city'>
              {" "}
              <span> Mailing City </span>{" "}
            </div>
            <div className='listing_heading state'>
              {" "}
              <span> Mailing State </span>{" "}
            </div>
            <div className='listing_heading zip'>
              {" "}
              <span> Mailing Zip </span>
              <ReactTooltip
                id={"expand_icon"}
                aria-haspopup='true'
                role='example'>
                Expand
              </ReactTooltip>
            </div>
            <div
              onClick={() => showHiddenColumn()}
              className={
                showItem === "none" ? "action rotate-180 right--5" : "action"
              }>
              <svg
                width='14'
                height='8'
                viewBox='0 0 14 8'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                {" "}
                <a data-for={"expand_icon"}>
                  {" "}
                  <path
                    d='M13.1558 0.177495C12.9192 -0.0591515 12.5354 -0.0591919 12.2987 0.177535L6.66681 5.80957L1.03461 0.177495C0.79796 -0.0591515 0.414202 -0.0591919 0.177515 0.177535C-0.0591717 0.414222 -0.0591717 0.797939 0.177515 1.03463L6.23828 7.09523C6.35194 7.20889 6.50608 7.27273 6.66681 7.27273C6.82753 7.27273 6.98172 7.20885 7.09533 7.09519L13.1558 1.03459C13.3925 0.797939 13.3925 0.414182 13.1558 0.177495Z'
                    fill='black'></path>
                </a>
              </svg>
            </div>
          </div>
          {files && props.count === 0 && files.length === 0 && !isLoading ? (
            <div className='empty-record'>No Records Found</div>
          ) : (
            items
          )}
        </div>
      </Wrapper>
      <TagModal
        onClose={() => {
          setTShow(false)
        }}
        refetch={refetch}
        show={TShow}
        data={TagModalData}
        selectedRecord={selectedRecord}
      />
    </>
  )
}

const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`

export default Table
