import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { resetPasword } from "services/auth";
import Loading from "components/loading";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';

import TextInput from "components/textInput";
import Text from "components/text";
import Image from "components/image";
import Logo from "assets/images/logo.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ResetPasswrod = () => {
  let history = useHistory();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const ActivatingEmail = new URLSearchParams(search).get("email");

  const [password, setPassword] = useState("");
  const [reTypePasswrod, setreTypePasswrod] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isActive, setisActive] = useState(true);
  document.title = "Reset Password | AnalyzeREI";
  // useEffect(() => {
  //   ActiveAccountCall();
  // });

  function onChange(event) {
    if (event.target.name === "retype_password") {
      setreTypePasswrod(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  }
  // function ActiveAccountCall() {
  //   if (isActive) {
  //     if (token != null) {
  //       ActiveAccount({
  //         token: token,
  //       })
  //         .then(function (response) {
  //           setisActive(false);
  //           if (response.status === true) {
  //             toast.success(response.message);
  //           } else {
  //             toast.error(response.message);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("error", error);
  //         });
  //     }
  //   }
  // }
  function onSubmit() {
    setisActive(false);
    setisLoading(true);
    resetPasword({
      reTypePasswrod: reTypePasswrod,
      password: password,
      token: token,
    })
      .then(function (response) {
        setisLoading(false);
        if (response.status === true) {
          toast.success(response.message);
          history.push("/login");
          window.location.reload();
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  return (
    <Wrapper>
      <Loading show={isLoading} />
      <FormWrapper>
        <Icon src={Logo} width="50px" height="50px" />
        <Text>Reset your password!</Text>
        <form>
          <TextInput
            type={"password"}
            label={"Password"}
            value={password}
            placeholder={"password"}
            onChange={onChange}
            name="password"
          />

          <TextInput
            type={"password"}
            label={"Re-Type Password"}
            value={reTypePasswrod}
            placeholder={"Re-Type Password"}
            onChange={onChange}
            name="retype_password"
          />
        </form>

        <button
          onClick={onSubmit}
          className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Submit
        </button>
      </FormWrapper>
    </Wrapper>
  );
};
export default ResetPasswrod;
const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const Icon = styled(Image)`
  img {
    margin: auto;
  }
`;
const FormWrapper = styled.div`
  ${tw`
        w-2/5
        rounded
        shadow-2xl
        
    `}
  padding: 50px 50px 100px;
  margin: auto;
  margin-top: 10%;
`;
