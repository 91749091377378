import React, { useState } from "react"
import ReactTooltip from "react-tooltip"
import styled from "styled-components"
import tw from "twin.macro"
import DownloadIcon from "assets/images/download-icon.png"
import Modal from "components/lead-modal"
import COLORS from "utils/colors"
const Item = (props) => {
  const [show, setShow] = useState(0)

  function DownloadPopup(id) {
    localStorage.setItem("file_id", id)
    setShow(true)
  }
  const tooltipID = "tooltip_ID_" + props.id
  return (
    <div>
      <div>
        {/* <input type="checkbox" onChange={checkAllCheckbox} />Select All */}
      </div>

      <Wrapper>
        <Modal
          onClose={() => {
            setShow(false)
          }}
          show={show}
        />
        <ItemWrapper>
          {/* <Checkbox type='checkbox' name="slectedCheckBox[]"  onChange={checkboxes} /> */}
          <Mobile>
            <ParaTwo data-tip data-for={tooltipID} className='itemHeading'>
              File
            </ParaTwo>
            <Para>{props.name}</Para>
            <ReactTooltip id={tooltipID} aria-haspopup='true' role='example'>
              {props.name}
            </ReactTooltip>
          </Mobile>
          <Mobile>
            <ParaTwo className='itemHeading'>Created at</ParaTwo>
            <Para>{props.created_at}</Para>
          </Mobile>
          <DownloadIconWrapper>
            <a href={props.download_url} rel='noreferrer' target='_blank'>
              <Icon
                src={DownloadIcon}
                width='34px'
                height='34px'
                onClick={() => DownloadPopup(props.id)}
              />
            </a>
          </DownloadIconWrapper>
        </ItemWrapper>
      </Wrapper>
    </div>
  )
}

export default Item

const Icon = styled.img`
  margin-left: 0px;
  padding-top: 5px;
  cursor: pointer;
`

const Wrapper = styled.div`
  z-index: 0;
  display: flex;
  height: 80px;
  background-color: ${COLORS.white};
  border-radius: 19px;
  margin: auto;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 10px;
  ${tw`
    shadow-lg
  `}
`

const ItemWrapper = styled.div`
  position: relative;
  top: 10%;
  display: flex;
  width: 100%;
  justify-content: space-around;
`

const DownloadIconWrapper = styled.span`
  margin-top: 10px;
`

const Para = styled.p`
  color: ${COLORS.createdat};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`

const Mobile = styled.label`
  margin-top: 10px;
  color: ${COLORS.blue};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`

const ParaTwo = styled.p`
  color: ${COLORS.username};
  font-family: Montserrat;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  word-break: break-all;
`
