import React from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import COLORS from "utils/colors"
const Modal = (props) => {
  if (!props.show) {
    return null
  }
  return (
    <WrapperModal>
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <Para2>
              <span className='low_credit'> Low Credits</span>
            </Para2>
          </ModalTitle>
          <WrapperBody>
            <div className='lead-input-container'>
              You do not have sufficient credits to proceed. Please add more
              blow:
            </div>

            <div className='lead-input-container purhcase-credit'>
              <span> Credit </span>
              <input
                type='number'
                min='1'
                max='100000'
                className='creditAmount'
                value={props.requiredCredit}
                onChange={props.GetCreatePrice}
              />
            </div>
          </WrapperBody>
          <WrapperFooter>
            <ModalButton onClick={props.onClose}>Cancel</ModalButton>
            <ModalButtonSubmit onClick={props.Payment}>
              Purchase {props.Price}
            </ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 567px;
  min-height: 200px;
  left: 420px;
  top: 40px;
  z-index: 1;
  display: flex;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 520px;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px 20px;
  border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 520px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;
`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align: center;
`

const ModalButtonSubmit = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #89de58;
  color: #fff;
  margin-top: 30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
  margin-left: 10px;
`

const ModalButton = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #d4d4d4;
  color: #6d6e6e;
  margin-top: 30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
`

const Para2 = styled.p`
  color: 000000;
  font-size: 10px;
  font-weight: bold;
  position: relative;
  top: 28px;
  width: 88%;
  text-align: center;
`
