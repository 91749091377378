import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import TopNavProfile from "components/navbar/TopNavProfile";
import PlanCard from "./PlanCard";
import {
  StripePlans,
  selectedStripePlans,
  CurrentStripePlans,
} from "services/paymentDeatil";
import { toast } from "react-toastify";

function Plans() {
  const [plansRecords, setPlanRecords] = useState([]);
  const [userPlanHistory, setUserPlanHistory] = useState({});

  const handlePlans = () => {
    StripePlans()
      .then(function (response) {
        // setisLoading(false)
        console.log(response);
        if (response) {
          setPlanRecords(response?.plans);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleSelectedPlans = (plan_id) => {
    selectedStripePlans({ plan_id })
      .then(function (response) {
        if (response) {
          toast.success(response?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleCurrentUserPlans = () => {
    CurrentStripePlans({ is_active: "yes" })
      .then(function (response) {
        if (response) {
          setUserPlanHistory(response?.plans);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    handlePlans();
    handleCurrentUserPlans();
  }, []);

  return (
    <Wrapper>
      <TopNavProfile />
      <div className="width-inpayment-set">
        <div className="payments-method-page">
          <div className="wrapper-payments-method-page">
            <div className="container-fluid">
              <div className="MainDownloadTabs">
                <ul>
                  <li>
                    <Link to="/payments">Payments</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/credits"> Credits</Link>
                  </li>
                  <li className="active">
                    <Link to="/plans"> Plans</Link>
                  </li>
                </ul>
              </div>
              <div className="main-div-payments-card">
                <div className="credit-page-main-card">
                  <div className="my-card-payments ">
                    <div className="card padding-top-credit">
                      <div className="card-in-credit-ranger">
                        <div className="flex flex-wrap justify-center gap-3">
                          {plansRecords.map(({ plan_name, price, plan_id }) => (
                            <PlanCard
                              active={userPlanHistory?.plan_id == plan_id}
                              title={plan_name}
                              price={price}
                              onClick={() => handleSelectedPlans(plan_id)}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
  position: relative;
`;
export default Plans;
