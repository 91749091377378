import React, { useState } from "react"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import styled from "styled-components"
import TopNavProfile from "components/navbar/TopNavProfile"

const Credits = (props) => {
  let history = useHistory()
  const [getValue, setValue] = useState(7600)

  const pushAddCreditPage = () => {
    // history.push(`/add-payment?p=${dataVariable}&rangevalue=${getValue}`)
    history.push(`/add-payment`)
    localStorage.setItem("purchasePrice", JSON.stringify(dataVariable))
    localStorage.setItem(
      "rangevalue",
      JSON.stringify(numberWithCommas(getValue))
    )
  }
  document.title = "Credits | AnalyzeREI"

  const [item, setItem] = useState([false, true, false])
  const [dataVariable, setDataVariable] = useState(
    numberWithCommas((7600 * 0.015).toFixed(2))
  )

  const handleChange = (e) => {
    setValue(e.target.value)
    checkValue(e.target.value)
  }
  const checkValue = (value) => {
    if (value >= 2500 && value <= 7599) {
      setItem([true, false, false])
      setDataVariable(numberWithCommas((value * 0.017).toFixed(2)))
    }
    if (value >= 7600 && value <= 30999) {
      setItem([false, true, false])
      setDataVariable(numberWithCommas((value * 0.015).toFixed(2)))
    }
    if (value >= 31000 && value <= 100000) {
      setItem([false, false, true])
      setDataVariable(numberWithCommas((value * 0.013).toFixed(2)))
    }
  }
  const onChangeInput = (event) => {
    setValue(event.target.value)
    checkValue(event.target.value)
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  return (
    <Wrapper>
      <TopNavProfile />
      <div className='width-inpayment-set'>
        <div className='payments-method-page'>
          <div className='wrapper-payments-method-page'>
            <div className='container-fluid'>
              <div className='MainDownloadTabs'>
                <ul>
                  <li>
                    <Link to='/payments'>Payments</Link>
                  </li>
                  <li className='active'>
                    {" "}
                    <Link to='/credits'> Credits</Link>
                  </li>
                   <li >
                    <Link to='/plans'> Plans</Link>
                  </li>
                </ul>
              </div>
              <div className='main-div-payments-card'>
                <div className='credit-page-main-card'>
                  <div className='my-card-payments '>
                    <div className='card padding-top-credit'>
                      <div className='card-in-credit-ranger'>
                        <div className='row'>
                          <div className='col-md-4'>
                            {item[0] ? (
                              <div className='card active-credit-card'>
                                <div className='first-heading-credit'>
                                  <p>2,500 - 7,599</p>
                                </div>
                                <div className='second-heading-credit'>
                                  <p>Account Credits</p>
                                </div>
                                <div className='first-line-credit'></div>
                                <div className='last-heading-credit'>
                                  {/* <p> {('$' + dataVariable)}</p> */}
                                  <p>$0.017 / credit</p>
                                </div>
                              </div>
                            ) : (
                              <div className='card'>
                                <div className='first-heading-credit'>
                                  <p>2,500 - 7,599</p>
                                </div>
                                <div className='second-heading-credit'>
                                  <p>Account Credits</p>
                                </div>
                                <div className='first-line-credit'></div>
                                <div className='last-heading-credit'>
                                  <p>$0.017 / credit</p>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className='col-md-4'>
                            {item[1] ? (
                              <div className='card active-credit-card'>
                                <div className='first-heading-credit'>
                                  <p>7,600 - 30,999</p>
                                </div>
                                <div className='second-heading-credit'>
                                  <p>Account Credits</p>
                                </div>
                                <div className='first-line-credit'></div>
                                <div className='last-heading-credit'>
                                  <p>$0.015 / credit</p>
                                  {/* <p>{('$' + dataVariable)}</p> */}
                                </div>
                              </div>
                            ) : (
                              <div className='card '>
                                <div className='first-heading-credit'>
                                  <p>7,600 - 30,999</p>
                                </div>
                                <div className='second-heading-credit'>
                                  <p>Account Credits</p>
                                </div>
                                <div className='first-line-credit'></div>
                                <div className='last-heading-credit'>
                                  <p>$0.015 / credit</p>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className='col-md-4'>
                            {item[2] ? (
                              <div className='card active-credit-card'>
                                <div className='first-heading-credit'>
                                  <p>31,000 - 100,000</p>
                                </div>
                                <div className='second-heading-credit'>
                                  <p>Account Credits</p>
                                </div>
                                <div className='first-line-credit'></div>
                                <div className='last-heading-credit'>
                                  {/* <p>{('$' + dataVariable)}</p> */}
                                  <p>$0.013 / credit</p>
                                </div>
                              </div>
                            ) : (
                              <div className='card'>
                                <div className='first-heading-credit'>
                                  <p>31,000 - 100,000</p>
                                </div>
                                <div className='second-heading-credit'>
                                  <p>Account Credits</p>
                                </div>
                                <div className='first-line-credit'></div>
                                <div className='last-heading-credit'>
                                  <p>$0.013 / credit</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='labeland-input-in-credit d-flex'>
                            <label>Enter your desired Amount</label>
                            <input
                              type='number'
                              min='2500'
                              max='100000'
                              name='enter-amount'
                              value={getValue}
                              onChange={onChangeInput}
                            />
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='upper-class-ranger'>
                            <div className='range'>
                              <div className='slidervalue'>
                                {/* <span>100</span> */}
                              </div>
                              <div className='field'>
                                {/* <div className="value left">0</div> */}
                                <input
                                  type='range'
                                  min='2500'
                                  max='100000'
                                  value={getValue}
                                  onChange={handleChange}
                                />
                                {/* <div className="value right">{' $'+getValue}</div> */}
                                <div className='w-100 center-position'>
                                  <button
                                    className=' btn-pay'
                                    onClick={pushAddCreditPage}>
                                    {"$" + dataVariable}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
  position: relative;
`

export default Credits
