import React from "react"
import styled from "styled-components"
import TopNav from "components/navbar/topNav"

const Services = () => {
  return (
    <Wrapper>
      <TopNav />
      This is services page
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
`

export default Services
