import React,{useState,useEffect} from 'react'
import Select from "react-select"


function Relative({onChange,options,index,step,relative}) {
  const [expand,setExpand]=useState(false)

  useEffect(()=>{
    setExpand(false)
  },[step])

  const getSelectedValue=(index,field,subfield)=>{
    if(step=='mapping'){
      return `phone${index}`
    }
    else if(subfield){
      return `${step}_${field}${index}_${subfield}`
    }
    else{
      return `${step}_${field}${index}`
    }
  }
  

  const Connected = () => (<>
  <label>Phone {index} Connected </label>
    <Select
      name={getSelectedValue(index,'phone',"connected")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue(index,'phone',"connected")]!=''?{label:relative[getSelectedValue(index,'phone',"connected")],value:relative[getSelectedValue(index,'phone',"connected")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

    const Carrier = () => (<>
    <label>Phone {index} Carrier</label>
    <Select
      name={getSelectedValue(index,'phone',"carrier")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue(index,'phone',"carrier")]!=''?{label:relative[getSelectedValue(index,'phone',"carrier")],value:relative[getSelectedValue(index,'phone',"carrier")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

  const CallerIdName  = () => (<>
    <label>Phone {index} Caller ID Name </label>
    <Select
      name={getSelectedValue(index,'phone',"caller_id_name")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue(index,'phone',"caller_id_name")]!=''?{label:relative[getSelectedValue(index,'phone',"caller_id_name")],value:relative[getSelectedValue(index,'phone',"caller_id_name")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

    const CallerIdMatch  = () => (<>
    <label>Phone {index} Caller ID Match</label>
    <Select
      name={getSelectedValue(index,'phone',"caller_id_match")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue(index,'phone',"caller_id_match")]!=''?{label:relative[getSelectedValue(index,'phone',"caller_id_match")],value:relative[getSelectedValue(index,'phone',"caller_id_match")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

    const LineType  = () => (<>
    <label> Phone {index} Line Type</label>
    <Select
      name={getSelectedValue(index,'phone',"line_type")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue(index,'phone',"line_type")]!=''?{label:relative[getSelectedValue(index,'phone',"line_type")],value:relative[getSelectedValue(index,'phone',"line_type")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

  return (
     < div className='col-md-6'>
              <div className='fields max-w-fit divider'>
                <label className="capitalize">{`Phone ${index}`}</label>
                 <Select
                  name={getSelectedValue(index,"phone")}
                  options={[{label:'Select',value:''},...options]}
                  className='basic-multi-select w-350'
                  classNamePrefix='select'
                  value={relative?.[getSelectedValue(index,"phone")]!=''?{label:relative[getSelectedValue(index,"phone")],value:relative[getSelectedValue(index,"phone")]}:{label:"Select",value:""}}
                  onChange={onChange}
                  
    />              
                {expand&&
                        <>
                        <Connected/>
                        <Carrier/>
                        <CallerIdName />
                        <CallerIdMatch />
                        <LineType/>
                        </>
                        }
                        <button onClick={()=>setExpand(prev=>!prev)} className="block w--165 margin-t-20 mx--auto bg-primary hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 rounded">{expand?'Collapse':'Expand'}</button>
                
              </div>
            </div>
  )
}

export default Relative