import React from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
const Modal = (props) => {
  if (!props.show) {
    return null
  }
  const { address, owner1, owner2, data } = props

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='manipulator-close'
        onClick={props.onClose}>
        ×
      </button>
      <WrapperContent>
        <WrapperHeader className='manipulator-phone-modal'>
          <h1 className='popupModalHeader'>Batch Dialer</h1>
          <h3 className='popupModalHeader'>
            Mailing Address:
            <span className='mailing_address'> {address}</span>
          </h3>
          <h3 className='popupModalHeader'>
            Owner 1: <span className='ownerName'>{owner1}</span>
          </h3>
          <h3 className='popupModalHeader'>
            Owner 2: <span className='ownerName'>{owner2}</span>
          </h3>
          <WrapperBody>
            <table className='popupTablelisting'>
              <tr className='heading'>
                <th>Customer Name</th>
                <th>Customer Number</th>
                <th>Campaign Name</th>
                <th>Call From</th>
                <th>Duration</th>
                <th>Date Time</th>
                <th>Note</th>
                <th>Status</th>
              </tr>
              <React.Fragment>
                <tr>
                  <td>{data.customer_name}</td>
                  <td>{data.customer_number}</td>
                  <td>{data.campaign_name}</td>
                  <td>{data.call_from}</td>
                  <td>{data.duration}</td>
                  <td>{data.date_time}</td>
                  <td>{data.note}</td>
                  <td>{data.status}</td>
                </tr>
              </React.Fragment>
            </table>
          </WrapperBody>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 968px;
  min-height: 200px;
  left: 280px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 9999;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 968px;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px 0px;
  border: #d1d1d1 solid 1px;
`

const WrapperBody = styled.div`
  padding: 5px;
  height: 325px;
  overflow-y: auto;
  border-radius: 10px;
  margin: 1% 2%;
`
