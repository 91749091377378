import React, { useEffect, useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import { getCustomFieldData } from "services/dataValidation"
import styled from "styled-components"
import RecordItem from "./RecordItem"
import COLORS from "utils/colors"
import tw from "twin.macro"

const Modal = ({ fileId, setisLoading, setCustomFields, ...props }) => {
  const {
    address,
    owner1,
    owner2,
    setConfirm,
    deleteCustomField,
    setAddFieldModal,
    setShowModalCustomFields,
  } = props
  const [response, setResponse] = useState([])

  const requestCustomData = async () => {
    setisLoading(true)
    const result = await getCustomFieldData({
      file_id: fileId,
      mailing_address: address,
    })
    if (result?.status) {
      setisLoading(false)
      setCustomFields(result?.data)
      setResponse(result?.data)
    } else {
      setisLoading(false)
    }
  }

  useEffect(() => {
    requestCustomData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='manipulator-close close-btn1'
        onClick={props.onClose}>
        x
      </button>
      <WrapperContent>
        <WrapperHeader className='manipulator-phone-modal'>
          <div className='flex heading-title'>
            <h1 className='popupModalHeader'>
              <span className='float-left'>{/* {`0 Total Records`} */}</span>
              Custom Fields
              {/* <span className="float-right">0</span> */}
            </h1>
            {/* <div className="w-8% mr-20 font-bold">({Data?.filtered_out_of_total})</div> */}
          </div>
          {/* <div className='d-flex'> */}
          <div className='flex justify-end h-11'>
            <MainButton className='blue_btn'>
              <Label>
                Add Field
                <Upload
                  id='file-selecter'
                  type='button'
                  size='80'
                  onClick={(e) => {
                    setAddFieldModal(true)
                    setShowModalCustomFields(false)
                  }}
                />
              </Label>
            </MainButton>
          </div>
          <div style={{ width: "100%" }}>
            <h3 className='popupModalHeader'>
              Mailing Address:
              <span className='mailing_address'> {address}</span>
            </h3>
            <h3 className='popupModalHeader'>
              Owner 1: <span className='ownerName'>{owner1}</span>
            </h3>
            <h3 className='popupModalHeader'>
              Owner 2: <span className='ownerName'>{owner2}</span>
            </h3>
          </div>

          {/* </div> */}

          <WrapperBody>
            <table className='popupTablelisting popupTablelisting-cus'>
              <tr className='heading'>
                <th width='30%'>Custom Name</th>
                <th>Custom Value</th>
                <th width='30%'>Action</th>
              </tr>
              {response.map(
                ({ id, custom_field_name, custom_field_value }, index) => (
                  <>
                    <RecordItem
                      onClose={props.onClose}
                      deleteCustomField={deleteCustomField}
                      setConfirm={setConfirm}
                      index={index}
                      id={id}
                      customFieldName={custom_field_name}
                      customFieldValue={custom_field_value}
                    />
                  </>
                )
              )}
            </table>
          </WrapperBody>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 968px;
  min-height: 200px;
  left: 280px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 9999;
  top: 10px;
  max-height: 76vh;

  border-radius: 0;
  width: calc(98% - 245px);
  left: 260px;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0px 0px 20px 0px;
  border: #d1d1d1 solid 1px;
  overflow-y: hidden;
  border-radius: 20px;
`

const WrapperBody = styled.div`
  padding: 5px;
  max-height: 57vh;
  overflow: auto;
  border-radius: 10px;
  margin: 1% 2%;
`
const MainButton = styled.div`
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height: 35px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  ${tw`
    shadow-lg
  `}
`
const Label = styled.label`
  font-size: 12px;
  display: table;
  cursor: pointer;
  color: #fff;
  font-family: "Montserrat";
  text-align: center;
  font-weight: bold;
`

const Upload = styled.input`
  display: none;
  cursor: pointer;
`
