import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import styled from "styled-components"
import tw from "twin.macro"
import FileIcon from "assets/images/file.png"
import Modal from "components/lead-modal"
import Loading from "components/loading"
import COLORS from "utils/colors"
import ConfirmModal from "components/confirm-modal"

import {
  deleteManipulatorItem,
  uploadManipulatorFile,
} from "services/dataValidation"
import ProgressBar from "components/progressbar"

const Item = (props) => {
  const [isLoading, setisLoading] = useState(false)

  const [show, setShow] = useState(0)
  let history = useHistory()

  function deleteItem(id) {
    deleteManipulatorItem({
      action: "delete-file",
      file_id: id,
    })
      .then(function (response) {
        props.getPageData(1, 10)
        closeConfirmModal()
        // window.location.reload()
      })
      .catch((error) => {
        console.error("error", error)
      })
  }

  function downloads(id) {
    setShow(true)
    const listFiled = document.querySelectorAll("#fileIds")
    for (let i = 0; i < listFiled.length - 1; i++) {
      listFiled[i].value = id
    }
  }

  function uploadFile(uploadID) {
    document.querySelector(`#${uploadID}`).click()
  }
  const onFileChange = (ID) => {
    setisLoading(true)
    const uploadID = "ID_" + ID
    var input = document.getElementById(uploadID)
    var file = input.files[0]
    var fileName = file.name
    const reader = new FileReader()

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        var csv_file = reader.result

        uploadManipulatorFile({
          file_id: ID,
          csv_file: csv_file,
          file_name: fileName,
          unique_column_match: "mailing_address",
          update_behavior: "not_blank",
          action: "update",
        })
          .then(function (response) {
            setisLoading(false)
            const fields = response.fileFelds
            let optionsSelect = []
            for (let i = 0; i < fields.length; i++) {
              optionsSelect.push({ value: fields[i], label: fields[i] })
            }
            var data = JSON.stringify(response)
            localStorage.setItem("mappingData", data)
            localStorage.setItem("fileName", fileName)

            localStorage.setItem("mappingFields", JSON.stringify(optionsSelect))
            history.push("/mapping-fields")
          })
          .catch((error) => {
            console.error("error", error)
          })
      },
      false
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  const tooltipID = "tooltip_ID_" + props.id
  const uploadID = "ID_" + props.id
  const HistoryUrl = "/data-manipulator-history/" + props.id
  const ContactsUrl = "/data-manipulator-contacts/" + props.id
  const [confirm, setConfirm] = useState(false)
  const closeConfirmModal = () => {
    setConfirm(false)
  }
  const handleConfirm = () => {
    deleteItem(props.id)
  }

  return (
    <div>
      <div>
        {/* <input type="checkbox" onChange={checkAllCheckbox} />Select All */}
      </div>

      <Wrapper>
        <Loading show={isLoading} />
        <Modal
          onClose={() => {
            setShow(false)
          }}
          show={show}
        />

        <ItemWrapper>
          {/* <Checkbox type='checkbox' name="slectedCheckBox[]"  onChange={checkboxes} /> */}

          <InnerWrapperIcon>
            <BookIcon src={FileIcon} width='46px' height='46px' />
          </InnerWrapperIcon>
          <Heading>
            <div className='flex'>
              <ParaTwo
                data-tip
                data-for={tooltipID}
                className='itemHeading downloads fineName'>
                {props.name}
                <Para>{props.created_at}</Para>
              </ParaTwo>
              <div className='cursor-pointer'>
                <ProgressBar percentage={props.percentage} />
              </div>
            </div>

            <ReactTooltip id={tooltipID} aria-haspopup='true' role='example'>
              {props.name}
            </ReactTooltip>
            <Para>{props.size}</Para>
          </Heading>

          <DownloadIconWrapper>
            {/* <Link to="/setting">
             <img src="/images/download.jpeg" className="setting-icon"/>
            </Link> */}
            <svg
              width='210'
              height='34'
              viewBox='0 0 210 34'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <a
                // href='javascript:void(0);'
                onClick={(e) => downloads(props.id)}
                className='icons'>
                <g opacity='0.5'>
                  <circle
                    opacity='0.2'
                    cx='61'
                    cy='17'
                    r='16.5'
                    fill='#047CFF'
                    stroke='#047CFF'
                  />
                </g>
              </a>
              <path
                d='M60.9995 19.5C60.8458 19.5 60.6988 19.437 60.593 19.326L56.6555 15.201C56.3143 14.844 56.5678 14.25 57.062 14.25H59.1245V9.9375C59.1245 9.42075 59.5453 9 60.062 9H61.937C62.4538 9 62.8745 9.42075 62.8745 9.9375V14.25H64.937C65.4313 14.25 65.6848 14.844 65.3435 15.201L61.406 19.326C61.3003 19.437 61.1533 19.5 60.9995 19.5Z'
                fill='#047CFF'
              />
              <path
                d='M68.6875 24H53.3125C52.5888 24 52 23.4112 52 22.6875V22.3125C52 21.5888 52.5888 21 53.3125 21H68.6875C69.4112 21 70 21.5888 70 22.3125V22.6875C70 23.4112 69.4112 24 68.6875 24Z'
                fill='#047CFF'
              />
              <Link to={ContactsUrl} className='icons'>
                <g opacity='0.5'>
                  <circle
                    opacity='0.2'
                    cx='105'
                    cy='17'
                    r='16.5'
                    fill='#047CFF'
                    stroke='#047CFF'
                  />
                </g>
              </Link>
              <a
                onClick={(e) => uploadFile(uploadID)}
                // href='javascript:void(0);'
                className='icons'>
                <g opacity='0.5'>
                  <circle
                    opacity='0.2'
                    cx='17'
                    cy='17'
                    r='16.5'
                    fill='#047CFF'
                    stroke='#047CFF'
                  />
                </g>
              </a>
              <a
                // href='javascript:void(0);'
                className='icons'>
                <path
                  d='M20.669 10.7479L18.2044 8.29325C17.8141 7.90255 17.181 7.90221 16.7904 8.29247C16.7901 8.29272 16.7898 8.293 16.7896 8.29325L14.2899 10.7929C13.9072 11.1909 13.9195 11.8239 14.3175 12.2066C14.3497 12.2376 14.3839 12.2663 14.4199 12.2927C14.8333 12.5781 15.392 12.5254 15.7447 12.1677L15.8597 12.0527C15.9629 11.9494 16.0505 11.8315 16.1197 11.7028L16.4996 10.9979V17.9419C16.4798 18.4496 16.8314 18.8965 17.3295 18.9968C17.8737 19.0907 18.3909 18.7257 18.4849 18.1816C18.4954 18.1206 18.5002 18.0588 18.4993 17.9969V10.9979C18.6282 11.2811 18.7734 11.5565 18.9343 11.8228C19.0331 11.9693 19.1546 12.0993 19.2942 12.2077C19.6846 12.5983 20.3176 12.5986 20.7083 12.2082C20.7277 12.1888 20.7463 12.1687 20.764 12.1477C21.0993 11.7268 21.0581 11.1196 20.669 10.7479Z'
                  fill='#047CFF'
                />

                <path
                  d='M24.999 22.4964V16.4972C24.999 16.4972 24.999 16.4572 24.999 16.4372C24.904 14.9374 24.0341 14.0675 22.4993 14.0026H20.0397C19.4875 14.0026 19.0398 14.4502 19.0398 15.0024C19.0398 15.5546 19.4875 16.0023 20.0397 16.0023H22.4543C22.8793 16.0023 22.9543 16.0623 22.9993 16.5372V22.4514C22.9993 22.8313 22.9043 22.9513 22.4643 22.9963H12.5357C12.0708 22.9613 12.0358 22.8513 12.0008 22.4964V16.5422C12.0008 15.9973 12.1708 15.9973 12.5007 15.9973H15.0004C15.5526 15.9973 16.0002 15.5496 16.0002 14.9974C16.0002 14.4452 15.5526 13.9976 15.0004 13.9976H12.5007C11.0009 13.9976 10.066 14.9374 10.0011 16.4972V22.5364C9.96216 23.8556 11 24.9565 12.3193 24.9954C12.3581 24.9966 12.3969 24.9968 12.4357 24.996H22.5643C23.9062 24.9994 24.9967 23.9143 25 22.5724C25.0001 22.5471 24.9997 22.5217 24.999 22.4964Z'
                  fill='#047CFF'
                />
              </a>
              <Link to={ContactsUrl} className='icons'>
                <g clipPath='url(#clip0)'>
                  <path
                    d='M110.956 12.8872L107.207 9.13734C107.12 9.05081 107 9 106.875 9H100.312C99.5371 9 98.9062 9.63084 98.9062 10.4062V23.5938C98.9062 24.3692 99.5371 25 100.312 25H109.688C110.463 25 111.094 24.3692 111.094 23.5938V13.2188C111.094 13.0912 111.04 12.9703 110.956 12.8872ZM107.344 10.6004L109.493 12.75H107.812C107.554 12.75 107.344 12.5397 107.344 12.2812V10.6004ZM109.688 24.0625H100.312C100.054 24.0625 99.8438 23.8522 99.8438 23.5938V10.4062C99.8438 10.1478 100.054 9.9375 100.312 9.9375H106.406V12.2812C106.406 13.0567 107.037 13.6875 107.812 13.6875H110.156V23.5938C110.156 23.8522 109.946 24.0625 109.688 24.0625Z'
                    fill='#047CFF'
                  />
                  <path
                    d='M107.812 15.625H102.188C101.929 15.625 101.719 15.8349 101.719 16.0938V21.7188C101.719 21.9776 101.929 22.1875 102.188 22.1875H107.812C108.071 22.1875 108.281 21.9776 108.281 21.7188V16.0938C108.281 15.8349 108.071 15.625 107.812 15.625ZM102.656 18.4375H104.531V19.375H102.656V18.4375ZM105.469 18.4375H107.344V19.375H105.469V18.4375ZM107.344 17.5H105.469V16.5625H107.344V17.5ZM104.531 16.5625V17.5H102.656V16.5625H104.531ZM102.656 20.3125H104.531V21.25H102.656V20.3125ZM105.469 21.25V20.3125H107.344V21.25H105.469Z'
                    fill='#047CFF'
                  />
                </g>
              </Link>
              <Link to={HistoryUrl} className='icons'>
                <g opacity='0.5'>
                  <circle
                    opacity='0.2'
                    cx='149'
                    cy='17'
                    r='16.5'
                    fill='#047CFF'
                    stroke='#047CFF'
                  />
                </g>{" "}
              </Link>
              <path
                d='M149.254 13.2065V17.4129L152.855 19.5497L153.46 18.5276L150.516 16.7819V13.2065H149.254Z'
                fill='#047CFF'
              />
              <path
                d='M150.091 9C145.906 9 142.524 12.3903 142.524 16.5714H140L143.277 19.8482L143.336 19.9701L146.73 16.5714H144.206C144.206 13.3199 146.844 10.6826 150.095 10.6826C153.347 10.6826 155.984 13.3199 155.984 16.5714C155.984 19.8229 153.347 22.4603 150.095 22.4603C148.467 22.4603 146.999 21.7957 145.935 20.7315L144.745 21.9219C146.112 23.2932 148 24.1429 150.091 24.1429C154.276 24.1429 157.667 20.7526 157.667 16.5714C157.667 12.3903 154.276 9 150.091 9Z'
                fill='#047CFF'
              />
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                // eslint-disable-next-line no-script-url
                // href='javascript:void(0);'
                className='icons'
                onClick={() => setConfirm(true)}>
                <g opacity='0.5'>
                  <circle
                    opacity='0.2'
                    cx='193'
                    cy='17'
                    r='16.5'
                    fill='#FF000F'
                    stroke='#FF000F'
                  />
                </g>{" "}
              </a>
              <path
                d='M196.176 13.8677L194.844 13.8188L194.555 21.7065L195.887 21.7552L196.176 13.8677Z'
                fill='#E44C4C'
              />
              <path
                d='M193.599 13.8433H192.266V21.731H193.599V13.8433Z'
                fill='#E44C4C'
              />
              <path
                d='M191.31 21.7066L191.021 13.8188L189.688 13.8677L189.977 21.7554L191.31 21.7066Z'
                fill='#E44C4C'
              />
              <path
                d='M185 10.5552V11.8883H186.389L187.491 24.4116C187.521 24.7557 187.81 25.0197 188.155 25.0197H197.687C198.032 25.0197 198.321 24.7555 198.351 24.4113L199.453 11.8883H200.864V10.5552H185ZM197.076 23.6866H188.765L187.727 11.8883H198.114L197.076 23.6866Z'
                fill='#E44C4C'
              />
              <path
                d='M195.109 8H190.754C190.142 8 189.644 8.49836 189.644 9.11093V11.2217H190.977V9.33312H194.887V11.2217H196.22V9.11093C196.22 8.49836 195.722 8 195.109 8Z'
                fill='#E44C4C'
              />
              <defs>
                <clipPath id='clip0'>
                  <rect
                    width='16'
                    height='16'
                    fill='white'
                    transform='translate(97 9)'
                  />
                </clipPath>
              </defs>
            </svg>

            <input
              type='file'
              id={uploadID}
              name='myfile'
              className='data-manipulator-upload-action'
              onChange={(e) => onFileChange(props.id)}
            />
          </DownloadIconWrapper>
        </ItemWrapper>
      </Wrapper>
      <ConfirmModal
        showModal={confirm}
        handleClose={() => closeConfirmModal()}
        handleConfirm={handleConfirm}
        title={`Delete file`}
        message={`Are you sure you want to delete this File?`}
      />
    </div>
  )
}

export default Item

const BookIcon = styled.img`
  cursor: pointer;
  padding-top: 9px;
`
const Wrapper = styled.div`
  z-index: 0;
  display: flex;
  height: 80px;
  background-color: ${COLORS.white};
  border-radius: 19px;
  margin: auto;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 10px;
  ${tw`
    shadow-lg
  `}
`

const ItemWrapper = styled.div`
  position: relative;
  top: 10%;
  display: flex;
  width: 100%;
  justify-content: space-around;
`

const InnerWrapperIcon = styled.span`
  display: inline-block;
  font-size: 10px;
  margin-left: -20px;
  margin-right: 30px;
`

const DownloadIconWrapper = styled.span`
  margin-top: 10px;
  display: flex;
`

const Heading = styled.label`
  margin-top: 0px;
  color: ${COLORS.username};
  font-family: Montserrat;
  overflow: hidden;
  text-overflow: ellipse;
  white-space: nowrap;
  font-size: 13px;
  margin-left: -80px;
  width: 620px;
`

const Para = styled.p`
  color: ${COLORS.createdat};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`

const ParaTwo = styled.span`
  color: ${COLORS.username};
  font-family: Montserrat;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  width: 100%;
  word-break: break-all;
`
