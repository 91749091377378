import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Notification from "components/download-notification";
import {
  clearAllnotification,
  getUserNotifications,
} from "services/dataValidation";
import COLORS from "utils/colors";
import { getUserID } from "utils/helper";
import FavIcon from "components/favicon";
import io from "socket.io-client";
import { SOCKET_URL } from "config";

const Profile = (props) => {
  // setTimeout(function () {
  //   getNoticationFun();
  // }, 5000);
  const [notificationCount, setNotificationCount] = useState([]);
  const [notificationmsg, setNotificationMsgt] = useState([]);
  const [notifShow, setnotifShow] = useState(false);

  let history = useHistory();
  let displayNameobj = JSON.parse(localStorage.getItem("userData"));
  let displayName =
    displayNameobj?.first_name + " " + displayNameobj?.last_name;
  let show = true;
  const user = getUserID();
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.notifData !== undefined) {
        setnotifShow(true);
        setNotificationMsgt(window.notifData);
        setNotificationCount(window.notifData.length);
        console.log(window.notifData);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const socket = io.connect(SOCKET_URL);
    let UserId = getUserID();
    socket.on("connect", function () {
      socket.emit("join_user_room", UserId);
    });
    socket.on("notification", (data) => {
      let tmpData = [];
      tmpData = [...notificationmsg];
      let found = false;
      for (let i = 0; i < tmpData.length; i++) {
        if (tmpData["id"] === data.data["id"]) {
          found = true;
        }
      }
      if (found === false) {
        tmpData.push(data.data);
        window.notifData = tmpData;
      }

      // if (notificationmsg.length != 0) {
      //   let tmpData = data.data;
      //   console.log(tmpData);
      //   setNotificationMsgt((notificationmsg) => [...notificationmsg, tmpData]);
      // } else {
      //   let tmpData = data.data;
      //   console.log(tmpData);
      //   setNotificationMsgt(tmpData);
      // }
    });
  }, [notificationmsg]);

  const getNoticationFun = useCallback(() => {
    getUserNotifications({
      user_id: user,
    })
      .then(function (response) {
        setNotificationCount(response.total_unread_notification);
        setNotificationMsgt(response.data);
        if (response.data.length > 0) {
          setnotifShow(true);
          setNotificationCount(response.data.length);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [user]);

  useEffect(() => {
    getNoticationFun();
  }, [getNoticationFun]);
  function onLogout() {
    localStorage.clear();
    history.push("/");
    window.location.reload();
  }
  // const myNotification = [];
  // let NotificationClass = "";
  // if (notificationmsg != null) {
  //   for (let i = 0; i < notificationmsg.length; i++) {
  //     if (i === 0) {
  //       NotificationClass = "even";
  //     } else if (i % 2 === 0) {
  //       NotificationClass = "even";
  //     } else {
  //       NotificationClass = "odd";
  //     }
  //     console.log("test");
  //     myNotification.push(
  //       <Notification
  //         key={i}
  //         id={i}
  //         data={notificationmsg[i]}
  //         NotificationClass={NotificationClass}
  //       />
  //     );
  //   }
  // }

  var alertIcon;
  if (notificationCount > 0) {
    alertIcon = <div className="alertIcon">{notificationCount}</div>;
  } else {
    alertIcon = "";
    show = false;
  }

  const clearALL = (e) => {
    clearAllnotification({
      action: "clear-notification",
    })
      .then(function (response) {
        if (response.status === true) {
          toast.success(response.message);
          setNotificationCount(0);
          setNotificationMsgt([]);
          setnotifShow(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <Wrapper>
      <InnerWrapper>
        <Figer>
          <figure tabIndex="-1" className="notificationBell">
            <FaIcon>
              <FavIcon class="fas fa-bell" />
              {alertIcon}
            </FaIcon>

            <HiddenDiv className="menu notification">
              {/* <notificationBtn> */}
              <p className="noticationHead">
                Notifications{" "}
                {notifShow && (
                  <button className="clear_notification" onClick={clearALL}>
                    Clear All
                  </button>
                )}
              </p>
              {notifShow && (
                <div className="notification-container">
                  {notificationmsg.map((name, key) => (
                    <Notification
                      key={name["id"]}
                      id={name["id"]}
                      data={name}
                      NotificationClass={"even"}
                    />
                  ))}
                </div>
              )}
              {notifShow && (
                <div className="NotificationViewAll">
                  <Link to="/downloads"> All Downloads</Link>
                </div>
              )}
              {/* </notificationBtn> */}
            </HiddenDiv>
          </figure>
        </Figer>
        <Figer>
          <figure tabIndex="-1">
            <Icon src={displayNameobj.profile_picture} className="ProfileImg" />
            <HiddenDiv className="menu profile_section">
              <Link to="/profile" className="edit_profile_btn ProfileImg">
                Edit Profile
              </Link>
              <Btn>
                <button
                  onClick={onLogout}
                  className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 mb-2 rounded"
                >
                  LOGOUT
                </button>
              </Btn>
            </HiddenDiv>
          </figure>
        </Figer>

        <Figer>
          <figure tabIndex="-1">
            <Para className="userName">{displayName}</Para>
            <HiddenDiv className="menu profile_section">
              <Link to="/profile" className="edit_profile_btn ProfileImg">
                Edit Profile
              </Link>
              <Btn>
                <button
                  onClick={onLogout}
                  className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  LOGOUT
                </button>
              </Btn>
            </HiddenDiv>
          </figure>
        </Figer>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Profile;

const Wrapper = styled.div``;

const Btn = styled.div`
  margin: auto;
  padding-top: 15px;
  padding: 0px 20px;
`;
const Figer = styled.figure`
  z-index: 1;
  margin-top: 20px;
  figure:active .menu,
  figure:focus .menu {
    visibility: visible;
  }
`;
const HiddenDiv = styled.div`
  visibility: Hidden;
  width: 300px;
  position: absolute;
  right: 20px;
  background-color: rgb(252, 248, 248);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 20px;
`;
const InnerWrapper = styled.div`
  display: flex;
  z-index: 2;
  position: relative;
`;
const FaIcon = styled.span`
  padding-top: 30px;
  color: ${COLORS.grey};
  font-size: 24px;
  margin-right: 35px;
  margin-top: 30px;
`;

const Icon = styled.img`
  width: 53px;
  height: 53px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -2px;
`;
const Para = styled.p`
  color: #3c3c3c;
  font-family: Roboto;
  font-weight: normal;
  margin-left: 10px;
  padding-top: 10px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  min-width: 60.95px;
`;
