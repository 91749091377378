import React, { useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import Prev from "assets/images/prev-arrow.svg"
import Next from "assets/images/next-arrow.svg"
import Select from "react-select"
import EditFieldsModal from "components/edit-customfield-modal"
import AddFieldsModal from "components/add-customfield-modal"

const Modal = ({
  fileId,
  setisLoading,
  setCustomFields,
  setLoading,
  setConfirm,
  customFields,
  recordUniqueId,
  addressPopup,
  updateCount,
  setRecordUniqueId,
  addreses,
  data,
  ...props
}) => {
  const { index, totalRecords, totalfiles } = props
  const Data = data ? data : []
  const headers = Data?.custom_fields_headers || []
  const [editModal, setEditModal] = useState(false)
  const [addFieldModal, setAddFieldModal] = useState(false)

  let NewData = Data.data ? Data.data : []
  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base
    },
  }

  if (!props.show) {
    return null
  }

  return (
    <>
      <WrapperModal>
        <button
          _ngcontent-hex-c78=''
          type='button'
          data-dismiss='modal'
          className='manipulator-close close-btn1'
          onClick={props.onClose}>
          ×
        </button>
        <WrapperContent>
          <WrapperHeader className='manipulator-phone-modal'>
            <div className='heading-title flex'>
              <h1 className='popupModalHeader'>
                <span className='float-left'>{`(${index} - ${totalRecords} Total Records)`}</span>
                Property Addresses
                <span className='float-right'>
                  {Data?.filtered_out_of_total
                    ? `(${Data?.filtered_out_of_total})`
                    : null}
                </span>
              </h1>
            </div>
            <div className='flex justify-end'>
              <button
                className='custom-btn  mr-4'
                onClick={() => {
                  setAddFieldModal(true)
                }}>
                Add Custom Field
              </button>
              <button
                className='custom-btn mr-4'
                onClick={() => setEditModal(true)}>
                Edit Custom Field
              </button>
            </div>
            <h3 className='popupModalHeader'>
              Mailing Address: {Data.mailing_address}
            </h3>
            <WrapperBody>
              {index - 1 ? (
                <img
                  alt=''
                  onClick={() =>
                    addressPopup(
                      totalfiles[index - 2],
                      index - 1,
                      Data?.previous
                    )
                  }
                  src={Prev}
                  className='w-20 transform-y-center left-8 cursor-pointer'
                />
              ) : null}

              {totalfiles[index] ? (
                <img
                  alt=''
                  onClick={() =>
                    addressPopup(totalfiles[index], index + 1, Data?.next)
                  }
                  src={Next}
                  className='w-20 transform-y-center right-8 cursor-pointer'
                />
              ) : null}

              <table className='popupTablelisting phone'>
                <tr className='heading'>
                  <th className='frist'>Row Id</th>
                  <th>Owner 1</th>
                  <th>Owner 2</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zip</th>
                  <th>Tags</th>
                  {headers.length > 0 &&
                    headers.map((item, index) => <th>{item}</th>)}
                </tr>
                {NewData.length > 0 &&
                  NewData.map((phoneData, index) => (
                    <React.Fragment>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{Data.owner_1}</td>
                        <td className='Owner1_adddress'>{Data.owner_2}</td>
                        <td>{phoneData.address}</td>
                        <td>{phoneData.city}</td>
                        <td>{phoneData.state}</td>
                        <td>{phoneData.zip}</td>
                        <td className='tag'>
                          <Select
                            name='connected'
                            placeholder={<div></div>}
                            options={
                              phoneData.property_tag
                                ? JSON.parse(phoneData.property_tag).map(
                                    (item) => ({
                                      label: item,
                                      value: item,
                                      isFixed: true,
                                    })
                                  )
                                : []
                            }
                            className='active input-border'
                            classNamePrefix='select'
                            styles={styles}
                            isClearable={
                              phoneData.property_tag
                                ? JSON.parse(phoneData.property_tag).some(
                                    (item) => false
                                  )
                                : []
                            }
                            isMulti
                            isDisabled={true}
                            value={
                              phoneData.property_tag
                                ? JSON.parse(phoneData.property_tag).map(
                                    (item) => ({
                                      label: item,
                                      value: item,
                                      isFixed: true,
                                    })
                                  )
                                : []
                            }
                          />
                        </td>

                        <>
                          {headers.map((item, index) => (
                            <td>{phoneData?.custom_fiels_values[item]}</td>
                          ))}
                        </>
                      </tr>{" "}
                    </React.Fragment>
                  ))}
              </table>
              {NewData.length === 0 && (
                <div className='mt-20 text-center font-bold'>
                  No Record Found
                </div>
              )}
            </WrapperBody>
          </WrapperHeader>
        </WrapperContent>
      </WrapperModal>
      {addFieldModal && (
        <AddFieldsModal
          onClose={() => {
            setAddFieldModal(false)
            // setAShow(true)
          }}
          data={Data?.data}
          setisLoading={setisLoading}
          address={addreses}
          fileId={fileId}
          AddressPopup={addressPopup}
          recordUniqueId={recordUniqueId}
          updateCount={updateCount}
        />
      )}
      {editModal && (
        <EditFieldsModal
          data={Data}
          onClose={() => {
            setEditModal(false)
            // setAShow(false)
          }}
          mailingAddress={addreses}
          addressPopup={addressPopup}
        />
      )}
    </>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 968px;
  min-height: 200px;
  left: 280px;
  top: 40px;
  z-index: 10;
  display: flex;

  top: 10px;
  max-height: 76vh;
  border-radius: 20px;
  width: calc(98% - 245px);
  left: 260px;
`

const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 100%;
  background-color: #ffffff;
  overflow-y: hidden;
  padding: 0px 0px 20px 0px;
  border-radius: 20px;
  border: #d1d1d1 solid 1px;
`

const WrapperBody = styled.div`
  padding: 5px;
  max-height: 325px;
  overflow-y: auto;
  border-radius: 10px;
  margin: 1% 2%;
`
