import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import TopNav from "components/navbar/topNav"
import styled from "styled-components"
import Loading from "components/loading"
import "assets/css/style.css"
import Reportgrid from "./reportgrid"
import { useHistory } from "react-router-dom"
import { dataManipulatorUploadSummary} from "services/dataValidation"

const Report = () => {
const mappingFields = JSON.parse(localStorage.getItem("mappingData"))
const [reportData,setReportData] = useState({})

const uploadSummaryDetails=()=>{

 dataManipulatorUploadSummary({file_id: mappingFields.filelist_id})  .then(function (response) {
     setReportData(response?.reporLog)
      })
      .catch((error) => {
    
      })
  }

  useEffect(() => {
  
  uploadSummaryDetails()
    
  }, [])
  

  const [isLoading] = useState(false)
  
  let history = useHistory()

  const closeReport = () => {
    if (localStorage.getItem("mergeCsv") != null) {
      history.push(
        "/data-manipulator-contacts/" + localStorage.getItem("mergeCsv")
      )
      localStorage.removeItem("mergeCsv")
    } else {
      history.push("/data-manipulator")
    }
  }

  const backBtn = () => {
    history.push("/mapping-fields")
  }

  let reportBox = []
  // for (let key in reportData) {
  //   if(key!='times_called')
  //   reportBox.push(
  //     <Reportgrid data={reportData[key]} data_key={key}></Reportgrid>
  //   )
  // }

  reportBox.push(
      <Reportgrid data={reportData['owner1fname']} data_key={'owner1fname'}></Reportgrid>,
      // <Reportgrid data={reportData['owner1lname']} data_key={'owner1lname'}></Reportgrid>,
      <Reportgrid data={reportData['owner2fname']} data_key={'owner2fname'}></Reportgrid>,
      // <Reportgrid data={reportData['owner2lname']} data_key={'owner2lname'}></Reportgrid>,
      <Reportgrid data={reportData['phone']} data_key={'phone'}></Reportgrid>,
      <Reportgrid data={reportData['mailing_address']} data_key={'mailing_address'}></Reportgrid>,
      <Reportgrid data={reportData['addresses']} data_key={'addresses'}></Reportgrid>,
      <Reportgrid data={{New: "0", Updated: "0", Skipped: "0", Blank_cell: "0", Error: "0"}} data_key={'tags'}></Reportgrid>,
      <Reportgrid data={reportData['emails']} data_key={'emails'}></Reportgrid>,
      <Reportgrid data={{New: "0", Updated: "0", Skipped: "0", Blank_cell: "0", Error: "0"}} data_key={'customFields'}></Reportgrid>
    )

  const FileName = localStorage.getItem("fileName")
  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNav />
      
      <div className='row mapping_fields'>
        <HeadingFiles>{FileName}</HeadingFiles>

        <svg
          width='1016'
          height='1'
          viewBox='0 0 1016 1'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <line opacity='0.05' y1='0.5' x2='1016' y2='0.5' stroke='black' />
        </svg>
      </div>

      <div className='row mapping_fields top'>
        <div className='col-md-3'>
          <div className='row left_side'>
            <div className='col-md-8'>
              <Link to='/data-manipulator'>
                <span className='icon_heading'> Upload File </span>
              </Link>
              <Link to='/mapping-fields'>
                {" "}
                <span className='icon_heading'> Mapping Fields </span>
              </Link>
              <Link to='/report'>
                <span className='icon_heading'> Report </span>
              </Link>
            </div>{" "}
            <div className='col-md-4'>
              <div className='main_icons'>
                <span className='left_icons'>
                  <svg
                    width='23'
                    height='23'
                    viewBox='0 0 28 23'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M13.764 6.94118C13.5288 6.94118 13.304 7.03753 13.1423 7.20729L7.12022 13.5161C6.59831 14.0621 6.98602 14.9706 7.74193 14.9706H10.8963V21.5662C10.8963 22.3565 11.5398 23 12.3302 23H15.1978C15.9881 23 16.6316 22.3565 16.6316 21.5662V14.9706H19.786C20.542 14.9706 20.9297 14.0621 20.4078 13.5161L14.3857 7.20729C14.224 7.03753 13.9991 6.94118 13.764 6.94118Z'
                      fill='#A0A0A0'
                    />
                    <path
                      d='M25.5221 0.0587373H2.00735C0.900441 0.0587373 0 0.959178 0 2.06609V2.63962C0 3.74653 0.900441 4.64697 2.00735 4.64697H25.5221C26.629 4.64697 27.5294 3.74653 27.5294 2.63962V2.06609C27.5294 0.959178 26.629 0.0587373 25.5221 0.0587373Z'
                      fill='#A0A0A0'
                    />
                  </svg>
                </span>
              </div>
              <div className='icon_border' />
              <div className='main_icons'>
                <span className='left_icons'>
                  <svg
                    width='24'
                    height='18'
                    viewBox='0 0 24 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M21.002 0.749023H6.00728C5.17915 0.749023 4.50781 1.42037 4.50781 2.2485C4.50781 3.07663 5.17915 3.74797 6.00728 3.74797H21.002C21.8301 3.74797 22.5015 3.07663 22.5015 2.2485C22.5015 1.42037 21.8301 0.749023 21.002 0.749023Z'
                      fill='#A0A0A0'
                    />
                    <path
                      d='M3.00734 2.24853C3.00566 1.8515 2.84657 1.47133 2.565 1.1914C1.98016 0.610028 1.03559 0.610028 0.450744 1.1914C0.169124 1.47133 0.0100394 1.8515 0.00839939 2.24853C-0.0027998 2.34567 -0.0027998 2.44379 0.00839939 2.54093C0.0253622 2.63863 0.0530087 2.73417 0.0908704 2.82583C0.131122 2.91462 0.178777 2.99991 0.23332 3.08074C0.287161 3.1648 0.349998 3.24273 0.420754 3.31316C0.48898 3.3811 0.564376 3.44145 0.645675 3.49309C0.724632 3.55059 0.810242 3.59843 0.900586 3.63554C0.999973 3.68709 1.10592 3.72495 1.21547 3.748C1.31261 3.75887 1.41073 3.75887 1.50787 3.748C1.90383 3.74833 2.28385 3.59201 2.565 3.31316C2.63576 3.24273 2.69859 3.1648 2.75243 3.08074C2.80698 2.99991 2.85463 2.91462 2.89488 2.82583C2.94273 2.7353 2.98045 2.63975 3.00734 2.54093C3.01854 2.44379 3.01854 2.34567 3.00734 2.24853Z'
                      fill='#A0A0A0'
                    />
                    <path
                      d='M3.00676 8.99609C3.01791 8.89895 3.01791 8.80083 3.00676 8.70369C2.98084 8.60693 2.94308 8.51377 2.8943 8.42629C2.85569 8.33431 2.80794 8.2464 2.75185 8.16388C2.69988 8.08075 2.63681 8.00508 2.56441 7.93896C1.97957 7.35759 1.035 7.35759 0.450157 7.93896C0.168537 8.21889 0.00945255 8.59906 0.0078125 8.99609C0.0107177 9.19369 0.0488137 9.38914 0.120273 9.57339C0.157853 9.66209 0.203025 9.74737 0.255225 9.8283C0.312252 9.90978 0.377573 9.98518 0.450157 10.0532C0.516415 10.1255 0.592044 10.1885 0.675078 10.2407C0.754034 10.2982 0.839598 10.346 0.929988 10.3831C1.02141 10.4216 1.11705 10.4493 1.21489 10.4656C1.31081 10.4871 1.40898 10.4972 1.50728 10.4956C1.60442 10.5068 1.70254 10.5068 1.79968 10.4956C1.89509 10.4792 1.98824 10.4515 2.07708 10.4131C2.16991 10.3763 2.25801 10.3284 2.33949 10.2706C2.42253 10.2185 2.49816 10.1555 2.56441 10.0832C2.63667 10.0169 2.69974 9.94132 2.75185 9.85829C2.80948 9.77938 2.85733 9.69381 2.8943 9.60338C2.94551 9.50385 2.98333 9.39795 3.00676 9.28849C3.01828 9.19135 3.01828 9.09323 3.00676 8.99609Z'
                      fill='#A0A0A0'
                    />
                    <path
                      d='M3.00685 15.7436C3.01796 15.6465 3.01796 15.5484 3.00685 15.4512C2.98094 15.3521 2.94317 15.2565 2.89439 15.1663C2.85414 15.0776 2.80648 14.9923 2.75194 14.9114C2.69983 14.8284 2.63676 14.7528 2.56451 14.6865C1.97966 14.1051 1.03509 14.1051 0.450249 14.6865C0.377993 14.7528 0.314921 14.8284 0.262815 14.9114C0.208271 14.9923 0.160616 15.0776 0.120365 15.1663C0.0814723 15.2576 0.0537789 15.3533 0.0378939 15.4512C0.0167607 15.5473 0.00673295 15.6453 0.00790442 15.7436C0.00959132 16.1407 0.168676 16.5208 0.450249 16.8008C0.516507 16.873 0.592137 16.9361 0.67517 16.9882C0.754126 17.0458 0.83969 17.0936 0.93008 17.1307C1.0215 17.1691 1.11714 17.1968 1.21498 17.2131C1.3109 17.2346 1.40907 17.2447 1.50738 17.2431C1.60451 17.2543 1.70264 17.2543 1.79977 17.2431C1.89518 17.2268 1.98833 17.1991 2.07718 17.1606C2.17 17.1238 2.2581 17.076 2.33958 17.0182C2.42262 16.9661 2.49825 16.903 2.56451 16.8308C2.63676 16.7645 2.69983 16.6889 2.75194 16.6058C2.80962 16.527 2.85747 16.4414 2.89439 16.3509C2.94556 16.2514 2.98337 16.1455 3.00685 16.036C3.01838 15.9389 3.01838 15.8408 3.00685 15.7436Z'
                      fill='#A0A0A0'
                    />
                    <path
                      d='M22.5015 7.49658H6.00728C5.17915 7.49658 4.50781 8.16792 4.50781 8.99606C4.50781 9.82419 5.17915 10.4955 6.00728 10.4955H22.5015C23.3296 10.4955 24.001 9.82419 24.001 8.99606C24.001 8.16792 23.3296 7.49658 22.5015 7.49658Z'
                      fill='#A0A0A0'
                    />
                    <path
                      d='M15.7539 14.2441H6.00729C5.17915 14.2441 4.50781 14.9155 4.50781 15.7436C4.50781 16.5717 5.17915 17.2431 6.00729 17.2431H15.7539C16.582 17.2431 17.2533 16.5717 17.2533 15.7436C17.2533 14.9155 16.582 14.2441 15.7539 14.2441Z'
                      fill='#A0A0A0'
                    />
                  </svg>
                </span>
              </div>

              <div className='icon_border' />
              <div className='main_icons'>
                <span className='left_icons active'>
                  <svg
                    width='22'
                    height='22'
                    viewBox='0 0 21 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M19.8068 6.07383L13.9477 0.2146C13.8125 0.0793945 13.6252 0 13.4297 0H3.17578C1.96421 0 0.978516 0.985693 0.978516 2.19727V22.8027C0.978516 24.0143 1.96421 25 3.17578 25H17.8242C19.0358 25 20.0215 24.0143 20.0215 22.8027V6.5918C20.0215 6.39248 19.9368 6.20366 19.8068 6.07383ZM14.1621 2.50063L17.5208 5.85938H14.8945C14.4907 5.85938 14.1621 5.53081 14.1621 5.12695V2.50063ZM17.8242 23.5352H3.17578C2.77192 23.5352 2.44336 23.2066 2.44336 22.8027V2.19727C2.44336 1.79341 2.77192 1.46484 3.17578 1.46484H12.6973V5.12695C12.6973 6.33853 13.683 7.32422 14.8945 7.32422H18.5566V22.8027C18.5566 23.2066 18.2281 23.5352 17.8242 23.5352Z'
                      fill='white'
                    />
                    <path
                      d='M14.8945 10.3516H6.10547C5.70098 10.3516 5.37305 10.6795 5.37305 11.084V19.873C5.37305 20.2775 5.70098 20.6055 6.10547 20.6055H14.8945C15.299 20.6055 15.627 20.2775 15.627 19.873V11.084C15.627 10.6795 15.299 10.3516 14.8945 10.3516ZM6.83789 14.7461H9.76758V16.2109H6.83789V14.7461ZM11.2324 14.7461H14.1621V16.2109H11.2324V14.7461ZM14.1621 13.2812H11.2324V11.8164H14.1621V13.2812ZM9.76758 11.8164V13.2812H6.83789V11.8164H9.76758ZM6.83789 17.6758H9.76758V19.1406H6.83789V17.6758ZM11.2324 19.1406V17.6758H14.1621V19.1406H11.2324Z'
                      fill='white'
                    />
                  </svg>
                </span>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-9 repot_col'>
          <div className='row'>{reportBox}</div>
        </div>

        <div className='row button'>
          <div className=' text-right col-md-6 mt-4'>
            <button
              onClick={(e) => backBtn()}
              className='previous-button  font-bold py-2 px-4 w-40 rounded'>
              Back
            </button>
          </div>
          <div className='submitBtnMain col-md-6 mt-4'>
            <button
              onClick={(e) => closeReport()}
              className='bg-primary hover:bg-blue-700 text-white w-40 font-bold py-2 px-4 rounded'>
              Close
            </button>
          </div>
        </div>
      </div>
    
    </Wrapper>
  )
}

const HeadingFiles = styled.h2`
  font-weight: bold;
  color: #15342e;
  font-family: roboto;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.7;
  padding: 20px 0px 45px 0px;
`

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

export default Report
