import React, { useEffect, useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import { addCustomFieldData } from "services/dataValidation"
import styled from "styled-components"
import { postRequest } from "services/dataValidation"
import COLORS from "utils/colors"
import tw from "twin.macro"
import CreatableSelect from "react-select/creatable"
import Select from "react-select"
import { toast } from "react-toastify"

const Modal = ({
  fileId,
  setisLoading,
  address,
  updateCount,
  AddressPopup,
  onClose,
  data,
}) => {
  const [sourceValue, setSourceValue] = useState("")
  const [sourceName, setSourceName] = useState("Select")
  const [defaultOptions, setDefaultOptions] = useState([])
  const [addressOptions, setAddressOptions] = useState([])
  const [recordUniqueId, setRecordUniqueId] = useState({
    label: "Select",
    value: "Select",
  })

  const handleGetAllTags = (recordUniqueId = "") => {
    const customFields =
      data.filter((val) => val.record_unique_id === recordUniqueId) || []
    const mapOptionsToValues = (options) => {
      return options.map((option) => ({
        value: option.field_name,
        label: option.field_name,
      }))
    }
    postRequest({ action: "get-all" }, "custom-fields").then(function (
      response
    ) {
      if (response?.data?.data) {
        const fields = response?.data?.data
        function getDifference(array1, array2) {
          return array1.filter((object1) => {
            return !array2.some((object2) => {
              return object1.field_name === object2.custom_field_name
            })
          })
        }
        setDefaultOptions(
          mapOptionsToValues(
            getDifference(fields, customFields[0]?.custom_fields || [])
          )
        )
      }
    })
  }

  const handleAddressOptions = () => {
    const mapOptionsToValues = (options) => {
      return options.map((option, index) => ({
        value: option?.record_unique_id,
        label:
          option?.address || option?.city || option?.state || option?.zip
            ? (option?.address ? `${option?.address}, ` : "") +
              (option.city ? `${option?.city}, ` : "") +
              (option.state ? `${option?.state}, ` : "") +
              option?.zip
            : `Row ${index + 1}`,
      }))
    }
    setAddressOptions(mapOptionsToValues(data))
  }

  const handleAddress = (label, value) => {
    if (value == "Select") {
      setSourceName("Select")
    }
    setRecordUniqueId({ label, value })

    handleGetAllTags(value)
  }

  const addCustomData = async () => {
    if (sourceName === "Select" || sourceValue === "") {
      toast.error("please fill all fields")
    } else {
      setisLoading(true)
      const result = await addCustomFieldData({
        file_id: fileId,
        mailing_address: address,
        record_unique_id: recordUniqueId.value,
        custom_field_name: sourceName,
        custom_field_value: sourceValue,
      })

      if (result?.status) {
        setisLoading(false)
        updateCount()
        AddressPopup(address)
        onClose()
        toast.success("custom field added successfully")
      } else {
        setisLoading(false)
        toast.error(result?.errors)
      }
    }
  }

  useEffect(() => {
    handleGetAllTags()
    handleAddressOptions()
  }, [])

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='manipulator-close close-btn1'
        onClick={onClose}>
        x
      </button>
      <WrapperContent>
        <WrapperHeader className='manipulator-phone-modal'>
          <div className='flex heading-title'>
            <h1 className='popupModalHeader'>
              <span className='float-left'></span>Add Custom Field
            </h1>
          </div>
          <div className='text-center msg-pop mt-2'>
            Any custom fields selected here will be added to the "Property
            Address" section inside of all contacts
          </div>
          <WrapperBody>
            <table className='popupTablelisting popupTablelisting-cus'>
              <tr className='heading'>
                <th width='33%'>Choose Record</th>
                <th width='33%'>Source Name</th>
                <th width>Source Value</th>
                {/* <th width='30%'>Action</th> */}
              </tr>
              <tr className='heading'>
                <th width='33%'>
                  <Select
                    onChange={({ label, value }) => {
                      handleAddress(label, value)
                    }}
                    options={[
                      { label: "Select", value: "Select" },
                      ...addressOptions,
                    ]}
                    value={{
                      label: recordUniqueId?.label,
                      value: recordUniqueId?.value,
                    }}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </th>
                <th width='33%'>
                  <CreatableSelect
                    // defaultOptions={defaultOptions}
                    onChange={({ value }) => {
                      setSourceName(value)
                    }}
                    options={[
                      { label: "Select", value: "Select" },
                      ...defaultOptions,
                    ]}
                    isDisabled={recordUniqueId?.label == "Select"}
                    value={{ label: sourceName, value: sourceName }}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </th>
                <th>
                  <input
                    type='text'
                    placeholder='Enter Sourcce Value'
                    className='customFiledValueInput'
                    name={"sourceValue"}
                    value={sourceValue}
                    onChange={(e) => setSourceValue(e.target.value)}
                  />
                </th>
              </tr>
            </table>
            <div className='d-flex w-100 justify-content-end'>
              <MainButton className='blue_btn'>
                <Label>
                  Submit
                  <Upload
                    id='file-selecter'
                    type='button'
                    size='80'
                    onClick={addCustomData}
                  />
                </Label>
              </MainButton>
            </div>
          </WrapperBody>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 968px;
  min-height: 200px;
  left: 280px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 9999;
  top: 10px;
  max-height: 76vh;

  border-radius: 0;
  width: calc(98% - 245px);
  left: 260px;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0px 0px 20px 0px;
  border: #d1d1d1 solid 1px;
  overflow-y: hidden;
  border-radius: 20px;
`

const WrapperBody = styled.div`
  padding: 5px;
  max-height: 57vh;
  overflow: auto;
  border-radius: 10px;
  margin: 1% 2%;
`
const MainButton = styled.div`
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 3px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height: 35px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  ${tw`
    shadow-lg
  `}
`
const Label = styled.label`
  font-size: 12px;
  display: table;
  cursor: pointer;
  color: #fff;
  font-family: "Montserrat";
  text-align: center;
  padding: 10px;
  font-weight: bold;
`

const Upload = styled.input`
  display: none;
  cursor: pointer;
`
