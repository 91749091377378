import React, { useEffect, useState } from "react"
import {
  downloadLeadslisting,
  DeleteDownloadsFiles,
} from "services/dataValidation"
import { getUserID } from "utils/helper"
import TopNav from "components/navbar/topNav"
import Table from "components/navbar/tableItems/download/table"
import styled from "styled-components"
import Search from "components/downoad-search"
import Pagination from "components/pagination"
import Loading from "components/loading"
import "assets/css/style.css"
import Modal from "components/lead-modal"
import COLORS from "utils/colors"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const ManipulatorDownloads = () => {
  const [, setExcelUserData] = useState([])
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([])
  const [noOfPages, setNoofPages] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [getTotalExcelData, setTotalExcelData] = useState(false)
  const [show, setShow] = useState(false)
  const [filedata] = useState(false)
  const [emptyDataMsg, setemptyDataMsg] = useState("")
  document.title = "Downloads | AnalyzeREI"
  useEffect(() => {
    downloadLeadsData(noOfPages, recordsPerPage)
  }, [noOfPages, recordsPerPage])

  useEffect(() => {
    const interval = setInterval(() => {
      downloadLeadsData2(noOfPages, recordsPerPage)
    }, 5000)
    return () => clearInterval(interval)
  }, [noOfPages, recordsPerPage])

  function downloadLeadsData(noOfPages, recordsPerPage) {
    setisLoading(true)
    const user = getUserID()
    downloadLeadslisting(
      {
        user_id: user,
        per_page: recordsPerPage,
      },
      noOfPages
    )
      .then(function (response) {
        setisLoading(false)
        setTotalRecords(response.total_records)
        setExcelUserData(response.data)
        setFilteredUserExcelData(response.data)
        setTotalExcelData(response.data.length)
        setemptyDataMsg("No downloads available")
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function downloadLeadsData2(noOfPages, recordsPerPage) {
    //setisLoading(true)
    // noOfPages = $("li.paginationActive a").text()
    noOfPages = document.querySelector("li.paginationActive > a")
    if (!noOfPages) {
      noOfPages = 1
    } else {
      noOfPages = noOfPages.textContent
    }
    // recordsPerPage = $("select.PerPageSelect :selected").val()
    let search = document.querySelector("input.download.search").value
    const user = getUserID()
    downloadLeadslisting(
      {
        user_id: user,
        per_page: recordsPerPage,
        search: search,
      },
      noOfPages
    )
      .then(function (response) {
        //setisLoading(false)
        setTotalRecords(response.total_records)
        setExcelUserData(response.data)
        setFilteredUserExcelData(response.data)
        setTotalExcelData(response.data.length)
        setemptyDataMsg("No downloads available")
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function onChangeRecordPerPage(perPageValue, pageCount) {
    setRecordsPerPage(perPageValue)
    setNoofPages(pageCount)
    downloadLeadsData(1, perPageValue)
  }

  function searchdata(noOfPages, recordsPerPage) {
    setisLoading(true)
    // noOfPages = $("li.paginationActive a").text()
    noOfPages = document.querySelector("li.paginationActive > a")
    if (!noOfPages) {
      noOfPages = 1
    } else {
      noOfPages = noOfPages.textContent
    }
    // let search = $("input.download.search").val()
    let search = document.querySelector("input.download.search").value
    const user = getUserID()
    downloadLeadslisting(
      {
        user_id: user,
        per_page: recordsPerPage,
        search: search,
      },
      noOfPages
    )
      .then(function (response) {
        setisLoading(false)
        setTotalRecords(response.total_records)
        setExcelUserData(response.data)
        setFilteredUserExcelData(response.data)
        setTotalExcelData(response.data.length)
        setemptyDataMsg("No Data Found")
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  const bulkDelete = () => {
    setisLoading(true)
    var checkboxes = document.getElementsByName("slectedCheckBox[]")
    for (var i in checkboxes) {
      if (checkboxes[i].checked === true) {
        var inputElems = document.getElementsByName("slectedCheckBox[]"),
          row = []
        for (var j = 0; j < inputElems.length; j++) {
          if (
            inputElems[j].type === "checkbox" &&
            inputElems[j].checked === true
          ) {
            row.push(inputElems[j].value)
          }
        }
      }
    }
    if (row === undefined) {
      alert("Please Select altest one Item")
      return false
    }
    var rowIds = row.join(",")
    DeleteDownloadsFiles({
      file_ids: rowIds,
      action: "data-manipulator",
    })
      .then(function (response) {
        setisLoading(false)
        // $("input.deleteCheckbox.downloads").prop("checked", false)
        if (response.status === true) {
          downloadLeadsData2(noOfPages, recordsPerPage)
        } else {
          toast.error(response.message)
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  return (
    <Wrapper>
      <Modal
        onClose={() => {
          setShow(false)
        }}
        show={show}
        fields={filedata}
      />
      <Loading show={isLoading} />
      <TopNav />
      <div className='MainDownloadTabs'>
        <ul>
          <li>
            <Link to='/downloads'>Phone Validation</Link>
          </li>{" "}
          <li className='active'>
            {" "}
            <Link to='/manipulator-downloads'> Data Manipulator</Link>
          </li>{" "}
          <li>
            <Link to='/skip-Tracing-downloads'>Skip Tracing</Link>
          </li>
        </ul>
      </div>

      <SearchWrapper className='download search'>
        <Search search={searchdata} />
      </SearchWrapper>
      {getTotalExcelData > 0 && (
        <div className='select-all-data-downloads'>
          <Delete onClick={bulkDelete} className='DeleteAction'>
            Delete
          </Delete>
        </div>
      )}
      {getTotalExcelData < 1 && <div className='nodata'>{emptyDataMsg}</div>}

      <Table files={filteredUserExcelData}></Table>
      <WrapperPagination>
        <Pagination
          getPageData={downloadLeadsData}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          onChangeRecordPerpage={onChangeRecordPerPage}></Pagination>
      </WrapperPagination>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`
const SearchWrapper = styled.div`
  margin-top: 2%;
`
const WrapperPagination = styled.div`
  width: 98%;
`
const Delete = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.red};
  padding-left: 10px;
  display: none;
  cursor: pointer;
`

export default ManipulatorDownloads
