import React from "react"
import styled from "styled-components"

const NoComponent = () => {
  return <Wrapper>Coming Soon</Wrapper>
 
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
  padding: 10% 0 0 0;
  font-size: 28px;
`

export default NoComponent
