const COLORS = {
  blue: "#047CFF",
  grey: "#959595",
  createdat: "#c0c0c0",
  black: "#000000",
  white: "#ffffff",
  username: "#333333",
  darkgrey: "#2c2c2c",
  paginationgrey: "#888888",
  lightGreen: "#67D723",
  lightblue: "#1479ff",
  red: "#FF0000",
  green: "#89DE58",
}


export default COLORS;