import React from "react"
import styled from "styled-components"

const TextInput = (props) => {
  return (
    <Wrapper>
      <LabelWrapper>
        <Label>{props.label}</Label>
        {props.helperLabel && <LabelHelper>{props.helperLabel}</LabelHelper>}
      </LabelWrapper>
      <TextInputField
        width={props.width}
        onChange={props.onChange}
        placeholder={props.placeholder ? props.placeholder : ""}
        type={props.type ? props.type : "text"}
        errorLabel={props.errorLabel}
        {...props}
        name={props.name}
      />
      {props.errorLabel && <LabelError>{props.errorLabel}</LabelError>}
    </Wrapper>
  )
}

export default TextInput

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`

const TextInputField = styled.input`
  box-sizing: border-box;
  padding: 1em;
  margin-top: 8px;
  border-radius: 8px;
  width: ${(props) => (props.width ? props.width : "100%")};
  display: block;
  border-width: 1px;
  border-style: ${({ dotted }) => (dotted ? "dotted" : "solid")};
  border-color: ${({ dotted, errorLabel, theme }) =>
    errorLabel ? "red" : dotted ? "green" : "#e0e0e0"};
  &:focus {
    border-style: solid;
    outline: none;
    border-color: ${({ theme }) => "green"};
  }
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Label = styled.label`
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 16px;
`

const LabelHelper = styled(Label)`
  color: ${({ theme }) => theme.colour.blackGray};
`

const LabelError = styled(LabelHelper)`
  display: block;
  padding-top: 10px;
  color: ${({ theme }) => theme.colour.redError};
`
