import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import DownloadIcon from "assets/images/download-icon.png";
import COLORS from "utils/colors";
import ProgressBar from "components/progressbar";

import { BASE_URL } from "config";
import axiosService from "services/axios.service";

export async function getFileIDDynamic(fid) {
  axiosService
    .post(BASE_URL + "dv-download-list?fid=" + fid)
    .then(function (response) {
      window.open(response.link, "_blank", "noreferrer");
    })
    .catch((error) => {
      console.log("error", error);
    });
}

const Item = (props) => {
  let disabled = false;
  if (props.percentage == 100) {
    disabled = true;
  }
  const tooltipID = "tooltip_ID_" + props.id;
  function onChange(event) {
    // var checkedNum = $('input[name="slectedCheckBox[]"]:checked').length
    const isChecker = document.querySelectorAll("input:checked");
    //console.log(isChecker.length);
    if (isChecker.length == 0) {
      document.querySelector(".DeleteAction").style.display = "none";
    } else {
      document.querySelector(".DeleteAction").style.display = "block";
    }
  }

  function getFileID(fid) {
    var res = getFileIDDynamic(fid);
  }
  return (
    <React.Fragment>
      <tr>
        <td>
          <input
            type="checkbox"
            name="slectedCheckBox[]"
            className="deleteCheckbox downloads selectAllCheckbox"
            value={props.id}
            onChange={onChange}
          />{" "}
          <ParaTwo
            data-tip
            data-for={tooltipID}
            className="itemHeading downloads"
          >
            {props.name}
          </ParaTwo>
          <ReactTooltip id={tooltipID} aria-haspopup="true" role="example">
            {props.name}{" "}
          </ReactTooltip>
        </td>
        <td>
          {" "}
          <Para>{props.category}</Para>{" "}
        </td>
        <td>
          {" "}
          <span>{props.line_type}</span>{" "}
          <span className="total_row">{props.total_rows}</span>
        </td>
        <td>
          {" "}
          <span>{props.connected}</span>{" "}
        </td>
        <td>
          <Para>{props.caller_id_match}</Para>
        </td>
        <td>
          {" "}
          <InnerWrapper className="status_progress">
            {" "}
            <ProgressBar percentage={props.percentage} />
          </InnerWrapper>
        </td>
        <td>{props.updated_at}</td>
        <td>
          {" "}
          <DownloadIconWrapper className="download-icon-validation table-cols">
            {" "}
            {disabled && (
              <span
                fid={props.download_url}
                className="download-icon"
                onClick={() => getFileID(props.download_url)}
              >
                <Icon src={DownloadIcon} width="34px" height="34px" />
              </span>
            )}{" "}
          </DownloadIconWrapper>
        </td>
      </tr>
      <tr className="emptyrow">&nbsp;</tr>
    </React.Fragment>
    /*
    <div>

      <div>
       
      </div>
	  
      <Wrapper>    	  
        <ItemWrapper>
          
          <Heading className="table-cols selectCheck"><input type="checkbox" name="slectedCheckBox[]" className="deleteCheckbox downloads" value={props.id} className="selectAllCheckbox" onChange={onChange}/></Heading>
          <Heading className="table-cols filename">
		   
		    <ParaTwo data-tip data-for={tooltipID} className="itemHeading downloads fineName">{props.name}</ParaTwo>
            
			<ReactTooltip id={tooltipID} aria-haspopup='true' role='example'>
			  {props.name}
            </ReactTooltip>
            <Para>{props.size}</Para>
          </Heading>
          <Mobile className="format_col table-cols">
            <Para>{props.category}</Para>
          </Mobile>
          <Mobile  className="line_type_col table-cols">
            <span className="Linetype">{props.line_type}</span>
			<span className="total_row lintype">{props.total_rows}</span>
          </Mobile>
		  
          <Mobile className="table-cols">
            <Para>{props.match}</Para>
          </Mobile>
		  
          <InnerWrapper className="status_progress table-cols">
            <ProgressBar percentage={props.percentage} />
          </InnerWrapper>		  
          <Mobile className="table-cols">
            <Para>{props.updated_at}</Para>
          </Mobile>
          <DownloadIconWrapper className="download-icon-validation table-cols"> 
		  { disabled  &&
		  <a href={props.download_url} target="_blank" className="download-icon"><Icon src={DownloadIcon} width='34px' height='34px'/></a> }
          </DownloadIconWrapper>
        </ItemWrapper>
      </Wrapper>
    </div>*/
  );
};

export default Item;

const Icon = styled.img`
  margin-left: 0px;
  padding-top: 5px;
  cursor: pointer;
`;

const InnerWrapper = styled.span`
  display: inline-block;
  font-size: 10px;
`;

const DownloadIconWrapper = styled.span`
  margin-top: 10px;
`;

const Para = styled.p`
  color: ${COLORS.createdat};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`;

const ParaTwo = styled.p`
  color: ${COLORS.username};
  font-family: Montserrat;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  word-break: break-all;
`;
