import React, { useState } from "react"
import styled from "styled-components"
import COLORS from "utils/colors"
import FavIcon from "components/favicon"
import Modal from "components/modal"
const Search = (props) => {
  const [show, setShow] = useState(false)
  const [filedata] = useState(false)

  const searchItem = (e) => {
    props.search(e.target.value)
  }
  return (
    <Wrapper>
      <Modal
        onClose={() => {
          setShow(false)
        }}
        show={show}
        fields={filedata}
      />
      <WrapperInput>
        <SearchIcon>
          <FavIcon class='fas fa-search'></FavIcon>
        </SearchIcon>
        <Input
          type='text'
          placeholder='Search'
          onChange={searchItem}
          className='download search'></Input>
      </WrapperInput>
    </Wrapper>
  )
}

export default Search

const Wrapper = styled.div`
  display: flex;
  margin-left: 0%;
  margin-right: 2%;
  justify-content: space-between;
`

const Input = styled.input`
  width: 100%;
  height: 50px;
  border: #d2d2d2 solid 1px;
  border-radius: 5px;
  padding-left: 50px;
  ::placeholder,
  ::-webkit-input-placeholder {
    position: absolute;
    padding: 10px;
    padding-top: 15px;
    padding-left: 5px;
  }
  &:focus {
    border-style: solid;
    outline: none;
  }
`

const SearchIcon = styled.span`
  position: absolute;
  color: ${COLORS.grey};
  display: block;
  padding: 10px;
  margin-left: 5px;
  padding-top: 13px;
  color: ${COLORS.grey};
  min-width: 50px;
`
const WrapperInput = styled.div`
  width: 50%;
  margin-bottom: 10px;
  margin-left: 35px;
`
