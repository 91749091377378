import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import Select from "react-select"
import { toast } from "react-toastify"
import styled from "styled-components"
import "assets/css/style.css"
import FavIcon from "components/favicon"
import Loading from "components/loading"
import TopNav from "components/navbar/topNav"
import { contactMappingPost, getAvailbleCreadit, mappingFileData } from "services/dataValidation"
import COLORS from "utils/colors"
import { getUserID } from "utils/helper"

const MapContact = () => {
  const [isLoading, setisLoading] = useState(false)
  const relativeData=localStorage.getItem("relative-data");
  const [subHeading,setSubHeading]=useState("default");
  const [availableCreadit, setAvailableCreadit] = useState()
  const [contactInfo,setContactInfo]=useState();
  let history = useHistory()

  useEffect(() => {
    getAvailbleCreadit2()
  }, [])

  function getAvailbleCreadit2() {
    const userID = getUserID()
    getAvailbleCreadit({ user_id: userID })
      .then(function (response) {
        setAvailableCreadit((state) => response.balance_credit)
      })
      .catch((error) => {
        alert("Some error occured")
        console.log("file upload error: ", error)
      })
  }

  function MappingPhone() {
    setisLoading(true)
    const MappedData = JSON.parse(localStorage.getItem("phoneMapping"))
    const phoneMappedFields = JSON.parse(
      localStorage.getItem("phoneContactFields")
    )

  const copyPhoneMappingFields={...phoneMappedFields};
   Object.entries( copyPhoneMappingFields).map(([keys,values])=>{
     if(relativeData=='no'&&(keys.includes("relative")||keys.includes("person"))){
       delete copyPhoneMappingFields[keys]
     }
    //  else if(relativeData=='yes'&&(keys.includes("combined_name")||keys.includes("full_name")||keys.includes('owner_2_last_name')||keys.includes('owner_2_first_name')||keys.includes('owner_1_last_name')||keys.includes('owner_1_first_name'))){
    //   delete copyPhoneMappingFields[keys]
    //  }
 
   })


    contactMappingPost({
      filelist_id: MappedData.filelist_id,
      contact_mapping: copyPhoneMappingFields,
      relative_status:relativeData
    })
      .then(function (response) {
        setisLoading(false)
        if (response.status === false) {
          toast.error(response.message)
          setisLoading(false)
        } else {
          localStorage.setItem("reportData", JSON.stringify(response))
          history.push("/revamp-report")
          //setShow4(true);
          //setmappingFields(response);
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  const ContactMap = JSON.parse(localStorage.getItem("phoneMapping"))
  const phoneContactFields = JSON.parse(
    localStorage.getItem("phoneContactFields")
  )

  const [options,setOptions] = useState(ContactMap.file_columns)


  const handleInputChange = (selectedOption, Select,val) => {
    const phoneMappedFields = JSON.parse(
      localStorage.getItem("phoneContactFields")
    )

     let copyOptions=[];
const optio=ContactMap.file_columns;

        optio.forEach(({key,value})=>{
     
     if(phoneMappedFields[key]&&phoneMappedFields[key]!=''){
     }
     if(phoneMappedFields[key]==value){
     }
    else{
        copyOptions.push({label:value,value,key})
      }
    })

 

    let unique = [...new Map(copyOptions.map(item =>
  [item['value'], item])).values()];

     Object.entries(phoneMappedFields).map(([key,value])=>
    {
      const index = unique.findIndex(object =>object.value === value)
      const index1 = unique.findIndex(object =>object.value == selectedOption.value)
      if(index1>-1){
        unique.splice(index1, 1);
      }
      if(index>-1){
        unique.splice(index, 1);
      }
  })

   unique.push(val)

   
   
    setOptions(unique)

    let NewData = {}

    for (const [key, value] of Object.entries(phoneMappedFields)) {
      if (key === Select.name) {
        if (selectedOption.label === "Select") {
          NewData[key] = ""
        } else {
          NewData[key] = selectedOption.label
        }
      } else {
        NewData[key] = value
      }
    }
    setContactInfo(NewData)
    localStorage.setItem("phoneContactFields", JSON.stringify(NewData))
  }
 

    useEffect(()=>{
    let phoneMappedFields={};
  
   phoneMappedFields = JSON.parse(
    localStorage.getItem("phoneContactFields")
  )
  const phoneColumns = ContactMap.mappedContactValues
  let defaultOption = {}
  let copyOptions=[];

   options.forEach(({key,value},ind)=>{
   
      if(phoneColumns[key]&&phoneColumns[key]!=''){
      }
      
      else{
        copyOptions.push({label:value,value,key})
      }
    })
    setOptions(copyOptions)


  for (const [key, value] of Object.entries(phoneColumns)) {
        defaultOption[key] = value
    } 
  
  setContactInfo(defaultOption)

  localStorage.setItem("phoneContactFields", JSON.stringify(defaultOption))
  },[])

const relativeFieldsData=[
    {label:'Name',name:'name'},
    {label:'Age',name:'age'},
]

const personFieldsData=[
    {label:'First Name',name:'first_name'},
    {label:'Last name',name:'last_name'},
    {label:'Age',name:'age'},
    {label:'Deceased',name:'deceased'},
]

const contactFieldsData=[
    {label:'Owner 1 First name',name:'owner_1_first_name'},
    {label:'Owner 1 Last name',name:'owner_1_last_name'},
    {label:'Owner 2 First name',name:'owner_2_first_name'},
    {label:'Owner 2 Last name',name:'owner_2_last_name'},
    {label:'Full Name',name:'full_name'},
    {label:'Combined Name',name:'combined_name'}
]


const ContactField = (name,label) => (
  <>
    <label>{label}</label>
    <Select
      name={name}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={(select,name1)=>handleInputChange(select,name1,contactInfo&&contactInfo[name]!=''&&{label:contactInfo[name],value:contactInfo[name]})}
      value={contactInfo&&contactInfo[name]!=''?{label:contactInfo[name],value:contactInfo[name]}:{label:"Select",value:"Select"}}
    />
  </>)


const next=()=>{
if(relativeData=='no'){
  MappingPhone()
}
else if(subHeading=='default'){
  setSubHeading('relative')
}

else if(subHeading=='relative'){
setSubHeading("person2")
}
else if(subHeading=='person2'){
setSubHeading("person2_relative")
}
else if(subHeading=='person2_relative'){
setSubHeading("person3")
}
else if(subHeading=='person3'){
setSubHeading("person3_relative")
}

else{
  MappingPhone()
}

}

const previous=()=>{
  if(subHeading=='default'){
     history.push("revamp-phone-mapping")
  }
  if(subHeading=='relative'){
     setSubHeading("default")
  }
  else if(subHeading=='person3_relative'){
    setSubHeading("person3")
  }
   else if(subHeading=='person3'){
    setSubHeading("person2_relative")
  }
  else if(subHeading=='person2_relative'){
    setSubHeading("person2")
  }
   else if(subHeading=='person2'){
    setSubHeading("relative")
  }

}

  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNav />
      <div className='row revamp_upload top'>
        <div className='col-md-3'>
          <div className='row left_side'>
            <div className='col-md-8'>
              {/* <Link to='/upload-process-revamp'>
                <span className='icon_heading'> Upload File </span>
              </Link> */}
              <Link to='/revamp-phone-mapping'>
                <span className='icon_heading'> Map Phone Number </span>
              </Link>
              {/* <Link to='' style={{color:'#047CFF',textDecoration:'none'}}> */}
                {" "}
                <span className={subHeading=='default'?'icon_heading1 active--heading':'icon_heading1'} onClick={()=>setSubHeading('default')}> Map Contact Names </span>
                  {relativeData!='no'&&
                <>
                <div onClick={()=>setSubHeading('relative')}  className={!subHeading.includes('person')&&subHeading!='default'?"sub-heading--active mt-10":'sub-heading mt-10'}>Relatives:</div>
                {/* {!subHeading.includes('person')&&Array.from(Array(11).keys())
                  .slice(1)
                  .map((index) => (
                <div onClick={()=>setSubHeading(`relative${index}`)} className={subHeading==`relative${index}`?"sub-relative-data--active":"sub-relative-data"}>Relative {index} Contact Info </div>))} */}
                <div onClick={()=>setSubHeading('person2')} className={subHeading=='person2'?"sub-heading--active":'sub-heading'}>Person 2:</div>
                {subHeading.includes('person2')&&
                <div onClick={()=>setSubHeading(`person2_relative`)} className={subHeading==`person2_relative`?"sub-relative-data--active":"sub-relative-data"}>Relatives</div>}
                <div onClick={()=>setSubHeading('person3')} className={subHeading=='person3'?"sub-heading--active":'sub-heading'}>Person 3:</div>
                {subHeading.includes('person3')&&
                <div onClick={()=>{setSubHeading('person3');setSubHeading(`person3_relative`)}} className={subHeading==`person3_relative`?"sub-relative-data--active":"sub-relative-data"}>Relatives</div>
                }</>
                }
              {/* </Link> */}
              {/* <Link to='/revamp-report'> */}
                <span className='icon_heading'> Report & Confirmation </span>
              {/* </Link> */}
            </div>{" "}
            <div className='col-md-4'>
              {/* <div className='main_icons'>
                <span className='left_icons'>
                  <svg
                    width='22'
                    height='22'
                    viewBox='0 0 28 23'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M14.238 6.94117C14.4731 6.94117 14.6979 7.03753 14.8597 7.20729L20.8817 13.5161C21.4036 14.0621 21.0159 14.9706 20.26 14.9706L17.1056 14.9706L17.1056 21.5662C17.1056 22.3565 16.4621 23 15.6718 23L12.8041 23C12.0138 23 11.3703 22.3565 11.3703 21.5662L11.3703 14.9706L8.2159 14.9706C7.45999 14.9706 7.07229 14.0621 7.5942 13.5161L13.6163 7.20729C13.778 7.03753 14.0028 6.94117 14.238 6.94117Z'
                      fill='#A0A0A0'
                    />
                    <path
                      d='M2.47794 0.058735L25.9926 0.0587371C27.0996 0.0587372 28 0.959178 28 2.06609L28 2.63962C28 3.74653 27.0996 4.64697 25.9926 4.64697L2.47794 4.64697C1.37103 4.64697 0.470589 3.74653 0.470589 2.63962L0.470589 2.06609C0.470589 0.959176 1.37103 0.058735 2.47794 0.058735Z'
                      fill='#A0A0A0'
                    />
                  </svg>
                </span>
              </div>
              <div className='icon_border' /> */}
              <div className='main_icons'>
                <span className='left_icons'>
                  <svg
                    width='21'
                    height='21'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M17.142 12.9464L14.6802 10.4846C13.8009 9.60541 12.3063 9.95713 11.9546 11.1001C11.6908 11.8914 10.8116 12.331 10.0203 12.1551C8.26189 11.7155 5.88801 9.42957 5.44841 7.58322C5.18464 6.79189 5.71217 5.91268 6.50346 5.64895C7.64644 5.29726 7.99813 3.8026 7.11891 2.92339L4.65711 0.461587C3.95374 -0.153862 2.89869 -0.153862 2.28324 0.461587L0.61273 2.13209C-1.05778 3.89052 0.788573 8.55035 4.92088 12.6827C9.05318 16.815 13.713 18.7493 15.4714 16.9908L17.142 15.3203C17.7574 14.6169 17.7574 13.5619 17.142 12.9464Z'
                      fill='#A0A0A0'
                    />
                  </svg>
                </span>
              </div>

              <div className='icon_border' />
              <div className='main_icons'>
                <span className='left_icons active'>
                  <svg
                    width='22'
                    height='22'
                    viewBox='0 0 18 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M8.86449 10.3927C10.2923 10.3927 11.5286 9.88063 12.5388 8.87029C13.5489 7.86012 14.061 6.62412 14.061 5.19619C14.061 3.76875 13.5489 2.53258 12.5386 1.52208C11.5283 0.51208 10.2921 0 8.86449 0C7.43656 0 6.20055 0.51208 5.19038 1.52225C4.18021 2.53242 3.66797 3.76859 3.66797 5.19619C3.66797 6.62412 4.18021 7.86029 5.19055 8.87046C6.20088 9.88046 7.43705 10.3927 8.86449 10.3927Z'
                      fill='white'
                    />
                    <path
                      d='M17.9564 16.5899C17.9272 16.1695 17.8683 15.7109 17.7816 15.2266C17.694 14.7388 17.5812 14.2775 17.4463 13.856C17.3069 13.4203 17.1172 12.99 16.8828 12.5777C16.6396 12.1497 16.3538 11.7771 16.0332 11.4704C15.6979 11.1496 15.2873 10.8917 14.8126 10.7035C14.3396 10.5164 13.8153 10.4216 13.2545 10.4216C13.0343 10.4216 12.8213 10.5119 12.4099 10.7797C12.1568 10.9448 11.8606 11.1358 11.5301 11.3469C11.2475 11.527 10.8646 11.6957 10.3917 11.8485C9.93034 11.9978 9.46188 12.0735 8.99951 12.0735C8.53714 12.0735 8.06884 11.9978 7.60696 11.8485C7.13455 11.6959 6.75169 11.5272 6.46939 11.3471C6.142 11.1379 5.84571 10.947 5.58877 10.7796C5.17792 10.5118 4.96476 10.4214 4.74452 10.4214C4.18355 10.4214 3.65945 10.5164 3.18655 10.7037C2.71216 10.8915 2.30148 11.1494 1.96585 11.4706C1.64537 11.7774 1.35946 12.1499 1.1165 12.5777C0.882272 12.99 0.69265 13.4201 0.553066 13.8562C0.418256 14.2777 0.305503 14.7388 0.217934 15.2266C0.131189 15.7102 0.0722607 16.169 0.043126 16.5904C0.0144851 17.0032 0 17.4317 0 17.8644C0 18.9906 0.358012 19.9024 1.06399 20.5748C1.76125 21.2383 2.68385 21.5749 3.80579 21.5749H14.1942C15.3161 21.5749 16.2384 21.2384 16.9358 20.5748C17.642 19.9029 18 18.9909 18 17.8642C17.9998 17.4295 17.9852 17.0007 17.9564 16.5899Z'
                      fill='white'
                    />
                  </svg>
                </span>{" "}
              </div>

              <div className={relativeData=='no'?'icon_border':subHeading.includes('person')?'icon_border3':'icon_border4'} />
              <div className='main_icons'>
                <span className='left_icons'>
                  <svg
                    width='17'
                    height='23'
                    viewBox='0 0 17 23'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M11.3333 7.08333C10.5527 7.08333 9.91667 6.44725 9.91667 5.66667V0H1.41667C0.636083 0 0 0.636083 0 1.41667V21.25C0 22.032 0.636083 22.6667 1.41667 22.6667H15.5833C16.3653 22.6667 17 22.032 17 21.25V7.08333H11.3333ZM5.66667 19.8333H2.83333V15.5833H5.66667V19.8333ZM9.91667 19.8333H7.08333V12.75H9.91667V19.8333ZM14.1667 19.8333H11.3333V9.91667H14.1667V19.8333Z'
                      fill='#A0A0A0'
                    />
                  </svg>
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-9 right-column'>
          <span className='icon_heading_black'> Map Contact Name </span>
          <Credit>
            <Inner>
              <Icon>
                <FavIcon class='far fa-star'></FavIcon>
              </Icon>
              <AvailableCredit>Available Credit</AvailableCredit>
            </Inner>
            <Data>{availableCreadit}</Data>
          </Credit>

          <div className='row form upload-process-revamp'>
            {Array.from(Array((subHeading=='person2'||subHeading=='person3')||subHeading=='default'?2:6).keys())
                  .slice(1)
                  .map((index) => (<>
            {(subHeading=='default'?contactFieldsData:
            (subHeading=='person2'||subHeading=='person3')?personFieldsData:subHeading.includes('relative')?relativeFieldsData:[]).map(({name,label})=>
             <div className='col-md-6'>
                   <div className='fields'>
                    {ContactField(subHeading=='default'?name:(subHeading=='person2'||subHeading=='person3')?`${subHeading}_${name}`:`${subHeading}${index}_${name}`,!(subHeading=='person2'||subHeading=='person3'||subHeading=='default')?`Relative ${index} ${label}`:label)} 
              </div>
            </div>
            )}</>))}
            <div className='col-md-6 mt-4'>
              <button
                onClick={(e) => previous()}
                className='previous-button basic-multi-select font-bold py-2 px-4 w-100 rounded'>
                Previous
              </button>
            </div>
            <div className='col-md-6 mt-4'>
              <button
                onClick={(e) =>next()}
                className='basic-multi-select  bg-primary hover:bg-blue-700 text-white w-100 font-bold py-2 px-4 rounded'>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`
const Credit = styled.div`
  display: block;
  width: 50%;
  float: left;
`
const Inner = styled.div`
  display: inline-flex;
  text-align: right;
  float: right;
`
const Icon = styled.p`
  color: ${COLORS.grey};
  margin-right: 10px;
  margin-top: -2px;
  border: solid 1px #959595;
  padding: 2px;
  border-radius: 50%;
  line-height: 1;
`
const Data = styled.h6`
  color: ${COLORS.blue};
  display: block;
  text-align: center;
  font-weight: 800;
  font-family: Montserrat;
  font-size: 24px;
  padding-left: 15px;
  float: left;
  text-align: right;
  width: 100%;
  padding-right: 32px;
`

const AvailableCredit = styled.h6`
  color: ${COLORS.grey};
  font-weight: 500;
`

export default MapContact
