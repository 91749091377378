import React from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import COLORS from "utils/colors"

const Modal = (props) => {
  if (!props.show) {
    return null
  }
  let data = props.data.manipulator_fieldsArr
    ? props.data.manipulator_fieldsArr
    : []
  const AutosuggetsLineSelect = (e) => {
    const items = []
    for (const key in data) {
      if (key !== "error_code") {
        items.push(<MultiSelect name={key} value={data[key]} key={key} />)
      }
    }
    return items
  }
  const MultiSelect = (props) => {
    return (
      <React.Fragment>
        <div className='col-md-6 z-50'>{props.value}123</div>
        <div className='col-md-6 z-50'>
          <select name={props.name}>
          </select>
        </div>
      </React.Fragment>
    )
  }
  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='lead-close'
        onClick={props.onClose}>
        ×
      </button>
      <WrapperContent className='mapping_popup'>
        <WrapperHeader>
          <ModalTitle>Mapping Fileds</ModalTitle>
          <WrapperBody>
            <div className=' row lead-input-container'>
              <AutosuggetsLineSelect />
            </div>
          </WrapperBody>
          <WrapperFooter>
            <ModalButtonSubmit
            >
              Submit
            </ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 567px;
  min-height: 200px;
  left: 420px;
  top: 40px;
  z-index: 1;
  display: flex;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 520px;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px 20px;
  border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  text-align: center !important;
  float: none;
  width: 100%;
  display: block;
`
const WrapperBody = styled.div`
  padding: 10px;
`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align: center;
`

const ModalButtonSubmit = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #89de58;
  color: #fff;
  margin-top: 30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
  margin-left: 10px;
`
