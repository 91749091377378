import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  dataManipulatorUploadStatus,
  mnipulatorMappingFields,
} from "services/dataValidation"
import TopNav from "components/navbar/topNav"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import Loading from "components/loading"
import "assets/css/style.css"
import Select from "react-select"
import { useHistory } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { assoc, curry, map, propEq, set, when } from "ramda"
import Phone from "./Phone"
import Relative from "./Relative"
import CreatableSelect from "react-select/creatable"
import ContactFields from "./ContactFields"
import { postRequest } from "services/dataValidation"
import { toast } from "react-toastify"
import ConfirmModal from "components/confirm-modal"
import CustomFieldModal from "./customField"

const MappingFields = () => {
  const [step, setStep] = useState("0")

  const [isLoading, setisLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [hide, setHide] = useState(false)
  const [totalMailingAddress, settotalMailingAddress] = useState(false)
  const [uniqueMmailingAddress, setuniqueMmailingAddress] = useState(false)
  const [duplicateMmailingAddress, setduplicateMmailingAddress] =
    useState(false)
  const [, setoldMailing] = useState(false)
  const [, setoutofnew] = useState(false)
  const [, setphoneFound] = useState(false)
  const [, setaddressesFound] = useState(false)
  const [, setmatchrows] = useState(false)
  const [, setnewrows] = useState(false)
  const [, setmailingAddress] = useState(false)
  const [TotalMatchRows, setTotalMatchRows] = useState(false)
  const [NewRowsFound, setNewRowsFound] = useState(false)
  const [MailingAlreadyExists, setMailingAlreadyExists] = useState(false)
  const [NewPhoneNumberFound, setNewPhoneNumberFound] = useState(false)
  const [Newaddressfound, setNewaddressfound] = useState(false)
  const [mergeCsv, setmergeCsv] = useState(false)

  const [phone, setPhone] = useState({})
  const [relative, setRelative] = useState({})
  const [email, setEmail] = useState({})
  const [callerIdMatch, setCallerIdMatch] = useState({})
  const [callerIdName, setCallerIdName] = useState({})
  const [connected, setConnected] = useState({})
  const [lineType, setLineType] = useState({})
  const [carrier, setCarrier] = useState({})

  let history = useHistory()
  const location = useLocation()
  const isREISift =
    location?.state?.fileFormat === "REISift" ||
    localStorage.getItem("file_format") === "REISift"

  const backBtn = () => {
    let BackUrl = "/data-manipulator"
    if (
      localStorage.getItem("mergeCsv") !== null ||
      localStorage.getItem("mergeCsv") === ""
    ) {
      BackUrl =
        "/data-manipulator-contacts-merge-csv/" +
        localStorage.getItem("mergeCsv")
    }
    history.push(BackUrl)
  }

  useEffect(() => {
    mnipulatorData()
  }, [])

  function mnipulatorData() {
    const mappingFields = JSON.parse(localStorage.getItem("mappingData"))
    settotalMailingAddress(mappingFields.totalMmailingAddress)
    setuniqueMmailingAddress(mappingFields.uniqueMmailingAddress)
    setduplicateMmailingAddress(mappingFields.duplicateMmailingAddress)
    setoldMailing(mappingFields.oldMailing)
    setoutofnew(mappingFields.outofnew)
    setmatchrows(mappingFields.matchrows)
    setphoneFound(mappingFields.phoneFound)
    setnewrows(mappingFields.newrows)
    setaddressesFound(mappingFields.addressesFound)
    setmailingAddress(mappingFields.mailingAddress)
    setTotalMatchRows(mappingFields.matchrows)
    setNewRowsFound(mappingFields.newrows)
    setMailingAlreadyExists(mappingFields.oldMailing)
    setNewPhoneNumberFound(mappingFields.phoneFound)
    setNewaddressfound(mappingFields.outofnew)
    setmergeCsv(localStorage.getItem("mergeCsv"))
    if (
      JSON.parse(localStorage.getItem("mappedData")) === "" ||
      JSON.parse(localStorage.getItem("mappedData")) == null
    ) {
      localStorage.setItem(
        "mappedData",
        JSON.stringify({
          ...mappingFields.mappedValues,
          ...mappingFields.mappedPhoneValues,
          ...mappingFields.mappedContactValues,
        })
      )
    }
    setRelative({
      ...mappingFields.mappedValues,
      ...mappingFields.mappedPhoneValues,
      ...mappingFields.mappedContactValues,
    })
  }

  let selectedData = []
  let allOptions = JSON.parse(localStorage.getItem("mappingFields"))
  const [options, setOptions] = useState(allOptions)
  // options.unshift({ label: "Select", value: "" })

  let mappedData1 = JSON.parse(localStorage.getItem("mappedData"))

  const mappingFields = JSON.parse(localStorage.getItem("mappingData"))
  const SelectChangeHandle = (selectedOption, name, type = "") => {
    const listType = [
      "phone",
      "email",
      "caller_id_name",
      "caller_id_match",
      "carrier",
      "connected",
      "line_type",
    ]
    const value = selectedOption.value
    var option_name = name.name

    //   let NewData = {}
    let copyOptions = []
    allOptions.forEach(({ label }) => {
      const key = label.toLowerCase().replaceAll(" ", "_")
      if (mappedData1[key] && mappedData1[key] != "") {
      }
      if (label == value) {
      } else {
        copyOptions.push({ label, value: label, key })
      }
    })
    let unique = [
      ...new Map(copyOptions.map((item) => [item["value"], item])).values(),
    ]

    Object.entries(mappedData1).map(([label, value]) => {
      const index = unique.findIndex((object) => object.value === value)
      const index1 = unique.findIndex(
        (object) => object.value == selectedOption.value
      )
      if (index1 > -1) {
        unique.splice(index1, 1)
      }

      if (index > -1) {
        unique.splice(index, 1)
      }
    })
    unique.push({
      label: mappedData1[option_name],
      value: mappedData1[option_name],
    })

    setOptions(unique)

    selectedData.push({ [option_name]: value })
    mappedData1[option_name] = value
    localStorage.setItem("mappedData", JSON.stringify(mappedData1))
    setRelative(mappedData1)
    // }
  }

  const totalRecords = mappingFields.totalRows

  function saveMappingData() {
    let error = false
    let custom_fields = {}
    mapListCustomFields.map((item) => {
      // if (item?.sourceField == "Select" || item.mappingField == "Select") {
      //   toast.error("Please select source and mapping fields")
      //   error = true
      //   return
      // } else

      if (
        custom_fields[item.sourceField.toLocaleLowerCase().replace(/\s/g, "_")]
      ) {
        toast.error("Duplicate source field name")
        error = true
        return
      } else {
        custom_fields[
          item.sourceField.toLocaleLowerCase().replace(/\s/g, "_")
        ] = item.mappingField == "Select" ? "" : item.mappingField
      }
    })

    if (error) {
    } else {
      let mappedData = JSON.parse(localStorage.getItem("mappedData"))
      if (isREISift) {
        if (phone) {
          mappedData = {
            ...mappedData,
            phones: {
              ...phone,
            },
          }
        }
      }
      const version_id = mappingFields.version_id
      const filelist_id = mappingFields.filelist_id

      let action1 = "insert"
      if (localStorage.getItem("mergeCsv") !== null) {
        action1 = "update"
      }
      var i = 0

      setHide(true)
      mnipulatorMappingFields({
        action: action1,
        file_id: filelist_id,
        file_version_id: version_id,
        custom_fields,
        update_column_match: location?.state?.columnMatch || "mailing_address",
        update_column_behavior: location?.state?.blankType || "not_blank",
        mapping: [{ ...mappedData, ...custom_fields }],

        relative_status: relativeData,
      })
        .then(function (response) {
          // checkStatus()
          history.push("/data-manipulator")
        })

        .catch((error) => {
          setisLoading(false)
          //console.log("error", error)
        })
    }
  }
  let Owner1firstNameDefaultSelect = [{ label: "Select", value: "" }]
  let Owner1lastNameDefaultSelect = [{ label: "Select", value: "" }]
  let Owner2firstNameDefaultSelect = [{ label: "Select", value: "" }]
  let Owner2lastNameDefaultSelect = [{ label: "Select", value: "" }]
  let OwnerfullNameDefaultSelect = [{ label: "Select", value: "" }]
  let MailingAddressDefaultSelect = [{ label: "Select", value: "" }]
  let MailingcityDefaultSelect = [{ label: "Select", value: "" }]
  let MailingstateDefaultSelect = [{ label: "Select", value: "" }]
  let MailingzipDefaultSelect = [{ label: "Select", value: "" }]
  let AddressDefaultSelect = [{ label: "Select", value: "" }]
  let CityDefaultSelect = [{ label: "Select", value: "" }]
  let StateDefaultSelect = [{ label: "Select", value: "" }]
  let ZipDefaultSelect = [{ label: "Select", value: "" }]
  let PhoneDefaultSelect = [{ label: "Select", value: "" }]
  let PhonePositionDefaultSelect = [{ label: "Select", value: "" }]
  let CarrierSelectDefaultSelect = [{ label: "Select", value: "" }]
  let CallerIDDefaultSelect = [{ label: "Select", value: "" }]
  let CallerIDMatchDefaultSelect = [{ label: "Select", value: "" }]
  let LineTypeDefaultSelect = [{ label: "Select", value: "" }]
  let ConnectedDefaultSelect = [{ label: "Select", value: "" }]
  let LastDisposistionDefaultSelect = [{ label: "Select", value: "" }]
  let list = [{ label: "Select", value: "" }]
  let TimecalledDefaultSelect = [{ label: "Select", value: "" }]
  let TagDefaultSelect = [{ label: "Select", value: "" }]
  let DateTimeDefaultSelect = [{ label: "Select", value: "" }]
  let CallFromDefaultSelect = [{ label: "Select", value: "" }]
  let CustomerNumberDefaultSelect = [{ label: "Select", value: "" }]
  let CampaignNameDefaultSelect = [{ label: "Select", value: "" }]
  let CallResultDefaultSelect = [{ label: "Select", value: "" }]
  let DurationDefaultSelect = [{ label: "Select", value: "" }]
  let StatusDefaultSelect = [{ label: "Select", value: "" }]
  let NoteDefaultSelect = [{ label: "Select", value: "" }]
  let CustomerNameDefaultSelect = [{ label: "Select", value: "" }]
  let PhoneREISiftDefaultSelect = [{ label: "Select", value: "" }]

  useEffect(() => {
    let copyOptions = [...options]
    const dataColumns = JSON.parse(localStorage.getItem("mappedData"))

    Object.entries(dataColumns).map(([key, value]) => {
      if (value != "") {
        const indexOfOptions = copyOptions.findIndex((object) => {
          return object.label == value
        })
        copyOptions.splice(indexOfOptions, 1)
      }
    })

    setOptions(copyOptions)
  }, [])

  let EmailsREISiftDefaultSelect = [{ label: "Select", value: "" }]
  let CustomFieldREISiftDefaultSelect = [{ label: "Select", value: "" }]

  if (mappedData1 !== null) {
    if (mappedData1.owner_1_first_name !== "") {
      Owner1firstNameDefaultSelect = [
        {
          label: mappedData1.owner_1_first_name,
          value: mappedData1.owner_1_first_name,
        },
      ]
    }
    if (mappedData1.owner_1_last_name !== "") {
      Owner1lastNameDefaultSelect = [
        {
          label: mappedData1.owner_1_last_name,
          value: mappedData1.owner_1_last_name,
        },
      ]
    }
    if (mappedData1.owner_2_first_name !== "") {
      Owner2firstNameDefaultSelect = [
        {
          label: mappedData1.owner_2_first_name,
          value: mappedData1.owner_2_first_name,
        },
      ]
    }
    if (mappedData1.owner_2_last_name !== "") {
      Owner2lastNameDefaultSelect = [
        {
          label: mappedData1.owner_2_last_name,
          value: mappedData1.owner_2_last_name,
        },
      ]
    }
    if (mappedData1.full_name_of_owner !== "") {
      OwnerfullNameDefaultSelect = [
        {
          label: mappedData1.full_name_of_owner,
          value: mappedData1.full_name_of_owner,
        },
      ]
    }
    if (mappedData1.mailing_address !== "") {
      MailingAddressDefaultSelect = [
        {
          label: mappedData1.mailing_address,
          value: mappedData1.mailing_address,
        },
      ]
    }
    if (mappedData1.mailing_city !== "") {
      MailingcityDefaultSelect = [
        { label: mappedData1.mailing_city, value: mappedData1.mailing_city },
      ]
    }
    if (mappedData1.mailing_state !== "") {
      MailingstateDefaultSelect = [
        { label: mappedData1.mailing_state, value: mappedData1.mailing_state },
      ]
    }
    if (mappedData1.mailing_zip !== "") {
      MailingzipDefaultSelect = [
        { label: mappedData1.mailing_zip, value: mappedData1.mailing_zip },
      ]
    }
    if (mappedData1.address !== "") {
      AddressDefaultSelect = [
        { label: mappedData1.address, value: mappedData1.address },
      ]
    }
    if (mappedData1.city !== "") {
      CityDefaultSelect = [{ label: mappedData1.city, value: mappedData1.city }]
    }
    if (mappedData1.state !== "") {
      StateDefaultSelect = [
        { label: mappedData1.state, value: mappedData1.state },
      ]
    }
    if (mappedData1.zip !== "") {
      ZipDefaultSelect = [{ label: mappedData1.zip, value: mappedData1.zip }]
    }
    if (mappedData1.phone !== "") {
      PhoneDefaultSelect = [
        { label: mappedData1.phone, value: mappedData1.phone },
      ]
    }
    if (mappedData1.phone_position !== "") {
      PhonePositionDefaultSelect = [
        {
          label: mappedData1.phone_position,
          value: mappedData1.phone_position,
        },
      ]
    }
    if (mappedData1.carrier !== "") {
      CarrierSelectDefaultSelect = [
        { label: mappedData1.carrier, value: mappedData1.carrier },
      ]
    }
    if (mappedData1.caller_id_name !== "") {
      CallerIDDefaultSelect = [
        {
          label: mappedData1.caller_id_name,
          value: mappedData1.caller_id_name,
        },
      ]
    }
    if (mappedData1.caller_id_match !== "") {
      CallerIDMatchDefaultSelect = [
        {
          label: mappedData1.caller_id_match,
          value: mappedData1.caller_id_match,
        },
      ]
    }
    if (mappedData1.line_type !== "") {
      LineTypeDefaultSelect = [
        { label: mappedData1.line_type, value: mappedData1.line_type },
      ]
    }
    if (mappedData1.connected !== "") {
      ConnectedDefaultSelect = [
        { label: mappedData1.connected, value: mappedData1.connected },
      ]
    }
    if (mappedData1.last_disposition !== "") {
      LastDisposistionDefaultSelect = [
        {
          label: mappedData1.last_disposition,
          value: mappedData1.last_disposition,
        },
      ]
    }
    if (mappedData1.times_called !== "") {
      TimecalledDefaultSelect = [
        { label: mappedData1.times_called, value: mappedData1.times_called },
      ]
    }
    if (mappedData1.property_tag !== "") {
      TagDefaultSelect = [
        { label: mappedData1.property_tag, value: mappedData1.property_tag },
      ]
    }

    if (mappedData1.tag !== "") {
      list = [{ label: mappedData1.tag, value: mappedData1.tag }]
    }
  }

  const Owner1firstNameSelect = () => (
    <Select
      name='owner_1_first_name'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={Owner1firstNameDefaultSelect}
    />
  )

  const Owner1lastNameSelect = () => (
    <Select
      name='owner_1_last_name'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={Owner1lastNameDefaultSelect}
    />
  )

  const Owner2firstNameSelect = () => (
    <Select
      name='owner_2_first_name'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={Owner2firstNameDefaultSelect}
    />
  )

  const Owner2lastNameSelect = () => (
    <Select
      name='owner_2_last_name'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={Owner2lastNameDefaultSelect}
    />
  )

  const OwnerfullNameSelect = () => (
    <Select
      name='full_name_of_owner'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={OwnerfullNameDefaultSelect}
    />
  )

  const MailingAddressSelect = () => (
    <Select
      name='mailing_address'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={MailingAddressDefaultSelect}
    />
  )

  const MailingcitySelect = () => (
    <Select
      name='mailing_city'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={MailingcityDefaultSelect}
    />
  )

  const MailingstateSelect = () => (
    <Select
      name='mailing_state'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={MailingstateDefaultSelect}
    />
  )

  const MailingzipSelect = () => (
    <Select
      name='mailing_zip'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={MailingzipDefaultSelect}
    />
  )

  const AddressSelect = () => (
    <Select
      name='address'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={AddressDefaultSelect}
    />
  )

  const CitySelect = () => (
    <Select
      name='city'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={CityDefaultSelect}
    />
  )

  const StateSelect = () => (
    <Select
      name='state'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={StateDefaultSelect}
    />
  )

  const ZipSelect = () => (
    <Select
      name='zip'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={ZipDefaultSelect}
    />
  )

  const PropertyTags = () => (
    <Select
      name='property_tag'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={TagDefaultSelect}
    />
  )

  const LastDisposistionSelect = () => (
    <Select
      name='last_disposition'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={LastDisposistionDefaultSelect}
    />
  )

  const MappingField = () => (
    <Select
      name='last_disposition'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      // onChange={SelectChangeHandle}
      defaultValue={LastDisposistionDefaultSelect}
    />
  )

  const TimecalledSelect = () => (
    <Select
      name='times_called'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={TimecalledDefaultSelect}
    />
  )

  const TagSelect = () => (
    <Select
      name='tag'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={list}
    />
  )

  const DateTimeSelect = () => (
    <Select
      name='date_time'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={DateTimeDefaultSelect}
    />
  )

  const CallFromSelect = () => (
    <Select
      name='call_from'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={CallFromDefaultSelect}
    />
  )

  const CustomerNumberSelect = () => (
    <Select
      name='customer_number'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={CustomerNumberDefaultSelect}
    />
  )

  const CampaignNameSelect = () => (
    <Select
      name='campaign_name'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={CampaignNameDefaultSelect}
    />
  )

  const CallResultSelect = () => (
    <Select
      name='campaign_name'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={CallResultDefaultSelect}
    />
  )

  const DurationSelect = () => (
    <Select
      name='duration'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={DurationDefaultSelect}
    />
  )

  const StatusSelect = () => (
    <Select
      name='status'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={StatusDefaultSelect}
    />
  )

  const NoteSelect = () => (
    <Select
      name='note'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={NoteDefaultSelect}
    />
  )

  const CustomerNameSelect = () => (
    <Select
      name='customer_name'
      options={[{ label: "Select", value: "" }, ...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={SelectChangeHandle}
      defaultValue={CustomerNameDefaultSelect}
    />
  )

  const PhoneREISiftSelect = ({ index }) => (
    <Select
      name={`phone_${index}`}
      options={options}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={(selectedOption, name) =>
        SelectChangeHandle(selectedOption, name, "phone")
      }
      value={{
        label: phone[`phone_${index}`] || "Select",
        value: phone[`phone_${index}`],
      }}
      defaultValue={PhoneREISiftDefaultSelect}
    />
  )

  const EmailsREISiftSelect = ({ index }) => (
    <>
      <Select
        name={`email_${index}`}
        options={options}
        className='basic-multi-select'
        classNamePrefix='select'
        onChange={(selectedOption, name) =>
          SelectChangeHandle(selectedOption, name, "email")
        }
        value={
          relative[`email_${index}`] == ""
            ? { label: "Select", value: "" }
            : {
                label: relative[`email_${index}`],
                value: relative[`email_${index}`],
              }
        }
      />
    </>
  )

  const CustomFieldREISiftSelect = ({ index, className = true }) => (
    <Select
      options={[{ label: "Select", value: "Select" }, ...customFieldsOptions]}
      className={className ? "basic-multi-select" : ""}
      classNamePrefix='select'
      onChange={(selectedOption, name) =>
        onChangeCustomField(selectedOption.value, index, "mappingField", name)
      }
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      value={{
        label: listCustomFields[index]?.mappingField,
        value: listCustomFields[index]?.mappingField,
      }}
      // defaultValue={CustomFieldREISiftDefaultSelect}
    />
  )

  const CustomFieldMapping = ({ index, className = true, sourceField }) => (
    <Select
      options={[{ label: "Select", value: "Select" }, ...customFieldsOptions]}
      className={className ? "basic-multi-select" : ""}
      classNamePrefix='select'
      onChange={(selectedOption, name) =>
        onChangeMappedCustomField(
          selectedOption.value,
          index,
          "mappingField",
          name,
          mapListCustomFields[index]?.mappingField
        )
      }
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      value={{
        label: mapListCustomFields[index]?.mappingField,
        value: mapListCustomFields[index]?.mappingField,
      }}
      // defaultValue={CustomFieldREISiftDefaultSelect}
    />
  )

  const FileName = localStorage.getItem("fileName")

  const removeSourceField = (id) => {
    const filter = listCustomFields.filter((item) => item.id !== id)
    const array = filter.map((i, index) => ({ ...i, id: index }))
    setListCustomFields(array)
    if (listCustomFields[id].mappingField != "") {
      let copycustomFieldsOptions = [...customFieldsOptions]
      copycustomFieldsOptions.push({
        label: listCustomFields[id].mappingField,
        value: listCustomFields[id].mappingField,
      })
      const check = defaultOptions.find(
        (item) => item.value == listCustomFields[id]?.sourceField
      )
      if (listCustomFields[id]?.sourceField != "Select" && check) {
        let copySourceFieldsOptions = [...sourceFieldOptions]
        copySourceFieldsOptions.push({
          label: listCustomFields[id]?.sourceField,
          value: listCustomFields[id]?.sourceField,
        })
        setSourceFieldOptions(copySourceFieldsOptions)
      }
      // setSourceFieldOptions(copySourceFieldsOptions)
      setCustomFieldsOptions(copycustomFieldsOptions)
    }
  }

  const updateKeyValue = curry((id, key, value, array) =>
    map(when(propEq("id", id), assoc(key, value)), array)
  )

  const onChangeCustomField = (value, id, name, option_name) => {
    setListCustomFields(updateKeyValue(id, name, value, listCustomFields))
    let copycustomFieldsOptions = [...customFieldsOptions]
    const copyOptions = [...options]

    const indexOfObject = copycustomFieldsOptions.findIndex((object) => {
      return object.label == value
    })

    if (listCustomFields[id].mappingField != "Select") {
      copycustomFieldsOptions.push({
        label: listCustomFields[id].mappingField,
        value: listCustomFields[id].mappingField,
      })
    }
    copycustomFieldsOptions.splice(indexOfObject, 1)

    setCustomFieldsOptions(copycustomFieldsOptions)
  }

  const onChangeMappedCustomField = (value, id, sourceField) => {
    const copyMapList = [...mapListCustomFields]
    const copyObjects = { ...copyMapList[id] }
    copyObjects.mappingField = value
    copyMapList[id] = copyObjects
    setMapCustomFields(copyMapList)
    const mappingFields11 = JSON.parse(localStorage.getItem("mappingData"))
    const customFields = mappingFields?.mappedCustomFieldsValues || {}
    customFields[sourceField] = value
    mappingFields11.mappedCustomFieldsValues = customFields
    localStorage.setItem("mappingData", JSON.stringify(mappingFields11))
  }

  const [phoneCount, setPhoneCount] = useState(11)
  const relativeData = localStorage.getItem("relative-data")

  const handleNext = () => {
    const mappedData = JSON.parse(localStorage.getItem("mappedData"))
    if (mappedData["mailing_address"] == "") {
      toast.error("Please select Mailing Address")
    } else if (step == "0" && relativeData == "yes") {
      setStep("relative-contact")
    } else if (step == "0" && relativeData == "no") {
      setStep("1")
    } else if (step == "relative-contact") {
      setStep("person2-contact")
    } else if (step == "person2-contact") {
      setStep("person2_relative-contact")
    } else if (step == "person2_relative-contact") {
      setStep("person3-contact")
    } else if (step == "person3-contact") {
      setStep("person3_relative-contact")
    } else if (step == "person3_relative-contact") {
      setStep("1")
    } else if (step == "1" && relativeData == "yes") {
      setStep("relative1")
    } else if (step == "1") {
      setStep("2")
    } else if (
      !step.includes("person") &&
      step.includes("relative") &&
      step != "relative5"
    ) {
      setStep((prev) => `relative${+prev.slice(-1) + 1}`)
    } else if (step == "relative5") {
      setStep("person2")
    } else if (step == "person2") {
      setStep("person2_relative1")
    } else if (
      step.includes("person2") &&
      step.includes("relative") &&
      step != "person2_relative5"
    ) {
      setStep((prev) => `person2_relative${+prev.slice(-1) + 1}`)
    } else if (step == "person2_relative5") {
      setStep("person3")
    } else if (step == "person3") {
      setStep("person3_relative1")
    } else if (
      step.includes("person3") &&
      step.includes("relative") &&
      step != "person3_relative5"
    ) {
      setStep((prev) => `person3_relative${+prev.slice(-1) + 1}`)
    } else if (step == "person3_relative5" || step == "1") {
      setStep("2")
    } else if (step == "2") {
      // setStep("3")
      saveMappingData()
    }
    // else if (step == "3") {
    //   saveMappingData()
    // }
  }

  const handlePrev = () => {
    if (step == "0") {
      let BackUrl = "/data-manipulator"
      if (
        localStorage.getItem("mergeCsv") !== null ||
        localStorage.getItem("mergeCsv") === ""
      ) {
        BackUrl =
          "/data-manipulator-contacts-merge-csv/" +
          localStorage.getItem("mergeCsv")
      }
      history.push(BackUrl)
    } else if (step == "relative-contact") {
      setStep("0")
    } else if (step == "person2-contact") {
      setStep("relative-contact")
    } else if (step == "person2_relative-contact") {
      setStep("person2-contact")
    } else if (step == "person3-contact") {
      setStep("person2_relative-contact")
    } else if (step == "person3_relative-contact") {
      setStep("person3-contact")
    } else if (step == "1" && relativeData == "yes") {
      setStep("person3_relative-contact")
    } else if (step == "1" && relativeData == "no") {
      setStep("0")
    } else if (step == "relative1") {
      setStep("1")
    } else if (
      !step.includes("person") &&
      step.includes("relative") &&
      step != "relative1"
    ) {
      setStep((prev) => `relative${+prev.slice(-1) - 1}`)
    } else if (step == "person2") {
      setStep("relative5")
    } else if (step == "person2_relative1") {
      setStep("person2")
    } else if (
      step.includes("person2_relative") &&
      step != "person2_relative1"
    ) {
      setStep((prev) => `person2_relative${+prev.slice(-1) - 1}`)
    } else if (step == "person3") {
      setStep("person2_relative5")
    } else if (step == "person3_relative1") {
      setStep("person3")
    } else if (
      step.includes("person3_relative") &&
      step != "person3_relative1"
    ) {
      setStep((prev) => `person3_relative${+prev.slice(-1) - 1}`)
    } else if (step == "2" && relativeData == "yes") {
      setStep("person3_relative5")
    } else if (step == "2") {
      setStep("1")
    } else if (step == "3") {
      setStep("2")
    }
  }

  const headingStyling = (condition) => {
    if (condition) {
      return "sub-heading--active"
    } else {
      return "sub-heading"
    }
  }

  const [listCustomFields, setListCustomFields] = useState([
    {
      id: 0,
      sourceField: "Select",
      mappingField: "Select",
    },
  ])

  const [mapListCustomFields, setMapCustomFields] = useState([])
  const [defaultOptions, setDefaultOptions] = useState([])
  const [sourceFieldOptions, setSourceFieldOptions] = useState([])

  const [customFieldsOptions, setCustomFieldsOptions] = useState([])

  useEffect(() => {
    let copyListCustomField = []
    let copyDefaultOptions = [...defaultOptions]
    let copyCustomField = []
    const mappingFields = JSON.parse(localStorage.getItem("mappingData"))
    let customFields = mappingFields?.mappedCustomFieldsValues

    let i = 0

    if (options && Array.isArray(options)) {
      options.map(({ label, value }, index) => {
        if (customFields && customFields[value]) {
          copyListCustomField.push({
            id: i,
            sourceField: customFields[value],
            mappingField: value,
          })
          i = i + 1
        } else {
          copyCustomField.push({ label: label, value: value })
        }
        // else if (value != "") {
        //   copyListCustomField.push({
        //     id: index,
        //     sourceField:
        //       customFields && customFields[value]
        //         ? customFields[value]
        //         : "Select",
        //     mappingField: value,
        //   })
        // }

        if (customFields) {
          let a = copyDefaultOptions.filter(
            (item) => item.value != customFields[value]
          )
          copyDefaultOptions = a ? a : []
        }
      })
      setCustomFieldsOptions(copyCustomField)
      setSourceFieldOptions(copyDefaultOptions)

      // setListCustomFields(copyListCustomField)
      // setMapCustomFields(copyListCustomField)
      // setCustomFieldsOptions([])
    }
  }, [options, defaultOptions])

  useEffect(() => {
    const copyOptions = [...options]
    if (mapListCustomFields.length != 0) {
      mapListCustomFields.map((item) => {
        const indexOfOptions = copyOptions.findIndex((object) => {
          return object.label == item.mappingField
        })
        copyOptions.splice(indexOfOptions, 1)
      })
      setOptions(copyOptions)
    }
  }, [mapListCustomFields])

  useEffect(() => {
    let copyListCustomField = []
    let copyDefaultOptions = [...defaultOptions]
    let copyCustomField = []
    const mappingFields = JSON.parse(localStorage.getItem("mappingData"))
    let customFields = mappingFields?.mappedCustomFieldsValues
    let i = 0

    if (options && Array.isArray(options)) {
      options.map(({ label, value }, index) => {
        if (customFields && customFields[value]) {
          copyListCustomField.push({
            id: i,
            sourceField: customFields[value],
            mappingField: value,
          })
          i = i + 1
        } else {
          copyCustomField.push({ label: label, value: value })
        }
      })
      setSourceFieldOptions(copyDefaultOptions)
      setMapCustomFields(copyListCustomField)
    }
  }, [])

  const mapOptionsToValues = (options) => {
    return options.map((option) => ({
      value: option.field_name,
      label: option.field_name,
    }))
  }

  const handleGetAllTags = () => {
    // setisLoading(true)
    postRequest({ action: "get-all" }, "custom-fields").then(function (
      response
    ) {
      if (response?.data?.data) {
        setDefaultOptions(mapOptionsToValues(response?.data?.data))
      }
    })
  }

  useEffect(() => {
    handleGetAllTags()
  }, [])

  const onChangeSourceField = (value, id) => {
    setListCustomFields(
      updateKeyValue(id, "sourceField", value, listCustomFields)
    )
    let copyOptions = []
    sourceFieldOptions.map((item) => {
      if (value != item.value) {
        copyOptions.push(item)
      }
    })
    const check = defaultOptions.find(
      (item) => item.value == listCustomFields[id]?.sourceField
    )
    if (listCustomFields[id]?.sourceField != "Select" && check) {
      copyOptions.push({
        label: listCustomFields[id]?.sourceField,
        value: listCustomFields[id]?.sourceField,
      })
    }

    setSourceFieldOptions(copyOptions)
  }

  const [resetField, setResetField] = useState(false)

  const handleReset = () => {
    let mappedData2 = JSON.parse(localStorage.getItem("mappedData"))
    let steps = {
      0: [
        "owner_1_first_name",
        "owner_1_last_name",
        "owner_2_first_name",
        "owner_2_last_name",
        "full_name_of_owner",
        "mailing_address",
        "mailing_city",
        "mailing_state",
        "mailing_zip",
        "address",
        "city",
        "state",
        "zip",
        "property_tag",
        "last_disposition",
        "tag",
      ],
      "relative-contact": Array.from(Array(6).keys())
        .slice(1)
        .flatMap((index) => [`relative${index}_name`, `relative${index}_age`]),
      "person2-contact": [
        "person2_first_name",
        "person2_last_name",
        "person2_age",
        "person2_deceased",
        "person2_mailing_address",
        "person2_mailing_city",
        "person2_mailing_zip",
        "person2_mailing_state",
      ],
      "person2_relative-contact": Array.from(Array(6).keys())
        .slice(1)
        .flatMap((index) => [
          `person2_relative${index}_name`,
          `person2_relative${index}_age`,
        ]),
      "person3-contact": [
        "person3_first_name",
        "person3_last_name",
        "person3_age",
        "person3_deceased",
        "person3_mailing_address",
        "person3_mailing_city",
        "person3_mailing_zip",
        "person3_mailing_state",
      ],

      "person3_relative-contact": Array.from(Array(6).keys())
        .slice(1)
        .flatMap((index) => [
          `person3_relative${index}_name`,
          `person3_relative${index}_age`,
        ]),
      1: Array.from(Array(31).keys())
        .slice(1)
        .flatMap((index) => [
          `aeri_phone_${index}`,
          `carrier_${index}`,
          `caller_id_name_${index}`,
          `line_type_${index}`,
          `connected_${index}`,
          `caller_id_match_${index}`,
        ]),
      person2_phone: Array.from(Array(8).keys())
        .slice(1)
        .flatMap((index) => [
          `person2_phone${index}`,
          `person2_phone${index}_carrier`,
          `person2_phone${index}_caller_id_name`,
          `person2_phone${index}_line_type`,
          `person2_phone${index}_connected`,
          `caller_id_match`,
        ]),
      person3_phone: Array.from(Array(8).keys())
        .slice(1)
        .flatMap((index) => [
          `person3_phone${index}`,
          `person3_phone${index}_carrier`,
          `person3_phone${index}_caller_id_name`,
          `person3_phone${index}_line_type`,
          `person3_phone${index}_connected`,
          `person3_phone${index}_caller_id_match`,
        ]),
      2: Array.from(Array(11).keys())
        .slice(1)
        .flatMap((index) => `email_${index}`),
    }

    Array.from(Array(6).keys())
      .slice(1)
      .map((i) => {
        steps[`relative${i}`] = Array.from(Array(6).keys())
          .slice(1)
          .flatMap((index) => [
            `relative${i}_phone${index}`,
            `relative${i}_phone${index}_carrier`,
            `relative${i}_phone${index}_caller_id_name`,
            `relative${i}_phone${index}_line_type`,
            `relative${i}_phone${index}_connected`,
            `relative${i}_phone${index}_caller_id_match`,
          ])
        steps[`person2_relative${i}`] = Array.from(Array(6).keys())
          .slice(1)
          .flatMap((index) => [
            `person2_relative${i}_phone${index}`,
            `person2_relative${i}_phone${index}_carrier`,
            `person2_relative${i}_phone${index}_caller_id_name`,
            `person2_relative${i}_phone${index}_line_type`,
            `person2_relative${i}_phone${index}_connected`,
            `person2_relative${i}_phone${index}_caller_id_match`,
          ])
        steps[`person3_relative${i}`] = Array.from(Array(6).keys())
          .slice(1)
          .flatMap((index) => [
            `person3_relative${i}_phone${index}`,
            `person3_relative${i}_phone${index}_carrier`,
            `person3_relative${i}_phone${index}_caller_id_name`,
            `person3_relative${i}_phone${index}_line_type`,
            `person3_relative${i}_phone${index}_connected`,
            `person3_relative${i}_phone${index}_caller_id_match`,
          ])
      })
    if (step == 3) {
      setListCustomFields([])
      setSourceFieldOptions(defaultOptions)
      setCustomFieldsOptions(options)
    } else {
      steps[step].map((items) => {
        mappedData2[items] = ""
      })
      let allOptions = JSON.parse(localStorage.getItem("mappingFields"))
      let copyOptions = []
      allOptions.forEach(({ label, value }, ind) => {
        const key = label.toLowerCase().replaceAll(" ", "_")
        if (mappedData2[key] && mappedData2[key] != "") {
        } else {
          copyOptions.push({ label: value, value, key })
        }
      })

      setOptions(copyOptions)
      localStorage.setItem("mappedData", JSON.stringify(mappedData2))
      setRelative(mappedData2)
    }
    const copyCustomField = mapListCustomFields.map((item) => {
      let copyItem = { ...item }
      return { sourceField: copyItem.sourceField, mappingField: "Select" }
    })
    setMapCustomFields(copyCustomField)
    setResetField(false)
  }

  const [confirmModal, setConfirmModal] = useState(false)

  return (
    <>
      <ConfirmModal
        showModal={resetField}
        handleClose={() => setResetField(false)}
        handleConfirm={handleReset}
        title={"Reset Fields"}
        message='Are you sure you want to reset all fields from this page?'
      />
      {confirmModal && (
        <CustomFieldModal
          listCustomFields={listCustomFields}
          options={options}
          setOptions={setOptions}
          setListCustomFields={setListCustomFields}
          mapListCustomFields={mapListCustomFields}
          setMapCustomFields={setMapCustomFields}
          onClose={() => setConfirmModal(false)}
          removeSourceField={removeSourceField}
          onChangeSourceField={onChangeSourceField}
          sourceFieldOptions={sourceFieldOptions}
          CustomFieldREISiftSelect={CustomFieldREISiftSelect}
        />
      )}
      <Wrapper>
        <Loading show={isLoading} />
        <TopNav />

        <div className='row mapping_fields'>
          <div className='flex justify-between'>
            <HeadingFiles>{FileName}</HeadingFiles>
            <div>
              {step == "0" && (
                <button
                  className='reset-btn mr-5'
                  onClick={() => setConfirmModal(true)}>
                  Add Custom Fields
                </button>
              )}
              <button className='reset-btn' onClick={() => setResetField(true)}>
                Reset Fields
              </button>
            </div>
          </div>
          <svg
            width='1016'
            height='1'
            viewBox='0 0 1016 1'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <line opacity='0.05' y1='0.5' x2='1016' y2='0.5' stroke='black' />
          </svg>
        </div>

        <div className='row mapping_fields top'>
          <div className='col-md-4'>
            <div className='row left_side'>
              <div className='col-md-8'>
                <Link to='/data-manipulator'>
                  <span className='icon_heading'> Upload File </span>
                </Link>
                <Link to='/mapping-fields' style={{ textDecoration: "none" }}>
                  <span
                    className='icon_heading_mapping icon_heading '
                    style={{
                      color:
                        step.includes("contact") || step == "0"
                          ? "#047cff"
                          : "",
                    }}>
                    {" "}
                    Map Contact Fields{" "}
                  </span>
                  <div
                    className={headingStyling(step == "0" || step == "0")}
                    onClick={() => setStep("0")}>
                    Person 1
                  </div>
                  {relativeData == "yes" && (
                    <>
                      {(step == "0" ||
                        (step.includes("contact") &&
                          step.includes("relative"))) && (
                        <div
                          style={{ textDecoration: "none" }}
                          onClick={() => setStep(`relative-contact`)}
                          className={
                            step == `relative-contact`
                              ? "sub-relative-data--active"
                              : "sub-relative-data"
                          }>
                          Relative
                        </div>
                      )}
                      <div
                        onClick={() => setStep("person2-contact")}
                        className={headingStyling(step == "person2-contact")}>
                        Person 2
                      </div>
                      {step.includes("person2") && step.includes("contact") && (
                        <div
                          style={{ textDecoration: "none" }}
                          onClick={() => setStep(`person2_relative-contact`)}
                          className={
                            step == `person2_relative-contact`
                              ? "sub-relative-data--active"
                              : "sub-relative-data"
                          }>
                          Relative{" "}
                        </div>
                      )}
                      <div
                        onClick={() => setStep("person3-contact")}
                        className={headingStyling(step == "person3-contact")}>
                        Person 3
                      </div>
                      {step.includes("person3") && step.includes("contact") && (
                        <div
                          style={{ textDecoration: "none" }}
                          onClick={() => setStep(`person3_relative-contact`)}
                          className={
                            step == `person3_relative-contact`
                              ? "sub-relative-data--active"
                              : "sub-relative-data"
                          }>
                          Relative
                        </div>
                      )}
                    </>
                  )}
                </Link>
                <Link to='/mapping-fields' style={{ textDecoration: "none" }}>
                  <span
                    style={{
                      color:
                        !step.includes("contact") &&
                        step != "0" &&
                        step != "2" &&
                        step != "3"
                          ? "#047cff"
                          : "",
                    }}
                    className={`${
                      isREISift ? "icon_heading_mapping" : ""
                    } icon_heading`}>
                    Mapping Phone Fields
                  </span>
                  {isREISift && (
                    <>
                      <span
                        className={`${
                          isREISift
                            ? "icon_heading_mapping sub_label pr-38"
                            : ""
                        } icon_heading`}
                        onClick={() => setStep("1")}
                        style={{ color: step == 1 ? "#047cff" : "" }}>
                        Phone
                      </span>
                      {relativeData != "no" && (
                        <>
                          <div
                            onClick={() => setStep("relative1")}
                            className={
                              !step.includes("contact") &&
                              !step.includes("person") &&
                              step.includes("relative")
                                ? "sub-heading--active"
                                : "sub-heading"
                            }>
                            Relatives:
                          </div>
                          {!step.includes("contact") && (
                            <>
                              {!step.includes("person") &&
                                step.includes("relative") &&
                                Array.from(Array(6).keys())
                                  .slice(1)
                                  .map((index) => (
                                    <div
                                      style={{ textDecoration: "none" }}
                                      onClick={() =>
                                        setStep(`relative${index}`)
                                      }
                                      className={
                                        step == `relative${index}`
                                          ? "sub-relative-data--active"
                                          : "sub-relative-data"
                                      }>
                                      Relative {index}
                                    </div>
                                  ))}
                            </>
                          )}
                          <div
                            onClick={() => setStep("person2")}
                            className={
                              step == "person2"
                                ? "sub-heading--active"
                                : "sub-heading"
                            }>
                            Person 2:
                          </div>
                          {!step.includes("contact") &&
                            step.includes("person2") &&
                            Array.from(Array(6).keys())
                              .slice(1)
                              .map((index) => (
                                <div
                                  onClick={() =>
                                    setStep(`person2_relative${index}`)
                                  }
                                  className={
                                    step == `person2_relative${index}`
                                      ? "sub-relative-data--active"
                                      : "sub-relative-data"
                                  }>
                                  Relative {index}
                                </div>
                              ))}
                          <div
                            onClick={() => setStep("person3")}
                            className={headingStyling(step == "person3")}>
                            Person 3:
                          </div>
                          {!step.includes("contact") &&
                            step.includes("person3") &&
                            Array.from(Array(6).keys())
                              .slice(1)
                              .map((index) => (
                                <div
                                  onClick={() => {
                                    setStep(`person3_relative${index}`)
                                    setPhoneCount(11)
                                  }}
                                  className={
                                    step == `person3_relative${index}`
                                      ? "sub-relative-data--active"
                                      : "sub-relative-data"
                                  }>
                                  Relative {index}
                                </div>
                              ))}
                        </>
                      )}

                      <span
                        className={`${
                          isREISift
                            ? "icon_heading_mapping sub_label pr-38"
                            : ""
                        } icon_heading`}
                        onClick={() => setStep("2")}
                        style={{ color: step == 2 ? "#047cff" : "" }}>
                        Email
                      </span>
                      {/* <span
                        className={`${
                          isREISift ? "icon_heading_mapping sub_label" : ""
                        } icon_heading`}
                        onClick={() => setStep("3")}
                        style={{ color: step == 3 ? "#047cff" : "" }}>
                        Custom Field
                      </span> */}
                    </>
                  )}
                </Link>
                {/* <Link to='/report'> */}
                <span className='icon_heading'> Report </span>
                {/* </Link> */}
              </div>{" "}
              <div className='col-md-4'>
                <div className='main_icons'>
                  <span className='left_icons'>
                    <svg
                      width='23'
                      height='23'
                      viewBox='0 0 28 23'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M13.764 6.94118C13.5288 6.94118 13.304 7.03753 13.1423 7.20729L7.12022 13.5161C6.59831 14.0621 6.98602 14.9706 7.74193 14.9706H10.8963V21.5662C10.8963 22.3565 11.5398 23 12.3302 23H15.1978C15.9881 23 16.6316 22.3565 16.6316 21.5662V14.9706H19.786C20.542 14.9706 20.9297 14.0621 20.4078 13.5161L14.3857 7.20729C14.224 7.03753 13.9991 6.94118 13.764 6.94118Z'
                        fill='#A0A0A0'
                      />
                      <path
                        d='M25.5221 0.0587373H2.00735C0.900441 0.0587373 0 0.959178 0 2.06609V2.63962C0 3.74653 0.900441 4.64697 2.00735 4.64697H25.5221C26.629 4.64697 27.5294 3.74653 27.5294 2.63962V2.06609C27.5294 0.959178 26.629 0.0587373 25.5221 0.0587373Z'
                        fill='#A0A0A0'
                      />
                    </svg>
                  </span>
                </div>
                <div className='icon_border' />
                <div className='main_icons'>
                  <span className='left_icons active'>
                    <svg
                      width='24'
                      height='18'
                      viewBox='0 0 24 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M21.002 0.749023H6.00728C5.17915 0.749023 4.50781 1.42037 4.50781 2.2485C4.50781 3.07663 5.17915 3.74797 6.00728 3.74797H21.002C21.8301 3.74797 22.5015 3.07663 22.5015 2.2485C22.5015 1.42037 21.8301 0.749023 21.002 0.749023Z'
                        fill='white'
                      />
                      <path
                        d='M3.00734 2.24853C3.00566 1.8515 2.84657 1.47133 2.565 1.1914C1.98016 0.610028 1.03559 0.610028 0.450744 1.1914C0.169124 1.47133 0.0100394 1.8515 0.00839939 2.24853C-0.0027998 2.34567 -0.0027998 2.44379 0.00839939 2.54093C0.0253622 2.63863 0.0530087 2.73417 0.0908704 2.82583C0.131122 2.91462 0.178777 2.99991 0.23332 3.08074C0.287161 3.1648 0.349998 3.24273 0.420754 3.31316C0.48898 3.3811 0.564376 3.44145 0.645675 3.49309C0.724632 3.55059 0.810242 3.59843 0.900586 3.63554C0.999973 3.68709 1.10592 3.72495 1.21547 3.748C1.31261 3.75887 1.41073 3.75887 1.50787 3.748C1.90383 3.74833 2.28385 3.59201 2.565 3.31316C2.63576 3.24273 2.69859 3.1648 2.75243 3.08074C2.80698 2.99991 2.85463 2.91462 2.89488 2.82583C2.94273 2.7353 2.98045 2.63975 3.00734 2.54093C3.01854 2.44379 3.01854 2.34567 3.00734 2.24853Z'
                        fill='white'
                      />
                      <path
                        d='M3.00773 8.99609C3.01889 8.89895 3.01889 8.80083 3.00773 8.70369C2.98182 8.60693 2.94405 8.51377 2.89527 8.42629C2.85666 8.33431 2.80891 8.2464 2.75282 8.16388C2.70086 8.08075 2.63779 8.00508 2.56539 7.93896C1.98055 7.35759 1.03597 7.35759 0.451133 7.93896C0.169514 8.21889 0.0104291 8.59906 0.00878906 8.99609C0.0116943 9.19369 0.0497903 9.38914 0.121249 9.57339C0.15883 9.66209 0.204002 9.74737 0.256202 9.8283C0.313229 9.90978 0.37855 9.98518 0.451133 10.0532C0.517391 10.1255 0.593021 10.1885 0.676054 10.2407C0.755011 10.2982 0.840575 10.346 0.930965 10.3831C1.02239 10.4216 1.11802 10.4493 1.21586 10.4656C1.31178 10.4871 1.40995 10.4972 1.50826 10.4956C1.6054 10.5068 1.70352 10.5068 1.80066 10.4956C1.89606 10.4792 1.98922 10.4515 2.07806 10.4131C2.17089 10.3763 2.25898 10.3284 2.34047 10.2706C2.4235 10.2185 2.49913 10.1555 2.56539 10.0832C2.63765 10.0169 2.70072 9.94132 2.75282 9.85829C2.81046 9.77938 2.8583 9.69381 2.89527 9.60338C2.94649 9.50385 2.9843 9.39795 3.00773 9.28849C3.01926 9.19135 3.01926 9.09323 3.00773 8.99609Z'
                        fill='white'
                      />
                      <path
                        d='M3.00783 15.7436C3.01893 15.6465 3.01893 15.5484 3.00783 15.4512C2.98191 15.3521 2.94415 15.2565 2.89537 15.1663C2.85511 15.0776 2.80746 14.9923 2.75292 14.9114C2.70081 14.8284 2.63774 14.7528 2.56548 14.6865C1.98064 14.1051 1.03607 14.1051 0.451225 14.6865C0.37897 14.7528 0.315898 14.8284 0.263791 14.9114C0.209248 14.9923 0.161593 15.0776 0.121341 15.1663C0.0824489 15.2576 0.0547555 15.3533 0.0388704 15.4512C0.0177372 15.5473 0.00770952 15.6453 0.00888098 15.7436C0.0105679 16.1407 0.169653 16.5208 0.451225 16.8008C0.517483 16.873 0.593113 16.9361 0.676146 16.9882C0.755103 17.0458 0.840667 17.0936 0.931057 17.1307C1.02248 17.1691 1.11812 17.1968 1.21596 17.2131C1.31188 17.2346 1.41004 17.2447 1.50835 17.2431C1.60549 17.2543 1.70361 17.2543 1.80075 17.2431C1.89615 17.2268 1.98931 17.1991 2.07815 17.1606C2.17098 17.1238 2.25907 17.076 2.34056 17.0182C2.42359 16.9661 2.49922 16.903 2.56548 16.8308C2.63774 16.7645 2.70081 16.6889 2.75292 16.6058C2.8106 16.527 2.85844 16.4414 2.89537 16.3509C2.94654 16.2514 2.98435 16.1455 3.00783 16.036C3.01935 15.9389 3.01935 15.8408 3.00783 15.7436Z'
                        fill='white'
                      />
                      <path
                        d='M22.5015 7.49658H6.00728C5.17915 7.49658 4.50781 8.16792 4.50781 8.99606C4.50781 9.82419 5.17915 10.4955 6.00728 10.4955H22.5015C23.3296 10.4955 24.001 9.82419 24.001 8.99606C24.001 8.16792 23.3296 7.49658 22.5015 7.49658Z'
                        fill='white'
                      />
                      <path
                        d='M15.7539 14.2441H6.00729C5.17915 14.2441 4.50781 14.9155 4.50781 15.7436C4.50781 16.5717 5.17915 17.2431 6.00729 17.2431H15.7539C16.582 17.2431 17.2533 16.5717 17.2533 15.7436C17.2533 14.9155 16.582 14.2441 15.7539 14.2441Z'
                        fill='white'
                      />
                    </svg>
                  </span>
                </div>

                <div
                  className={`${
                    isREISift ? "icon_border_mapping" : ""
                  } icon_border`}
                />
                <div className='main_icons'>
                  <span className='left_icons'>
                    <svg
                      width='21'
                      height='25'
                      viewBox='0 0 21 25'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M19.8068 6.07383L13.9477 0.2146C13.8125 0.0793945 13.6252 0 13.4297 0H3.17578C1.96421 0 0.978516 0.985693 0.978516 2.19727V22.8027C0.978516 24.0143 1.96421 25 3.17578 25H17.8242C19.0358 25 20.0215 24.0143 20.0215 22.8027V6.5918C20.0215 6.39248 19.9368 6.20366 19.8068 6.07383ZM14.1621 2.50063L17.5208 5.85938H14.8945C14.4907 5.85938 14.1621 5.53081 14.1621 5.12695V2.50063ZM17.8242 23.5352H3.17578C2.77192 23.5352 2.44336 23.2066 2.44336 22.8027V2.19727C2.44336 1.79341 2.77192 1.46484 3.17578 1.46484H12.6973V5.12695C12.6973 6.33853 13.683 7.32422 14.8945 7.32422H18.5566V22.8027C18.5566 23.2066 18.2281 23.5352 17.8242 23.5352Z'
                        fill='#A0A0A0'
                      />
                      <path
                        d='M14.8945 10.3516H6.10547C5.70098 10.3516 5.37305 10.6795 5.37305 11.084V19.873C5.37305 20.2775 5.70098 20.6055 6.10547 20.6055H14.8945C15.299 20.6055 15.627 20.2775 15.627 19.873V11.084C15.627 10.6795 15.299 10.3516 14.8945 10.3516ZM6.83789 14.7461H9.76758V16.2109H6.83789V14.7461ZM11.2324 14.7461H14.1621V16.2109H11.2324V14.7461ZM14.1621 13.2812H11.2324V11.8164H14.1621V13.2812ZM9.76758 11.8164V13.2812H6.83789V11.8164H9.76758ZM6.83789 17.6758H9.76758V19.1406H6.83789V17.6758ZM11.2324 19.1406V17.6758H14.1621V19.1406H11.2324Z'
                        fill='#A0A0A0'
                      />
                    </svg>
                  </span>{" "}
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-8'>
            {mergeCsv !== null && (
              <div>
                <span className='icon_heading_blue'>
                  {" "}
                  Matching Rows Summary{" "}
                </span>
                <div className='row'>
                  <div className='col-md-4 merge'>
                    <span> Total Match Rows : </span>{" "}
                    <span className='count'>{TotalMatchRows}</span>
                  </div>
                  <div className='col-md-8 merge'>
                    <span> New Rows Found : </span>{" "}
                    <span className='count'>{NewRowsFound}</span>
                  </div>
                  <div className='col-md-12 merge'>
                    <span>
                      Mailing Address Already Exists in Previous Records :{" "}
                    </span>
                    <span className='count'>{MailingAlreadyExists}</span>
                  </div>

                  <div className='col-md-12 merge'>
                    <span>
                      {" "}
                      New Phone Number Found (Existing Mailing Address) :{" "}
                    </span>{" "}
                    <span className='count'>{NewPhoneNumberFound}</span>
                  </div>

                  <div className='col-md-12 merge'>
                    <span>
                      {" "}
                      New Address Found (Existing Mailing Address) :{" "}
                    </span>{" "}
                    <span className='count'>{Newaddressfound}</span>
                  </div>
                </div>
              </div>
            )}

            <div className='row form'>
              {isREISift && step == 1 && (
                <>
                  {Array.from(Array(phoneCount).keys())
                    .slice(1)
                    .map((index) => (
                      <Phone
                        index={index}
                        phone={relative}
                        SelectChangeHandle={SelectChangeHandle}
                        options={options}
                      />
                    ))}
                  {phoneCount < 31 ? (
                    <button
                      onClick={() => setPhoneCount((prev) => prev + 10)}
                      className='block w--200 margin-t-30 mx--auto bg-primary hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 rounded'>
                      Show more
                    </button>
                  ) : null}
                </>
              )}
              {relativeData == "yes" &&
                step != 0 &&
                step != 1 &&
                step != 2 &&
                step != 3 && (
                  <>
                    {Array.from(
                      Array(
                        step == "mapping"
                          ? phoneCount
                          : step == "person2" || step == "person3"
                          ? 8
                          : step.includes("contact")
                          ? 2
                          : 6
                      ).keys()
                    )
                      .slice(1)
                      .map((index) => (
                        <>
                          {step.includes("contact") ? (
                            <ContactFields
                              step={step.split("-")[0]}
                              relative={relative}
                              options={options}
                              onChange={SelectChangeHandle}
                            />
                          ) : (
                            <Relative
                              step={step}
                              relative={relative}
                              index={index}
                              options={options}
                              onChange={SelectChangeHandle}
                            />
                          )}
                        </>
                      ))}
                  </>
                )}

              {isREISift && step == 2 && (
                <>
                  {Array.from(Array(11).keys())
                    .slice(1)
                    .map((emails) => (
                      <div className='col-md-6 mb-0' key={emails}>
                        <div className='fields'>
                          <label> Email {emails} </label>{" "}
                          <EmailsREISiftSelect index={emails} />
                        </div>
                      </div>
                    ))}
                </>
              )}

              {isREISift && step == 3 && (
                <>
                  {listCustomFields.map((cus, index) => (
                    <div className='row mb-20 relative'>
                      <div className='col-md-7 '>
                        <LabelWrapper className='mb-2'>
                          <Label>Source Field</Label>
                        </LabelWrapper>
                        <CreatableSelect
                          // defaultOptions={defaultOptions}
                          onChange={(e) => onChangeSourceField(e.value, index)}
                          options={[
                            { label: "Select", value: "Select" },
                            ...sourceFieldOptions,
                          ]}
                          // loadOptions={handleSearch}
                          value={{
                            label: listCustomFields[index]?.sourceField,
                            value: listCustomFields[index]?.sourceField,
                          }}
                        />
                      </div>
                      <div className='col-md-4'>
                        <div className='fields'>
                          <label className='mb-2'>Mapping</label>
                          <CustomFieldREISiftSelect index={index} />
                        </div>
                      </div>
                      <div className='w-auto flex justify-end top-25 right-_20 absolute'>
                        <svg
                          className='icon-delete'
                          xmlns='http://www.w3.org/2000/svg'
                          width='46'
                          height='46'
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          className='bi bi-x'
                          viewBox='0 0 16 16'>
                          <path
                            onClick={() => removeSourceField(index)}
                            fill='#b34242'
                            cursor='pointer'
                            d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                  {customFieldsOptions.length != 0 && (
                    <svg
                      className='icon-delete'
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      className='bi bi-plus-circle-fill'
                      viewBox='0 0 16 16'>
                      <path
                        fill='#362e2e'
                        cursor='pointer'
                        // onClick={addCustomField}
                        d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z'
                      />
                    </svg>
                  )}
                </>
              )}

              {step == 0 && (
                <>
                  <div className='col-md-6'>
                    <div className='fields'>
                      {" "}
                      <label>Owner 1 First Name</label>{" "}
                      <Owner1firstNameSelect />
                    </div>
                    <div className='fields'>
                      {" "}
                      <label>Owner 2 First Name</label>{" "}
                      <Owner2firstNameSelect />
                    </div>
                    <div className='fields'>
                      {" "}
                      <label>Full name of Owner</label> <OwnerfullNameSelect />
                    </div>
                    <div className='fields'>
                      {" "}
                      <label>Mailing City</label> <MailingcitySelect />
                    </div>
                    <div className='fields'>
                      {" "}
                      <label> Mailing Zip</label> <MailingzipSelect />
                    </div>
                    <div className='fields'>
                      {" "}
                      <label>Property City</label> <CitySelect />
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='fields'>
                      {" "}
                      <label>Owner 1 Last Name</label> <Owner1lastNameSelect />
                    </div>
                    <div className='fields'>
                      {" "}
                      <label>Owner 2 Last Name</label> <Owner2lastNameSelect />
                    </div>
                    <div className='fields'>
                      {" "}
                      <label> Mailing Address </label> <MailingAddressSelect />
                    </div>
                    <div className='fields'>
                      {" "}
                      <label> Mailing State </label> <MailingstateSelect />
                    </div>
                    <div className='fields'>
                      {" "}
                      <label>Property Address </label> <AddressSelect />
                    </div>
                    <div className='fields'>
                      {" "}
                      <label>Property State</label> <StateSelect />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <label>Property Zip</label> <ZipSelect />
                  </div>
                  <div className='col-md-6'>
                    <div className='fields'>
                      {" "}
                      <label> Property Tag's</label> <PropertyTags />
                    </div>
                  </div>

                  {/* <div className='col-md-6'>
                    <div className='fields'>
                      {" "}
                      <label> Disposition</label> <LastDisposistionSelect />
                    </div>
                  </div> */}
                  <div className='col-md-6'>
                    <div className='fields'>
                      {" "}
                      <label> Contact Tag's </label> <TagSelect />
                    </div>
                  </div>
                  {mapListCustomFields.map(
                    ({ sourceField, mappingField, id }, index) => {
                      let text = sourceField
                      let result = text.replace(/_/g, " ")
                      sourceField = result
                        .toLowerCase()
                        .replace(/\b[a-z]/g, function (letter) {
                          return letter.toUpperCase()
                        })

                      return (
                        <div className='col-md-6' key={index}>
                          <div className='fields'>
                            {" "}
                            <label> {sourceField}</label>{" "}
                            <CustomFieldMapping
                              index={index}
                              sourceField={sourceField}
                            />
                          </div>
                        </div>
                      )
                    }
                  )}
                </>
              )}

              {(location?.state?.fileFormat === "Batch Dialer" ||
                localStorage.getItem("file_format") === "Batch Dialer") && (
                <>
                  <div className='col-md-6'>
                    <div className='fields'>
                      <label> Date/Time </label> <DateTimeSelect />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fields'>
                      <label> Call From </label> <CallFromSelect />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fields'>
                      <label> Customer Number </label> <CustomerNumberSelect />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fields'>
                      <label> Campaign Name </label> <CampaignNameSelect />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fields'>
                      <label> Call Result </label> <CallResultSelect />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fields'>
                      <label> Duration </label> <DurationSelect />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fields'>
                      <label>Status </label> <StatusSelect />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fields'>
                      <label> Note </label> <NoteSelect />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fields'>
                      <label> Customer Name </label> <CustomerNameSelect />
                    </div>
                  </div>
                </>
              )}
              {/* {(!isREISift || step == 3) && (
              <>
                <div className='main_status_bar'>
        
                  {
                    hide&&!done?
                  <div id='progresscount' >Records Processing: {records} out of {totalRecords}</div>:null
                  }
                  </div>
               
              </>
            )} */}
            </div>

            {done ? (
              <div className=''>
                <div className=' text-right flex justify-center mb-20'>
                  <button
                    onClick={(e) => history.push("/report")}
                    className='bg-primary hover:bg-blue-700 w-180 text-white font-bold py-2 px-4 rounded'>
                    Done
                  </button>
                </div>
              </div>
            ) : null}
            {!hide ? (
              <>
                {!isREISift ? (
                  <div className='row button'>
                    <div className=' text-right col-md-6 mt-4'>
                      <button
                        // onClick={(e) => {saveMappingData()}}s

                        onClick={(e) => {
                          saveMappingData()
                        }}
                        className='bg-primary hover:bg-blue-700 text-white w-50 font-bold py-2 px-4 rounded'>
                        Save
                      </button>
                    </div>
                    <div className='submitBtnMain col-md-6 mt-4'>
                      <button
                        onClick={handlePrev}
                        className='previous-button  font-bold py-2 px-4 w-50 rounded'>
                        Back
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='row button'>
                    <div className=' text-right col-md-6 mt-4'>
                      <button
                        onClick={handlePrev}
                        className='previous-button  font-bold py-2 px-4 w-50 rounded'>
                        Back
                      </button>
                    </div>
                    <div className='submitBtnMain col-md-6 mt-4'>
                      <button
                        onClick={
                          handleNext
                          // step == 3 ? saveMappingData() : setStep((pre) => pre + 1)
                        }
                        className='bg-primary hover:bg-blue-700 text-white w-50 font-bold py-2 px-4 rounded'>
                        {step == 2 ? "Save" : "Next"}
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </Wrapper>
    </>
  )
}

const HeadingFiles = styled.h2`
  font-weight: bold;
  color: #15342e;
  font-family: roboto;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.7;
  padding: 20px 0px 45px 0px;
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Label = styled.label`
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 16px;
`

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

export default MappingFields
