import React from "react"
import styled from "styled-components"

const Files = () => {
  return <Wrapper>This is files page</Wrapper>
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
`

export default Files
