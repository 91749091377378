import React from "react"
import styled from "styled-components"
import Item from "./tableItem"
const Table = (props) => {
  const files = props.files ? props.files : []
  const items = []
  for (let i = 0; i < files.length; i++) {
    items.push(
      <Item
        phone={files[i].phone}
        phone_position={files[i].phone_position}
        carrier={files[i].carrier}
        caller_id_name={files[i].caller_id_name}
        caller_id_match={files[i].caller_id_match}
        key={i}
        connected={files[i].connected}
        original_disposition={files[i].original_disposition}
        times_called={files[i].times_called}
        id={files[i].id}></Item>
    )
  }

  return (
    <Wrapper>
      <WrapperTitle></WrapperTitle>
      <div className='scroll-in-mobile dowloads'>
        {items.length > 0 && (
          <table className='listing crm-phone'>
            <tr className='heading'>
              <th>Phone Number</th>
              <th>Phone Position</th>
              <th>Carrier</th>
              <th>Caller ID Name</th>
              <th>Caller ID Match</th>
              <th>Line Type</th>
              <th>Conntected</th>
              <th>Disposition</th>
              <th>Time Called</th>
            </tr>
            {items}
          </table>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 10px;
`

export default Table
