import React, { useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import COLORS from "utils/colors"
import tw from "twin.macro"
import RecordItem from "./RecordItem"
import { editCustomFieldData } from "services/dataValidation"
import { toast } from "react-toastify"

const Modal = ({
  data,
  setConfirm,
  deleteCustomField,
  onClose,
  addressPopup,
  mailingAddress,
}) => {
  const fields = data?.data || []

  const [editValues, setEditValues] = useState({})

  const updateValues = async () => {
    let flag = true
    const custom_fields = Object.entries(editValues).map(([key, value]) => {
      return { id: key, custom_field_value: value }
    })

    const copyCustomField = [...custom_fields]
    if (custom_fields.length == 0) {
      fields.map(
        ({ custom_fields, address, city, state, zip, mailing_address }) => {
          custom_fields?.map(
            ({ custom_field_name, custom_field_value, id }) => {
              copyCustomField.push({ id, custom_field_value })
            }
          )
        }
      )
    }

    copyCustomField.map(({ custom_field_value }) => {
      if (custom_field_value == "") {
        flag = false
      }
    })

    if (flag) {
      const result = await editCustomFieldData({
        custom_fields: copyCustomField,
      })
      if (result.status) {
        toast.success("Custom field is updated successful")
        addressPopup(mailingAddress)
        onClose()
      }
    } else {
      toast.error("Please enter the source value")
    }
  }

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='manipulator-close close-btn1'
        onClick={onClose}>
        x
      </button>
      <WrapperContent>
        <WrapperHeader className='manipulator-phone-modal'>
          <div className='flex heading-title'>
            {fields.length != 0 && (
              <div className='w-61 font-bold pl-10'>Row-1</div>
            )}
            <h1 className='popupModalHeader'>
              <span className='float-left'></span>Edit Custom Field
            </h1>
          </div>
          <WrapperBody>
            {fields.map(
              (
                { custom_fields, address, city, state, zip, mailing_address },
                index
              ) => (
                <>
                  {index != 0 && (
                    <div className='flex header-title'>
                      <div className='w-100 font-bold pl-10'>
                        Row-{index + 1}
                      </div>
                    </div>
                  )}

                  <div className='flex justify-center my-10 font-bold'>
                    {(address ? ` ${address},` : "") +
                      (city ? ` ${city},` : "") +
                      (state ? ` ${state}, ` : "") +
                      zip}
                  </div>
                  <div className='mx-2%'>
                    <table className='popupTablelisting popupTablelisting-cus'>
                      <tr className='heading'>
                        {/* <th width='33%'>Source Name</th>
                      <th width>Source Value</th>
                      <th width='33%'>Action</th> */}
                        {/* <th width='30%'>Action</th> */}
                      </tr>
                      {custom_fields?.map(
                        ({ custom_field_name, custom_field_value, id }) => (
                          <RecordItem
                            customFieldName={custom_field_name}
                            customFieldValue={custom_field_value}
                            id={id}
                            setConfirm={setConfirm}
                            onClose={onClose}
                            AddressPopup={addressPopup}
                            mailingAddress={mailing_address}
                            deleteCustomField={deleteCustomField}
                            setEditValues={setEditValues}
                          />
                        )
                      )}
                    </table>
                    <button
                      className='edit-submit-btn ml-auto'
                      onClick={updateValues}>
                      Save
                    </button>
                  </div>
                </>
              )
            )}
          </WrapperBody>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 968px;
  min-height: 200px;
  left: 280px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 9999;
  top: 10px;
  max-height: 76vh;

  border-radius: 0;
  width: calc(98% - 245px);
  left: 260px;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0px 0px 20px 0px;
  border: #d1d1d1 solid 1px;
  overflow-y: hidden;
  border-radius: 20px;
`

const WrapperBody = styled.div`
  max-height: 57vh;
  overflow: auto;
  border-radius: 10px;
`
const MainButton = styled.div`
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 3px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height: 35px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  ${tw`
    shadow-lg
  `}
`
const Label = styled.label`
  font-size: 12px;
  display: table;
  cursor: pointer;
  color: #fff;
  font-family: "Montserrat";
  text-align: center;
  padding: 10px;
  font-weight: bold;
`

const Upload = styled.input`
  display: none;
  cursor: pointer;
`
