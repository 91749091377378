import React, { useState } from "react"
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js"
import { getUserID } from "utils/helper"
import { AddNewCardAPI } from "services/paymentDeatil"
import CardSection from "./CardSection"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Loading from "components/loading"

// class CheckoutForm extends React.Component {
const CheckoutForm = (props) => {
  const [isLoading, setisLoading] = useState(false)
  const handleSubmit = async (event) => {
    setisLoading(true)
    event.preventDefault()
    const { stripe, elements,getUserCreditCardsDetails } = props
    if (!stripe || !elements) {
      return
    }

    const card = elements.getElement(CardElement)
    const result = await stripe.createToken(card)
    // setCardDat(result.token.card['last4']);
    if (result.error) {
      toast.error(result.error.message)
      setisLoading(false)
    } else {
      const cardToken = result.token["id"]
      const userId = getUserID()
      AddNewCardAPI({
        user_id: userId,
        stripeToken: cardToken,
      })
        .then(function (response) {
          if (response.status === true) {
            setisLoading(false)
            toast.success(response["message"])
            setTimeout(function () {
              window.location.reload()
            }, 1000)
           
          } else {
            setisLoading(false)
            toast.error(response["message"])
          }
        })
        .catch((error) => {
          setisLoading(false)
          console.log("error", error)
        })
    }
  }

  return (
    <div>
      <Loading show={isLoading} />
      <form onSubmit={handleSubmit}>
        <CardSection />
        <button disabled={!props.stripe} className='btn-pay'>
          Add New Card
        </button>
      </form>
    </div>
  )
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  )
}
