import { BASE_URL } from "config"
import axiosService from "./axios.service"

export async function getUserByIdCall() {
  const url = BASE_URL + "user"
  return axiosService.get(url)
}

export async function upddateProfileinfoCall(requestObj, id) {
  const url = BASE_URL + "profile-update"
  return axiosService.post(url, requestObj)
}

export async function upddateProfileImageCall(requestObj, id) {
  const url = BASE_URL + "profile-update"
  return axiosService.post(url, requestObj)
}

export async function ResetPassword(requestObj, page) {
  const url = BASE_URL + "reset-profile-password"
  return axiosService.post(url, requestObj)
}
