import React from 'react';
import Select from "react-select"
import AdvanceFilters from '../advance-filter/AdvanceFilters'


export const PhoneSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="phone" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

export const Owner1fnSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="owner_1" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const Owner2fnSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="owner_2" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const MailingAddSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="mailing_address" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const MailingCitySelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="mailing_city" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const MailingZipSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="mailing_zip" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const MailingStateSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="mailing_state" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const AddressSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="address" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const CitySelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="city" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const ZipSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="zip" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const StateSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="state" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const CarrierSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="carrier" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const CarrierIDSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="caller_id_name" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  //  const mapOptionsToValues = options => {
  //   return options.map(option => ({
  //     value: option.title,
  //     label: option.title,
  //     id:option.id
  //   }));
  // };

  export const TagSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <>
      <AdvanceFilters name="tag" fileID={fileID}
        advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
    </>
  )

  export const LineTypeSelect = ({advanceSearch, fileID, setAdvanceSearch}) => (
    <AdvanceFilters name="line_type" fileID={fileID}
      advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} />
  )

  export const ActiveSelect = ({ActiveOption, customStyles, SelectChangeHandle, SelectedActive, SelectedCallerIDMatch}) => (
    <Select
      name='connected'
      options={ActiveOption}
      className='active'
      classNamePrefix='select'
      styles={customStyles}
      onChange={SelectChangeHandle}
      defaultValue={{ label: SelectedActive, value: SelectedActive }}
    />
  )

  export const CallerIDMatchSelect = ({ActiveOption, customStyles, SelectChangeHandle, SelectedCallerIDMatch}) => (
    <Select
      name='caller_id_match'
      options={ActiveOption}
      className='caller_id_match'
      classNamePrefix='select'
      styles={customStyles}
      onChange={SelectChangeHandle}
      defaultValue={{
        label: SelectedCallerIDMatch,
        value: SelectedCallerIDMatch,
      }}
    />
  )
  export const CustomFilters = ({advanceSearch, setAdvanceSearch, fileID,ActiveOption, customStyles, SelectChangeHandle, SelectedActive, SelectedCallerIDMatch, AdvanSearch, AdvanceFilterToggle, AdClear}) => (
    <div className='row manipulator-contacts filters'>
        <div className='col-md-3'>
          {" "}
          <label> Phone</label>
          <PhoneSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID}/>
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Owner 1 </label> <Owner1fnSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID} />
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Owner 2 </label>
          <Owner2fnSelect  advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID}/>
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Mailing Address</label>
          <MailingAddSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID} />
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Mailing City</label> <MailingCitySelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID} />
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Mailing Zip</label> <MailingZipSelect  advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID}/>
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Mailing State</label> <MailingStateSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID} />
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Address</label> <AddressSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID}/>
        </div>
        <div className='col-md-3'>
          {" "}
          <label> City</label> <CitySelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID}/>
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Zip</label> <ZipSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID} />
        </div>
        <div className='col-md-3'>
          {" "}
          <label> State</label> <StateSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID}/>
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Carrier</label> <CarrierSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID}/>
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Caller ID Name</label> <CarrierIDSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID}/>
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Tag</label> <TagSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID}/>
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Line Type</label> <LineTypeSelect advanceSearch={advanceSearch} setAdvanceSearch={setAdvanceSearch} fileID={fileID}/>
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Connected (yes /no)</label> <ActiveSelect ActiveOption={ActiveOption} customStyles={customStyles} SelectChangeHandle={SelectChangeHandle} SelectedActive={SelectedActive} />
        </div>
        <div className='col-md-3'>
          {" "}
          <label> Caller ID Match</label> <CallerIDMatchSelect ActiveOption={ActiveOption} customStyles={customStyles} SelectChangeHandle={SelectChangeHandle}  SelectedCallerIDMatch={SelectedCallerIDMatch} />
        </div>

        <div className='col-md-3'>
          {" "}
          <label> Enter Time Called</label>{" "}
          <input type='text' name='time_called' />
        </div>

        <div className='col-md-12'>
          <button
            onClick={() => AdvanSearch()}
            className='bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-5'>
            Search
          </button>
          <button
            onClick={() => AdClear()}
            className='bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
            Clear
          </button>
        </div>
        <div className='row'>
          <div className='seeMore'>
            {/* <span>See more</span> */}
            <span className='filterOpenIcon'>
              <svg
                onClick={(e) => AdvanceFilterToggle()}
                width='11'
                height='6'
                viewBox='0 0 11 6'
                fill='none'
                style={{transform: 'rotate(180deg)'}}
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.12844 5.84674C4.94461 5.84674 4.76081 5.77655 4.62066 5.63647L0.210412 1.22618C-0.0701374 0.945626 -0.0701374 0.490763 0.210412 0.210327C0.490848 -0.070109 0.94562 -0.070109 1.22619 0.210327L5.12844 4.1128L9.03071 0.210463C9.31126 -0.0699727 9.76598 -0.0699727 10.0464 0.210463C10.3271 0.490899 10.3271 0.945762 10.0464 1.22631L5.63622 5.63661C5.496 5.77671 5.3122 5.84674 5.12844 5.84674Z'
                  fill='#047CFF'
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    
  )