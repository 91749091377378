import React, { useState,useEffect } from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import COLORS from "utils/colors"
import Modal from "react-bootstrap/Modal"
import Component from "components/creatable-select"

const RemoveAllModal = ({data,show,onClose,handleSubmit,title,deleteAll}) => {
  const [Tags,setTags] =useState([])
  const[selectedTags,setSelectedTags]=useState([]); 

  useEffect(()=>{
    if(data){
        let defaultItems = []
        Object.entries(data).map(([key,item]) =>  defaultItems.push({ value: item.title, label: item.title,id:item.id }))
        setTags(defaultItems)
}
  },[data])

//   Tags.map((item)=> )

    const removeTags=(updateTags)=>{
  setSelectedTags(updateTags);
   }

  
  return (<>
      <Modal show={show} onHide={()=>onClose(setSelectedTags)} dialogClassName="" >
        <Modal.Body style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <WrapperModal>
      {/* <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='tag-close'
        onClick={props.onClose}>
        ×
      </button> */}
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
              <div className="flex justify-between w-full">
            <Para2>{title}</Para2>
            <button className="w-180 h-32 text-white bg-red-900 rounded" onClick={()=>deleteAll(Tags,setSelectedTags)}>Delete All</button>
            </div>
          </ModalTitle>
          <WrapperBody>
            <div className='lead-input-container'>
                <Component preselectedItems={selectedTags}  handleChange={removeTags} notCreateable={true} options={Tags}/> 
             {/* <input className="input-cont" placeholder="please add tag title" value={value} onChange={(e)=>setValue(e.target.value)}/> */}
            </div>
          </WrapperBody>
          <WrapperFooter>
            <ModalButtonSubmit onClick={()=>{handleSubmit(selectedTags,setSelectedTags)}}>
              Submit
            </ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>

             
          </Modal.Body>
   </Modal> 
      {/* <ConfirmModal showModal={confirm} handleClose={()=>setConfirm(false)} handleConfirm={handleSubmit(selectedTags)} title={"Remove Tags"} message={`Are you sure you want to remove these tags ?`}/> */}
   </> )
}

export default RemoveAllModal

const WrapperModal = styled.div`
width:100%
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`

`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
//   width: 367px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;
`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align: center;
`

const ModalButtonSubmit = styled.button`
  width: 100%;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #89de58;
  color: #fff;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
`

const Para2 = styled.p`
  color: 000000;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  margin-left:10px;
  top: 5px;
  text-align: center;`