import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import tw from "twin.macro"
import COLORS from "utils/colors"
import FavIcon from "components/favicon"
// import Modal from "components/modal"
import Modal from "components/manipulator-modal"
import { toast } from "react-toastify"
import {  uploadRevampFile } from "services/dataValidation"


const Search = (props) => {
  let history = useHistory()
  // const [show, setShow] = useState(true)
  const [progress,setProgress]=useState(0)
  const [uploadModal, setUploadModal] = useState(false)

  const onFileChange = (event) => {
    setUploadModal(true)
    localStorage.setItem("relative-data", "yes")
    // history.push("upload-process-revamp")
  }

  const myUploadProgress = (e) => {
    let processing = Math.round((e.loaded / e.total) * 100);
    if(processing<97){
    setProgress(processing)}
  };

    function UploadFile() {
    //   const file1 = document.querySelector("input[type=file]").files[0]
    // var fileName = file1.name
    // // $("#fileName").text(fileName)
    // document.querySelector("#fileName").innerHTML = fileName
    // localStorage.removeItem("FileName")
    // if (localStorage.getItem("FileName") !== null) {
    //   history.push("revamp-phone-mapping")
    //   return false
    // }
    localStorage.removeItem("phoneMapping")
    localStorage.removeItem("file_id")
    localStorage.removeItem("phoneContactFields")
    localStorage.removeItem("phoneMappedFields")
    // setisLoading(true);
    const file = document.querySelector("input[type=file]").files[0]
    if (file === undefined) {
      toast.error("Please upload a CSV file before continuing")
      return false
    }
    var fileName = file.name

    const reader = new FileReader()
    //$(".progressPointer").show();
    // var i = 0
    // if (i === 0) {
    //   i = 1
    //   var elem = document.getElementById("UploadBar")
    //   var width = 1
    //   var id = setInterval(frame, 10)
    //   function frame() {
    //     if (width >= 80) {
    //       clearInterval(id)
    //       i = 0
    //     } else {
    //       if (width <= 80) {
    //         width++
    //         elem.style.width = width + "%"
    //       }
    //       // $("#progresscount").html(width + "%")
    //       document.querySelector("#progresscount").innerHTML = `${width}%`
    //     }
    //   }
    // }
    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        var csv_file = reader.result

        uploadRevampFile({
          csv_file: csv_file,
          file_name: fileName,
        },
        e =>
          myUploadProgress(e))
          .then(function (response) {
            //  var width1 = 81
           
          
              localStorage.setItem("phoneMapping", JSON.stringify(response))
   
                if (response.status !== false) {
                  localStorage.setItem("FileName", fileName)
                  history.push("revamp-phone-mapping")
                } else {
                  toast.error(response.message)
                }
        
          })
          .catch((error) => {
            console.log("error", error)
          })
      },
      false
    )

 if (file) {
      reader.readAsDataURL(file)
    }
    
  }

  const searchItem = (e) => {
    props.search(e.target.value)
  }
  return (
    <Wrapper>
      {/* <Modal
        onClose={() => {
          setShow(false)
        }}
        show={show}
        fields={false}
      /> */}
      <WrapperInput>
        <SearchIcon>
          <FavIcon class='fas fa-search'></FavIcon>
        </SearchIcon>
        <Input type='text' placeholder='Search' onChange={searchItem}></Input>
      </WrapperInput>
      <Credit>
        <Inner>
          <Icon>
            <FavIcon class='far fa-star'></FavIcon>
          </Icon>
          <AvailableCredit>Available Credit</AvailableCredit>
        </Inner>
        <Data>{props.availableCreadit}</Data>
      </Credit>
      <RightButton onClick={onFileChange}>
        <UploadIcon>
          <FavIcon class='fas fa-upload'></FavIcon>
        </UploadIcon>
        <Label>
          Upload CSV
          <Upload id='file-selecter' type='Button' size='80' />
        </Label>
      </RightButton>
        <Modal show={uploadModal} onClose={()=>setUploadModal(false)} selectFile={false} progress={progress} button={true} submitFile={UploadFile}/>
    </Wrapper>
  )
}

export default Search

const Wrapper = styled.div`
  display: flex;
  margin-left: 0%;
  margin-right: 2%;
  justify-content: space-between;
`
const Upload = styled.input`
  display: none;
  cursor: pointer;
`
const UploadIcon = styled.span`
  color: white;
  cursor: pointer;
`
const Label = styled.label`
  margin-left: 20px;
  font-size: 20px;
  display: table;
  cursor: pointer;
  color: #fff;
`
const Input = styled.input`
  width: 100%;
  height: 50px;
  border: #d2d2d2 solid 1px;
  border-radius: 5px;
  padding-left: 40px;
  ::placeholder,
  ::-webkit-input-placeholder {
    position: absolute;
    padding: 10px;
    padding-top: 15px;
    padding-left: 5px;
  }
  &:focus {
    border-style: solid;
    outline: none;
  }
`
const Icon = styled.p`
  color: ${COLORS.grey};
  margin-right: 10px;
  margin-top: -2px;
  border: solid 1px #959595;
  padding: 2px;
  border-radius: 50%;
  line-height: 1;
`
const Credit = styled.div`
  display: block;
`

const AvailableCredit = styled.h6`
  color: ${COLORS.grey};
  font-weight: 500;
`
const RightButton = styled.div`
  display: flex;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 20%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height: 44px;
  margin-right: 13px;
  cursor: pointer;

  ${tw`
    shadow-lg
  `}
`
const Inner = styled.div`
  display: flex;
`
const Data = styled.h6`
  color: ${COLORS.blue};
  display: block;
  text-align: center;
  font-weight: 800;
  font-family: Montserrat;
  font-size: 24px;
  padding-left: 15px;
`
const SearchIcon = styled.span`
  position: absolute;
  color: ${COLORS.grey};
  display: block;
  padding: 10px;
  margin-left: 5px;
  padding-top: 13px;
  color: ${COLORS.grey};
  min-width: 50px;
`
const WrapperInput = styled.div`
  width: 50%;
  margin-bottom: 10px;
  margin-left: 35px;
`
