import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"

function FilterTextField({ onChange, value, handleClearValue }) {
  const [hover, setHover] = useState(true)
  const [focus, setFocus] = useState(false)

  return (
    <TextField
      onKeyDown={(evt) =>
        ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()
      }
      id='controllable-states-demo'
      label='Filter Values'
      variant='outlined'
      type='number'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      sx={{ width: 300 }}
      onChange={onChange}
      value={value}
      size='small'
      InputProps={{
        endAdornment: (
          <>
            {(value != "" && hover) || (value != "" && focus) ? (
              <IconButton
                style={{ borderRadius: "100%", height: 28 }}
                onClick={handleClearValue}>
                <CloseIcon style={{ margin: "-4px", width: 20 }} />
              </IconButton>
            ) : null}
          </>
        ),
      }}
    />
  )
}

export default FilterTextField
