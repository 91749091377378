import { BASE_URL, API_URL } from "config";
import axiosService from "./axios.service";

export async function getAdminUsers(requestObj) {
  const url = BASE_URL + "admin-users";
  return axiosService.post(url, requestObj);
}

export async function getUsersExcelData(requestObj, page) {
  const url = BASE_URL + "phone-validation-files?page=" + page;
  return axiosService.post(url, requestObj);
}

export async function getFileHeader(file_id) {
  const url = BASE_URL + "file/get-header?file_id=" + file_id;
  return axiosService.post(url);
}

export async function uploadExcelFile(requestObj) {
  let formData = new FormData();
  formData.append("user_id", requestObj.user_id);
  formData.append("file_name", requestObj.file_name, requestObj.file_name.name);
  const url = BASE_URL + "uploadExcelFiles";
  return axiosService.post(url, formData);
}

export async function downloadLeads(requestObj) {
  const url = BASE_URL + "download-file-post";
  return axiosService.post(url, requestObj);
}

export async function phonedownloadLeads(requestObj) {
  const url = BASE_URL + "dv-download-post";
  return axiosService.post(url, requestObj);
}
export async function filePreview(requestObj) {
  const url = BASE_URL + "dv-download-post-preview";
  return axiosService.post(url, requestObj);
}
export async function saveTemplateNamePost(requestObj) {
  const url = BASE_URL + "dw-save-template-name";
  return axiosService.post(url, requestObj);
}
export async function updateTemplateNamePost(requestObj) {
  const url = BASE_URL + "dw-update-template-name";
  return axiosService.post(url, requestObj);
}
export async function deleteTemplatePost(requestObj) {
  const url = BASE_URL + "dw-delete-template";
  return axiosService.post(url, requestObj);
}
export async function changeTemplatePost(requestObj) {
  const url = BASE_URL + "dw-chnage-template";
  return axiosService.post(url, requestObj);
}
export async function downloadLeadsPhone(requestObj) {
  const url = API_URL + "dv-download-post";
  return axiosService.post(url, requestObj);
}

export async function mnipulator(requestObj, page) {
  const url = BASE_URL + "manipulator-files-list?page=" + page;
  return axiosService.post(url, requestObj);
}

export async function mnipulatorMappingFields(requestObj) {
  const url = BASE_URL + "manipulator-mapping";
  return axiosService.post(url, requestObj);
}

export async function uploadManipulatorFile(requestObj, myUploadProgress) {
  const url = BASE_URL + "upload-mnp-file";
  return axiosService.post(url, requestObj, myUploadProgress);
}

export async function downloadLeadslisting(requestObj, page) {
  const url = BASE_URL + "download-list?page=" + page;
  return axiosService.post(url, requestObj);
}

export async function getListOptionCustomFields() {
  const url = BASE_URL + "data-manipulator-custom-fields";
  return axiosService.post(url);
}

export async function downloadPhonelisting(requestObj, page) {
  const url = BASE_URL + "dv-download-list?page=" + page;
  return axiosService.post(url, requestObj);
}

export async function downloadSkipTracinglisting(requestObj, page) {
  const url = BASE_URL + "skip-tracing-download?page=" + page;
  return axiosService.post(url, requestObj);
}

export async function getAvailbleCreadit(requestObj, page) {
  const url = BASE_URL + "user-credit-details";
  return axiosService.post(url, requestObj);
}

export async function getTotalCountPhone(requestObj) {
  const url = BASE_URL + "pv-total-count";
  return axiosService.post(url, requestObj);
}

export async function getPhoneValidationSummary(requestObj) {
  const url = BASE_URL + "dv-phone-vaidation-summary";
  return axiosService.post(url, requestObj);
}

export async function getRelativeData(requestObj) {
  const url = BASE_URL + "get-relative-data";
  return axiosService.post(url, requestObj);
}

export async function getCustomFieldData(requestObj) {
  const url = BASE_URL + "get-custom-fields-data";
  return axiosService.post(url, requestObj);
}

export async function addCustomFieldData(requestObj) {
  const url = BASE_URL + "add-custom-fields-data";
  return axiosService.post(url, requestObj);
}

export async function deleteCustomFieldData(requestObj) {
  const url = BASE_URL + "delete-custom-fields-data";
  return axiosService.post(url, requestObj);
}

export async function editCustomFieldData(requestObj) {
  const url = BASE_URL + "update-custom-fields-data";
  return axiosService.post(url, requestObj);
}

export async function deleteSelectedFile(requestObj) {
  const url = BASE_URL + "delete-selected-files";
  return axiosService.post(url, requestObj);
}

export async function mappingFileData(requestObj, page) {
  const url = BASE_URL + "createTableMapping";
  return axiosService.post(url, requestObj);
}

export async function getUploadedFileDetails(requestObj, page) {
  const url = BASE_URL + "getUploadedFileDetails";
  return axiosService.post(url, requestObj);
}

export async function getUserNotifications(requestObj, page) {
  const url = BASE_URL + "notifications";
  return axiosService.post(url, requestObj);
}

export async function dataManipulatorUploadStatus(requestObj) {
  const url = BASE_URL + "data-manipulator-upload-status";
  return axiosService.post(url, requestObj);
}

export async function dataManipulatorUploadSummary(requestObj) {
  const url = BASE_URL + "data-manipulator-upload-summary";
  return axiosService.post(url, requestObj);
}

export async function deleteManipulatorItem(requestObj) {
  const url = BASE_URL + "data-manipulator-actions";
  return axiosService.post(url, requestObj);
}

export async function getManipulatorHistory(requestObj, page) {
  const url = BASE_URL + "data-manipulator-filehistory?page=" + page;
  return axiosService.post(url, requestObj);
}

export async function getManipulatorContacts(requestObj, page) {
  const url = BASE_URL + "data-manipulator-report?page=" + page;
  return axiosService.post(url, requestObj);
}

export async function getFreeSearchData(requestObj, page) {
  const url = BASE_URL + "data-manipulator-report?page=" + page;
  return axiosService.post(url, requestObj);
}

export async function getActionData(requestObj) {
  const url = BASE_URL + "data-manipulator-actions";
  return axiosService.post(url, requestObj);
}

export async function uploadRevampFile(requestObj, myUploadProgress) {
  const url = API_URL + "dv-file-upload";
  return axiosService.post(url, requestObj, myUploadProgress);
}

export async function phoneMappingPost(requestObj) {
  const url = API_URL + "dv-phone-mapping";
  return axiosService.post(url, requestObj);
}

export async function contactMappingPost(requestObj) {
  const url = API_URL + "dv-contact-mapping";
  return axiosService.post(url, requestObj);
}

export async function revampReportSubmission(requestObj) {
  const url = API_URL + "dv-submit";
  return axiosService.post(url, requestObj);
}

export async function clearAllnotification(requestObj) {
  const url = BASE_URL + "data-manipulator-actions";
  return axiosService.post(url, requestObj);
}

export async function DeleteDownloadsFiles(requestObj) {
  const url = BASE_URL + "delete-download-files";
  let confirm = window.confirm("Do you really want to delete?");
  if (confirm == true) {
    return axiosService.post(url, requestObj);
  } else {
    return false;
  }

  //
}

export async function ManipulatorActions(requestObj) {
  const url = BASE_URL + "data-manipulator-actions";
  return axiosService.post(url, requestObj);
}

export async function getAllTags() {
  const url = BASE_URL + "get-all-tags";
  return axiosService.get(url);
}

export async function postRequest(requestObj, link) {
  const url = BASE_URL + link;
  return axiosService.post(url, requestObj);
}

export async function createFields(requestObj, link) {
  const url = BASE_URL + link;
  return axiosService.post(url, requestObj);
}
