import React,{useState} from 'react'
import Select from "react-select"

function Phone({phone,options,index,SelectChangeHandle}) {
  const PhoneREISiftSelect = () => (
    <Select
      name={`aeri_phone_${index}`}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={(selectedOption, name) =>
        SelectChangeHandle(selectedOption, name, "phone")
      }
      value={phone[`aeri_phone_${index}`]==''?{label:"Select",value:""}:{label:phone[`aeri_phone_${index}`],value:phone[`aeri_phone_${index}`]}}
    />
  )

  const CarrierSelect = () => (<>
  <label>Carrier  {index} </label>
  
    <Select
      name={`carrier_${index}`}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={(selectedOption, name) =>
        SelectChangeHandle(selectedOption, name, "carrier")
      }
      value={phone[`carrier_${index}`]==''?{label:"Select",value:""}:{label:phone[`carrier_${index}`],value:phone[`carrier_${index}`]}}
    />
  </>)

  const CallerIdNameSelect = () => (<>
  <label>Caller ID Name  {index} </label>
    <Select
      name={`caller_id_name_${index}`}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={(selectedOption, name) =>
        SelectChangeHandle(selectedOption, name, "caller_id_name")
      }
      value={phone[`caller_id_name_${index}`]==''?{label:"Select",value:""}:{label:phone[`caller_id_name_${index}`],value:phone[`caller_id_name_${index}`]}}
    />
  </>)

  const LineTypeSelect = () => (<>
  <label> Line Type  {index} </label>
    <Select
      name={`line_type_${index}`}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={(selectedOption, name) =>
        SelectChangeHandle(selectedOption, name, "line_type")
      }
    value={phone[`line_type_${index}`]==''?{label:"Select",value:""}:{label:phone[`line_type_${index}`],value:phone[`line_type_${index}`]}}

    />
  </>)

  const ConnectedSelect = () => (<>
     <label> Connected {index} </label>
    <Select
      name={`connected_${index}`}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={(selectedOption, name) =>
        SelectChangeHandle(selectedOption, name, "connected")
      }
      value={phone[`connected_${index}`]==''?{label:"Select",value:""}:{label:phone[`connected_${index}`],value:phone[`connected_${index}`]}}
    />
  </>)

  const CallerIdMatchSelect = () => (<>
    <label> Caller ID Match {index} </label>
    <Select
      name={`caller_id_match_${index}`}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={(selectedOption, name) =>
        SelectChangeHandle(selectedOption, name, "caller_id_match")
      }
      value={phone[`caller_id_match_${index}`]==''?{label:"Select",value:""}:{label:phone[`caller_id_match_${index}`],value:phone[`caller_id_match_${index}`]}}      
    />
 </> )

  const [expand,setExpand]=useState()

  return (
     <div className='col-md-6 mb-0' key={index}>
                      <div className='fields divider'>
                      <label> Phone {index} </label>{" "}
                        <PhoneREISiftSelect />
                        {expand&&
                        <>
                        <ConnectedSelect/>
                        <CarrierSelect/>
                        <CallerIdNameSelect/>
                        <CallerIdMatchSelect/>
                        <LineTypeSelect/>
                        </>
                        }
                        <button onClick={()=>setExpand(prev=>!prev)} className="block w--165 margin-t-20 mx--auto bg-primary hover:bg-blue-700 text-white w-20 font-bold py-2 px-4 rounded">{expand?'Collapse':'Expand'}</button>
                      </div>
    </div>
  )
}

export default Phone