import React from "react";

function PlanCard({ title, price, onClick, active }) {
  return (
    <div className={`plan-card ${active ? "plan-card--active" : ""}`}>
      <div className="plan-card__heading">{title}</div>
      <div className="plan-card__body">
        <div className="plan-card__price">${price}</div>
        <div className="plan-card__credits">15 Credits</div>
        <button className="buy-btn" onClick={onClick}>
          Buy Now
        </button>
      </div>
    </div>
  );
}

export default PlanCard;
