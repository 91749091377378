import React, { useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import Modal from "components/task-modal"
import { getCrmActions } from "services/crm"
const Item = (props) => {
  const [show, setShow] = useState(false)
  const [TaskData, setTaskData] = useState(false)
  function deleteTask(id) {
    getCrmActions(
      {
        id: id,
        action: "delete-task",
      },
      1
    )
      .then(function (response) {
        if (response.status === false) {
          toast.error(response.message)
        } else {
          toast.success(response.message)
          setTimeout(function () {
            window.location.reload(false)
          }, 1000)
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }
  function editTask(id) {
    getCrmActions({ task_id: id, action: "get-task" }, 1)
      .then(function (response) {
        setTaskData(response.data)
        setShow(true)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  return (
    <React.Fragment>
      <Modal
        onClose={() => {
          setShow(false)
        }}
        show={show}
        data={TaskData}
      />
      <div className='row listing crm-activity'>
        <div className='activity_icon col-md-1 icon'>
          {" "}
          <svg
            width='10'
            height='19'
            viewBox='0 0 10 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M7.5415 8.60425C6.79821 8.19349 6.00799 7.88053 5.22559 7.55583C4.77179 7.36805 4.33756 7.14898 3.95418 6.84385C3.19916 6.2414 3.34391 5.26339 4.22802 4.8761C4.47839 4.76657 4.7405 4.73136 5.00651 4.71571C6.03146 4.66094 7.00555 4.84872 7.9327 5.29469C8.39431 5.51767 8.54688 5.44726 8.70336 4.96608C8.86767 4.45752 9.00459 3.94113 9.15716 3.42866C9.25887 3.0844 9.13368 2.85751 8.80899 2.71276C8.21436 2.45066 7.60409 2.26288 6.96252 2.16117C6.12535 2.03207 6.12535 2.02816 6.12144 1.18708C6.11752 0.00173869 6.11753 0.00173867 4.92827 0.00173867C4.75615 0.00173867 4.58402 -0.00217334 4.41189 0.00173867C3.85638 0.0173867 3.76249 0.115187 3.74685 0.674605C3.73902 0.924973 3.74685 1.17534 3.74293 1.42962C3.73902 2.1729 3.73511 2.16117 3.02312 2.41936C1.30184 3.04528 0.237773 4.21889 0.124324 6.09665C0.022612 7.75926 0.891078 8.882 2.25637 9.69961C3.09745 10.2043 4.02851 10.5016 4.92045 10.8967C5.26862 11.0493 5.60114 11.2253 5.89063 11.4678C6.74736 12.1759 6.59088 13.3534 5.57376 13.7994C5.02999 14.038 4.45492 14.0967 3.86421 14.0224C2.95271 13.9089 2.08033 13.6703 1.25881 13.2439C0.77763 12.9935 0.636797 13.06 0.472493 13.5803C0.331661 14.0302 0.206477 14.484 0.0812922 14.9378C-0.0869242 15.5481 -0.0243324 15.6928 0.558557 15.9784C1.30184 16.3383 2.09598 16.5222 2.90576 16.6513C3.53951 16.753 3.55907 16.7804 3.56689 17.4376C3.57081 17.7349 3.57081 18.0361 3.57472 18.3334C3.57863 18.709 3.75858 18.928 4.14587 18.9359C4.58402 18.9437 5.02607 18.9437 5.46422 18.932C5.82412 18.9241 6.00799 18.7285 6.00799 18.3647C6.00799 17.9579 6.02755 17.5471 6.0119 17.1403C5.99234 16.7256 6.17229 16.5143 6.57132 16.4048C7.49064 16.1544 8.27304 15.6615 8.87549 14.9261C10.5498 12.8918 9.91217 9.91477 7.5415 8.60425Z'
              fill='white'
            />
          </svg>
        </div>
        <div className='activity_content col-md-5'>
          <div className='task_title_main'>
            <Para> {props.title} </Para>{" "}
            <span className='task_edit_icon'>
              <svg
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={(e) => editTask(props.id)}>
                <path
                  d='M0 9.50014V12.0006H2.50047L9.87851 4.62254L7.37804 2.12207L0 9.50014Z'
                  fill='#047CFF'
                />
                <path
                  d='M11.8047 1.75199L10.2477 0.195037C9.98769 -0.0650122 9.56426 -0.0650122 9.30421 0.195037L8.08398 1.41527L10.5845 3.91574L11.8047 2.69551C12.0648 2.43546 12.0648 2.01203 11.8047 1.75199Z'
                  fill='#047CFF'
                />
              </svg>
            </span>
            <span className='task_delete_icon'>
              <svg
                width='12'
                height='13'
                viewBox='0 0 12 13'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={(e) => deleteTask(props.id)}>
                <path
                  d='M8.45278 4.48862L7.44504 4.45166L7.22656 10.418L8.2343 10.4549L8.45278 4.48862Z'
                  fill='#E44C4C'
                />
                <path
                  d='M6.50449 4.47021H5.49609V10.4366H6.50449V4.47021Z'
                  fill='#E44C4C'
                />
                <path
                  d='M4.77114 10.4181L4.55266 4.45166L3.54492 4.48865L3.76343 10.455L4.77114 10.4181Z'
                  fill='#E44C4C'
                />
                <path
                  d='M0 1.98242V2.99082H1.05076L1.88435 12.4636C1.90721 12.7239 2.12521 12.9236 2.38654 12.9236H9.59647C9.85782 12.9236 10.076 12.7238 10.0986 12.4634L10.9323 2.99082H12V1.98242H0ZM9.13464 11.9152H2.84824L2.06303 2.99082H9.92002L9.13464 11.9152Z'
                  fill='#E44C4C'
                />
                <path
                  d='M7.64813 0.0498047H4.354C3.89064 0.0498047 3.51367 0.426771 3.51367 0.890133V2.48678H4.52207V1.0582H7.48006V2.48678H8.48846V0.890133C8.48846 0.426771 8.11149 0.0498047 7.64813 0.0498047Z'
                  fill='#E44C4C'
                />
              </svg>
            </span>
          </div>
          <ParaTwo> {props.description}</ParaTwo>
          <ParaThree>
            {" "}
            <span className='activity_date'>DATE CREATED:</span>{" "}
            {props.created_at}{" "}
          </ParaThree>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Item

const Para = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #686868;
  float: left;
`
const ParaTwo = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #919191;
`
const ParaThree = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #919191;
`
