import React from "react";
import styled from "styled-components";
import Item from "./tableItem";
const Table = (props) => {
  const files = props.files ? props.files : [];
  const items = [];
  for (let i = 0; i < files.length; i++) {
    items.push(
      <Item
        name={files[i].title}
        category={files[i].format}
        line_type={files[i].line_type}
        connected={files[i].connected}
        caller_id_match={files[i].caller_id_match}
        status={files[i].status}
        updated_at={files[i].updated_at}
        size={files[i].size}
        key={i}
        download_url={files[i].download_url}
        /* match={files[i].caller_id_match} */
        percentage={files[i].percentage}
        id={files[i].id}
        total_rows={files[i].total_rows}
      ></Item>
    );
  }
  function checkAllCheckbox(event) {
    const allCheckboxChecked = event.target.checked;
    var checkboxes = document.getElementsByName("slectedCheckBox[]");
    if (allCheckboxChecked) {
      for (var i in checkboxes) {
        if (checkboxes[i].checked === false) {
          checkboxes[i].checked = true;
          var inputElems = document.getElementsByName("slectedCheckBox[]");

          for (var j = 0; j < inputElems.length; j++) {
            if (
              inputElems[j].type === "checkbox" &&
              inputElems[j].checked === true
            ) {
              // alert(count);
            }
            // $(".DeleteAction").show()
            document.querySelector(".DeleteAction").style.display = "block";
          }
        }
      }
    } else {
      // $(".DeleteAction").hide()
      document.querySelector(".DeleteAction").style.display = "none";

      for (var k in checkboxes) {
        if (checkboxes[k].checked === true) {
          checkboxes[k].checked = false;
        }
      }
    }
  }

  return (
    <Wrapper>
      <WrapperTitle></WrapperTitle>
      <div className="scroll-in-mobile dowloads">
        {items.length > 0 && (
          <table className="listing download">
            <tr className="heading">
              <th>
                <input
                  type="checkbox"
                  name="checkAllCheckbox[]"
                  onChange={checkAllCheckbox}
                />{" "}
                File Name
              </th>
              <th>Format</th>
              <th>Line Type</th>
              <th>Connected</th>
              <th>Caller Id Match</th>
              <th>Status</th>
              <th>Date</th>
              <th>&nbsp;</th>
            </tr>
            {items}
          </table>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`;
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 10px;
`;

export default Table;
