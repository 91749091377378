import React, { useState } from "react"
import { toast } from "react-toastify"
import { editCustomFieldData } from "services/dataValidation"

function RecordItem({ customFieldName, customFieldValue, id, setEditValues }) {
  const [value, setValue] = useState(customFieldValue)
  const ref = React.useRef(null)

  const updateValue = async (value) => {
    setEditValues((prev) => {
      const copyObj = { ...prev }
      copyObj[id] = value
      return copyObj
    })
    setValue(value)
  }

  return (
    <tr className='heading'>
      <th width='50%'>
        <div className='ml-10'>{customFieldName}</div>
      </th>
      <th>
        <input
          type='text'
          placeholder='Enter Source Value'
          className='customFiledValueInput'
          name={"sourceValue"}
          value={value}
          ref={ref}
          // onFocus={editCustomValue}
          onChange={(e) => {
            updateValue(e.target.value)
          }}
          // onBlur={updateValue}
        />
      </th>
    </tr>
  )
}

export default RecordItem
