import React, { useState } from "react"
import styled from "styled-components"
import ConfirmModal from "components/confirm-modal"
import EditModal from "./modal"
import { postRequest } from "services/dataValidation"
import { toast } from "react-toastify"
import RemoveAllModal from "./remove-all-modal"

function Table({
  heading1,
  data = [],
  handleGetAllTags,
  modalTitle = "Tag",
  setisLoading,
  checked,
  setChecked,
}) {
  const [confirm, setConfirm] = useState(false)
  const [confirmRemove, setConfirmRemove] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [TShow, setTShow] = useState(false)
  const [removeShow, setRemoveShow] = useState(false)
  const [title, setTitle] = useState()
  const [id, setId] = useState()
  // const [selected,setSelected]=useState({});
  const [selectedTagsIds, setSelectedTagsIds] = useState({})

  const updateTag = (tag) => {
    postRequest(
      modalTitle == "Custom Field"
        ? { id, action: "update", field_name: tag }
        : { id, title: tag },
      modalTitle == "Custom Field" ? "custom-fields" : `update-tag`
    )
      .then(function (response) {
        if (response.status) {
          handleGetAllTags()
          setTShow(false)
          toast.success(`This ${modalTitle} is updated successfully`)
          // setisLoading(false);
        } else {
          toast.error(response.errors)
        }
      })
      .catch((error) => {
        toast.error("you need to update the title")
      })
  }

  const removeAllTags = (items, setItems) => {
    let copyTags = []
    items.map((item) => copyTags.push(item.id))
    setSelectedTagsIds(copyTags)
    setRemoveShow(false)
    setConfirmRemove(true)
    setItems([])
  }

  const handleConfirmRemoveAll = (ids) => {
    postRequest({ tagIds: ids }, `delete-tags`).then(function (response) {
      // setisLoading(true);
      if (response.data) {
        handleGetAllTags()
        setConfirmRemove(false)
        setConfirmDelete(false)
        setChecked({})
        toast.success("These tags are deleted successfully")
        // setisLoading(false);
      }
    })
  }

  const deleteTag = () => {
    postRequest(
      modalTitle == "Custom Field" ? { id, action: "delete" } : { id },
      modalTitle == "Custom Field" ? "custom-fields" : `delete-tag-by-id`
    ).then(function (response) {
      if (response.data) {
        handleGetAllTags()
        setConfirm(false)
        toast.success("Your tag is deleted successfully")
      }
    })
  }

  const checkAll = () => {
    let copySelected = { ...checked }
    if (Object.keys(checked).length !== data.length) {
      data.map((item) => {
        copySelected[item.id] = item
      })
    } else {
      copySelected = {}
    }
    setChecked(copySelected)
  }

  const handleCheck = (id, item) => {
    let copySelected = { ...checked }
    if (copySelected[id]) {
      delete copySelected[id]
    } else {
      copySelected[id] = item
    }
    setChecked(copySelected)
  }

  const deleteAll = (items, setItems) => {
    let copyTags = []
    items.map((item) => copyTags.push(item.id))
    setSelectedTagsIds(copyTags)
    setRemoveShow(false)
    setConfirmDelete(true)
    setItems([])
  }

  const handleEditTag = (title, id) => {
    setTShow(true)
    setTitle(title)
    setId(id)
  }

  const handleDeleteTag = (title, id) => {
    setConfirm(true)
    setTitle(title)
    setId(id)
  }

  return (
    <OverLay>
      <Wrapper>
        <div className='w-full contacts'>
          <div className='SelectAllMain row'>
            <div>
              {Object.keys(checked).length !== 0 && (
                <div className='flex'>
                  <span className='flex items-baseline add_tags'>
                    <span
                      className='mr-2 cursor-pointer'
                      onClick={() => setChecked({})}>
                      ×
                    </span>
                    <p
                      className='font-bold col text-gray-500 text-xs cursor-pointer'
                      onClick={() => setRemoveShow(true)}>
                      Remove Tags
                    </p>{" "}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='row reports_listing w-100'>
            <input type='hidden' name='fileIds' id='fileIds' value='' />
            <div className='listing_heading owner1 w-60 '>
              {" "}
              <input
                type='Checkbox'
                id='Checkbox'
                checked={
                  data.length === Object.keys(checked).length &&
                  data.length !== 0
                }
                onClick={checkAll}
              />{" "}
              <span className='ml-47%'>{heading1} </span>{" "}
            </div>
            <div className='listing_heading ml--18'>
              {" "}
              <span> Actions </span>{" "}
            </div>
          </div>
          {data.length === 0 ? (
            <Item1>
              <div className='text-red-700'>No Records Found</div>
            </Item1>
          ) : (
            <>
              {data.map(({ title, id, field_name }) => (
                <Item key={id}>
                  <div className='row reports_listing w-100 setting-row'>
                    <input type='hidden' name='fileIds' id='fileIds' value='' />
                    <div className='listing_heading owner1 pt--8 w-60 '>
                      {" "}
                      <input
                        type='Checkbox'
                        id='Checkbox'
                        onClick={() => handleCheck(id, { title, id })}
                        checked={checked[id]}
                        // onClick={checkAll}
                      />{" "}
                      <span className='tags-title ml-50%'>
                        {title || field_name}{" "}
                      </span>{" "}
                    </div>
                    <div className='listing_heading  ml--18 pl--41'>
                      {" "}
                      <span className='flex'>
                        <img
                          src='/images/edit.png'
                          className='link'
                          onClick={() => handleEditTag(title || field_name, id)}
                          alt=''
                        />
                        <img
                          src='/images/delete.png'
                          className='link'
                          onClick={() => {
                            handleDeleteTag(title || field_name, id)
                          }}
                          alt=''
                        />
                      </span>{" "}
                    </div>
                  </div>
                </Item>
              ))}
            </>
          )}
        </div>
        <ConfirmModal
          showModal={confirm}
          handleClose={() => setConfirm(false)}
          handleConfirm={deleteTag}
          title={`Remove ${modalTitle}`}
          message={`Are you sure you want to remove this ${title} ${modalTitle} ?`}
        />
        <ConfirmModal
          showModal={confirmRemove}
          handleClose={() => setConfirmRemove(false)}
          handleConfirm={() => handleConfirmRemoveAll(selectedTagsIds)}
          title={`Remove ${modalTitle}s`}
          message={`Are you sure you want to remove these ${modalTitle}s ?`}
        />
        <ConfirmModal
          showModal={confirmDelete}
          handleClose={() => setConfirmDelete(false)}
          handleConfirm={() => handleConfirmRemoveAll(selectedTagsIds)}
          title={`Remove ${modalTitle}s`}
          message={`Are you sure you want to remove All selected ${modalTitle}s ?`}
        />
      </Wrapper>
      <EditModal
        onClose={() => {
          setTShow(false)
        }}
        id={id}
        title={`Edit ${modalTitle}`}
        tag={title}
        show={TShow}
        handleSubmit={updateTag}
      />
      <RemoveAllModal
        onClose={(callback) => {
          setRemoveShow(false)
          callback([])
        }}
        id={id}
        title='Remove All Tags'
        data={checked}
        show={removeShow}
        deleteAll={deleteAll}
        handleSubmit={removeAllTags}
      />
    </OverLay>
  )
}
const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`

const OverLay = styled.div``
const Item = styled.div`
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
  border-radius: 19px;
  margin: auto;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 10px;
  padding: 14px 0px 20px 0px;
`

const Item1 = styled.div`
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #ffffff;
  border-radius: 19px;
  margin: auto;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 10px;
  padding: 0px 0px 0px 0px;
`
export default Table
