import React from "react"
import Profile from "components/navbar/topNav/profile"
import styled from "styled-components"
import COLORS from "utils/colors"
import arrow from "assets/images/arrow.png"

const TopNavProfile = (props) => {
  const pathName = window.location.pathname.substr(1)
  return (
    <MainWrapper>
      <Wrapper>
        <LeftSide>
          <Para>Dashboard</Para>
          <GreaterSign src={arrow} />
          <Para>My Profile</Para>
          <GreaterSign src={arrow} />
          <Selected> {pathName}</Selected>
        </LeftSide>
        <RightSide>
          <Profile></Profile>
        </RightSide>
      </Wrapper>
      <hr className='hr' />
    </MainWrapper>
  )
}

export default TopNavProfile
const MainWrapper = styled.div`
  width: 100%;
  padding-left: 15px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 65px;
`
const LeftSide = styled.div`
  text-transform: uppercase;
  display: flex;
  width: 50%;
`
const Para = styled.p`
  margin-left: 20px;
  padding-top: 31px;
  font-weight: 500;
  font-size: 12px;
  font-family: Montserrat;
  color: #b5b5b5;
`
const Selected = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  margin-left: 15px;
  padding-top: 30px;
  font-weight: 500;
  color: ${COLORS.blue};
`
const RightSide = styled.div`
  padding-right: 40px;
`
const GreaterSign = styled.img`
  width: 9px;
  height: 14px;
  margin-top: 32px;
  margin-left: 15px;
  font-weight: bolder;
`
