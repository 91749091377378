import React, { useEffect, useState, useRef } from "react";
import { getUsersExcelData, getAvailbleCreadit } from "services/dataValidation";
import { getUserID } from "utils/helper";
import TopNav from "components/navbar/topNav";
import Table from "components/navbar/tableItems/table";
import styled from "styled-components";
import Search from "components/search";
import Pagination from "components/pagination";
import Loading from "components/loading";
import "assets/css/style.css";
import { postRequest } from "services/dataValidation";
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import { SOCKET_URL } from "config";

const DataValidation = () => {
  const [userExcelData, setExcelUserData] = useState([]);
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([]);
  const [noOfPages, setNoofPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [, setTotalExcelData] = useState(false);
  const [availableCreadit, setAvailableCreadit] = useState();
  const [percentageData, setPercentageData] = useState({});
  const dataFetchedRef = useRef(false);

  document.title = "Phone validation | AnalyzeREI";

  let history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.tmpData !== undefined) {
        setFilteredUserExcelData(window.tmpData);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const socket = io.connect(SOCKET_URL);
    let UserId = getUserID();
    socket.on("connect", function () {
      socket.emit("join_user_room", UserId);
    });
    socket.on("phone_valid_progress", (data) => {
      //console.log(filteredUserExcelData);
      let tmpData = [];
      tmpData = [...filteredUserExcelData];
      if (tmpData.length > 0) {
        for (let i = 0; i < tmpData.length; i++) {
          if (data.data.file_id === tmpData[i]["id"]) {
            tmpData[i]["percentage"] = data.data.percentage;
            tmpData[i]["valid_disconnect_total_count"] =
              data.data.total_disconnected;
            tmpData[i]["valid_landline_total_count"] = data.data.total_landline;
            tmpData[i]["valid_mobile_total_count"] = data.data.total_mobile;
          }
        }
        window.tmpData = tmpData;
      }
    });
  }, [filteredUserExcelData]);

  const handleProgress = (file_ids) => {
    const fileIds = file_ids.map((item) => item.filelist_id).toString();
  };

  useEffect(() => {
    GetAvailbleCreaditdata();
  }, []);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getFiles(noOfPages, recordsPerPage);
  }, [noOfPages, recordsPerPage]);

  const GetAvailbleCreaditdata = (searchTerm) => {
    getAvailbleCreadit({ user_id: getUserID() })
      .then(function (response) {
        setAvailableCreadit((state) => response.balance_credit);
      })
      .catch((error) => {
        alert("Some error occured");
        console.log("file upload error: ", error);
      });
  };

  function ViewAll() {
    setisLoading(true);
    const user = getUserID();
    getUsersExcelData({
      user_id: user,
      view: "yes",
    })
      .then(function (response) {
        setisLoading(false);
        setTotalRecords(response.total_records);
        setExcelUserData(response.data);
        setFilteredUserExcelData(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getFiles(noOfPages, recordsPerPage) {
    setisLoading(true);
    const user = getUserID();
    //console.log(recordsPerPage);
    getUsersExcelData(
      {
        user_id: user,
        per_page: recordsPerPage,
      },
      noOfPages
    )
      .then(function (response) {
        setisLoading(false);
        setTotalRecords(response.total_records);
        setExcelUserData(response.data);
        setFilteredUserExcelData(response.data);
        handleProgress(response?.data);
        setTotalExcelData(response.data.length);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function onChangeRecordPerPage(perPageValue, pageCount) {
    setRecordsPerPage(perPageValue);
    setNoofPages(pageCount);
    getFiles(pageCount, perPageValue);
  }

  const search = (searchTerm) => {
    if (searchTerm !== "") {
      let filteredList = [];
      userExcelData.forEach((file) => {
        if (file.file_name.toLowerCase().includes(searchTerm.toLowerCase())) {
          filteredList.push(file);
        }
      });
      setFilteredUserExcelData(filteredList);
    } else {
      setFilteredUserExcelData(userExcelData);
    }
  };

  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNav />
      <SearchWrapper>
        <Search search={search} availableCreadit={availableCreadit} />
      </SearchWrapper>
      <Table
        percentageData={percentageData}
        files={filteredUserExcelData}
        allData={ViewAll}
      ></Table>
      <WrapperPagination>
        <Pagination
          getPageData={getFiles}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          onChangeRecordPerpage={onChangeRecordPerPage}
        ></Pagination>
      </WrapperPagination>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
const SearchWrapper = styled.div`
  margin-top: 2%;
`;
const WrapperPagination = styled.div`
  width: 98%;
`;

export default DataValidation;
