import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  updateTemplateNamePost,
  deleteTemplatePost,
} from "services/dataValidation";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ManageTemplates = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    //setTemplateName("");
  };
  const handleShow = () => setShow(true);
  const [templateName, setTemplateName] = useState(props.name);

  if (props.name !== "") {
    if (templateName == "") {
      setTemplateName(localStorage.getItem("templateName"));
    }
  }
  const updateTemplateName = (event) => {
    if (templateName.trim() == "") {
      toast.error("template name is required.");
      return false;
    }
    var preText = event.target.innerText;
    event.target.innerText = "updating...";

    updateTemplateNamePost({
      template_name: templateName,
      temp_id: localStorage.getItem("templateID"),
    })
      .then(function (response) {
        event.target.innerText = preText;
        props.setTemplateList(response.data.templates);
        toast.success("template is updated.");
      })
      .catch((error) => {});
  };
  const deleteTemplate = (event) => {
    if (window.confirm("Do you want to delete?") == false) {
      return;
    }
    var preText = event.target.innerText;
    event.target.innerText = "deleting...";

    deleteTemplatePost({
      temp_id: localStorage.getItem("templateID"),
    })
      .then(function (response) {
        console.log(response);
        event.target.innerText = preText;
        props.setTemplateList(response.data.templates);
        toast.success("template is deleted.");
        setShow(false);
      })
      .catch((error) => {});
  };
  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="btn btn-link btn-sm mng-btn"
        style={{ float: "right" }}
      >
        Manage Template
      </Button>

      <Modal show={show} onHide={handleClose}>
        <div className="modal-header" style={{ padding: "0px" }}>
          <h5 className="modal-title" id="exampleModalLongTitle">
            Manage Template
          </h5>
          <button
            onClick={handleClose}
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style={btn_close}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Modal.Body>
          <input
            className="form-control-sm"
            type="text"
            placeholder="template name"
            onChange={(event) => setTemplateName(event.target.value)}
            style={textStyle}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={updateTemplateName}
            className="btn-sm"
          >
            Update Template
          </Button>
          <Button variant="danger" className="btn-sm" onClick={deleteTemplate}>
            X Delete Template
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageTemplates;
const textStyle = {
  height: "40px !important",
  width: "100%",
  border: "solid 1px #ccc",
};
const btn_close = {
  right: "16px",
  "font-size": "28px",
  top: "-6px",
  color: "#000",
};
