import React, { useState, useEffect } from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import COLORS from "utils/colors"
import Modal from "react-bootstrap/Modal"

const EditModal = ({
  title,
  id,
  data,
  show,
  onClose,
  handleSubmit,
  tag,
  placeholder,
}) => {
  const [files, setFiles] = useState()
  const [record_id, setRecordId] = useState()
  const [value, setValue] = useState("")

  useEffect(() => {
    if (tag || data || id) {
      setValue(tag)
      setFiles(data)
      setRecordId(id)
    }
  }, [tag, data, id])

  return (
    <Modal show={show} onHide={onClose} dialogClassName=''>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <WrapperModal>
          <WrapperContent>
            <WrapperHeader>
              <ModalTitle>
                <Para2>{title}</Para2>
              </ModalTitle>
              <WrapperBody>
                <div className='lead-input-container'>
                  <input
                    className='input-cont'
                    placeholder='please edit tag title'
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </div>
              </WrapperBody>
              <WrapperFooter>
                <ModalButtonSubmit onClick={() => handleSubmit(value)}>
                  Submit
                </ModalButtonSubmit>
              </WrapperFooter>
            </WrapperHeader>
          </WrapperContent>
        </WrapperModal>
      </Modal.Body>
    </Modal>
  )
}

export default EditModal

const WrapperModal = styled.div`
  width: 100%;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div``
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 367px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;
`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align: center;
`

const ModalButtonSubmit = styled.button`
  width: 100%;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #89de58;
  color: #fff;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
`

const Para2 = styled.p`
  color: 000000;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  width: 100%;
  top: 5px;
  text-align: center;
`
