import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js"
import React, { useState } from "react"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Loading from "components/loading"
import { AddNewCardAPI, paybutton } from "services/paymentDeatil"
import { getUserID } from "utils/helper"
import CardSection from "./CardSection"

const CheckoutForm = (props) => {
  let history = useHistory()
  let userObject = localStorage.getItem("purchasePrice")
  var obj = JSON.parse(userObject)
  let getPurchasePrice = obj
  const amount = Math.round(+getPurchasePrice.replace(/,/g, "") * 100)

  let rangeceObject = localStorage.getItem("rangevalue")
  var obj2 = JSON.parse(rangeceObject)

  let getRangevalue = obj2
  

  const [isLoading, setisLoading] = useState(false)

  //add card Payment
  const handleSubmit = async (event) => {
    setisLoading(true)
    event.preventDefault()
    const { stripe, elements } = props
    if (!stripe || !elements) {
      return
    }

    const card = elements.getElement(CardElement)
    const result = await stripe.createToken(card)
    // setCardDat(result.token.card['last4']);
    if (result.error) {
      toast.error(result.error.message)
      setisLoading(false)
    } else {
      const cardToken = result.token["id"]
      const userId = getUserID()
      AddNewCardAPI({
        user_id: userId,
        stripeToken: cardToken,
      })
        .then(function (response) {
          if (response.status === true) {
            // toast.success(response['message'])
            setisLoading(true)
            const userId = getUserID()
            paybutton({
              card_id: result.token.card["id"],
              user_id: userId,
              amount,
              credit: getRangevalue.replace(/,/g, ""),
              save_card: getCheckBoxValue,
            })
              .then(function (response) {
                if (response.status === true) {
                  setisLoading(false)
                  toast.success(response["message"])
                  history.push("/data-validation")
                } else {
                  setisLoading(false)
                  toast.error(response["errors"])
                }
              })
              .catch((error) => {
                console.log("error", error)
              })
          } else {
            toast.error(response["message"])
          }
        })
        .catch((error) => {
          setisLoading(false)
          console.log("error", error)
        })
    }
  }
  let getCheckBoxValue
  const checkboxes = (e) => {
    if (e.target.checked) {
      getCheckBoxValue = "no"
    } else {
      getCheckBoxValue = "no"
    }
  }

  return (
    <div>
      <Loading show={isLoading} />
      <form onSubmit={handleSubmit}>
        <CardSection />
        <div className='button-in-credit-two position-relative mar-top-50'>
          <button disabled={!props.stripe} className='btn-pay'>
            Pay
          </button>
          <div className='checkbox-in-credit-two'>
            <input
              type='checkbox'
              id='checkbox-credit2'
              name='checkbox-credit2'
              value='checkbox-credit'
              onChange={checkboxes}
            />
            <label for='checkbox-credit2'>Save card for future use</label>
          </div>
        </div>
      </form>
    </div>
  )
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  )
}
