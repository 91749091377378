import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { deleteSelectedFile, getUsersExcelData } from "services/dataValidation";
import COLORS from "utils/colors";
import { getUserID } from "utils/helper";
import Item from "./tableItem";

import Modal from "components/phone-modal";
import { getFileHeader } from "../../../services/dataValidation";

const Table = (props) => {
  const [selectedExceldata, setSelectedExceldata] = useState([]);
  const [getExcelTotle, setExcelTotle] = useState([]);
  const [slectedBoxTrue, setslectedBoxTrue] = useState(false);

  let [fileHeader, setfileHeader] = useState([]);
  let [templates, setTemplates] = useState([]);
  const [show, setShow] = useState(0);
  //console.log(props);
  function downloadPopup(id) {
    localStorage.setItem("file_id", id);
    setShow(true);
    getFileHeader(id)
      .then(function (response) {
        if (response.sheet_header.length > 0) {
          setfileHeader(response.sheet_header);
          setTemplates(response.templates);
        }
        setShow(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getFiles();
  }, []);

  function getFiles() {
    const user = getUserID();
    getUsersExcelData({
      user_id: user,
    })
      .then(function (response) {
        setExcelTotle(response.data.length);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const deleteItem = (id) => {
    const user = getUserID();
    deleteSelectedFile({
      user_id: user,
      filelist_id: id,
    })
      .then(function (response) {
        props.allData();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const bulkDelete = () => {
    var checkboxes = document.getElementsByName("slectedCheckBox[]");
    for (var i in checkboxes) {
      if (checkboxes[i].checked === true) {
        var inputElems = document.getElementsByName("slectedCheckBox[]"),
          row = [];
        for (var j = 0; j < inputElems.length; j++) {
          if (
            inputElems[j].type === "checkbox" &&
            inputElems[j].checked === true
          ) {
            row.push(inputElems[j].value);
          }
        }
      }
    }

    var rowIds = row.join(",");
    const user = getUserID();
    deleteSelectedFile({
      user_id: user,
      filelist_id: rowIds,
    })
      .then(function (response) {
        props.allData();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const files = props.files ? props.files : [];
  const items = [];
  for (let i = 0; i < files.length; i++) {
    items.push(
      <Item
        name={files[i].file_name}
        landline={files[i].valid_landline_total_count}
        mobile={files[i].valid_mobile_total_count}
        disconnected={files[i].valid_disconnect_total_count}
        percentage={files[i].percentage || 0}
        created={files[i].created_at}
        file={files[i].download_file}
        key={i}
        id={files[i].id}
        files={files}
        deleteItem={deleteItem}
        downloadPopup={downloadPopup}
      ></Item>
    );
  }
  //select all checkboxes
  function checkAllCheckbox(event) {
    const allCheckboxChecked = event.target.checked;
    var checkboxes = document.getElementsByName("slectedCheckBox[]");
    if (allCheckboxChecked) {
      setslectedBoxTrue(true);
      for (var i in checkboxes) {
        if (checkboxes[i].checked === false) {
          checkboxes[i].checked = true;
          var inputElems = document.getElementsByName("slectedCheckBox[]"),
            count = 0;
          for (var j = 0; j < inputElems.length; j++) {
            if (
              inputElems[j].type === "checkbox" &&
              inputElems[j].checked === true
            ) {
              count++;
              // alert(count);

              setSelectedExceldata(count);
            }
          }
        }
      }
    } else {
      setslectedBoxTrue(false);
      for (var k in checkboxes) {
        if (checkboxes[k].checked === true) {
          count--;
          setSelectedExceldata(null);
          checkboxes[k].checked = false;
        }
      }
    }
  }
  //select single checkbox

  return (
    <Wrapper>
      <Modal
        fileHeader={fileHeader}
        templates={templates}
        onClose={() => {
          setShow(false);
        }}
        show={show}
      />
      <WrapperTitle>
        <HeadingFiles>Files:</HeadingFiles>
        {slectedBoxTrue === true ? (
          <p className="Item_total_count">
            {getExcelTotle} out of {selectedExceldata}
          </p>
        ) : (
          <p></p>
        )}
        {/* <p>{ setselectedExceldataSingle}</p> */}
        <div className="select-all-data-validation">
          <input
            type="checkbox"
            id="selectAllData"
            onChange={checkAllCheckbox}
            style={{ opacity: 0 }}
          />
          <label htmlFor="selectAllData">Select All</label>
          <span className="devider">|</span>
          <Delete onClick={bulkDelete}>Delete</Delete>
        </div>
      </WrapperTitle>

      <div className="scroll-in-mobile">{items}</div>
    </Wrapper>
  );
};
const Delete = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.red};
  padding-left: 10px;
`;
const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`;
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 20px;
`;
const HeadingFiles = styled.h2`
  font-weight: bold;
  color: #15342e;
  margin-left: 13px;
  font-family: roboto;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.7;
  padding-top: 14px;
`;

export default Table;
