import { BASE_URL } from "config"
import axiosService from "./axios.service"

export async function getCrmContactInfo(requestObj) {
  const url = BASE_URL + "crm"
  return axiosService.post(url, requestObj)
}

export async function getCrmActions(requestObj, page = 1) {
  const url = BASE_URL + "data-manipulator-actions?page=" + page
  return axiosService.post(url, requestObj)
}
