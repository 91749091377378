import React, { useState,useEffect } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import { getActionData } from "services/dataValidation"
import COLORS from "utils/colors"
import Component from "components/creatable-select"
import {postRequest} from "services/dataValidation"

const Modal = (props) => {
  const [files,setFiles] =useState( [])
  const [record_id,setRecordId] = useState(props.selectedRecord || "")
  const [options,] = useState([])
  const [selectedTags,setSelectedTags]=useState([]);
  const {refetch}=props;

  const mapOptionsToValues = options => {
    return options.map(option => ({
      value: option.title,
      label: option.title,
      id:option.id
    }));
  };

  // const getDefaultValues=()=>{
  //     postRequest({
  //    per_page:10
  //   },`get-all-tags`).then(function (response) {
  //     if (response?.data?.data) {
  //    setOptions(mapOptionsToValues(response.data.data))
  //     }
  //   });
  // }

  // useEffect(()=>{
  // getDefaultValues();
  // },[])

  useEffect(()=>{
   
setFiles(props.data?mapOptionsToValues(props.data) : [])

setRecordId(props.selectedRecord || "")
  },[props])

  let { fileID } = useParams()
  if (!props.show) {
    return null
  }

  function addTags() {
    getActionData({
      file_id: fileID,
      action:"update-tag",
      tags: selectedTags,
      record_id: record_id,
    })
      .then(function (response) {
        props.onClose()
        // setisLoading(false)
        if (response.status === false) {
          toast.error(response.message)
        } else {
          
          toast.success("Tags are updated successfully")
          refetch()
          // setTimeout(function () { window.location.reload(false); }, 3000);
        }
      })
      .catch((error) => {
        toast.error(error.message)
      })
  }

   const mapSelectedOptions = options => {
    return options.map(option => ({
      title: option.value,
      id:option.id||0
    }));
  };

  const handleChange=(updateTags)=>{
     let tags=mapSelectedOptions(updateTags)
     setFiles(updateTags)
     setSelectedTags(tags)
   }

  const handleSearch=(inputValue, callback)=>{
      if(inputValue){
       postRequest({
     search:inputValue
    },`get-all-tags`).then(function (response) {
      if (response?.data?.data) {
     callback(mapOptionsToValues(response.data.data))
      }
    });
      }else{

      }
  }

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='tag-close'
        onClick={props.onClose}>
        ×
      </button>
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <Para2>Tags</Para2>
          </ModalTitle>
          <WrapperBody>
            <div className='lead-input-container'>
              <Component preselectedItems={files} defaultOptions={options} handleChange={handleChange} handleSearch={handleSearch} async={true}/>
            </div>
          </WrapperBody>
          <WrapperFooter>
            <ModalButtonSubmit onClick={(e) => addTags()}>
              Submit
            </ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 367px;
  min-height: 200px;
  left: 535px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 999;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 367px;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px 20px;
  border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 367px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;
`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align: center;
`

const ModalButtonSubmit = styled.button`
  width: 100%;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #89de58;
  color: #fff;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
`

const Para2 = styled.p`
  color: 000000;
  font-size: 10px;
  font-weight: bold;
  position: relative;
  width: 100%;
  top: 28px;
  text-align: center;
  max-width: 325px;
`
