import React, { useState, useEffect } from "react"
import Table from "./table"
import styled from "styled-components"
import FavIcon from "components/favicon"
import Select from "react-select"
import COLORS from "utils/colors"
import ReactPaginate from "react-paginate"
import Loading from "components/loading"
import EditModal from "./modal"
import { toast } from "react-toastify"
import { postRequest } from "services/dataValidation"

function ManageCustomFields() {
  const [isLoading, setisLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const [totalRecords, setTotalRecords] = useState(0)
  const [data, setData] = useState([])
  const [addShow, setAddShow] = useState(false)
  const [search, setSearch] = useState("")
  const [perPage, setPerPage] = useState()
  const [checked, setChecked] = useState({})
  const [tabs, setTabs] = useState(1)

  const [perPageOptions, setPerPageOptions] = useState([
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
    { value: 250, label: "250" },
  ])

  const handleGetAllTags = () => {
    setisLoading(true)
    postRequest({ action: "get-all" }, "custom-fields").then(function (
      response
    ) {
      if (response.data) {
        setData(response.data?.data)
        setCurrentPage(response.data?.current_page)
        setTotalPages(response.data?.last_page)
        setTotalRecords(response.data?.total)
        setisLoading(false)
      }
    })
  }

  const handleAddTag = (tag) => {
    postRequest(
      {
        field_name: tag,
        action: "create",
      },
      `custom-fields`
    )
      .then(function (response) {
        if (response?.status) {
          handleGetAllTags()
          setAddShow(false)
          toast.success("Your Custom Field is added successfully")
        } else {
          toast.error(response.errors)
        }
      })
      .catch((error) => {
        toast.error("This Custom Field  is already added")
      })
  }

  const handleSearch = (value) => {
    setisLoading(true)
    postRequest({ action: "get-all", search: value }, "custom-fields").then(
      function (response) {
        if (response.data) {
          setData(response.data?.data)
          setCurrentPage(response.data?.current_page)
          setTotalPages(response.data?.last_page)
          setisLoading(false)
          setChecked({})
        }
      }
    )
    setSearch(value)
  }

  const handleReset = () => {
    handleGetAllTags()
    setSearch("")
    setChecked({})
  }

  const onPageChange = (e) => {
    setCurrentPage(e.selected + 1)
  }

  useEffect(() => {
    handleGetAllTags()
  }, [currentPage])

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      height: "35px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
    }),
  }

  const PerPageChangeHandle = (e) => {
    setPerPage(e.value)
    postRequest({ action: "get-all", per_page: e.value }, `get-all-tags`).then(
      function (response) {
        if (response.data) {
          setData(response.data?.data)
          setCurrentPage(response.data?.current_page)
          setTotalPages(response.data?.last_page)
          setisLoading(false)
        }
      }
    )
  }

  const PerPageSelect = () => (
    <Select
      name='selectType'
      options={perPageOptions}
      className='adselect'
      classNamePrefix='select'
      styles={customStyles}
      onChange={PerPageChangeHandle}
      defaultValue={{ value: "100", label: "100" }}
      value={perPageOptions.find((obj) => obj.value === perPage)}
    />
  )
  return (
    <>
      <Loading show={isLoading} />
      <div className='row manipulator-contacts topFilters'>
        <div className='flex justify-between'>
          <div className='flex'>
            <SearchIcon>
              <FavIcon class='fas fa-search'></FavIcon>
            </SearchIcon>
            <Input
              type='text'
              placeholder='Search'
              className='FreeSearch'
              // onKeyPress={(e) => freeSearch()}
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
            />
            <a className='searchBtn' onClick={handleReset}>
              <svg
                //   onClick={(e) => resetFilter()}
                width='22'
                height='25'
                viewBox='0 0 22 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.9498 24.6804C16.9146 24.1243 20.8935 20.1555 21.4598 15.1907C22.203 8.69904 17.1573 3.17305 10.8325 3.10227V0.190125C10.8325 0.0283388 10.6302 -0.0576099 10.4937 0.0435062L4.49754 4.44711C4.39642 4.52295 4.39642 4.66957 4.49754 4.7454L10.4937 9.14901C10.6302 9.25013 10.8325 9.15912 10.8325 9.00239V6.0953C15.2765 6.16609 18.8358 9.94783 18.5324 14.4677C18.2746 18.3556 15.1046 21.5105 11.2167 21.7582C7.09622 22.0211 3.59255 19.0786 2.96563 15.1856C2.84934 14.4627 2.21737 13.9369 1.48933 13.9369C0.584342 13.9369 -0.12347 14.7407 0.0180921 15.6356C0.897802 21.1818 6.01933 25.3428 11.9498 24.6804Z'
                  fill='white'
                />
              </svg>
            </a>
          </div>
          <div className='filter2-right '>
            <button
              className='bg-green text-white ml-10 text-white rounded border-none w-180 h-full'
              onClick={() => setAddShow(true)}>
              Add Custom Field
            </button>
          </div>
        </div>
      </div>
      <div className='row manipulator-contacts'>
        <div className='col-md-9'>
          {" "}
          <span className='filter_info'>
            {" "}
            <label>Total Records:</label>{" "}
            <p className='totalRecords'>{totalRecords}</p>
          </span>
        </div>

        <div className='col-md-3 rightPerPage'>
          {" "}
          <span className='filter_info'>
            {" "}
            <label>Per Page:</label> <PerPageSelect />
          </span>
        </div>
      </div>
      <Table
        heading1='Custom fields'
        modalTitle='Custom Field'
        data={data}
        handleGetAllTags={handleGetAllTags}
        setisLoading={setisLoading}
        checked={checked}
        setChecked={setChecked}
      />
      {data !== 0 && (
        <WrapperPagination>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPages}
            onPageChange={onPageChange}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </WrapperPagination>
      )}
      <EditModal
        onClose={() => {
          setAddShow(false)
        }}
        handleGetAllTags={handleGetAllTags}
        title='Add Custom Fields'
        show={addShow}
        handleSubmit={handleAddTag}
      />
    </>
  )
}

const SearchIcon = styled.span`
  position: absolute;
  color: ${COLORS.grey};
  display: block;
  padding: 13px 0px 0px 15px;
  margin-left: 5px;
  color: ${COLORS.grey};
  min-width: 50px;
`
const Input = styled.input`
  width: 85%;
  height: 47px;
  float: left;
  margin-right: 10px;
  border: #d2d2d2 solid 1px;
  border-radius: 5px;
  padding-left: 40px;
  margin-right: 5px;
  ::placeholder,
  ::-webkit-input-placeholder {
    position: absolute;
    padding: 10px;
    padding-top: 15px;
    padding-left: 5px;
  }
  &:focus {
    border-style: solid;
    outline: none;
  }
`
const WrapperPagination = styled.div`
  margin-left: -20px;
`

export default ManageCustomFields
