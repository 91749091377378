import React from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import { getCrmActions } from "services/crm"
import COLORS from "utils/colors"
const Modal = (props) => {
  let { contactID } = useParams()
  if (!props.show) {
    return null
  }
  function AddTask() {
    // const discription = $("textarea.actvity_textarea").val()
    const discription = document.querySelector(
      "textarea.actvity_textarea"
    ).value
    // const title = $(".tasktitle").val()
    const title = document.querySelector("input.tasktitle").value
    //setisLoading(true)
    getCrmActions(
      {
        file_id: "0",
        action: "add-task",
        contact_id: contactID,
        type: "note",
        description: discription,
        title: title,
      },
      1
    )
      .then(function (response) {
        if (response.status === false) {
          toast.error(response.message)
        } else {
          toast.success(response.message)
          setTimeout(function () {
            window.location.reload(false)
          }, 1000)
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }
  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='lead-close'
        onClick={props.onClose}>
        ×
      </button>
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <span className='task_title'>Add Note </span>
          </ModalTitle>
          <WrapperBody>
            <div className='lead-input-container'>
              <label>Title</label>
              <input type='text' name='title' className='tasktitle' />
            </div>
            <div className='lead-input-container'>
              <label>Description</label>
              <textarea name='Description' className='actvity_textarea' />
            </div>
          </WrapperBody>
          <WrapperFooter>
            <ModalButton onClick={props.onClose}>Cancel</ModalButton>
            <ModalButtonSubmit onClick={AddTask}>Submit</ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 567px;
  min-height: 200px;
  left: 420px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 9999999;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 520px;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px 20px;
  border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 520px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;
`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align: center;
`

const ModalButtonSubmit = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #89de58;
  color: #fff;
  margin-top: 30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
  margin-left: 10px;
`

const ModalButton = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #d4d4d4;
  color: #6d6e6e;
  margin-top: 30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
`
