import React from "react"
import ReactTooltip from "react-tooltip"
import styled from "styled-components"
import COLORS from "utils/colors"
const Item = (props) => {
  const tooltipID = "tooltip_ID_" + props.id

  return (
    <React.Fragment>
      <tr>
        <td>
          <ParaTwo
            data-tip
            data-for={tooltipID}
            className='itemHeading downloads'>
            {props.phone}
          </ParaTwo>
          <ReactTooltip id={tooltipID} aria-haspopup='true' role='example'>
            {props.phone}{" "}
          </ReactTooltip>
        </td>
        <td>
          {" "}
          <Para>{props.phone_position}</Para>{" "}
        </td>
        <td>
          {" "}
          <Para>{props.carrier}</Para>{" "}
        </td>
        <td>
          <Para>{props.caller_id_name}</Para>
        </td>
        <td>
          <Para>{props.caller_id_match}</Para>
        </td>
        <td>
          <Para>{props.connected}</Para>
        </td>
        <td>
          <Para>{props.original_disposition}</Para>
        </td>
        <td>
          <Para>{props.times_called}</Para>
        </td>
      </tr>
      <tr className='emptyrow'>&nbsp;</tr>
    </React.Fragment>
  )
}

export default Item

const Para = styled.p`
  color: ${COLORS.createdat};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`

const ParaTwo = styled.p`
  color: ${COLORS.username};
  font-family: Montserrat;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  word-break: break-all;
`
