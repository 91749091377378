import React from "react"
import ReactPaginate from "react-paginate"
import Select from "react-select"
import styled from "styled-components"
import "assets/css/style.css"
function Pagination(props) {
  let totalRecords = props.totalRecords
  if (totalRecords) {
    totalRecords = totalRecords.replace(/,/g, "")
  }

  let pageCount = Math.ceil(totalRecords / props.recordsPerPage)

  const onPageChange = ({ selected }) => {
    props.getPageData(selected + 1, props.recordsPerPage)
  }
  const onPageChange2 = (selectedOption, name) => {
    const value = selectedOption.value
    props.onChangeRecordPerpage(value, pageCount)
    // setSelectedValue(value);
  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      height: "35px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
    }),
  }
  const PerPageOption = [
    { value: "100", label: "100" },
    { value: "150", label: "150" },
    { value: "200", label: "200" },
    { value: "250", label: "250" },
  ]
  const PerPageSelect = () => (
    <Select
      name='selectType'
      options={PerPageOption}
      className='adselect'
      classNamePrefix='select'
      styles={customStyles}
      onChange={onPageChange2}
      defaultValue={{ value: "100", label: "100" }}
      value={PerPageOption.find((obj) => obj.value === props.selectedValue)}
    />
  )

  return (
    <div>
      <Wrapper>
        <WrapperPagination>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={onPageChange}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </WrapperPagination>
        <WrapperSelect>
          <span className='filter_info perPage'>
            <label>Per Page:</label> <PerPageSelect />
          </span>
        </WrapperSelect>
      </Wrapper>
    </div>
  )
}

export default Pagination

const Wrapper = styled.div`
  position: relative;
  float: left;
  width: 100%;
`
const WrapperPagination = styled.div`
  margin-left: -20px;
`

const WrapperSelect = styled.div`
  display: flex;
  float: right;
  margin-top: -67px;
  margin-right: 15px;
  margin-top: -86px;
  min-width: 150px;
`
