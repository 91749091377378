import React, { useState } from "react";
import { saveTemplateNamePost } from "services/dataValidation";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

const SaveTemplate = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tempateName, setTemplateName] = useState("");
  //const [templateList, setTemplateList] = useState([]);

  const handleTemplateName = (event) => {
    let value = event.target.value;
    setTemplateName(value);
  };
  const saveTemplateName = (event) => {
    if (tempateName.trim() == "") {
      toast.error("template name is required.");
      return false;
    }
    var flineType = props.template.lineType;
    var fconnected = props.template.connected;
    var fcaller_id_match = props.template.caller_id_match;
    var fformat = props.template.format;
    var fileID = localStorage.getItem("file_id");
    var preText = event.target.innerText;
    event.target.innerText = "saving...";

    saveTemplateNamePost({
      file_header: props.template.fileHeaderState,
      linetype: flineType,
      connected: fconnected,
      caller_id_match: fcaller_id_match,
      format: fformat,
      file_id: fileID,
      template_name: tempateName,
    })
      .then(function (response) {
        event.target.innerText = preText;
        setTemplateName("");
        props.setTemplateList(response.data.templates);
        toast.success("template is saved.");
        setShow(false);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="btn btn-secondary btn-sm"
        style={{ float: "left" }}
      >
        + Save
      </Button>

      <Modal show={show} onHide={handleClose}>
        <div className="modal-header" style={{ padding: "0px" }}>
          <h5 className="modal-title" id="exampleModalLongTitle">
            Create New Template
          </h5>
          <button
            onClick={handleClose}
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style={btn_close}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <input
            className="form-control-sm"
            type="text"
            placeholder="enter template name"
            onKeyUp={handleTemplateName}
            style={textStyle}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={saveTemplateName}
            className="btn-sm"
          >
            Save Template
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SaveTemplate;
const textStyle = {
  height: "40px !important",
  width: "100%",
  border: "solid 1px #ccc",
};
const btn_close = {
  right: "16px",
  "font-size": "28px",
  top: "-6px",
  color: "#000",
};
