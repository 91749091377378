import React,{useState} from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import { getManipulatorContacts } from "services/dataValidation"
import COLORS from "utils/colors"

const SaveFilter = (props) => {
  let { fileID } = useParams()
  const [,setValue]=useState('')
  if (!props.show) {
    return null
  }

  function saveFilterCall() {
    let Filter = {}
    const FilterName = document.querySelector("input.fiterSearchInput").value

    const listFields = props.presetType
      ? props.listFieldsUpdate
      : props.listFields
    listFields.map((item, index) => {
      const key = String(index)
      return (Filter[key] = {
        name: item.label,
        condition: item.condition,
        value: item.value,
        operator:item.operator
      })
    })
   props.setisLoading(true)
    if (props.presetType === true) {
      let data = {
        file_id: fileID,
        search_type: "filter",
        filter_id: props.filterID,
        save_filter: "YES",
        filter_name: FilterName,
        filters: Filter,
      }
      getManipulatorContacts(data,1).then(function (response) {
        toast.success("Filter Saved.")
        props.onClose()
        props.clearFilters()
        props.handlePreset();
        props.setPresets(FilterName);
        props.setisLoading(false)
        // props.Filter()
      })
    } else {
      let data = {
        file_id: fileID,
        search_type: "filter",
        save_filter: "YES",
        filter_name: FilterName,
        filters: Filter,
      }
      
      getManipulatorContacts(data,1).then(function (response) {
        toast.success("Filter Saved.")
        props.onClose()
        props.clearFilters()
        props.handlePreset();
        props.setPresets(FilterName);
        props.setTotalRecords(response.total_records)
        props.setExcelUserData(response.data);
        props.setFilteredUserExcelData(response.data)
        props.setfileName(response.file_name)
        props.setPresetValue(response.savedFilterId)
        props.setisLoading(false)
        setValue('')
        // props.Filter()
        
        
        // setTimeout(function () {
        //   window.location.reload(false)
        // }, 1000)
      })
    }
  }

  const onInputchange = (event) => {
    const listInput = document.querySelectorAll("input[class=fiterSearchInput]")
    for (let i = 0; i < listInput.length - 1; i++) {
      listInput[i].value = event.target.value
    }
    setValue(event.target.value)
  }

  

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='tag-close'
        onClick={props.onClose}>
        ×
      </button>
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <Para2>Save Filter</Para2>
          </ModalTitle>
          <WrapperBody>
            <div className='lead-input-container'>
              <input
                type='text'
                name='fiterSearchInput'
                
                defaultValue={props.filterName}
                className='fiterSearchInput'
                onChange={onInputchange}
              />
            </div>
          </WrapperBody>
          <WrapperFooter>
            <ModalButtonSubmit onClick={(e) => saveFilterCall()}>
              Submit
            </ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default SaveFilter

const WrapperModal = styled.div`
  position: fixed;
  width: 367px;
  min-height: 200px;
  left: 535px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 9999;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 367px;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px 20px;
  border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 367px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;
`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align: center;
`

const ModalButtonSubmit = styled.button`
  width: 100%;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #89de58;
  color: #fff;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
`

const Para2 = styled.p`
  font-weight: bold;
  position: relative;
  width: 100%;
  top: 28px;
  text-align: center;
  max-width: 312px;
`
