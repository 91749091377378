import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import "assets/css/style.css";
import Modal from "components/credit-required-modal";
import FavIcon from "components/favicon";
import Loading from "components/loading";
import TopNav from "components/navbar/topNav";
import {
  getAvailbleCreadit,
  revampReportSubmission,
} from "services/dataValidation";
import { CheckRequiredCreditCall, QuickPay } from "services/paymentDeatil";
import COLORS from "utils/colors";
import { getUserID } from "utils/helper";
import Tabs from "./Tabs";

const Report = () => {
  const [isLoading, setisLoading] = useState(false);
  const [availableCreadit, setAvailableCreadit] = useState();
  const [show, setShow] = useState(false);
  const [Price, setPrice] = useState(0);
  const [RequiredCredit, setRequiredCredit] = useState(0);
  const reportData = JSON.parse(localStorage.getItem("reportData"));
  const phone = reportData?.uniquePhones;
  let nf = new Intl.NumberFormat("en-US");

  let history = useHistory();
  useEffect(() => {
    getAvailbleCreadit2();
  }, []);
  const userID = getUserID();

  useEffect(() => {
    GetCreatePrice();
    CheckRequiredCredit();
    checkCreditRequired();
  }, []);

  const lists = [
    {
      label: "Total CSV File Count:",
      content: [
        { label: "CSV Row Count", value: 100 },
        { label: "CSV Columns", value: 100 },
        { label: "Phone Numbers", value: 100 },
        { label: "Duplicate Phone Numbers", value: 100 },
        { label: "Unique Phone Numbers", value: 100 },
        { label: "Blank Phone Numbers", value: 100 },
      ],
    },
    {
      label: "Total Phone Validation Count:",
      content: [
        { label: "Caller ID Names", value: "(100 45%)" },
        { label: "Carriers", value: "(100 55%)" },
        { label: "Caller ID Mobile Yes", value: "1(00 100%)" },
        { label: "Caller ID Mobile No", value: "(100 45%)" },
        { label: "Caller ID Landline Yes", value: "(100 92%)" },
        { label: "Caller ID Landline No", value: "(100 46%)" },
        { label: "Disconnected Mobile ", value: "(100 51%)" },
        { label: "Disconnected Landline", value: "(100 35%)" },
        { label: "Mobile", value: "(100 32%)" },
        { label: "Landline", value: "(100 67%)" },
        { label: "Disconnected", value: "(100 44%)" },
        { label: "Caller ID Landline No", value: "(100 23%)" },
      ],
    },
  ];

  function getAvailbleCreadit2() {
    getAvailbleCreadit({ user_id: userID })
      .then(function (response) {
        setAvailableCreadit((state) => response.balance_credit);
      })
      .catch((error) => {
        alert("Some error occured");
        console.log("file upload error: ", error);
      });
  }

  function checkCreditRequired() {
    const credits_required_to_buy = reportData?.credits_required_to_buy;
    if (credits_required_to_buy > 0) {
      setShow(true);
    }
  }

  function SubmitReport() {
    if (RequiredCredit > 0) {
      toast.error("The credit is low");
      setShow(true);
    } else {
      setisLoading(true);
      const MappedData = JSON.parse(localStorage.getItem("phoneMapping"));
      revampReportSubmission({
        file_id: MappedData.filelist_id,
        total_unique_phone: phone,
      })
        .then(function (response) {
          if (response.status === true) {
            setisLoading(false);
            localStorage.removeItem("FileName");
            localStorage.removeItem("phoneMapping");
            localStorage.removeItem("file_id");
            localStorage.removeItem("phoneContactFields");
            localStorage.removeItem("phoneMappedFields");
            //history.push("phone-validation")
            window.location.href = "phone-validation";
          } else {
            setisLoading(false);
            toast.error(response["message"]);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  function Previous() {
    history.push("revamp-contact-mapping");
  }

  function PurchaseCredit() {
    // let amount = $(".creditAmount").val()
    let amount = document.querySelector("input.creditAmount")?.value;
    if (amount === "") {
      alert("Please Enter the Credit Amount");
      return false;
    }
    setisLoading(true);
    QuickPay({
      credits: amount,
    })
      .then(function (response) {
        if (response.status === true) {
          setisLoading(false);
          setShow(false);
          getAvailbleCreadit2();
          CheckRequiredCredit();
          toast.success(response["message"]);
        } else {
          setisLoading(false);
          toast.error(response["message"]);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function CheckRequiredCredit() {
    const fileId = reportData?.filelist_id;
    CheckRequiredCreditCall({
      file_id: fileId,
      total_unique_phone: phone,
    })
      .then(function (response) {
        if (response.status === false) {
          setRequiredCredit(response.credits_required_to_buy);
          setShow(true);
          // GetCreatePrice()
        } else {
          setRequiredCredit(response.credits_required_to_buy);
          setShow(false);
          // GetCreatePrice()
        }
      })
      .catch((error) => {
        // GetCreatePrice()
        console.log("error", error);
      });
  }

  function GetCreatePrice() {
    // let Credits = $(".creditAmount").val()
    let Credits = document.querySelector("input.creditAmount")?.value;

    if (Credits === undefined) {
      Credits = RequiredCredit;
    }
    let dataVariable = "";
    if (Credits <= 7599) {
      dataVariable = (Credits * 0.017).toFixed(2);
    }
    if (Credits >= 7600 && Credits <= 30999) {
      dataVariable = (Credits * 0.015).toFixed(2);
    }
    if (Credits >= 31000) {
      dataVariable = (Credits * 0.013).toFixed(2);
    }

    setRequiredCredit(Credits);
    setPrice("$" + dataVariable);
  }

  return (
    <Wrapper>
      <Modal
        onClose={() => {
          setShow(false);
        }}
        show={show}
        data={reportData?.credits_required_to_buy}
        Payment={(e) => PurchaseCredit()}
        Price={Price}
        requiredCredit={RequiredCredit}
        GetCreatePrice={(e) => GetCreatePrice()}
      />
      <Loading show={isLoading} />
      <TopNav />
      <div className="row revamp_upload top">
        <div className="col-md-3">
          <div className="row left_side">
            <div className="col-md-8">
              <Link to="/revamp-phone-mapping">
                {" "}
                <span className="icon_heading"> Map phone Number </span>
              </Link>
              <Link to="/revamp-contact-mapping">
                {" "}
                <span className="icon_heading"> Map Contact Names </span>
              </Link>
              {/* <Link to='/revamp-report'> */}
              <span className="icon_heading"> Report & Confirmation </span>
              {/* </Link> */}
            </div>{" "}
            <div className="col-md-4">
              <div className="main_icons">
                <span className="left_icons">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.142 12.9464L14.6802 10.4846C13.8009 9.60541 12.3063 9.95713 11.9546 11.1001C11.6908 11.8914 10.8116 12.331 10.0203 12.1551C8.26189 11.7155 5.88801 9.42957 5.44841 7.58322C5.18464 6.79189 5.71217 5.91268 6.50346 5.64895C7.64644 5.29726 7.99813 3.8026 7.11891 2.92339L4.65711 0.461587C3.95374 -0.153862 2.89869 -0.153862 2.28324 0.461587L0.61273 2.13209C-1.05778 3.89052 0.788573 8.55035 4.92088 12.6827C9.05318 16.815 13.713 18.7493 15.4714 16.9908L17.142 15.3203C17.7574 14.6169 17.7574 13.5619 17.142 12.9464Z"
                      fill="#A0A0A0"
                    />
                  </svg>
                </span>
              </div>

              <div className="icon_border" />
              <div className="main_icons">
                <span className="left_icons">
                  <svg
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.86449 10.3927C10.2923 10.3927 11.5286 9.88063 12.5388 8.87029C13.5489 7.86012 14.061 6.62412 14.061 5.19619C14.061 3.76875 13.5489 2.53258 12.5386 1.52208C11.5283 0.51208 10.2921 0 8.86449 0C7.43656 0 6.20055 0.51208 5.19038 1.52225C4.18021 2.53242 3.66797 3.76859 3.66797 5.19619C3.66797 6.62412 4.18021 7.86029 5.19055 8.87046C6.20088 9.88046 7.43705 10.3927 8.86449 10.3927Z"
                      fill="#A0A0A0"
                    />
                    <path
                      d="M17.9564 16.5899C17.9272 16.1695 17.8683 15.7109 17.7816 15.2266C17.694 14.7388 17.5812 14.2775 17.4463 13.856C17.3069 13.4203 17.1172 12.99 16.8828 12.5777C16.6396 12.1497 16.3538 11.7771 16.0332 11.4704C15.6979 11.1496 15.2873 10.8917 14.8126 10.7035C14.3396 10.5164 13.8153 10.4216 13.2545 10.4216C13.0343 10.4216 12.8213 10.5119 12.4099 10.7797C12.1568 10.9448 11.8606 11.1358 11.5301 11.3469C11.2475 11.527 10.8646 11.6957 10.3917 11.8485C9.93034 11.9978 9.46188 12.0735 8.99951 12.0735C8.53714 12.0735 8.06884 11.9978 7.60696 11.8485C7.13455 11.6959 6.75169 11.5272 6.46939 11.3471C6.142 11.1379 5.84571 10.947 5.58877 10.7796C5.17792 10.5118 4.96476 10.4214 4.74452 10.4214C4.18355 10.4214 3.65945 10.5164 3.18655 10.7037C2.71216 10.8915 2.30148 11.1494 1.96585 11.4706C1.64537 11.7774 1.35946 12.1499 1.1165 12.5777C0.882272 12.99 0.69265 13.4201 0.553066 13.8562C0.418256 14.2777 0.305503 14.7388 0.217934 15.2266C0.131189 15.7102 0.0722607 16.169 0.043126 16.5904C0.0144851 17.0032 0 17.4317 0 17.8644C0 18.9906 0.358012 19.9024 1.06399 20.5748C1.76125 21.2383 2.68385 21.5749 3.80579 21.5749H14.1942C15.3161 21.5749 16.2384 21.2384 16.9358 20.5748C17.642 19.9029 18 18.9909 18 17.8642C17.9998 17.4295 17.9852 17.0007 17.9564 16.5899Z"
                      fill="#A0A0A0"
                    />
                  </svg>
                </span>{" "}
              </div>

              <div className="icon_border" />
              <div className="main_icons">
                <span className="left_icons active">
                  <svg
                    width="17"
                    height="23"
                    viewBox="0 0 17 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3333 7.08333C10.5527 7.08333 9.91667 6.44725 9.91667 5.66667V0H1.41667C0.636083 0 0 0.636083 0 1.41667V21.25C0 22.032 0.636083 22.6667 1.41667 22.6667H15.5833C16.3653 22.6667 17 22.032 17 21.25V7.08333H11.3333ZM5.66667 19.8333H2.83333V15.5833H5.66667V19.8333ZM9.91667 19.8333H7.08333V12.75H9.91667V19.8333ZM14.1667 19.8333H11.3333V9.91667H14.1667V19.8333Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-9 right-column">
          <span className="icon_heading_black revamp-report"> Report </span>
          <span className="creadit_needed revamp-report">
            <label>Credits Needed:</label>
            <span className="creadit_needed">{nf.format(phone)}</span>
          </span>
          <Credit>
            <Inner>
              <Icon>
                <FavIcon class="far fa-star"></FavIcon>
              </Icon>
              <AvailableCredit>Available Credit</AvailableCredit>
            </Inner>
            <Data>{availableCreadit}</Data>
          </Credit>
          <div className="row form upload-process-revamp report">
            <Tabs
              label="Total CSV File Count:"
              contents={reportData?.reportLogs || []}
            />
            <Tabs label="Total Phone Count:" contents={reportData?.mapReport} />
            <div className="text-center mt-4">
              <button
                onClick={(e) => Previous()}
                className="previous-button basic-multi-select   font-bold py-2 px-4 m-3 mt-4 w-10 rounded"
              >
                Previous
              </button>
              <button
                onClick={(e) => SubmitReport()}
                className="basic-multi-select  bg-primary hover:bg-blue-700 text-white m-3  w-10 font-bold py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Credit = styled.div`
  display: block;
  width: 32%;
  float: left;
  margin-top: 24px;
`;
const Inner = styled.div`
  display: inline-flex;
  text-align: right;
  float: right;
`;
const Icon = styled.p`
  color: ${COLORS.grey};
  margin-right: 10px;
  margin-top: -2px;
  border: solid 1px #959595;
  padding: 2px;
  border-radius: 50%;
  line-height: 1;
`;
const Data = styled.h6`
  color: ${COLORS.blue};
  display: block;
  text-align: center;
  font-weight: 800;
  font-family: Montserrat;
  font-size: 24px;
  padding-left: 15px;
  float: left;
  text-align: right;
  width: 100%;
  padding-right: 32px;
`;

const AvailableCredit = styled.h6`
  color: ${COLORS.grey};
  font-weight: 500;
`;

export default Report;
