import { BASE_URL } from "config"
import axiosService from "./axios.service"

export async function submit(ev) {
  ev.preventDefault()
  const { currentTarget } = ev
  const fD = new FormData(currentTarget)
  const cardInfo = {
    name: fD.get("name"),
  }
  let { token } = await this.props.stripe.createToken({ name: cardInfo.name })
  let response = await fetch(`${BASE_URL}add-stripe-card`, {
    method: "POST",
    headers: { "Content-Type": "text/plain" },
    body: token.id,
  })

  if (response.ok) console.log("Purchase Complete!")
}

//get Trasaction History API
export async function getUsersTransactionData(requestObj) {
  const url = BASE_URL + "user-transactions"
  return axiosService.post(url, requestObj)
}

//get User Credit Cards
export async function getUserCreditCardsAPI(requestObj) {
  const url = BASE_URL + "credit-cards"
  return axiosService.post(url, requestObj)
}

//Add New Card API
export async function AddNewCardAPI(requestObj) {
  const url = BASE_URL + "add-stripe-card"
  return axiosService.post(url, requestObj)
}

//make Default Card API
export async function makeStripeDefultCard(requestObj) {
  const url = BASE_URL + "set-default-card"
  return axiosService.post(url, requestObj)
}

//Delete User Stripe Card
export async function deleteStripeCardAPI(requestObj) {
  const url = BASE_URL + "delete-stripe-card"
  return axiosService.post(url, requestObj)
}

//add payment page pay button
export async function paybutton(requestObj) {
  const url = BASE_URL + "charge-bycredit"
  return axiosService.post(url, requestObj)
}

export async function QuickPay(requestObj) {
  const url = BASE_URL + "quick-pay"
  return axiosService.post(url, requestObj)
}

export async function CheckRequiredCreditCall(requestObj) {
  const url = BASE_URL + "check-required-credits"
  return axiosService.post(url, requestObj)
}

export async function StripePlans(requestObj) {
  const url = BASE_URL + "stripe-plans-list"
  return axiosService.post(url, requestObj)
}

export async function selectedStripePlans(requestObj) {
  const url = BASE_URL + "select-stripe-plan"
  return axiosService.post(url, requestObj)
}

export async function CurrentStripePlans(requestObj) {
  const url = BASE_URL + "user-plan-history"
  return axiosService.post(url, requestObj)
}
