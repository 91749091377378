import React, { useEffect, useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import { getRelativeData } from "services/dataValidation"
import styled from "styled-components"
import Prev from "assets/images/prev-arrow.svg"
import Next from "assets/images/next-arrow.svg"

const Modal = ({
  owner1,
  setisLoading,
  fileId,
  isLoading,
  mailingAddress,
  onClose,
  applyFilter,
  // totalCount,
  // filteredRelativeCounts,
  totalRecords,
  i,
  index,
  totalfiles,
  counter,
  setItemCount,
  ...props
}) => {
  const [step, setStep] = useState("person1")
  const [relativeData, setRelativeData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [filteredRelativeCounts, setFilteredRelativeCounts] = useState(0)
  const [, setCount] = useState(0)
  const [error, setError] = useState()
  const [subHeading, setSubHeading] = useState("relative1")
  // const Data = relativeData?.length

  const requestRelativeData = async (mailing_address) => {
    setisLoading(true)
    const Filter = {}
    let valid = true
    applyFilter.map((item, index) => {
      const key = String(index)
      if (!item.label || !item.value) {
        valid = false
      }
      return (Filter[key] = {
        name: item.option,
        condition: item.condition,
        value: item.value,
        operator: item.operator,
      })
    })

    const result = await getRelativeData({
      file_id: fileId,
      filters: valid ? Filter : undefined,
      person: "person1",
      relative: "relative1",
      mailing_address: mailing_address,
    })
    if (result?.status) {
      setError()
      setisLoading(false)
      setRelativeData(result?.data)
      setFilteredRelativeCounts(result?.filtered_relative_counts)
      setTotalCount(result?.relative_counts)
      const [, index] = counter[mailing_address]
      setItemCount(index + 1)
      setCount(result?.count)
    } else {
      setError(result?.message)
      setisLoading(false)
      setRelativeData([])
    }
  }

  useEffect(() => {
    requestRelativeData(mailingAddress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSteps = async (step) => {
    setStep(step)
    const Filter = {}
    let valid = true
    applyFilter.map((item, index) => {
      const key = String(index)
      if (!item.label || !item.value) {
        valid = false
      }
      return (Filter[key] = {
        name: item.option,
        condition: item.condition,
        value: item.value,
        operator: item.operator,
      })
    })
    setSubHeading((prev) => (step === "person1" ? "relative1" : ""))
    setisLoading(true)
    if (step === "person1") {
      requestRelativeData(mailingAddress)
    } else if (!step.includes("relative")) {
      const result = await getRelativeData({
        file_id: fileId,
        person: step,
        relative: "",
        filters: valid ? Filter : undefined,
        mailing_address: totalfiles[index - 1],
      })
      if (result?.status) {
        setError()
        setisLoading(false)
        setRelativeData(result?.data)
        setCount(result?.count)
      } else {
        setError(result?.message)
        setisLoading(false)
        setRelativeData([])
        setCount(result?.count)
      }
    } else {
      const result = await getRelativeData({
        file_id: fileId,
        person: step,
        relative: "relative1",
        filters: valid ? Filter : undefined,
        mailing_address: totalfiles[index - 1],
      })
      if (result?.status) {
        setError()
        setisLoading(false)
        setRelativeData(result?.data)
        setCount(result?.count)
      } else {
        setisLoading(false)
        setError(result?.message)
        setRelativeData([])
        setCount(result?.count)
      }
    }
  }

  const handleSubHeading = async (subheading) => {
    setSubHeading(subheading)
    setisLoading(true)
    const Filter = {}
    let valid = true
    applyFilter.map((item, index) => {
      const key = String(index)
      if (!item.label || !item.value) {
        valid = false
      }
      return (Filter[key] = {
        name: item.option,
        condition: item.condition,
        value: item.value,
        operator: item.operator,
      })
    })
    const result = await getRelativeData({
      file_id: fileId,
      person: step,
      relative: subheading,
      filters: valid ? Filter : undefined,
      mailing_address: totalfiles[index - 1],
    })
    if (result?.status) {
      setError()
      setisLoading(false)
      setRelativeData(result?.data)
      setCount(result?.count)
    } else {
      setisLoading(false)
      setError(result?.message)
      setRelativeData([])
      setCount(result?.count)
    }
  }

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='manipulator-close close-btn1'
        onClick={onClose}>
        ×
      </button>
      <WrapperContent>
        <WrapperHeader className=' manipulator-phone-modal'>
          <div className='flex heading-title'>
            <h1 className='popupModalHeader'>
              {!isLoading && (
                <span className='float-left'>{`(${index} - ${totalRecords} Total Records)`}</span>
              )}
              Relative Data{" "}
              <span className='float-right'>
                {filteredRelativeCounts} out of {totalCount}
              </span>
            </h1>
            {/* <div className="w-8% mr-20 font-bold">ssddsdss</div> */}
          </div>
          <div className='tab-relatives'>
            <button
              className={
                step === "person1" ? "tab-realtive--active" : "tab-realtive"
              }
              onClick={() => handleSteps("person1")}>
              {owner1}{" "}
            </button>
            <button
              className={
                step === "person2" ? "tab-realtive--active" : "tab-realtive"
              }
              onClick={() => handleSteps("person2")}>
              Person 2
            </button>
            {/* <button className={step=='person2_relative'?'tab-realtive--active':"tab-realtive"} onClick={()=>handleSteps('person2_relative')}>Person 2 - Relatives </button> */}
            <button
              className={
                step === "person3" ? "tab-realtive--active" : "tab-realtive"
              }
              onClick={() => handleSteps("person3")}>
              Person 3{" "}
            </button>
            {/* <button className={step=='person3_relative'?'tab-realtive--active':"tab-realtive"} onClick={()=>handleSteps('person3_relative')}>Person 3 - Relatives </button> */}
          </div>
          {/* {step.includes('relative')&& */}
          <div className='tab-relatives mb-20'>
            <div className='MainDownloadTabs w-full'>
              <ul className='flex relativeSteps'>
                {Array.from(Array(6).keys())
                  .slice(1)
                  .map((index) => (
                    <>
                      <li
                        className={
                          subHeading.slice(-1) == index
                            ? "active text-primary mr-12"
                            : "mr-12"
                        }
                        onClick={() => handleSubHeading(`relative${index}`)}>
                        {" "}
                        Relative {index}
                      </li>
                    </>
                  ))}
              </ul>
            </div>
          </div>
          {/* } */}
          <h3 className='popupModalHeader mt-10'>
            Mailing Address
            <span className='mailing_address'>
              {" "}
              {subHeading !== ""
                ? ""
                : `${
                    relativeData.length !== 0
                      ? `${
                          relativeData[0]?.p_mailing_address ||
                          relativeData[0]?.mailing_address
                        }, ${relativeData[0]?.mailing_city || ""} ${
                          relativeData[0]?.mailing_state || ""
                        } ${relativeData[0]?.mailing_zip || ""}`
                      : ""
                  }`}
            </span>
          </h3>
          <h3 className='popupModalHeader mt-10'>
            Name
            <span className='mailing_address'>
              {" "}
              {relativeData.length !== 0
                ? relativeData[0]?.name || relativeData[0]?.first_name
                  ? relativeData[0]?.name || relativeData[0]?.first_name
                  : "" + " " + relativeData[0]?.last_name
                  ? relativeData[0]?.last_name
                  : ""
                : ""}
            </span>
          </h3>
          <h3 className='popupModalHeader'>
            Age:{" "}
            <span className='ownerName'>
              {" "}
              {relativeData.length !== 0 && relativeData[0]?.age}
            </span>
          </h3>
          {!step.includes("relative") && (
            <h3 className='popupModalHeader'>
              Deceased:{" "}
              <span className='ownerName'>
                {" "}
                {relativeData.length !== 0 && relativeData[0]?.deceased}
              </span>
            </h3>
          )}
          <WrapperBody>
            {index - 1 ? (
              <img
                alt=''
                onClick={() => requestRelativeData(totalfiles[index - 2])}
                src={Prev}
                className='w-20 transform-y-center left-8 cursor-pointer'
              />
            ) : null}

            {totalfiles[index] ? (
              <img
                alt=''
                onClick={() => requestRelativeData(totalfiles[index])}
                src={Next}
                className='w-20 transform-y-center right-8 cursor-pointer'
              />
            ) : null}
            {error ? (
              <div className='mt-20 text-center font-bold'>No Record Found</div>
            ) : (
              <table className='popupTablelisting'>
                <tr className='heading'>
                  {/* {step.includes('relative')? */}
                  {/* <> */}
                  <th className='frist'>Phone Number</th>
                  <th>Phone Position </th>
                  <th>Carrier</th>
                  <th>Caller ID Name</th>
                  <th>Caller ID Match</th>
                  <th>Line Type</th>
                  <th>Connected</th>
                  <th>Disposition</th>
                  <th>Time Called</th>
                  <th>Date/Time</th>
                  <th>Call From</th>
                  <th>Campaign Name</th>
                  <th>Duration</th>
                  <th>Status</th>
                  <th>Note</th>
                  {/* </>:<>
                <th className='frist'>Phone</th>
                <th >First Name</th>
                <th>Last Name</th>
                <th>Age</th>
                <th >Deceased</th>
                <th>Mailing Address</th>
                <th>Mailing City</th>
                <th >Mailing Zip</th>
                <th>Mailing State</th>
                </>} */}
                </tr>
                {relativeData.map(
                  ({
                    name,
                    phone_position,
                    carrier,
                    caller_id_name,
                    caller_id_match,
                    line_type,
                    connected,
                    disposition,
                    time_called,
                    date_time,
                    call_from,
                    campaign_name,
                    duration,
                    status,
                    note,
                    first_name,
                    last_name,
                    age,
                    phone,
                    deceased,
                    mailing_address,
                    mailing_city,
                    mailing_zip,
                    mailing_state,
                  }) => (
                    <React.Fragment>
                      {/* { step.includes('relative')? */}
                      <tr>
                        <td>{phone}</td>
                        <td>{phone_position}</td>
                        <td>{carrier}</td>
                        <td>{caller_id_name}</td>
                        <td>{caller_id_match}</td>
                        <td>{line_type}</td>
                        <td>{connected}</td>
                        <td>{disposition}</td>
                        <td>{time_called}</td>
                        <td>{date_time}</td>
                        <td>{call_from}</td>
                        <td>{campaign_name}</td>
                        <td>{duration}</td>
                        <td>{status}</td>
                        <td>{note}</td>
                      </tr>
                      {/* // :
                    // <tr>
                    //   <td >{phone}</td>
                    //   <td >{first_name}</td>
                    //   <td >{last_name}</td>
                    //   <td >{age}</td>
                    //   <td >{deceased}</td>
                    //   <td >{mailing_address}</td>
                    //   <td >{mailing_city}</td>
                    //   <td >{mailing_zip}</td>
                    //   <td >{mailing_state}</td>
                    // </tr> */}
                      {/* } */}
                    </React.Fragment>
                  )
                )}
              </table>
            )}
          </WrapperBody>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 968px;
  min-height: 350px;
  left: 280px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 9999;
  top: 10px;
  height: fit-content;

  border-radius: 0;
  width: calc(98% - 245px);
  left: 260px;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0px 0px 20px 0px;
  border: #d1d1d1 solid 1px;
  overflow-y: hidden;
  border-radius: 20px;
`

const WrapperBody = styled.div`
  padding: 5px;
  max-height: 350px;
  overflow: auto;
  border-radius: 10px;
  margin: 1% 2%;
`
