import ProgressBar from "components/progressbar"
import React,{useState} from "react"
// import Select from "react-select"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"


const Modal = ({show,onClose,submitFile,progress}) => {
  const [name,setName]=useState()
  const [check,setCheck]=useState(true)
  if (!show) {
    return null
  }

  function DownloadhandleChange(e) {
    localStorage.setItem("file_format", 'REISift')
    setName(e.target.files[0]?.name)
    // if(!button){
    // submitFile('REISift')}
  }

  // const options = [
    // { value: "Call Tools", label: "Call Tools" },
    // { value: "Call Tools 2.0", label: "Call Tools 2.0" },
    // { value: "Xencall", label: "Xencall" },
    // { value: "Go High Level (REIReply)", label: "Go High Level (REIReply)" },
    // { value: "Mojo Dialer", label: "Mojo Dialer" },
    // { value: "REISift", label: "REISift" },
    // { value: "Batch Dialer", label: "Batch Dialer" },
  // ]

  // const AutosuggetsDownloadSelect = () => (
  //   <Select
  //     options={options}
  //     className='basic-multi-select-lead'
  //     value={{ value: "REISift", label: "REISift" }}
  //     // onChange={DownloadhandleChange}
  //   />
  // )

  const submit=()=>{
    if(name){
      submitFile('REISift')
    if(check){
      localStorage.setItem("relative-data", "yes")
    }else{
      localStorage.setItem("relative-data", "no")
    }
    }
      else{
      toast.error("Please upload the file")
      }
  }
  return (
    <WrapperModal>
      {!progress&&   <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='lead-close'
        onClick={onClose}>
        ×
      </button>}
   
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <Para2>
              <span className='low_credit'> Please Upload the File</span>
            </Para2>
          </ModalTitle>
          <WrapperBody>
            <div className="mx-auto upload-file-container mb-20">
            <label for="1" className="upload-file">
              <i className="fas fa-download absolute transform-xy-center top-22% left-50%"></i>
              <div className="absolute transform-xy-center w-full text-center top-50% left-50%">
                {name?name:
                <>
                <span className="font-bold cursor-pointer">Choose a file </span>
                <span>or drag it here</span>
                </>
                }
                </div>
            <input id="1" type="file" className="w-full h-full opacity-0 cursor-pointer" onChange={DownloadhandleChange}/>
            </label>
            </div>
            {/* {selectFile? */}
          {/* <div className='lead-input-container purhcase-credit'>
              <AutosuggetsDownloadSelect />
            </div>:
            <> */}
            <div className="text-lg text-center font-bold margin-t-20 margin-b-20">Does this file contain Relative data?</div>  
             <div className="flex justify-center">
                <label className="squareradio__container cursor-pointer">
                <span className="mr-20">Yes</span>
                <input
                  type="radio"
                  name="data"
                  onClick={()=>{
                    setCheck(true);
                    localStorage.setItem("relative-data", "yes")}}
                  checked={check}
                />
                </label>
              <label className="squareradio__container ml-32 cursor-pointer">
                <span className="mr-20">No</span>
                <input
                  type="radio"
                  name="data"
                  onClick={()=>{
                    setCheck(false);
                    localStorage.setItem("relative-data", "no")}}
                  checked={!check}
                />
                </label>
            </div>
            {/* </>
          } */}
            
            {progress?
            <div className='lead-input-container purhcase-credit'>
              <ProgressBar percentage={progress}/>
            </div>:null
            }
            {!progress&&
            <button className="mt-32 mx-auto block blue_btn w-3/4 font-bold h-32 text-white rounded" onClick={submit}>Submit</button>
            }

          </WrapperBody>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 567px;
  left: 60%;
  transform: translate(-50%, -50%);
  top: 50%;
  min-height: 200px;
  display: flex;
  z-index: 999999;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 520px;
  background-color: #ffffff;
  
  padding: 20px 20px;
  border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 520px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;
`

const Para2 = styled.p`
  color: 000000;
  font-size: 15px;
  font-weight: bold;
  position: relative;
  top: 28px;
  width: 88%;
  text-align: center;
`
