import { BASE_URL, API_URL } from "config";
import axiosService from "./axios.service";
export async function login(requestObj) {
  const url = BASE_URL + "login";
  return axiosService.post(url, requestObj);
}

export async function register(requestObj) {
  const url = BASE_URL + "register";
  return axiosService.post(url, requestObj);
}

export async function ActiveAccount(requestObj) {
  const url = BASE_URL + "active-account";
  return axiosService.post(url, requestObj);
}
// To reset the password
export async function resetPasword(requestObj) {
  const url = BASE_URL + "reset-password";
  return axiosService.post(url, requestObj);
}
