import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Popover, Typography } from "@mui/material";
//import { TreeView, useTreeItem } from "@mui/lab";
import { TreeItem } from "@mui/lab";
import { TreeView } from "@mui/lab";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import clsx from "clsx";
//import TreeItem from "@mui/lab/TreeItem";

function FilterSelectField({ options, handleNodeFilters, setTableListFields }) {
  const [placeholder, setPlaceholder] = useState("Filters");
  const [equipmentItem, setEquipmentItem] = useState("");
  function onChangeText(e) {
    // setAnchorEl(e.currentTarget);

    setEquipmentItem(e.target.value);
    setAnchorEl(e.currentTarget);
    if (e.target.value === "") {
      setAa(options);
      setExpanded([]);
    } else {
      const subChilData = options
        .map((obj) => {
          const matchingChildren = obj.children.filter((child) =>
            child.name
              .toLocaleLowerCase()
              .includes(e.target.value.toLocaleLowerCase())
          );
          return matchingChildren.length > 0
            ? { ...obj, children: matchingChildren }
            : null;
        })
        .filter((obj) => obj !== null);
      const child = options.filter((item) =>
        item.name
          .toLocaleLowerCase()
          .includes(e.target.value.toLocaleLowerCase())
      );
      const ids =
        child.length != 0
          ? child.map((item) => item.id)
          : subChilData.map((item) => item.id);
      setExpanded(ids);
      setAa(child.length ? child : subChilData.length ? subChilData : options);
    }
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const clearFilter = () => {
    setEquipmentItem("");
    setEquipmentId(["fit"]);
    setExpanded([]);
    setAa(options);
    setPlaceholder("Filters");
  };

  const handleNodeClick = (e, id) => {
    if (
      [
        "Primary Contacts",
        "Second Columns",
        "Second Contacts",
        "Third Contacts",
        "Tags",
        "Custom Fields",
        "Primary-Contacts",
      ].includes(id)
    ) {
    } else {
      handleNodeFilters(e, id);
      setEquipmentId(id);
      setEquipmentItem(e.target.innerText);
      handleClose(e);
      const subChilData = options.find(({ children }) =>
        children.find((a) => a.id.includes(id))
      );
      if (id == "tag" || id == "property_tag") {
        setPlaceholder("Tags > Primary Contacts");
      } else if (subChilData) {
        setPlaceholder(subChilData?.name);
      } else {
        setPlaceholder("Filters");
      }
    }
  };

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const [equipmentId, setEquipmentId] = useState(["fit"]);
  const [expanded, setExpanded] = useState([]);
  const [aa, setAa] = useState(options);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const CustomTreeItem = (props) => (
    <TreeItem ContentComponent={CustomContent} {...props} />
  );

  const renderTree = (nodes) => (
    <CustomTreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </CustomTreeItem>
  );

  return (
    <>
      <TextField
        id="outlined-controlled"
        label={placeholder}
        className="text-field-hover"
        value={equipmentItem}
        onChange={(e) => onChangeText(e)}
        onClick={handleClick}
        size="small"
        sx={{ width: 300 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {anchorEl ? (
                <>
                  {equipmentItem && (
                    <IconButton className="clear-icon" onClick={clearFilter}>
                      <CloseIcon style={{ margin: "-4px", width: 18 }} />
                    </IconButton>
                  )}
                  <ArrowDropUpIcon style={{ margin: "-4px" }} />
                </>
              ) : (
                <>
                  {equipmentItem && (
                    <IconButton className="clear-icon" onClick={clearFilter}>
                      <CloseIcon style={{ margin: "-4px", width: 18 }} />
                    </IconButton>
                  )}
                  <ArrowDropDownIcon style={{ margin: "-4px" }} />
                </>
              )}
            </InputAdornment>
          ),
          className: equipmentItem ? "text-field-hover" : "",
          ArrowDropUpIcon,
        }}
        InputLabelProps={{
          className: "text-field-hover",
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <TreeView
          aria-label="icon expansion"
          defaultSelected={equipmentId}
          selected={equipmentId}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={(e, id) => setExpanded(id)}
          onNodeSelect={(e, id) => {
            handleNodeClick(e, id);
          }}
          sx={{
            height: 300,
            flexGrow: 1,
            minWidth: "200px",
            overflowY: "auto",
          }}
        >
          {aa && aa.map((item, i) => renderTree(item))}
        </TreeView>
      </Popover>
    </>
  );
}

export default FilterSelectField;

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = TreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
      style={{ padding: "3px 0" }}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={classes.label}
      >
        {label}
      </Typography>
    </div>
  );
});
