import React,{ useState} from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import { getActionData } from "services/dataValidation"
import COLORS from "utils/colors"
import Component from "components/creatable-select"
import ConfirmModal from "components/confirm-modal"

const Modal = (props) => {
  const [confirm,setConfirm]=useState(false)
  const[selectedTags,setSelectedTags]=useState([]); 

   let record_ids = []

    const listCheckBox = document.querySelectorAll(
      "input[name=slectedCheckBox]"
    )

    for (let i = 0; i < listCheckBox.length - 1; i++) {
      if (listCheckBox[i].checked) {
        record_ids.push(listCheckBox[i].value)
      }
    }
  
    if (!props.show) {
    return null
      }
  
  const Tags = props.data ? props.data : []
  let defaultItems = []
  Tags.map((item)=> defaultItems.push({ value: item.title, label: item.title,id:item.id }) )

  const removeTags=(updateTags)=>{
    setSelectedTags(updateTags);
   }

  const updateRemoveTags=()=>{

  setConfirm(false)
  let tags=[];
  selectedTags.map((item)=>
  tags.push(item.id)
  )
  
     getActionData({
      action: "remove-tags-bulk",
      tag_ids:tags,
      record_ids
    })
      .then(function (response) {
      
       toast.success(response.message)
       props.onClose()
       props.getPageData()
          // setTimeout(function () {
          //   window.location.reload(false)
          // }, 1000)
      
      })
      .catch((error) => {})
  }

  return (<>
    <WrapperModal>
    <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='tag-close removetags'
        onClick={props.onClose}>
        ×
      </button>
    <WrapperContent>
        <WrapperHeader>
          <ModalTitle className='RemoveTags'>
            <Para2>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M17.5 10.9995C13.916 10.9995 11 13.9155 11 17.4995C11 21.0835 13.916 23.9995 17.5 23.9995C21.084 23.9995 24 21.0835 24 17.4995C24 13.9155 21.084 10.9995 17.5 10.9995ZM20 18.4995H18.5V19.9995C18.5 20.5515 18.052 20.9995 17.5 20.9995C16.948 20.9995 16.5 20.5515 16.5 19.9995V18.4995H15C14.448 18.4995 14 18.0515 14 17.4995C14 16.9475 14.448 16.4995 15 16.4995H16.5V14.9995C16.5 14.4475 16.948 13.9995 17.5 13.9995C18.052 13.9995 18.5 14.4475 18.5 14.9995V16.4995H20C20.552 16.4995 21 16.9475 21 17.4995C21 18.0515 20.552 18.4995 20 18.4995Z'
                  fill='#047CFF'
                />
                <path
                  d='M21 0H14C12.91 0 11.85 0.46 11.1 1.25L0.82 11.94C0.29 12.5 0 13.23 0 14C0 14.8 0.31 15.56 0.88 16.12L7.88 23.12C8.44 23.69 9.2 24 10 24C10.55 24 11.09 23.85 11.55 23.57C11.06 23.08 10.62 22.54 10.26 21.95C9.92 22.05 9.54 21.96 9.29 21.71L2.29 14.71C2.1 14.52 2 14.27 2 14C2 13.74 2.1 13.5 2.27 13.32L12.55 2.63C12.93 2.22 13.44 2 14 2H21C21.55 2 22 2.45 22 3V10C22 10.09 21.99 10.19 21.98 10.28C22.59 10.66 23.15 11.11 23.64 11.63C23.88 11.12 24 10.56 24 10V3C24 1.35 22.65 0 21 0Z'
                  fill='#047CFF'
                />
                <path
                  d='M19 7C17.897 7 17 6.103 17 5C17 3.897 17.897 3 19 3C20.103 3 21 3.897 21 5C21 6.103 20.103 7 19 7Z'
                  fill='#047CFF'
                />
              </svg>
              <span className='removeTagHeding'>Remove Tags</span>
            </Para2>
          </ModalTitle>
          <WrapperBody>
           
            <div className='lead-input-container'>
              <Component preselectedItems={selectedTags}  handleChange={removeTags} notCreateable={true} options={defaultItems}/> 
         
            </div>
          </WrapperBody>
          <WrapperFooter>
            <ModalButtonSubmit onClick={()=>setConfirm(true) }>
              Submit
            </ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
     
  <ConfirmModal showModal={confirm} handleClose={()=>setConfirm(false)} handleConfirm={updateRemoveTags} title={"Remove Tags"} message="Are you sure you want to remove these tags?"/>
  </>)
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 450px;
  min-height: 200px;
  left: 535px;
  top: 40px;
  z-index: 10;
  display: flex;

`
const WrapperHeader = styled.div`

   `
const WrapperContent = styled.div`
  width: 450px;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px 20px;
  border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 450px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;
`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align: center;
`

const ModalButtonSubmit = styled.button`
  width: 100%;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #89de58;
  color: #fff;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
`

const Para2 = styled.p`
  color: 000000;
  font-size: 10px;
  font-weight: bold;
  position: relative;
  width: 100%;
  top: 28px;
  padding-left: 100px;
  text-align: center;
`
