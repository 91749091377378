import React,{useState} from 'react'
import AsyncSelect from 'react-select/async';
import {
  getActionData,
} from "services/dataValidation"

function AdvanceFilters({name,advanceSearch,setAdvanceSearch,fileID}) {
  


const getPhoneData = () => {
    getActionData({
      file_id: fileID,
      action: "get-dropdown",
      dropdown_type: name,
      search: "",
    }).then(function (response) {
   
      if (response.data) {
        setIsLoading(false)
        setOptions(response.data.option)
      }
    })
  }

   const [options,setOptions]=useState([])
   const [isLoading,setIsLoading]=useState(false)
//   useEffect(()=>{
// getPhoneData()
//   },[])

    const SelectChangeHandle = (selectedOption) => {
       

    const value = selectedOption.value
    var option_name = name
    let AdvanSearch = JSON.parse(localStorage.getItem("AdvanSearchParam"))
    AdvanSearch[option_name] = value
    setAdvanceSearch(AdvanSearch);
    localStorage.setItem("AdvanSearchParam", JSON.stringify(AdvanSearch))
    localStorage.setItem(option_name, value)
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      height: "35px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
    }),
  }

   const handleSearching=(inputValue, callback)=>{
      if(inputValue){  
            getActionData({
            file_id: fileID,
            action: "get-dropdown",
            dropdown_type: name,
            search: inputValue,
            }).then(function (response) {
                if (response.data) {
                
                callback(response?.data?.option)
                }
    });
}
  }

 
  let copyInputValue;

  const onInputChange = (
    inputValue,
    action
  ) => {   
    if (action.action === "input-blur") {
    const value = copyInputValue
    var option_name = name;
    let AdvanSearch = JSON.parse(localStorage.getItem("AdvanSearchParam"))
    AdvanSearch[option_name] = value
    setAdvanceSearch(AdvanSearch);
    localStorage.setItem("AdvanSearchParam", JSON.stringify(AdvanSearch))
    localStorage.setItem(option_name, value)
      
       copyInputValue=undefined
    }else if(action.action === "input-change"){
          copyInputValue=inputValue
    }
  };

  const data1=()=>{
    if(options.length===0){
        getPhoneData()
        setIsLoading(true)
    }
  }

  return (
    <div>  
        <AsyncSelect
      name={name}
      value={advanceSearch[name]?{value: advanceSearch[name], label: advanceSearch[name]}:undefined}
      defaultOptions={options}
      className='phone'
      onInputChange={onInputChange}
      classNamePrefix='select'
      styles={customStyles}
      onChange={SelectChangeHandle}
      onFocus={data1}
      isLoading={isLoading}
      cacheOptions={options}
      loadOptions={(inputValue, callback)=>handleSearching(inputValue, callback,name)}
    />
    </div>
  )
}

export default AdvanceFilters;