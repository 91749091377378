import React from "react"
import styled from "styled-components"
import Item from "./tableItem"

const Table = (props) => {
  const files = props.files ? props.files : []
  const items = []
  for (let i = 0; i < files.length; i++) {
    items.push(
      <Item
        title={files[i].title}
        description={files[i].description}
        created_at={files[i].created_at}
        key={i}
        id={files[i].id}
        addTask={props.addTask}
        addNote={props.addNote}
        counter={i}></Item>
    )
  }
  return (
    <Wrapper>
      <WrapperTitle></WrapperTitle>
      <div className='row activity-actions'>
        <div className=' col-md-6'>
          <p onClick={(e) => props.addTask()}>+Add TASK</p>
        </div>
        <div className=' col-md-6'>
          <p onClick={(e) => props.addNote()}>+Add NÖTE</p>
        </div>
      </div>

      <div className='scroll-in-mobile dowloads'>{items}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 10px;
`

export default Table
