import styled from "styled-components"

const Text = (props) => {
  return <Element>{props.children}</Element>
}

const Element = styled.div`
  padding: 0.5rem 0;
  text-align: initial;
  font-weight: bold;
}
`
export default Text
