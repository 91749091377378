import React, { useState, useMemo } from "react"
import CreatableSelect from "react-select/creatable"
import AsyncCreatableSelect from "react-select/async-creatable"
import Select from "react-select"

const CreatableInputOnly = ({
  preselectedItems,
  defaultOptions,
  handleChange,
  notCreateable,
  options,
  handleSearch,
  async,
  isLoading,
}) => {
  const [state, setState] = useState({
    inputValue: "",
    value: preselectedItems || [],
  })

  const onSelectChange = (value, actionMeta) => {
    if (handleChange) {
      handleChange(value)
    }
    setState({ value })
  }

  //  const handleKeyDown = (event) => {
  //     const { inputValue, value } =state
  //     if (!inputValue) return
  //     switch (event.key) {
  //       case "Tab":
  //         setState({
  //           inputValue: "",
  //           value: [...value, createOption(inputValue)],
  //         })
  //         event.preventDefault()
  //         break
  //       default:
  //         break
  //     }
  //   }

  useMemo(() => {
    setState({
      inputValue: "",
      value: preselectedItems || [],
    })
  }, [preselectedItems])

  const { value } = state
  localStorage.setItem("tags", JSON.stringify(value))

  return (
    <>
      {notCreateable ? (
        <Select
          options={options}
          value={value}
          isMulti
          onChange={onSelectChange}
        />
      ) : async ? (
        <AsyncCreatableSelect
          isMulti
          value={value}
          defaultOptions={defaultOptions}
          onChange={onSelectChange}
          isLoading={isLoading}
          loadOptions={handleSearch}
        />
      ) : (
        <CreatableSelect
          // inputValue={inputValue}
          // isClearable
          isMulti
          options={options}
          // menuIsOpen={false}
          onChange={onSelectChange}
          // onKeyDown={handleKeyDown}
          // placeholder='Test Video For Design...'
          value={value}
        />
      )}
    </>
  )
}

export default CreatableInputOnly
