import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import tw from "twin.macro";
import DownloadIcon from "assets/images/download-icon.png";
import COLORS from "utils/colors";
import ProgressBar from "components/progressbar";
import ReportModal from "components/navbar/tableItems/ReportModal";

const Item = (props) => {
  const [selectedExceldata] = useState([]);

  const [showReport, setShowReport] = useState(false);

  const tooltipID = "tooltip_ID_" + props.id;
  return (
    <div>
      <h1> {selectedExceldata}</h1>
      <div>
        {/* <input type="checkbox" onChange={checkAllCheckbox} />Select All */}
      </div>
      <Wrapper>
        <ReportModal
          onClose={() => setShowReport(false)}
          show={showReport}
          id={props.id}
        />

        <ItemWrapper>
          {/* <Checkbox type='checkbox' name="slectedCheckBox[]"  onChange={checkboxes} /> */}
          <Heading>
            <Checkbox
              type="checkbox"
              name="slectedCheckBox[]"
              className="selectAllCheckbox"
              onChange={props.checkboxes}
              value={props.id}
            />
            <ParaTwo data-tip data-for={tooltipID} className="itemHeading">
              {props.name}
            </ParaTwo>
            <ReactTooltip id={tooltipID} aria-haspopup="true" role="example">
              {props.name}
            </ReactTooltip>
            <Para>Created {props.created}</Para>
          </Heading>
          <InnerWrapper>
            <div className="cursor-pointer" onClick={() => setShowReport(true)}>
              <ProgressBar percentage={props.percentage} />
            </div>
          </InnerWrapper>
          <Mobile>
            Mobile
            <Num>{props.mobile}</Num>
          </Mobile>
          <Mobile>
            Landline
            <Num>{props.landline}</Num>
          </Mobile>
          <Mobile>
            Disconnected
            <Num>{props.disconnected}</Num>
          </Mobile>

          <DownloadIconWrapper>
            <a href="#" className={props.percentage == 100 ? "" : "hidden"}>
              <Icon
                src={DownloadIcon}
                width="34px"
                height="34px"
                onClick={() => props.downloadPopup(props.id)}
              />
            </a>
          </DownloadIconWrapper>
        </ItemWrapper>
      </Wrapper>
    </div>
  );
};

export default Item;

const Icon = styled.img`
  margin-left: 0px;
  padding-top: 5px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  z-index: 0;
  display: flex;
  height: 80px;
  background-color: ${COLORS.white};
  border-radius: 19px;
  margin: auto;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 10px;
  ${tw`
    shadow-lg
  `}
`;

const ItemWrapper = styled.div`
  position: relative;
  top: 10%;
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

const InnerWrapper = styled.span`
  display: inline-block;
  font-size: 10px;
`;

const DownloadIconWrapper = styled.span`
  margin-top: 10px;
`;

const Heading = styled.label`
  margin-top: 0px;
  color: ${COLORS.username};
  font-family: Montserrat;
  max-width: 303px;
  min-width: 300px;
  overflow: hidden;
  text-overflow: ellipse;
  white-space: nowrap;
  font-size: 13px;
`;

const Para = styled.p`
  color: ${COLORS.createdat};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`;

const Mobile = styled.label`
  margin-top: 10px;
  color: ${COLORS.blue};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`;

const Num = styled.p`
  color: #5b5b5b;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
`;
const Checkbox = styled.input`
  margin-top: 25px;
  display: inline-grid;
  width: 1em;
  height: 1em;
  border-radius: 0.25em;
  border: 0.1em solid currentColor;
  margin-right: -20px;
  float: left;
  margin-left: -16px;
  font-size: 15px;
`;

const ParaTwo = styled.p`
  color: ${COLORS.username};
  font-family: Montserrat;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  word-break: break-all;
`;
