import Modal from "react-bootstrap/Modal"
import styled from "styled-components"
import COLORS from "utils/colors"

const ConfirmModal = ({ showModal, handleClose, handleConfirm,message,title,tag }) => {

  return (
      <Modal show={showModal} onHide={handleClose}>
          <Modal.Header style={{ backgroundColor: COLORS.lightblue }} >
              <Modal.Title style={{ color: '#fff', fontSize: 15 }}>
                 {title}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
             {message}
             
          </Modal.Body>
          <Modal.Footer>
              <ModalButton onClick={handleConfirm}>Confirm</ModalButton>
              <ModalButton onClick={handleClose}>Cancel</ModalButton>
          </Modal.Footer>
      </Modal>
  )
}
const ModalButton = styled.button`
width: 200px;
height: 40px;
color: blue;
font-size: 15px;
font-weight: bold;
&:hover {
  background-color: ${COLORS.lightblue};
  color: ${COLORS.white};
}
border: 1px solid #3486eb;
border-radius: 5px;
margin:auto
`
export default ConfirmModal