import React from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import Prev from "assets/images/prev-arrow.svg"
import Next from "assets/images/next-arrow.svg"

const Modal = (props) => {
  if (!props.show) {
    return null
  }

  const {index,totalRecords,totalfiles}=props;
  const {  emails} = props

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='manipulator-close close-btn1'
        onClick={props.onClose}>
        ×
      </button>
      <WrapperContent>
        <WrapperHeader className='manipulator-phone-modal'>
            <div className='flex heading-title'> 
          <h1 className="popupModalHeader"><span className="float-left">{`(${index} - ${totalRecords} Total Records)`}</span>Emails <span className="float-right">{emails?.filtered_out_of_total?`(${emails?.filtered_out_of_total})`:''}</span></h1> 
          {/* <div className="w-8% mr-32 font-bold">({emails?.filtered_out_of_total})</div> */}
          </div>
          <h3 className='popupModalHeader'>
            Mailing Address:
            <span className='mailing_address'> {emails?.mailing_address}</span>
          </h3>
          <h3 className='popupModalHeader'>
            Owner 1: <span className='ownerName'>{emails?.owner_1}</span>
          </h3>
          <h3 className='popupModalHeader'>
            Owner 2: <span className='ownerName'>{emails?.owner_2}</span>
          </h3>
          <WrapperBody>
              {index-1?
          <img alt="" onClick={()=>props?.emailPopup(totalfiles[index-2],index-1,emails?.previous)} src={Prev} className="w-20 transform-y-center left-8 cursor-pointer"/>:null 
          }
          {totalfiles[index]?
          <img alt="" onClick={()=>props?.emailPopup(totalfiles[index],index+1,emails?.next)} src={Next} className="w-20 transform-y-center right-8 cursor-pointer"/>:null
          }
            <table className='popupTablelisting'>
              {/* <tr className='heading'>
                <th className='frist'>Email Position</th>
                <th>Email</th>
              </tr> */}
              {emails?.data.reverse().map((email, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td className='frist'>
                      Email {email?.position?.split("_")[1]}{" "}
                    </td>
                    <td>{email.email} </td>
                  </tr>
                </React.Fragment>
              ))}
            </table>
            {emails?.data.length===0&&<div className="text-center font-bold">No Record Found</div>}
          </WrapperBody>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 968px;
  min-height: 200px;
  left: 280px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 9999;
      top: 0;
    max-height: 48vh;
    border-radius: 0;
    width: calc(98% - 245px);
    left:260px;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
width: 100%;
  background-color: #ffffff;
  padding: 0px  0px 20px 0px;
  border: #d1d1d1 solid 1px;
  overflow-y:hidden;
  border-radius:20px;
`

const WrapperBody = styled.div`
  padding: 5px;
  overflow-y: auto;
  max-height: 38vh;
  border-radius: 10px;
  margin: 1% 2%;
`
