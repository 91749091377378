import React, { useEffect, useState } from "react"
import {
  getUserByIdCall,
  upddateProfileinfoCall,
  upddateProfileImageCall,
  ResetPassword,
} from "services/userProfile"
import TopNavProfile from "components/navbar/TopNavProfile"
import styled from "styled-components"
import { Link } from "react-router-dom"
import "assets/css/style.css"
import { UPLOAD_URL } from "config"
import { getUserID } from "utils/helper"
import "react-toastify/dist/ReactToastify.css"
import Loading from "components/loading"
import { toast } from "react-toastify"

const Profile = (props) => {
  const [, setLoading] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [firstName, setFirstName] = useState(false)
  const [prevFirstName, setPrevFirstName] = useState(false)
  const [prevLastName, setPrevLastName] = useState(false)
  const [lastName, setLastName] = useState(false)
  const [email, setEmail] = useState(false)
  const [prevEmail, setPrevEmail] = useState(false)
  const [prev_billing_address1, setPrevBilling_address1] = useState(false)
  const [prev_billing_address2, setPrevBilling_address2] = useState(false)
  const [prev_billing_city, setPrevBilling_city] = useState(false)
  const [prev_billing_pincode, setPrevBilling_pincode] = useState(false)
  const [billing_address1, setBilling_address1] = useState(false)
  const [billing_address2, setBilling_address2] = useState(false)
  const [billing_city, setBilling_city] = useState(false)
  const [billing_pincode, setBilling_pincode] = useState(false)
  const [profilethumb, setProfilethumb] = useState(false)
  const [updateName,setUpdateName]=useState(false)

  document.title = "Profile | AnalyzeREI"
  useEffect(() => {
    setLoading(true)
    getUserById()
    
  }, [])
  
  function getUserById() {
    setisLoading(true)
    const userId = getUserID()
    getUserByIdCall(userId)
      .then(function (response) {
         setisLoading(false)
        if (response.data.profile_picture === "") {
          setProfilethumb(UPLOAD_URL + "/profile/default_user.png")
        } else {
          setProfilethumb(response.data.profile_picture)
        }
        setFirstName(response.data.first_name)
        setPrevFirstName(response.data.first_name)
        setPrevLastName(response.data.last_name)
        setLastName(response.data.last_name)
        setEmail(response.data.email)
        setPrevEmail(response.data.email)
        setPrevBilling_address1(response.data.billing_address1)
        setPrevBilling_address2(response.data.billing_address2)
        setPrevBilling_city(response.data.billing_city)
        setPrevBilling_pincode(response.data.billing_pincode)
        setBilling_address1(response.data.billing_address1)
        setBilling_address2(response.data.billing_address2)
        setBilling_city(response.data.billing_city)
        setBilling_pincode(response.data.billing_pincode)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function upddateProfileinfo() {
    setisLoading(true)
    const userId = getUserID()
    var firstName1 = document.getElementById("firstName").value
    var lastName2 = document.getElementById("lastName").value

    upddateProfileinfoCall(
      {
        first_name: firstName1,
        last_name: lastName2,
      },
      userId
    )
      .then(function (response) {
        toast.success(response.message)
        setisLoading(false)
        setPrevFirstName(response.data.first_name)
        setPrevLastName(response.data.last_name)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function upddateEmail() {
    setisLoading(true)
    const userId = getUserID()
    // var email = document.getElementById("email").value
    upddateProfileinfoCall(
      {
        email,
      },
      userId
    )
      .then(function (response) {
        setisLoading(false)
        setPrevEmail(response.data.email)
        toast.success(response.message)

      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function upddatePassword() {
    setisLoading(true)
    const userId = getUserID()
    var password = document.getElementById("Password").value
    upddateProfileinfoCall(
      {
        password: password,
      },
      userId
    )
      .then(function (response) {
        setisLoading(false)
        toast.success(response.message)
        //setEmail(response.data[0].email);
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function upddateProfileImage() {
    //setisLoading(true)
    document.getElementById("profileImg").click()
  }

  function encodeImageFileAsURL() {
    setisLoading(true)
    var filesSelected = document.getElementById("profileImg").files
    if (filesSelected.length > 0) {
      var fileToLoad = filesSelected[0]
      const userId = getUserID()
      var fileReader = new FileReader()

      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result // <--- data: base64

        var newImage = document.createElement("img")
        newImage.src = srcData

        upddateProfileImageCall(
          {
            profile_picture: srcData,
          },
          userId
        )
          .then(function (response) {
            setProfilethumb(srcData)
            const ImgData = JSON.parse(localStorage.getItem("userData"))
            ImgData.profile_picture = srcData
            localStorage.setItem("userData", JSON.stringify(ImgData))

            setisLoading(false)
          })
          .catch((error) => {
            console.log("error", error)
          })
      }
      fileReader.readAsDataURL(fileToLoad)
    }
  }

  function upddateBillingInfo() {
    setisLoading(true)
    const userId = getUserID()
    upddateProfileinfoCall(
      {
        billing_address1: billing_address1,
        billing_address2: billing_address2,
        billing_city: billing_city,
        billing_pincode: billing_pincode,
      },
      userId
    )
      .then(function (response) {
        setisLoading(false)
        setPrevBilling_address1(response.data.billing_address1)
        setPrevBilling_address2(response.data.billing_address2)
        setPrevBilling_city(response.data.billing_city)
        setPrevBilling_pincode(response.data.billing_pincode)
        toast.success(response.message)

      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function handleTextFieldChange(mySetFunction, event) {
    const value = event.currentTarget.value
    mySetFunction(value.trim())
  }

  function ResetPasswordCall() {
    if (window.confirm("Are you sure want to reset your password.")) {
      const userId = getUserID()
      var password = document.getElementById("Password").value
      var email = document.getElementById("email").value
      ResetPassword(
        {
          email: email,
          password: password,
          password_confirmation: password,
        },
        userId
      )
        .then(function (response) {
          setisLoading(false)
          toast.success(response.message)

        })
        .catch((error) => {
          console.log("error", error)
        })
    } else {
      return false
    }
  }

  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNavProfile />
      <div className='payments-method-page width-inpayment-set'>
        <div className='wrapper-payments-method-page'>
          <div className='container-fluid'>
            <div className='MainDownloadTabs'>
              <ul>
                <li>
                  <Link to='/payments'>Payments</Link>
                </li>
                <li>
                  {" "}
                  <Link to='/credits'> Credits</Link>
                </li>
                  <li >
                    <Link to='/plans'> Plans</Link>
                  </li>
              </ul>
            </div>
            <div className='main-div-payments-card profile'>
              <div className='second-div-for-option'>
                <div className='profile_image'>
                  <div className='pro_thumb'>
                    <img
                      src='https://dev-l.analyzerei.com//uploads/profile/default_user.png'
                      alt=''
                    />
                    <input
                      type='file'
                      id='profileImg'
                      onChange={() => encodeImageFileAsURL()}
                    />
                    <input
                      type='hidden'
                      name='Profilethumb'
                      id='Profilethumb'
                      value={profilethumb}
                    />
                    <svg
                      width='37'
                      height='37'
                      viewBox='0 0 37 37'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      onClick={() => upddateProfileImage()}>
                      <circle cx='18.5' cy='18.5' r='18.5' fill='#047CFF' />
                      <path
                        d='M22.6557 13.6402L18.7619 9.19024C18.6562 9.07009 18.5038 9 18.3436 9C18.1834 9 18.031 9.07009 17.9253 9.19024L14.0316 13.6402C13.8881 13.8049 13.8525 14.0374 13.9437 14.2365C14.0338 14.4346 14.2318 14.5625 14.4499 14.5625H16.6749V21.7938C16.6749 22.1008 16.9241 22.35 17.2311 22.35H19.4561C19.7632 22.35 20.0124 22.1008 20.0124 21.7938V14.5625H22.2374C22.4554 14.5625 22.6534 14.4357 22.7436 14.2365C22.8337 14.0374 22.8003 13.8038 22.6557 13.6402Z'
                        fill='white'
                      />
                      <path
                        d='M24.4625 21.2373V24.5748H12.225V21.2373H10V25.6873C10 26.3025 10.4984 26.7998 11.1125 26.7998H25.575C26.1902 26.7998 26.6875 26.3025 26.6875 25.6873V21.2373H24.4625Z'
                        fill='white'
                      />
                    </svg>
                  </div>
                </div>

                <div className='my-card-payments my-radio-cards'>
                  <div className='card'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='my-radio-button-payment'>
                          <div className='card'>
                            <span className='profile_heading'>Name </span>
                            <input
                              type='text'
                              className='textInput'
                              name='FirstName'
                              id='firstName'
                              placeholder='John'
                              value={firstName}
                              onChange={(e) =>
                                handleTextFieldChange(setFirstName, e)
                              }
                            />
                            <input
                              type='text'
                              className='textInput'
                              name='LastName'
                              id='lastName'
                              placeholder='Doe'
                              value={lastName}
                              onChange={(e) =>
                                handleTextFieldChange(setLastName, e)
                              }
                            />

                            <button
                              onClick={() => upddateProfileinfo()}
                              disabled={prevFirstName==firstName&&prevLastName==lastName}
                              className={`w-50 ${(prevFirstName==firstName&&prevLastName==lastName)?'opacity-50':''} bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg`}>
                              Update
                            </button>
                          </div>
                        </div>
                        <div className='my-radio-button-payment'>
                          <div className='card'>
                            <span className='profile_heading'>Email </span>
                            <input
                              type='text'
                              className='textInput'
                              name='Email'
                              id='email'
                              placeholder='john@rankhop.com'
                              value={email}
                              onChange={(e) =>
                                handleTextFieldChange(setEmail, e)
                              }
                            />

                            <button
                              onClick={() => upddateEmail()}
                              disabled={prevEmail==email}
                              className={`w-50 bg-primary ${(prevEmail==email)?'opacity-50':''} hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg`}>
                              Update
                            </button>
                          </div>
                        </div>

                        <div className='my-radio-button-payment'>
                          <div className='card'>
                            <span className='profile_heading'>Password </span>
                            <input
                              type='password'
                              className='textInput'
                              name='Email'
                              id='Password'
                              placeholder='*******'
                            />
                            <p className='forget_password'>
                              {" "}
                              Can’t remember your password?{" "}
                              <span
                                className='reset'
                                onClick={() => ResetPasswordCall()}>
                                Reset Now
                              </span>
                            </p>

                            <button
                              onClick={() => upddatePassword()}
                              
                              className={'w-50 bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg'}>
                              Update
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <div className='my-radio-button-payment'>
                          <div className='card'>
                            <span className='profile_heading'>Billing</span>
                            <input
                              type='text'
                              className='textInput'
                              name='billing_address1'
                              id='billing_address1'
                              placeholder='124 Main street'
                              value={billing_address1}
                              onChange={(e) =>
                                handleTextFieldChange(setBilling_address1, e)
                              }
                            />
                            <input
                              type='text'
                              className='textInput'
                              name='billing_address2'
                              id='billing_address2'
                              placeholder='Austin'
                              value={billing_address2}
                              onChange={(e) =>
                                handleTextFieldChange(setBilling_address2, e)
                              }
                            />
                            <input
                              type='text'
                              className='textInput'
                              name='billing_city'
                              id='billing_city'
                              placeholder='Texas'
                              value={billing_city}
                              onChange={(e) =>
                                handleTextFieldChange(setBilling_city, e)
                              }
                            />
                            <input
                              type='text'
                              className='textInput'
                              name='billing_pincode'
                              id='billing_pincode'
                              placeholder='78704'
                              value={billing_pincode}
                              onChange={(e) =>
                                handleTextFieldChange(setBilling_pincode, e)
                              }
                            />

                            <button
                              onClick={() => upddateBillingInfo()}
                              disabled={prev_billing_address1==billing_address1&&prev_billing_address2==billing_address2&&prev_billing_city==billing_city&&prev_billing_pincode==billing_pincode}
                              className={`w-50 bg-primary ${(prev_billing_address1==billing_address1&&prev_billing_address2==billing_address2&&prev_billing_city==billing_city&&prev_billing_pincode==billing_pincode)?'opacity-50':''} hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg`}>
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

export default Profile
