import { memo } from 'react';
import styled from 'styled-components';

const Image = (props) => {
  return (
    <Element src={props.src}
      width={props.width ? props.width : '50px;'}
      height={props.height ? props.height : '50px;'}
    />
  )
};

const Element = styled.img`
  max-width: 100%;
  margin: auto;
`;

export default memo(Image);