import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import "assets/css/style.css"
import Cardone from "assets/images/card1.png"
import visa from "assets/images/visa.png"
import Loading from "components/loading"
import TopNavProfile from "components/navbar/TopNavProfile"
import { STRIPE_TEST_KEY,STRIPE_LIVE_KEY,STRIPE_MODE} from "config"
import {
  deleteStripeCardAPI,
  getUserCreditCardsAPI,
  makeStripeDefultCard,
  paybutton,
} from "services/paymentDeatil"
import { getUserID } from "utils/helper"
import CheckoutForm from "./CheckoutForm"

const stripeKey=STRIPE_MODE?STRIPE_LIVE_KEY:STRIPE_TEST_KEY
const stripePromise = loadStripe(stripeKey)


const CreditPaymet = (props) => {
  let history = useHistory()
  const [, setLoading] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [getMyCardData, setMyCardData] = useState([])

  let priceObject = localStorage.getItem("purchasePrice")
  var obj = JSON.parse(priceObject)
  let getPurchasePrice = obj

  let rangeceObject = localStorage.getItem("rangevalue")
  var obj2 = JSON.parse(rangeceObject)

  let getRangevalue = obj2
  useEffect(() => {
    setLoading(true)
    getUserCreditCardsDetails()
  }, [props.message])

  function getUserCreditCardsDetails() {
    setisLoading(true)
    const userId = getUserID()
    getUserCreditCardsAPI({
      user_id: userId,
    })
      .then(function (response) {
        setisLoading(false)
        setMyCardData(response.data)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function makeDefaultCardAPI(id) {
    setisLoading(true)
    const userId = getUserID()
    makeStripeDefultCard({
      stripe_card_id: id,
      user_id: userId,
    })
      .then(function (response) {
        if (response.status === true) {
          setisLoading(false)
          toast.success(response["message"])
          getUserCreditCardsDetails()
        } else {
          toast.error(response["message"])
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function deleteStripeCardData(id) {
    setisLoading(true)
    const userId = getUserID()
    deleteStripeCardAPI({
      stripe_card_id: id,
      user_id: userId,
    })
      .then(function (response) {
        if (response.status === true) {
          setisLoading(false)
          toast.success(response["message"])
          getUserCreditCardsDetails()
        } else {
          toast.error(response["message"])
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }
  const amount = Math.round(getPurchasePrice * 100)

  //default card payment
  function payButtonApi() {
    var creditCardId
    for (var i = 0; i < getMyCardData.length; i++) {
      if (getMyCardData[i]["default_card"] === true) {
        creditCardId = getMyCardData[i].id
      }
    }
    setisLoading(true)
    const userId = getUserID()
   

    paybutton({
      card_id: creditCardId,
      user_id: userId,
      credit: getRangevalue.replace(/,/g, ""),
      amount: amount,
      save_card: "no",
    })
      .then(function (response) {
        if (response.status === true) {
          setisLoading(false)
          toast.success(response["message"])
          history.push("/data-validation")
        } else {
          setisLoading(false)
          toast.error(response["message"])
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNavProfile />
      <div className='payments-method-page width-inpayment-set'>
        <div className='wrapper-payments-method-page'>
          <div className='container-fluid'>
            <div className='MainDownloadTabs'>
              <ul>
                <li className='active'>
                  <Link to='/payments'>Payments</Link>
                </li>{" "}
                <li>
                  {" "}
                  <Link to='/credits'> Credits</Link>
                </li>
              </ul>
            </div>
            <div className='main-div-payments-card'>
              <div className='second-div-for-option'>
                <div className='headding-payment'>
                  <h1>Add Credit</h1>
                </div>
                <div className='my-card-payments my-radio-cards'>
                  <div className='card'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='card-headding-payment'>
                          <h3>My Cards</h3>
                          <p>Select card for all Purchases</p>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='right-side-div-credit-payment'>
                          <div className='icon-in-add-pay'>
                            <i className='far fa-star'></i>
                            <span className='purchese-text-ap'>
                              Purchase {getRangevalue} Credits for
                            </span>
                            <span className='amount-text-ap'>
                              {" "}
                              {"$" + getPurchasePrice}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      {getMyCardData?.length > 0 &&
                        getMyCardData.map((paymentData) => (
                          <div className='col-md-6'>
                            <div className='my-radio-button-payment'>
                              <div className='card'>
                                {paymentData["default_card"] === true ? (
                                  <input
                                    type='radio'
                                    id='card1'
                                    name='fav_language'
                                    value='card1'
                                    checked
                                  />
                                ) : (
                                  <input
                                    type='radio'
                                    id='card1'
                                    name='fav_language'
                                    value='card1'
                                    disabled
                                  />
                                )}

                                <label htmlFor='card1'>
                                  {paymentData["type"] === "Visa" ? (
                                    <span>
                                      {" "}
                                      <img
                                        src={visa}
                                        className='img-hw-payment'
                                        alt='img'
                                      />
                                    </span>
                                  ) : (
                                    <span>
                                      {" "}
                                      <img
                                        src={Cardone}
                                        className='img-hw-payment'
                                        alt='img'
                                      />
                                    </span>
                                  )}
                                  <span className='card-name-text-paym'>
                                    <p id='getCardlast4'>
                                      {paymentData.card_number}
                                    </p>
                                  </span>
                                  <span className='d-flex links-for-default-card'>
                                    <button
                                      type='submit'
                                      className='defaule-card-payment'
                                      onClick={() =>
                                        makeDefaultCardAPI(paymentData.id)
                                      }>
                                      Make default
                                    </button>
                                    <button
                                      type='submit'
                                      className='remove-card-payment'
                                      onClick={() =>
                                        deleteStripeCardData(paymentData.id)
                                      }>
                                      Remove
                                    </button>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                      <div className='button-in-credit-two'>
                        <button
                          className='btn-pay'
                          onClick={() => payButtonApi()}>
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='my-card-payments'>
                  <div className='card'>
                    <div className='card-headding-payment'>
                      <h3>Add Card</h3>
                    </div>
                    <div className='row'>
                      <div>
                        <Elements stripe={stripePromise}>
                          <CheckoutForm />
                        </Elements>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

export default CreditPaymet
