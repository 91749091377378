import React, { useEffect, useState, useRef } from "react";
import {
  downloadPhonelisting,
  DeleteDownloadsFiles,
} from "services/dataValidation";
import { getUserID } from "utils/helper";
import TopNav from "components/navbar/topNav";
import Table from "components/navbar/tableItems/download/table";
import styled from "styled-components";
import Search from "components/downoad-search";
import Pagination from "components/pagination";
import Loading from "components/loading";
import "assets/css/style.css";
import Modal from "components/lead-modal";
import COLORS from "utils/colors";
import { Link } from "react-router-dom";
import Upgradecomponent from "components/upgrade-plan";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import { SOCKET_URL } from "config";

//import Pushing from "../Pushing";
const Downloads = () => {
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([]);
  const [noOfPages, setNoofPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [getTotalExcelData, setTotalExcelData] = useState(false);
  const [show, setShow] = useState(false);
  const [filedata] = useState(false);
  const [emptyDataMsg, setemptyDataMsg] = useState("");
  const [UserId, setUserId] = useState(getUserID());
  const dataFetchedRef = useRef(false);
  document.title = "Downloads | AnalyzeREI";

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.tmpDowData !== undefined) {
        setFilteredUserExcelData(window.tmpDowData);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const socket = io.connect(SOCKET_URL);
    let UserId = getUserID();
    socket.on("connect", function () {
      socket.emit("join_user_room", UserId);
    });
    socket.on("down_progress", (data) => {
      let tmpData = [];
      tmpData = [...filteredUserExcelData];
      if (tmpData.length > 0) {
        for (let i = 0; i < tmpData.length; i++) {
          if (data.data.file_id === tmpData[i]["_id"]) {
            tmpData[i]["percentage"] = data.data.percentage;
            tmpData[i]["total_rows"] = data.data.total_rows;
          }
        }
        window.tmpDowData = tmpData;
      }
    });
  }, [filteredUserExcelData]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    downloadLeadsData(noOfPages, recordsPerPage);
  }, [noOfPages, recordsPerPage]);

  function downloadLeadsData(noOfPages, recordsPerPage) {
    setisLoading(true);
    const user = getUserID();
    downloadPhonelisting(
      {
        user_id: user,
        per_page: recordsPerPage,
      },
      noOfPages
    )
      .then(function (response) {
        setisLoading(false);
        setTotalRecords(response.total_records);

        setFilteredUserExcelData(response.data);
        setTotalExcelData(response.data.length);
        setemptyDataMsg("No downloads available");
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function downloadLeadsData2(noOfPages, recordsPerPage) {
    let search = document.querySelector("input.download.search").value;
    const user = getUserID();
    downloadPhonelisting(
      {
        user_id: user,
        per_page: recordsPerPage,
        search: search,
      },
      noOfPages
    )
      .then(function (response) {
        // setisLoading(false)
        setTotalRecords(response.total_records);

        setFilteredUserExcelData(response.data);
        setTotalExcelData(response.data.length);
        setemptyDataMsg("No downloads available");
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function searchdata(noOfPages, recordsPerPage) {
    setisLoading(true);
    let search = document.querySelector("input.download.search").value;
    const user = getUserID();
    downloadPhonelisting(
      {
        user_id: user,
        per_page: recordsPerPage,
        search: search,
      },
      noOfPages
    )
      .then(function (response) {
        setisLoading(false);
        setTotalRecords(response.total_records);

        setFilteredUserExcelData(response.data);
        setTotalExcelData(response.data.length);
        setemptyDataMsg("No Data Found");
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function onChangeRecordPerPage(perPageValue, pageCount) {
    setRecordsPerPage(perPageValue);
    setNoofPages(pageCount);
  }

  const bulkDelete = () => {
    var checkboxes = document.getElementsByName("slectedCheckBox[]");
    for (var i in checkboxes) {
      if (checkboxes[i].checked === true) {
        var inputElems = document.getElementsByName("slectedCheckBox[]"),
          row = [];
        for (var j = 0; j < inputElems.length; j++) {
          if (
            inputElems[j].type === "checkbox" &&
            inputElems[j].checked === true
          ) {
            row.push(inputElems[j].value);
          }
        }
      }
    }
    if (row === undefined) {
      alert("Please Select altest one Item");
      return false;
    }
    var rowIds = row.join(",");
    setisLoading(true);

    DeleteDownloadsFiles({
      file_ids: rowIds,
      action: "phone-validation",
    })
      .then(function (response) {
        setisLoading(false);
        document.querySelector(
          "input.deleteCheckbox.downloads"
        ).checked = false;

        if (response.status === true) {
          downloadLeadsData2(noOfPages, recordsPerPage);
        } else {
          toast.error(response.message);
        }
        document.querySelector(".DeleteAction").style.display = "none";
        var inputElems = document.getElementsByName("slectedCheckBox[]");
        for (var j = 0; j < inputElems.length; j++) {
          inputElems[j].checked = false;
        }
        document.querySelector(".DeleteAction").prop("checked", false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const user = JSON.parse(localStorage.getItem("userData"));
  const capability = user.capability;
  const downloads = capability.includes("Downloads");

  if (downloads === false) {
    return <Upgradecomponent />;
  }

  return (
    <Wrapper>
      <Modal
        onClose={() => {
          setShow(false);
        }}
        show={show}
        fields={filedata}
      />
      <Loading show={isLoading} />
      <TopNav />
      <div className="MainDownloadTabs">
        <ul>
          <li className="active">
            <Link to="/downloads">Phone Validation</Link>
          </li>{" "}
          <li>
            {" "}
            <Link to="/manipulator-downloads"> Data Manipulator</Link>
          </li>{" "}
          <li>
            <Link to="/skip-Tracing-downloads">Skip Tracing</Link>
          </li>
        </ul>{" "}
        nb
      </div>

      <SearchWrapper className="download search">
        <Search search={searchdata} />
      </SearchWrapper>
      {getTotalExcelData > 0 && (
        <div className="select-all-data-downloads">
          <Delete onClick={bulkDelete} className="DeleteAction">
            Delete
          </Delete>
        </div>
      )}
      {getTotalExcelData < 1 && <div className="nodata">{emptyDataMsg}</div>}
      <Table files={filteredUserExcelData}></Table>
      <WrapperPagination>
        <Pagination
          getPageData={downloadLeadsData}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          onChangeRecordPerpage={onChangeRecordPerPage}
        ></Pagination>
      </WrapperPagination>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
const SearchWrapper = styled.div`
  margin-top: 2%;
`;
const WrapperPagination = styled.div`
  width: 98%;
`;
const Delete = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.red};
  padding-left: 10px;
  text-decoration: none;
  display: none;
  cursor: pointer;
`;

export default Downloads;
