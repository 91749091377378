import { getAuthorizationToken } from "utils/helper"
import axios from "axios"

class AxiosService {
  service
  constructor() {
    const token = getAuthorizationToken()
    const service = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    })
    service.interceptors.request.use(function (config) {
      if (!!token) {
        config.headers.Authorization = `${token}`
      }
      return config
    })
    service.interceptors.response.use(this.handleSuccess)
    this.service = service
  }
  handleSuccess(response) {
    return response
  }


  async post(endpoint, payload,myUploadProgress) {
     const config = {
    onUploadProgress: myUploadProgress
  };
    const result = await this.service.post(endpoint, payload,config)
    return result.data
  }
  async get(endpoint) {
    const result = await this.service.get(endpoint)
    return result.data
  }
}

export default new AxiosService()
