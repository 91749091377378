import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import SaveTemplate from "../navbar/tableItems/SaveTemplate";
import ManageTemplates from "../navbar/tableItems/ManageTemplates";
import {
  phonedownloadLeads,
  filePreview,
  changeTemplatePost,
  updateTemplateNamePost,
} from "services/dataValidation";
import COLORS from "utils/colors";
import ReactTooltip from "react-tooltip";
import { filter, prop } from "ramda";

const Modal = (props) => {
  const [LineDefault, setLineDefault] = useState([]);

  const [DownloadDefault, setDownloadDefault] = useState(false);
  const [CallerDefault, setCallerDefault] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(false);

  const [lineType, setlineType] = useState("all");
  const [connected, setConnected] = useState("all");
  const [caller_id_match, setCallerIDMatched] = useState("all");
  const [format, setFormat] = useState("default");
  const [preview, setPreview] = useState([]);
  const [previewHeader, setPreviewHeader] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [tmpLoading, settmpLoading] = useState(false);
  const [isUpdateTemplate, setisUpdateTemplate] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState("");
  const [currentTemplateName, setCurrentTemplateName] = useState("");
  const [downloadStep, setDownloadStep] = useState(1);
  const [title, setTitle] = useState("");

  if (props.templates.length > 0) {
    if (templateList.length == 0) {
      setTemplateList(props.templates);
    }
  }
  let [file_header, setfileHeader] = useState(props.fileHeader);
  let [allFileHeader, setAllFileHeader] = useState(props.fileHeader);
  let [fhAll, setfileHeaderAll] = useState(false);

  let [fileHeaderState, setFileHeaderState] = useState([]);
  function showPrview(
    lineType,
    connected,
    caller_id_match,
    format,
    fileHeaderState
  ) {
    setPreviewHeader([]);
    setPreLoading(true);

    var flineType = lineType;
    var fconnected = connected;
    var fcaller_id_match = caller_id_match;
    var fformat = format;
    var fileID = localStorage.getItem("file_id");

    filePreview({
      file_header: fileHeaderState,
      linetype: flineType,
      connected: fconnected,
      caller_id_match: fcaller_id_match,
      format: fformat,
      file_id: fileID,
    })
      .then(function (response) {
        setPreLoading(false);
        if (response.status === true) {
          let data = response.records.slice(0, 5).map((item) => {
            return item;
          });
          setPreview(data);
          setPreviewHeader(response.header);
        }
      })
      .catch((error) => {});
  }

  if (!props.show) {
    return null;
  }

  if (file_header.length == 0) {
    file_header = props.fileHeader;
  }
  if (allFileHeader.length == 0) {
    allFileHeader = props.fileHeader;
  }
  const cancelDowload = (event) => {
    setlineType("all");
    setConnected("all");
    setCallerIDMatched("all");
    setFormat("default");
    file_header = [];
    setPreviewHeader([]);
    props.onClose();
    setTemplateList([]);
    setFileHeaderState([]);
    setTitle("");
  };
  const updateTemplateName = (event) => {
    var flineType = lineType;
    var fconnected = connected;
    var fcaller_id_match = caller_id_match;
    var fformat = format;
    var preText = event.target.innerText;

    event.target.innerText = "updating...";

    updateTemplateNamePost({
      file_header: fileHeaderState,
      linetype: flineType,
      connected: fconnected,
      caller_id_match: fcaller_id_match,
      format: fformat,
      temp_id: currentTemplate,
    })
      .then(function (response) {
        event.target.innerText = preText;
      })
      .catch((error) => {});
  };
  const changeTemplate = (event) => {
    settmpLoading(true);
    if (event.target.value == "") {
      setfileHeaderAll(false);
      setFileHeaderState([]);
      showPrview("all", "all", "all", "default", []);
      setlineType("all");
      setConnected("all");
      setCallerIDMatched("all");
      setFormat("default");
      settmpLoading(false);
      setisUpdateTemplate(false);
      return;
    }
    setCurrentTemplate(event.target.value);

    localStorage.setItem("templateID", event.target.value);

    changeTemplatePost({
      tem_id: event.target.value,
    })
      .then(function (response) {
        let temp = response.data.template;
        setisUpdateTemplate(true);
        setlineType(temp.linetype);
        setConnected(temp.connected);
        setCallerIDMatched(temp.caller_id_match);
        setFormat(temp.format);
        if (temp.file_header.length > 0) {
          setFileHeaderState([]);
          for (let i = 0; i < temp.file_header.length; i++) {
            setFileHeaderState((current) => [...current, temp.file_header[i]]);
          }
        }
        settmpLoading(false);
        setCurrentTemplateName(temp.template_name);
        localStorage.setItem("templateName", temp.template_name);

        showPrview(
          temp.linetype,
          temp.connected,
          temp.caller_id_match,
          temp.format,
          temp.file_header
        );
      })
      .catch((error) => {});
  };
  const updatelineType = (event) => {
    let value = event.target.value;
    setlineType(value);
    showPrview(value, connected, caller_id_match, format, fileHeaderState);
  };
  const updateConnected = (event) => {
    let value = event.target.value;
    setConnected(value);
    showPrview(lineType, value, caller_id_match, format, fileHeaderState);
  };
  const updateCallerIDMatched = (event) => {
    let value = event.target.value;
    setCallerIDMatched(value);
    showPrview(lineType, connected, value, format, fileHeaderState);
  };
  const updateFormat = (event) => {
    let value = event.target.value;
    setFormat(value);
    showPrview(lineType, connected, caller_id_match, value, fileHeaderState);
  };
  const goNextStep = (event) => {
    if (title.trim() == "") {
      toast.error("Title is required");
      return false;
    }
    setDownloadStep(2);
  };
  const goPreviousStep = (event) => {
    setDownloadStep(1);
  };

  const filterHeader = (event) => {
    let value = event.target.value.toLowerCase().trim();
    let newHeader = [];
    for (let i = 0; i < allFileHeader.length; i++) {
      let propValue = allFileHeader[i].toLowerCase().trim();
      if (propValue.includes(value) == true) {
        newHeader.push(allFileHeader[i]);
      }
    }
    setfileHeader(newHeader);
  };
  const onCheckHeader = (event) => {
    let value = event.target.value;
    let checked = event.target.checked;
    let tmpHeader = fileHeaderState;

    if (checked == true) {
      setFileHeaderState([...fileHeaderState, value]);
      tmpHeader.push(value);
    } else {
      let index = tmpHeader.indexOf(value);

      tmpHeader.splice(index, 1);

      setfileHeaderAll(false);
      setFileHeaderState(fileHeaderState.filter((e) => e !== value));
    }
    showPrview(lineType, connected, caller_id_match, format, tmpHeader);
  };
  const onCheckHeaderAll = (event) => {
    let checked = event.target.checked;

    if (checked == true) {
      setfileHeaderAll(true);

      for (let i = 0; i < allFileHeader.length; i++) {
        setFileHeaderState((current) => [...current, allFileHeader[i]]);
      }
      console.log(allFileHeader);
      showPrview(lineType, connected, caller_id_match, format, allFileHeader);
    } else {
      setfileHeaderAll(false);
      setFileHeaderState([]);
      showPrview(lineType, connected, caller_id_match, format, []);
    }
  };

  function DownloadsFiles() {
    var fileID = localStorage.getItem("file_id");

    var download_title = title;
    setLoading(true);
    var flineType = lineType;
    var fconnected = connected;
    var fcaller_id_match = caller_id_match;
    var fformat = format;
    phonedownloadLeads({
      file_id: fileID,
      file_header: fileHeaderState,
      linetype: flineType,
      connected: fconnected,
      caller_id_match: fcaller_id_match,
      download_title: download_title,
      format: fformat,
    })
      .then(function (response) {
        if (response.status === true) {
          setLoading(false);
          toast.success(response.message);
          setDownloadDefault();
          props.onClose();
          setLineDefault();
          download_title = "";
        } else {
          setLoading(false);
          if (typeof response?.errors == "string") {
            toast.error(response.errors);
            setLoading(false);
          } else {
            setLoading(false);
            setErrors(response.errors);
          }
        }
        const listButton = document.querySelectorAll("button.lead-close");
        for (let i = 0; i < listButton.length - 1; i++) {
          listButton[i].click();
        }
      })
      .catch((error) => {
        toast.error("We are getting errors");
      });
  }

  const LineValuehandleChange = (selectedOption) => {
    const newArray = [];
    const newArray2 = [];
    for (var i = 0, l = selectedOption.length; i < l; i++) {
      newArray.push(selectedOption[i].value);
      newArray2.push({
        value: selectedOption[i].value,
        label:
          selectedOption[i].value === "mobile_and_landline"
            ? "Landline & Mobile"
            : selectedOption[i].value,
      });
    }
    let copyError = { ...errors };
    delete copyError?.line_type;
    setErrors(copyError);
    setLineDefault(newArray2);
    localStorage.setItem("LineValue", newArray);
  };

  const DownloadhandleChange = (selectedOption) => {
    const newArray = [];
    const newArray2 = [];
    for (var i = 0, l = selectedOption.length; i < l; i++) {
      newArray.push(selectedOption[i].value);
      newArray2.push({
        value: selectedOption[i].value,
        label: selectedOption[i].value,
      });
    }
    localStorage.setItem("DownloadValue", newArray);
    let copyError = { ...errors };
    delete copyError?.format;
    setErrors(copyError);
    setDownloadDefault(newArray2);
  };

  const CallerhandleChange = (selectedOption) => {
    var newArray = [];
    var newArray2 = [];
    for (var i = 0, l = selectedOption.length; i < l; i++) {
      newArray.push(selectedOption[i].value);
      newArray2.push({
        value: selectedOption[i].value,
        label: selectedOption[i].value,
      });
    }
    setCallerDefault(newArray2);
    localStorage.setItem("CallerValue", newArray);
  };

  const options = [
    { value: "default", label: "Default" },
    { value: "original", label: "Original" },
    // { value: "Call Tools", label: "Call Tools" },
    // { value: "Call Tools 2.0", label: "Call Tools 2.0" },
    // { value: "Xencall", label: "Xencall" },
    // { value: "Go High Level (REIReply)", label: "Go High Level (REIReply)" },
    // { value: "Mojo Dialer", label: "Mojo Dialer" },
    // { value: "REISift", label: "REISift" },
    // { value: "Batch Dialer", label: "Batch Dialer" },
  ];

  const Coptions = [
    { value: "All", label: "All" },
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const AutosuggetsDownloadSelect = () => (
    <Select
      defaultValue={DownloadDefault}
      options={options}
      className="basic-multi-select-lead"
      onChange={DownloadhandleChange}
      isMulti
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );

  const LineOptions = [
    { value: "landline", label: "Valid Land Line" },
    { value: "mobile_and_landline", label: " Valid Landline & Valid Mobile" },
    { value: "mobile", label: "Valid mobile" },
    { value: "disconnected", label: "Disconnected" },
  ];

  const AutosuggetsLineSelect = () => (
    <Select
      value={LineDefault}
      options={LineOptions}
      className="basic-multi-select-lead"
      onChange={LineValuehandleChange}
      isMulti
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );

  const AutosuggetsCallerSelect = () => (
    <Select
      defaultValue={CallerDefault}
      options={Coptions}
      className="basic-multi-select-lead"
      onChange={CallerhandleChange}
      isMulti
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );

  // function checkAll() {
  //   if (document.querySelector("input[id=Checkboxall]:checked")) {
  //     setLineDefault(LineOptions)
  //     setDownloadDefault(options)
  //     setCallerDefault(Coptions)
  //     localStorage.setItem("LineValue", "landline,mobile,disconnected")
  //     localStorage.setItem(
  //       "DownloadValue",
  //       "Call Tools,Call Tools 2.0,Xencall,Go High Level (REIReply),Mojo Dialer,REISift,Batch Dialer"
  //     )
  //     localStorage.setItem("CallerValue", "All,Yes,No")
  //   } else {
  //     setLineDefault([])
  //     setDownloadDefault([])
  //     setCallerDefault([])
  //     localStorage.removeItem("LineValue")
  //     localStorage.removeItem("DownloadValue")
  //     localStorage.removeItem("CallerValue")
  //   }
  // }
  const makeid = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=""
        type="button"
        data-dismiss="modal"
        className="lead-close"
        onClick={cancelDowload}
        style={lead_close}
      >
        ×
      </button>
      {downloadStep == 1 ? (
        <WrapperContent>
          <WrapperHeader>
            <WrapperBody>
              <div
                className="row px-2 py-2 dow-modal"
                style={preLoading ? { opacity: 0.6 } : {}}
              >
                <div className="col-md-4 lef-col">
                  <div className="filter-header">
                    <select
                      className="form-select form-select-sm"
                      aria-label=".form-select-sm example"
                      style={{ marginBottom: 4 }}
                      onChange={changeTemplate}
                    >
                      <option selected value={""}>
                        -- Select Template --{" "}
                      </option>

                      {templateList.map((item) => (
                        <option value={item._id}>{item.template_name}</option>
                      ))}
                    </select>
                    {isUpdateTemplate ? (
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        style={{ float: "left" }}
                        onClick={updateTemplateName}
                      >
                        Update
                      </button>
                    ) : (
                      <SaveTemplate
                        template={{
                          lineType: lineType,
                          connected: connected,
                          caller_id_match: caller_id_match,
                          format: format,
                          fileHeaderState: fileHeaderState,
                        }}
                        setTemplateList={setTemplateList}
                      ></SaveTemplate>
                    )}
                    {isUpdateTemplate && (
                      <ManageTemplates
                        key="save-template"
                        name={currentTemplateName}
                        id={currentTemplate}
                        setTemplateList={setTemplateList}
                      ></ManageTemplates>
                    )}
                    <br />
                  </div>

                  <div
                    className="card-body border rounded"
                    style={{ "padding-top": "0px", height: "326px" }}
                  >
                    <div
                      className="allheader"
                      style={{
                        "margin-top": "0px",
                        "padding-top": "4px",
                        position: "fixed",
                        "background-color": "#fff",
                        width: "274px",
                      }}
                    >
                      <div className="row">
                        <div
                          className="col-md-3"
                          style={{
                            "font-size": "12px",
                            padding: "6px 0px 0px 20px",
                          }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={onCheckHeaderAll}
                            value="all"
                            name="allheader"
                            checked={fhAll}
                          ></input>{" "}
                          All ({file_header.length}){" "}
                        </div>
                        <div className="col-md-9">
                          <input
                            type="text"
                            onKeyUp={filterHeader}
                            placeholder="Type any column name"
                            style={formControl2}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="items-list" style={{ "margin-top": "42px" }}>
                      {file_header.map((item) => (
                        <div className="list-it itemHeading">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={onCheckHeader}
                            value={item || ""}
                            name="file_header[]"
                            checked={
                              fileHeaderState.includes(item) ? true : false
                            }
                          ></input>{" "}
                          <ParaTwo
                            data-tip
                            data-for={item}
                            className="itemHeading"
                          >
                            {item}
                          </ParaTwo>
                          <ReactTooltip
                            id={item}
                            aria-haspopup="true"
                            role="example"
                          >
                            {item}
                          </ReactTooltip>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-8 right-col">
                  <div className="card-body rounded" id="first">
                    <div className="row px-2 py-1">
                      <div
                        className="col-md-1 "
                        style={{ "padding-top": "8px" }}
                      >
                        <h6>Title:</h6>
                      </div>

                      <div className="col-md-11">
                        <input
                          type="text"
                          name="download_title"
                          onChange={(event) => setTitle(event.target.value)}
                          id="download_title"
                          placeholder="Insert title here"
                          style={formControl}
                          value={title}
                        />
                      </div>
                    </div>
                    <hr style={hrStyle} />
                    <h6>Line Type:</h6>
                    <div className="row px-2 py-1">
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="linetype"
                          value="all"
                          checked={lineType === "all"}
                          onChange={updatelineType}
                        />{" "}
                        All
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="linetype"
                          value="landline_mobile"
                          onChange={updatelineType}
                          checked={lineType === "landline_mobile"}
                        />{" "}
                        Landline + Mobile
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="linetype"
                          value="landline_voip"
                          onChange={updatelineType}
                          checked={lineType === "landline_voip"}
                        />{" "}
                        Landline + VOIP
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="linetype"
                          value="mobile_voip"
                          onChange={updatelineType}
                          checked={lineType === "mobile_voip"}
                        />{" "}
                        Mobile + VOIP
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="linetype"
                          value="landline"
                          checked={lineType === "landline"}
                          onChange={updatelineType}
                        />{" "}
                        Landline
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="linetype"
                          value="mobile"
                          onChange={updatelineType}
                          checked={lineType === "mobile"}
                        />{" "}
                        Mobile
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="linetype"
                          value="voip"
                          onChange={updatelineType}
                          checked={lineType === "voip"}
                        />{" "}
                        VOIP
                      </div>
                    </div>
                    <hr style={hrStyle} />
                    <h6>Connected:</h6>
                    <div className="row px-2 py-1">
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="connected"
                          value="all"
                          checked={connected === "all"}
                          onChange={updateConnected}
                        />{" "}
                        All
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="connected"
                          value="yes"
                          onChange={updateConnected}
                          checked={connected === "yes"}
                        />{" "}
                        Yes
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="connected"
                          value="no"
                          onChange={updateConnected}
                          checked={connected === "no"}
                        />{" "}
                        No
                      </div>
                    </div>
                    <hr style={hrStyle} />
                    <h6>Caller ID Match:</h6>
                    <div className="row px-2 py-1">
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="caller_id_match"
                          value="all"
                          checked={caller_id_match === "all"}
                          onChange={updateCallerIDMatched}
                        />{" "}
                        All
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="caller_id_match"
                          value="yes"
                          onChange={updateCallerIDMatched}
                          checked={caller_id_match === "yes"}
                        />{" "}
                        Yes
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="caller_id_match"
                          value="no"
                          onChange={updateCallerIDMatched}
                          checked={caller_id_match === "no"}
                        />{" "}
                        No
                      </div>
                    </div>
                    <hr style={hrStyle} />
                    <h6>Format:</h6>
                    <div className="row px-2 py-1">
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="format"
                          value="default"
                          checked={format === "default"}
                          onChange={updateFormat}
                        />{" "}
                        Default
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          name="format"
                          value="orignal"
                          onChange={updateFormat}
                          checked={format === "orignal"}
                        />{" "}
                        Orignal
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </WrapperBody>

            <WrapperBody>
              <div className="row px-2 py-2">
                <div
                  className="col-md-12"
                  style={{
                    overflow: "scroll",
                  }}
                >
                  {preLoading ? (
                    <div>
                      <h5
                        style={{
                          textAlign: "center",
                        }}
                      >
                        loading preview...
                      </h5>
                    </div>
                  ) : (
                    <div> </div>
                  )}
                  <table className="">
                    <tr>
                      {previewHeader.map((item) => (
                        <th
                          style={{
                            border: "1px solid #ccc",
                            "white-space": "nowrap",
                          }}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                    {preview.map((item) => (
                      <tr>
                        {previewHeader.map((initem) => (
                          <td
                            style={{
                              border: "1px solid #ccc",
                              "white-space": "nowrap",
                            }}
                          >
                            {item[initem]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </WrapperBody>
            <WrapperFooter>
              <div className="row dow-modal">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  <ModalButton onClick={cancelDowload}>Cancel</ModalButton>
                  <ModalButtonSubmit onClick={goNextStep}>
                    Next &#9655;
                  </ModalButtonSubmit>
                </div>
              </div>
            </WrapperFooter>
          </WrapperHeader>
        </WrapperContent>
      ) : (
        <WrapperContent>
          <WrapperHeader>
            <WrapperBody>
              <h5 style={{ "padding-left": "7px;" }}>Review All Changes</h5>
              <hr style={hrStyle2} />
              <div
                className="row px-2 py-2 dow-modal"
                style={tmpLoading ? tmpStyle : null}
              >
                <div className="col-md-4 lef-col">
                  <p style={{ "margin-bottom": "0px" }}>Selected Columns</p>
                  <div
                    className="card-body border rounded"
                    style={{ height: 360 }}
                  >
                    {fileHeaderState.length > 0 ? (
                      fileHeaderState.map((item) => (
                        <div className="list-it itemHeading">
                          <ParaTwo
                            data-tip
                            data-for={item}
                            className="itemHeading"
                          >
                            {item}
                          </ParaTwo>
                          <ReactTooltip
                            id={item}
                            aria-haspopup="true"
                            role="example"
                          >
                            {item}
                          </ReactTooltip>
                        </div>
                      ))
                    ) : (
                      <ParaTwo className="itemHeading">
                        <i>No header item selected</i>
                      </ParaTwo>
                    )}
                  </div>
                </div>
                <div className="col-md-8 right-col">
                  <div className="card-body rounded" id="first">
                    <h6>Title: </h6> <span>{title}</span>
                    <hr style={hrStyle2} />
                    <h6>Line Type: </h6>
                    <span style={{ "text-transform": "capitalize" }}>
                      {lineType}
                    </span>
                    <hr style={hrStyle2} />
                    <h6>Connected: </h6>
                    <span style={{ "text-transform": "capitalize" }}>
                      {connected}
                    </span>
                    <hr style={hrStyle2} />
                    <h6>Caller ID Match: </h6>
                    <span style={{ "text-transform": "capitalize" }}>
                      {caller_id_match}
                    </span>
                    <hr style={hrStyle2} />
                    <h6>Format: </h6>
                    <span style={{ "text-transform": "capitalize" }}>
                      {format}
                    </span>
                  </div>
                </div>
              </div>
            </WrapperBody>

            <WrapperBody>
              <div className="row px-2 py-2">
                <div
                  className="col-md-12"
                  style={{
                    overflow: "scroll",
                  }}
                >
                  <table className="">
                    <tr>
                      {previewHeader.map((item) => (
                        <th
                          style={{
                            border: "1px solid #ccc",
                            "white-space": "nowrap",
                          }}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                    {preview.map((item) => (
                      <tr>
                        {previewHeader.map((initem) => (
                          <td
                            style={{
                              border: "1px solid #ccc",
                              "white-space": "nowrap",
                            }}
                          >
                            {item[initem]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </WrapperBody>
            <WrapperFooter>
              <div className="row dow-modal">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                  <ModalButton onClick={goPreviousStep}>
                    &#9665; Previous
                  </ModalButton>
                  {loading ? (
                    <>
                      <DisableButtonSubmit data-tip="we're already processing this file for download">
                        Download
                      </DisableButtonSubmit>
                      <ReactTooltip />
                    </>
                  ) : (
                    <ModalButtonSubmit onClick={DownloadsFiles}>
                      Download
                    </ModalButtonSubmit>
                  )}
                </div>
              </div>
            </WrapperFooter>
          </WrapperHeader>
        </WrapperContent>
      )}
    </WrapperModal>
  );
};
export default Modal;
const formControl = {
  height: "32px",
  border: "solid 1px #ccc",
  padding: "2px 4px 2px 4px",
  "border-radius": "8px",
  width: "100%",
  "font-size": "14px",
};
const formControl2 = {
  height: "32px",
  border: "solid 1px #ccc",
  padding: "2px 4px 2px 4px",
  "border-radius": "8px",
  width: "100%",
  "font-size": "14px",
  "margin-bottom": "4px",
};
const tmpStyle = {
  opacity: ".6",
  "pointer-events": "none",
};
const lead_close = {
  right: "10px",
  top: "-10px",
};
const hrStyle = {
  margin: ".6rem 0",
  color: "inherit",
  border: "0",
  "border-top": "1px solid",
  opacity: ".25",
};
const hrStyle2 = {
  margin: ".6rem 0",
  color: "inherit",
  border: "0",
  "border-top": "1px solid",
  opacity: ".25",
};

const WrapperModal = styled.div`
  position: fixed;
  min-height: 200px;
  left: calc(42% + 240px);
  transform: translate(-50%, 0px);
  top: 0px;
  z-index: 11;
  display: flex;
`;

const WrapperHeader = styled.div``;
const WrapperContent = styled.div`
  width: 1000px;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px 20px;
  border: #d1d1d1 solid 1px;
`;
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 520px;
  height: 60px;
`;
const WrapperBody = styled.div`
  padding: 0px;
`;
const WrapperFooter = styled.div`
  padding: 10px;
  padding-top: 0px;

  text-align: center;
`;

const ModalButtonSubmit = styled.button`
  width: 222px;
  height: 40px;
  float: left;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #89de58;
  color: #fff;
  margin-top: 0px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
  margin-left: 10px;
`;
const DisableButtonSubmit = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #d3d3d3;
  color: #6d6e6e;
  margin-top: 0px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);

  border-radius: 5px;
  margin-left: 10px;
`;

const ModalButton = styled.button`
  width: 222px;
  height: 40px;
  float: left;
  margin-left: 8px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color: #d4d4d4;
  color: #6d6e6e;
  margin-top: 0px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
`;

const Para2 = styled.p`
  color: 000000;
  font-size: 10px;
  font-weight: bold;
  position: relative;

  top: 28px;
`;
const ParaTwo = styled.span`
  color: ${COLORS.username};
  font-family: Montserrat;
  font-size: 10px;
  margin-bottom: 5px;
  font-weight: 500;
  width: 100%;
  word-break: break-all;
`;
