import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { getActionData } from "services/dataValidation"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import CloseIcon from "@mui/icons-material/Close"
import FilterSelectField from "./FilterSelectField"
import IconButton from "@mui/material/IconButton"
import FilterTextField from "./FilterTextField"

function FilterBottom({
  item,
  index,
  tableListFields,
  setTableListFields,
  filters,
}) {
  const newData = [
    {
      id: "Primary Contacts",
      name: "Primary Contact",
      children: filters?.["Primary Contacts"].map((item) => {
        return { id: item.value, name: item.label }
      }),
    },
    {
      id: "Second Columns",
      name: "Second Columns",
      children: filters?.["Second Columns"].map((item) => {
        return { id: item.value, name: item.label }
      }),
    },
    {
      id: "Second Contacts",
      name: "Second Contacts",
      children: filters?.["Second Contacts"].map((item) => {
        return { id: item.value, name: item.label }
      }),
    },
    {
      id: "Third Contacts",
      name: "Third Contacts",
      children: filters?.["Third Contacts"].map((item) => {
        return { id: item.value, name: item.label }
      }),
    },
    {
      id: "Tags",
      name: "Tags",
      children: [
        {
          id: "Primary-Contacts",
          name: "Primary Contacts",
          children: filters?.["Tags"]?.["Primary Contacts"].map((item) => {
            return { id: item.value, name: item.label }
          }),
        },
      ],
    },
    {
      id: "Custom Fields",
      name: "Custom Fields",
      children: filters?.["Custom Fields"].map((item) => {
        return { id: item.value, name: item.label }
      }),
    },
  ]

  const { fileID } = useParams()

  const removeFilters = (index) => {
    let copyData = [...tableListFields]

    copyData.splice(index, 1)

    setTableListFields(copyData)
  }

  const [selectedFilterValue, setSelectedFilterValue] = useState("")

  const [values, setValues] = useState("")

  const [controller, setController] = useState("AND")

  let defaultOption = [{ value: "", label: "" }]

  if (
    [
      "Property Address (Total)",
      "Times Called (Total)",
      "Phone Number (Total)",
      "Tag (Total)",
      "Related Contacts (Total)",
      "Email (Total)",
      "Phone position",
      "Times Called",
      "Date/Time",
      "Duration",
    ].includes(selectedFilterValue?.label)
  ) {
    defaultOption = [
      { value: "contain", label: "Contains" },
      { value: "equal", label: "Is equal to" },
      { value: "not_equal", label: "Is not equal to" },
      { value: "greater_than", label: "Greater than" },
      { value: "greater_than_equal", label: "Greater than or equal to" },
      { value: "less_than", label: "Less than" },
      { value: "less_than_equal", label: "Less than or equal to" },
    ]
  } else {
    defaultOption = [
      { value: "contain", label: "Contains" },
      { value: "equal", label: "Is equal to" },
      { value: "not_equal", label: "Is not equal to" },
    ]
    // totalFields = false
  }

  const defaultOptionsValue = {
    Contains: "contain",
    "Is equal to": "equal",
    "Is not equal to": "not_equal",
    "Greater than": "greater_than",
    "Greater than or equal to": "greater_than_equal",
    "Less than": "less_than",
    "Less than or equal to": "less_than_equal",
  }

  const [copyDefaultOptions, setDefaultOptions] = useState(
    defaultOption.map((item) => item.label)
  )

  const operatorOptions = ["AND", "OR"]

  const [byDefaultOption, setByDefaultOptions] = useState([""])
  const [operatorValue, setOperatorValue] = useState("")

  const handleData = (item, value) => {
    let copyTableListFields = [...tableListFields]
    copyTableListFields[index][item] = value
    setTableListFields(copyTableListFields)
  }

  const getFilterValues = (id) => {
    getActionData({
      file_id: fileID,
      action: "get-dropdown",
      dropdown_type: id,
    }).then(function (response) {
      if (response.data) {
        setByDefaultOptions(response?.data?.option.map((item) => item))
      }
    })
  }

  const handleNodeFilters = (e, id) => {
    handleData("option", id)
    handleData("label", e.target.innerText)
    setValues("")
    setOperatorValue("")
    setSelectedFilterValue(e.target.innerText)
    const subChilData = newData.find(({ children }) =>
      children.find((a) => a.id.includes(id))
    )
    if (
      subChilData?.id == "Custom Fields" ||
      (subChilData?.id == "Second Contacts" &&
        e.target.innerText == "Phone Position") ||
      (subChilData?.id == "Third Contacts" &&
        e.target.innerText == "Phone Position") ||
      [
        "Property Address (Total)",
        "Times Called (Total)",
        "Phone Number (Total)",
        "Tag (Total)",
        "Related Contacts (Total)",
        "Email (Total)",
        "Times Called",
        "Date/Time",
        "Duration",
      ].includes(e.target.innerText)
    ) {
      defaultOption = [
        { value: "contain", label: "Contains" },
        { value: "equal", label: "Is equal to" },
        { value: "not_equal", label: "Is not equal to" },
        { value: "greater_than", label: "Greater than" },
        { value: "greater_than_equal", label: "Greater than or equal to" },
        { value: "less_than", label: "Less than" },
        { value: "less_than_equal", label: "Less than or equal to" },
      ]
    } else {
      defaultOption = [
        { value: "contain", label: "Contains" },
        { value: "equal", label: "Is equal to" },
        { value: "not_equal", label: "Is not equal to" },
      ]
    }

    setDefaultOptions(defaultOption)
    getFilterValues(id)
  }

  const handleOnChangeFilterValues = (e, newInputValue) => {
    setValues(newInputValue)
    handleData("value", newInputValue)
  }

  const handleTextFilterValues = (e) => {
    setValues(e.target.value)
    handleData("value", e.target.value)
  }

  return (
    <div
      className={`flex flex-row-reverse px-2 pt-10 gap-x-10 ${
        tableListFields.length == index + 1 && ""
      }`}>
      {[
        "Property Address (Total)",
        "Times Called (Total)",
        "Phone Number (Total)",
        "Tag (Total)",
        "Related Contacts (Total)",
        "Email (Total)",
      ].includes(selectedFilterValue) ? (
        <FilterTextField
          value={values}
          onChange={handleTextFilterValues}
          handleClearValue={() => setValues("")}
        />
      ) : (
        <Autocomplete
          key={"somethingMeaningful"}
          componentsProps={{
            popper: { style: { width: "fit-content", minWidth: 250 } },
          }}
          onInputChange={handleOnChangeFilterValues}
          value={values}
          filterSelectedOptions
          id='controllable-states-demo'
          options={byDefaultOption}
          sx={{ width: 300 }}
          size='small'
          renderInput={(params) => (
            <TextField size='small' {...params} label='Filter Values' />
          )}
        />
      )}

      <Autocomplete
        onInputChange={(event, newInputValue) => {
          handleData(
            "condition",
            defaultOptionsValue[newInputValue]
            // newInputValue.toLowerCase().replaceAll(" ", "_")
          )
          setOperatorValue(newInputValue)
        }}
        id='controllable-states-demo'
        options={copyDefaultOptions}
        sx={{ width: 300 }}
        size='small'
        value={operatorValue}
        renderInput={(params) => (
          <TextField size='small' {...params} label='Operator' />
        )}
      />
      <FilterSelectField
        options={newData}
        handleNodeFilters={handleNodeFilters}
      />

      {index != 0 && (
        <>
          <Autocomplete
            onInputChange={(event, newInputValue) => {
              handleData(
                "operator",
                newInputValue.toLowerCase().replaceAll(" ", "_")
              )
              setController(newInputValue)
            }}
            value={controller}
            id='controllable-states-demo'
            options={operatorOptions}
            sx={{ width: 200 }}
            size='small'
            renderInput={(params) => <TextField size='small' {...params} />}
          />
          <IconButton
            style={{
              borderRadius: "100%",
              height: 40,
              width: 40,
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => removeFilters(index)}>
            <CloseIcon
              style={{ color: "red", alignSelf: "center" }}
              className='cursor-pointer'
            />
          </IconButton>
        </>
      )}
    </div>
  )
}

export default FilterBottom
