import React,{useState} from 'react'

function Tabs({label,contents}) {
  const [open,setOpen]=useState(false)
let nf = new Intl.NumberFormat('en-US');

  return (<>
       <div className="bg-light-gray padding-0   ">
                  <div className={`flex px-18 ${open&&'bg-gray'} hover:bg-gray rounded-sm items-center justify-between h-49`}>
                    <div  className="font-bold text-lg">{label}</div>
                    <i class="fa fa-chevron-down cursor-pointer" aria-hidden="true" onClick={()=>setOpen(prev=>!prev)}></i>
                </div>
                </div>
                {open&&
                 <div className="bg-white">
                  {label=='Total Phone Count:'?
                  <>
                        {contents.map(({heading,content,lable,value})=>
                        <>
                        <div className="mt-3 font-bold">
                            {heading}
                        </div>
                        <div className="flex justify-between gap-y-20 flex-wrap mt-3 pb-3 px-18">
                        {content.map(({label,value})=>
                         <div className="w-212 text-center">
                            <div className="text-xs">{label}</div>
                            <div className="text-2xl font-bold">{nf.format(value)}</div>
                        </div>
                        )}                     
                        </div>
                        </>)}
                    
                    </>
                  : <div className="flex gap-y-20 flex-wrap mt-3 pb-3 px-18">
                        {contents.map(({lable,value,percent})=>
                        <div className="w-212 text-center">
                            <div className="text-xs">{lable}</div>
                            <div className="text-2xl font-bold flex justify-center relative">
                              <div >{typeof value=='string'? value:nf.format(value)}</div>
                              {percent&&<div className=" percent">{percent}</div>}
                            </div>
                        </div>

                        )}
                    </div>}
                    
                    
                </div>
                }
  </>)
}

export default Tabs;