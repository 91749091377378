import React from "react"

const Reportgrid = (props) => {
  let heading = ""
  if (props.data_key === "phone") {
    heading = "Phone Numbers"
  } else if (props.data_key === "mailing_address") {
    heading = "Mailing Addresses"
  } else if (props.data_key === "times_called") {
    heading = "Times Called"
  } else if (props.data_key === "owner1fname") {
    heading = "Owner 1"
  } else if (props.data_key === "owner1lname") {
    heading = "Owner 1 Last Name"
  } else if (props.data_key === "owner2fname") {
    heading = "Owner 2"
  } else if (props.data_key === "owner2lname") {
    heading = "Owner 2 Last Name"
  } else if (props.data_key === "addresses") {
    heading = "Property Addresses"
  }else if (props.data_key === "tags") {
    heading = "Tags"
  }
  else if (props.data_key === "emails") {
    heading = "Emails"
  }
  else if (props.data_key === "customFields") {
    heading = "Custom Fields"
  }
  else {
    return false
  }

  const New = props?.data?.New
  const Updated = props?.data?.Updated
  const Skipped = props?.data?.Skipped
  const Blank_cell = props?.data?.Blank_cell
  const Error = props?.data?.Error

  return (
    <div className='col-md-6'>
      <div className='report_box'>
        <div className='head'>{heading}</div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='report_icon'>
              <svg
                width='18'
                height='18'
                viewBox='0 0 12 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.8776 2.9189L9.02495 0.117182C8.94847 0.0420791 8.84556 0 8.73828 0H1.52635C0.684771 0 0 0.684664 0 1.52635V12.4367C0 13.2782 0.684664 13.963 1.52635 13.963H10.4736C11.3152 13.963 12 13.2784 12 12.4367V3.21079C12 3.10096 11.9559 2.99582 11.8776 2.9189ZM9.14736 1.38424L10.5906 2.80172H9.27743C9.20574 2.80172 9.14736 2.74334 9.14736 2.67165V1.38424ZM10.4736 13.1449H1.52635C1.13592 13.1449 0.818146 12.8272 0.818146 12.4367V1.52635C0.818146 1.13582 1.13582 0.818146 1.52635 0.818146H8.32932V2.67165C8.32932 3.1945 8.75469 3.61987 9.27754 3.61987H11.1819V12.4367C11.1819 12.8272 10.8642 13.1449 10.4736 13.1449Z'
                  fill='white'
                />
                <path
                  d='M8.84174 6.48926H3.07509C2.84914 6.48926 2.66602 6.67238 2.66602 6.89833C2.66602 7.12428 2.84914 7.3074 3.07509 7.3074H8.84174C9.06768 7.3074 9.25081 7.12428 9.25081 6.89833C9.25081 6.67238 9.06768 6.48926 8.84174 6.48926Z'
                  fill='white'
                />
                <path
                  d='M3.07509 8.94461H7.77708C8.00303 8.94461 8.18615 8.76149 8.18615 8.53554C8.18615 8.30959 8.00303 8.12646 7.77708 8.12646H3.07509C2.84914 8.12646 2.66602 8.30959 2.66602 8.53554C2.66602 8.76149 2.84914 8.94461 3.07509 8.94461Z'
                  fill='white'
                />
                <path
                  d='M8.34435 9.76465H3.07509C2.84914 9.76465 2.66602 9.94777 2.66602 10.1737C2.66602 10.3997 2.84914 10.5828 3.07509 10.5828H8.34435C8.57019 10.5828 8.75342 10.3997 8.75342 10.1737C8.75342 9.94777 8.57019 9.76465 8.34435 9.76465Z'
                  fill='white'
                />
              </svg>
            </div>{" "}
            <div className='report_content'>
              {" "}
              <label>New</label>
              <span className='report_content_value'>{New}</span>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='report_icon'>
              <svg
                width='18'
                height='18'
                viewBox='0 0 14 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M13.9733 9.62155C13.8807 9.33651 13.5746 9.18068 13.2895 9.27312L12.5838 9.50241C13.0161 8.60835 13.2425 7.62519 13.2425 6.62124C13.2425 2.97027 10.2723 0 6.62124 0C2.97027 0 0 2.97027 0 6.62124C0 10.2722 2.97027 13.2425 6.62124 13.2425C6.92096 13.2425 7.16397 12.9995 7.16397 12.6998C7.16397 12.4001 6.92096 12.157 6.62124 12.157C3.56879 12.157 1.08545 9.67374 1.08545 6.62124C1.08545 3.56878 3.56879 1.08545 6.62124 1.08545C9.67375 1.08545 12.157 3.56878 12.157 6.62124C12.157 7.46233 11.9673 8.28575 11.6046 9.03389L11.3635 8.29161C11.2709 8.00662 10.9647 7.85066 10.6797 7.94319C10.3946 8.03584 10.2386 8.34202 10.3312 8.62706L10.9712 10.5968C11.0456 10.8262 11.2584 10.9721 11.4872 10.9721C11.5427 10.9721 11.5993 10.9635 11.655 10.9454L13.6248 10.3055C13.9099 10.2129 14.0659 9.90672 13.9733 9.62155Z'
                  fill='white'
                />
              </svg>
            </div>{" "}
            <div className='report_content'>
              {" "}
              <label>Updated</label>
              <span className='report_content_value'>{Updated}</span>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='report_icon'>
              <svg
                width='18'
                height='18'
                viewBox='0 0 25 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <circle cx='12.5' cy='12.5' r='12.5' fill='#047CFF' />
                <path
                  d='M19.4531 11.2806L15.7186 7.54616C14.9913 6.81795 13.8056 6.81795 13.0783 7.54616C12.7254 7.89814 12.5312 8.36774 12.5312 8.86629C12.5312 9.36484 12.7254 9.83445 13.0783 10.1864L15.4927 12.6007L13.0783 15.0151C12.7254 15.368 12.5312 15.8366 12.5312 16.3352C12.5312 16.8337 12.7254 17.3024 13.0783 17.6553C13.4313 18.0082 13.8999 18.2024 14.3985 18.2024C14.897 18.2024 15.3657 18.0082 15.7186 17.6553L19.4531 13.9209C19.806 13.5689 20.0002 13.0993 20.0002 12.6007C20.0002 12.1022 19.806 11.6326 19.4531 11.2806ZM18.793 13.2608L15.0585 16.9953C14.7047 17.3482 14.0923 17.3482 13.7384 16.9953C13.3743 16.6312 13.3743 16.0392 13.7384 15.6751L16.8128 12.6007L13.7384 9.52636C13.562 9.3499 13.4649 9.11557 13.4649 8.86629C13.4649 8.61702 13.562 8.38268 13.7384 8.20623C13.9205 8.02417 14.1595 7.93268 14.3985 7.93268C14.6375 7.93268 14.8765 8.02417 15.0585 8.20529L18.793 11.9397C18.9694 12.1171 19.0665 12.3515 19.0665 12.6007C19.0665 12.85 18.9694 13.0844 18.793 13.2608Z'
                  fill='white'
                />
                <path
                  d='M12.9218 11.2806L9.18735 7.54616C8.46007 6.81795 7.27438 6.81795 6.5471 7.54616C6.19419 7.89814 6 8.36774 6 8.86629C6 9.36484 6.19419 9.83445 6.5471 10.1864L8.96142 12.6007L6.5471 15.0151C6.19419 15.368 6 15.8366 6 16.3352C6 16.8337 6.19419 17.3024 6.5471 17.6553C6.9 18.0082 7.36868 18.2024 7.86723 18.2024C8.36578 18.2024 8.83445 18.0082 9.18735 17.6553L12.9218 13.9209C13.2747 13.5689 13.4689 13.0993 13.4689 12.6007C13.4689 12.1022 13.2747 11.6326 12.9218 11.2806ZM12.2617 13.2608L8.52729 16.9953C8.17345 17.3482 7.561 17.3482 7.20716 16.9953C6.84305 16.6312 6.84305 16.0392 7.20716 15.6751L10.2815 12.6007L7.20716 9.52636C7.03071 9.3499 6.93361 9.11557 6.93361 8.86629C6.93361 8.61702 7.03071 8.38268 7.20716 8.20623C7.38922 8.02417 7.62822 7.93268 7.86723 7.93268C8.10623 7.93268 8.34524 8.02417 8.52729 8.20529L12.2617 11.9397C12.4382 12.1171 12.5353 12.3515 12.5353 12.6007C12.5353 12.85 12.4382 13.0844 12.2617 13.2608Z'
                  fill='white'
                />
              </svg>
            </div>{" "}
            <div className='report_content'>
              {" "}
              <label>Duplicates</label>
              <span className='report_content_value'>{Skipped}</span>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='report_icon'>
              <svg
                width='18'
                height='18'
                viewBox='0 0 13 13'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.5104 13H1.48962C0.667892 13 0 12.3321 0 11.5104V1.48962C0 0.667892 0.667892 0 1.48962 0H11.5104C12.3321 0 13 0.667892 13 1.48962V11.5104C13 12.3321 12.3321 13 11.5104 13ZM1.48962 0.8125C1.11639 0.8125 0.8125 1.11639 0.8125 1.48962V11.5104C0.8125 11.8836 1.11639 12.1875 1.48962 12.1875H11.5104C11.8836 12.1875 12.1875 11.8836 12.1875 11.5104V1.48962C12.1875 1.11639 11.8836 0.8125 11.5104 0.8125H1.48962Z'
                  fill='white'
                />
                <path
                  d='M12.5938 10.0195H0.40625C0.181999 10.0195 0 9.83753 0 9.61328C0 9.38903 0.181999 9.20703 0.40625 9.20703H12.5938C12.818 9.20703 13 9.38903 13 9.61328C13 9.83753 12.818 10.0195 12.5938 10.0195Z'
                  fill='white'
                />
                <path
                  d='M12.5938 3.79297H0.40625C0.181999 3.79297 0 3.61097 0 3.38672C0 3.16247 0.181999 2.98047 0.40625 2.98047H12.5938C12.818 2.98047 13 3.16247 13 3.38672C13 3.61097 12.818 3.79297 12.5938 3.79297Z'
                  fill='white'
                />
                <path
                  d='M6.5 3.79163C6.27575 3.79163 6.09375 3.60963 6.09375 3.38538V0.40625C6.09375 0.181999 6.27575 0 6.5 0C6.72425 0 6.90625 0.181999 6.90625 0.40625V3.38538C6.90625 3.60963 6.72425 3.79163 6.5 3.79163Z'
                  fill='white'
                />
                <path
                  d='M6.5 12.9987C6.27575 12.9987 6.09375 12.8167 6.09375 12.5924V9.61328C6.09375 9.38903 6.27575 9.20703 6.5 9.20703C6.72425 9.20703 6.90625 9.38903 6.90625 9.61328V12.5924C6.90625 12.8167 6.72425 12.9987 6.5 12.9987Z'
                  fill='white'
                />
              </svg>
            </div>{" "}
            <div className='report_content'>
              {" "}
              <label>Blank cell</label>
              <span className='report_content_value'>{Blank_cell}</span>
            </div>
          </div>

          <div className='col-md-6'>
            <div className='report_icon'>
              <svg
                width='18'
                height='18'
                viewBox='0 0 14 13'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M13.8407 9.09334C13.7288 8.81284 13.4107 8.67621 13.1302 8.78818C12.8497 8.90015 12.713 9.21827 12.825 9.49876C12.9674 9.85541 12.9207 10.2626 12.7001 10.5881C12.4795 10.9138 12.1262 11.1006 11.7308 11.1006H2.2714C1.84016 11.1006 1.45721 10.8755 1.24697 10.4986C1.03639 10.121 1.04629 9.67603 1.27344 9.30823L6.00315 1.65053C6.21851 1.30184 6.59156 1.09367 7.00111 1.09367C7.41067 1.09367 7.78369 1.30184 7.99908 1.65053L11.4971 7.31408C11.6558 7.57103 11.9928 7.65073 12.2497 7.49195C12.5066 7.33327 12.5863 6.99632 12.4275 6.73937L8.92951 1.07581C8.51344 0.402172 7.79255 0 7.00109 0C6.20962 0 5.48873 0.4022 5.07266 1.07581L0.342956 8.73355C-0.0955292 9.44356 -0.114615 10.3025 0.29185 11.0313C0.697987 11.7595 1.43799 12.1942 2.2714 12.1942H11.7308C12.4948 12.1942 13.178 11.8324 13.6055 11.2015C14.0298 10.5754 14.1177 9.78729 13.8407 9.09334Z'
                  fill='white'
                />
                <path
                  d='M7.00204 10.0331C7.30405 10.0331 7.54886 9.78829 7.54886 9.48627C7.54886 9.18426 7.30405 8.93945 7.00204 8.93945H7.00176C6.69975 8.93945 6.45508 9.18426 6.45508 9.48627C6.45508 9.78829 6.70002 10.0331 7.00204 10.0331Z'
                  fill='white'
                />
                <path
                  d='M7.54872 7.57333V3.55415C7.54872 3.25213 7.30391 3.00732 7.0019 3.00732C6.69989 3.00732 6.45508 3.25213 6.45508 3.55415V7.57333C6.45508 7.87534 6.69989 8.12015 7.0019 8.12015C7.30391 8.12015 7.54872 7.87534 7.54872 7.57333Z'
                  fill='white'
                />
              </svg>
            </div>{" "}
            <div className='report_content'>
              {" "}
              <label>Error</label>
              <span className='report_content_value'>{Error}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reportgrid
