import React, { useEffect, useState, useCallback } from "react"
import { getManipulatorHistory } from "services/dataValidation"
import TopNav from "components/navbar/topNav"
import Table from "components/navbar/tableItems/history/table"
import styled from "styled-components"
import Pagination from "components/pagination"
import Loading from "components/loading"
import "assets/css/style.css"
import Modal from "components/lead-modal"
import { useParams } from "react-router-dom"
const History = (props) => {
  let { id } = useParams()
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([])
  const [noOfPages, setNoofPages] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [, setTotalExcelData] = useState(false)
  const [show, setShow] = useState(false)
  const [filedata] = useState(false)

  const ManipulatorHistory = useCallback(
    (noOfPages, recordsPerPage) => {
      setisLoading(true)
      getManipulatorHistory(
        {
          file_id: id,
          per_page: recordsPerPage,
        },
        noOfPages
      )
        .then(function (response) {
          setisLoading(false)
          setTotalRecords(response.total_records)
          setFilteredUserExcelData(response.data)
          setTotalExcelData(response.data.length)
        })
        .catch((error) => {
          setisLoading(false)
          console.log("error", error)
        })
    },
    [id]
  )

  useEffect(() => {
    ManipulatorHistory(noOfPages, recordsPerPage)
  }, [ManipulatorHistory, noOfPages, recordsPerPage])

  function onChangeRecordPerPage(perPageValue, pageCount) {
    setRecordsPerPage(perPageValue)
    setNoofPages(pageCount)
    ManipulatorHistory(1, perPageValue)
  }

  return (
    <Wrapper>
      <Modal
        onClose={() => {
          setShow(false)
        }}
        show={show}
        fields={filedata}
      />
      <Loading show={isLoading} />
      <TopNav />

      <Table files={filteredUserExcelData}></Table>
      <WrapperPagination>
        <Pagination
          getPageData={ManipulatorHistory}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          onChangeRecordPerpage={onChangeRecordPerPage}></Pagination>
      </WrapperPagination>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

const WrapperPagination = styled.div`
  width: 98%;
`

export default History
