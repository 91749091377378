import React from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import Prev from "assets/images/prev-arrow.svg"
import Next from "assets/images/next-arrow.svg"


const Modal = (props) => {
  if (!props.show) {
    return null
  }
  const {index,totalRecords,totalfiles}=props;
  const Data = props.data ? props.data : []
  let NewData = Data.data ? Data.data : []

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='manipulator-close close-btn1'
        onClick={props.onClose}>
        ×
      </button>
      <WrapperContent>
        <WrapperHeader className=' manipulator-phone-modal'>
          <div className='flex heading-title'> 
          <h1 className="popupModalHeader"><span className="float-left">{`(${index} - ${totalRecords} Total Records)`}</span>Phone Numbers <span className="float-right">{Data?.filtered_out_of_total?`(${Data?.filtered_out_of_total})`:''}</span></h1> 
          {/* <div className="w-8% mr-20 font-bold">({Data?.filtered_out_of_total})</div> */}
          </div>
          <h3 className='popupModalHeader'>
            Mailing Address:
            <span className='mailing_address'> {Data.mailing_address}</span>
          </h3>
          <h3 className='popupModalHeader'>
            Owner 1: <span className='ownerName'>{Data.owner_1}</span>
          </h3>
          <h3 className='popupModalHeader'>
            Owner 2: <span className='ownerName'>{Data.owner_2}</span>
          </h3>
          <WrapperBody>
             {index-1?
          <img alt="" onClick={()=>props?.phonePopup(totalfiles[index-2],index-1,Data?.previous)} src={Prev} className="w-20 transform-y-center left-8 cursor-pointer"/>:null 
          }
          {totalfiles[index]?
          <img alt="" onClick={()=>props?.phonePopup(totalfiles[index],index+1,Data?.next)} src={Next} className="w-20 transform-y-center right-8 cursor-pointer"/>:null
          }
            <table className='popupTablelisting'>
              <tr className='heading'>
                <th className='frist'>Phone Number</th>
            
                <th>Phone Position</th>
                <th>Carrier</th>
                <th>Caller ID Name</th>
                <th>Caller ID Match</th>
                <th>Line Type</th>
                <th>Connected</th>
                <th>Disposition</th>
                <th>Time Called</th>
                <th>Date/Time</th>
                <th>Call From</th>
                <th>Campaign Name</th>
                <th>Duration</th>
                <th>Status</th>
                <th>Note</th>
              </tr>
              {NewData.length > 0 &&
                NewData.map((phoneData) => (
                  <React.Fragment>
                    <tr>
                      <td className='frist'>{phoneData.phone}</td>
                      <td>{phoneData.phone_position}</td>
                      <td>{phoneData.carrier}</td>
                      <td>{phoneData.caller_id_name}</td>
                      <td>{phoneData.caller_id_match}</td>
                      <td>{phoneData.line_type}</td>
                      <td>{phoneData.connected}</td>
                      <td>{phoneData.disposition}</td>
                      <td>{phoneData.times_called}</td>
                      <td>{phoneData.date_time}</td>
                      <td>{phoneData.call_from}</td>
                      <td>{phoneData.campaign_name}</td>
                      <td>{phoneData.duration}</td>
                      <td>{phoneData.status}</td>
                      <td>{phoneData.note}</td>
                    </tr>
                  </React.Fragment>
                ))}
            </table>
            {NewData.length === 0 &&<div className="mt-20 text-center font-bold">No Record Found</div>}
          </WrapperBody>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 968px;
  min-height: 200px;
  left: 280px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 9999;
      top: 10px;
    max-height: 76vh;
     
    border-radius: 0;
    width: calc(98% - 245px);
    left:260px;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0px  0px 20px 0px;
  border: #d1d1d1 solid 1px;
  overflow-y:hidden;
  border-radius:20px;
  
`

const WrapperBody = styled.div`
  padding: 5px;
  max-height: 57vh;
  overflow: auto;
  border-radius: 10px;
  margin: 1% 2%;
`
