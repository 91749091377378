import React from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
const Modal = (props) => {
  if (!props.show) {
    return null
  }

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='manipulator-close'
        onClick={props.onClose}>
        ×
      </button>
      <WrapperContent>
        <WrapperHeader className='manipulator-phone-modal'>
          <h1 className='popupModalHeader'>Time Called</h1>
          <WrapperBody>
            <div className='lead-input-container'></div>
          </WrapperBody>
          <WrapperFooter></WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 968px;
  min-height: 200px;
  left: 274px;
  top: 40px;
  z-index: 1;
  display: flex;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 968px;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px 0px;
  border: #d1d1d1 solid 1px;
`

const WrapperBody = styled.div`
  padding: 10px;
  height: 100px;
`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align: center;
`
