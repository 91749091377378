import React from "react"
import styled from "styled-components"
import Item from "./tableItem"
const Table = (props) => {
  const files = props.files ? props.files : []
  const items = []
  for (let i = 0; i < files.length; i++) {
    items.push(
      <Item
        address={files[i].address}
        city={files[i].city}
        state={files[i].state}
        zip={files[i].zip}
        key={i}
        id={files[i].id}></Item>
    )
  }

  return (
    <Wrapper>
      <WrapperTitle></WrapperTitle>
      <div className='scroll-in-mobile dowloads'>
        {items.length > 0 && (
          <table className='listing download'>
            <tr className='heading'>
              <th>Address</th>
              <th>City</th>
              <th>State</th>
              <th>Zip Code</th>
            </tr>
            {items}
          </table>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 10px;
`

export default Table
