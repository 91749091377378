import React, { useState, useEffect } from "react"
import { editCustomFieldData } from "services/dataValidation"

import { toast } from "react-toastify"

function RecordItem({
  index,
  id,
  record_unique_id,
  customFieldName,
  customFieldValue,
  setConfirm,
  deleteCustomField,
  setAddFieldModal,
  setRecordUniqueId,
  onClose,
}) {
  const [inputDisabled, setInputDisabled] = React.useState(true)
  const [value, setValue] = useState(customFieldValue)
  const ref = React.useRef(null)
  function editCustomValue() {
    setInputDisabled(!inputDisabled)
    ref.current.focus()
  }

  const updateValue = async () => {
    const result = await editCustomFieldData({
      record_id: id,
      custom_field_value: value,
    })
    if (result.status) {
      toast.success("Custom field is updated successful")
      setInputDisabled(true)
    }
  }

  // for (let i = 0; i < tx.length; i++) {
  //   if (tx[i].value == "") {
  //     tx[i].setAttribute(
  //       "style",
  //       "height:" + txHeight + "px;overflow-y:hidden;"
  //     )
  //   } else {
  //     tx[i].setAttribute("style", "height:" + tx[i].scrollHeight + "px")
  //   }
  //   tx[i].addEventListener("input", OnInput, false)
  // }

  // React.useLayoutEffect(() => {
  //   // Reset height - important to shrink on delete
  //   ref.current.style.height = "inherit"
  //   // Set height
  //   ref.current.style.height = `${Math.max(ref.current.scrollHeight, 12)}px`
  // }, [value])

  useEffect(() => {
    ref.current.style.lineHeight = `33px`
  }, [])
  return (
    <tr>
      <td width='30%'>{customFieldName}</td>
      <td width={inputDisabled ? "30%" : "40%"}>
        <div className={inputDisabled ? "break-all" : "hidden"}>{value}</div>
        {/* <label
          className={
            inputDisabled
              ? "text-center hidden bg-transparent resize-none"
              : "customFiledValueInput resize-none overflow-hidden "
          }> */}
        <input
          // onKeyUp={textAreaAdjust}
          type='text'
          className={
            inputDisabled
              ? "text-center hidden bg-transparent resize-none"
              : "customFiledValueInput  "
          }
          id={index}
          name={customFieldValue}
          value={value}
          ref={ref}
          onChange={(e) => {
            setValue(e.target.value)
          }}
          //   // Set height
          //   ref.current.style.height = `${Math.max(ref.current.scrollHeight, 12)}px`}}
          disabled={inputDisabled}
          onBlur={updateValue}
        />
        {/* </label> */}
      </td>
      <td width='30%'>
        {/* <span
          className='eidtLink'
          style={{ color: "#047cff" }}
          onClick={() => {
            setAddFieldModal(true)
            setRecordUniqueId(record_unique_id)
          }}>
          Add
        </span>
        <span> / </span> */}
        <span
          className='eidtLink'
          style={{ color: "#047cff" }}
          onClick={() => editCustomValue()}>
          Edit
        </span>
        <span> / </span>
        <span
          className='eidtLink'
          style={{ color: "#047cff" }}
          onClick={() => {
            setConfirm(true)
            onClose()
            deleteCustomField(id)
          }}>
          Delete
        </span>
      </td>
    </tr>
  )
}

export default RecordItem
