import React from "react"
import styled from "styled-components"

const Upgradecomponent = () => {
  return <Wrapper>This feature is unavailable for your plan. Please upgrade</Wrapper>
 
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: bold;
    padding: 15% 10px 5px 8px;
    font-size: 28px;
`

export default Upgradecomponent