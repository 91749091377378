import { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import styled from "styled-components"
import COLORS from "./../../../utils/colors"

const IdleModal = ({ showModal, handleClose, handleLogout }) => {
  const [count, setCount] = useState(60)
  useEffect(() => {
      let timeInterval = null;
      let seconds = 60;
      if (showModal) {
          seconds = 60;
          timeInterval = setInterval(() => {
              if (seconds <= 0) {
                  clearInterval(timeInterval)
                  handleLogout()
              } else {
                  seconds = seconds - 1
                  setCount(seconds)
              }
          }, 1000)
      } else {
          seconds = 60
          clearInterval(timeInterval);
      } 
      return (()=>{
          seconds = 60
          setCount(60)
          clearInterval(timeInterval);
      })
  }, [showModal, handleLogout])
  return (
      <Modal show={showModal} onHide={handleClose}>
          <Modal.Header style={{ backgroundColor: COLORS.lightblue }} closeButton>
              <Modal.Title style={{ color: '#fff', fontSize: 15 }}>
                  Click here to stay logged in
              </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Stay Signed in ?
              <div style={{ borderRadius: 100, height: 25, width: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'solid 1px', borderColor: COLORS.lightblue, color: 'blue', fontWeight:'bold'}}>{count}</div>
          </Modal.Body>
          <Modal.Footer>
              <ModalButton onClick={handleLogout}>Logout</ModalButton>
              <ModalButton onClick={handleClose}>Stay</ModalButton>
          </Modal.Footer>
      </Modal>
  )
}
const ModalButton = styled.button`
width: 200px;
height: 40px;
color: blue;
font-size: 15px;
font-weight: bold;
&:hover {
  background-color: ${COLORS.lightblue};
  color: ${COLORS.white};
}
border: 1px solid #3486eb;
border-radius: 5px;
margin:auto
`
export default IdleModal