import React from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import COLORS from "utils/colors"
import CreatableSelect from "react-select/creatable"
import { postRequest } from "services/dataValidation"
import { toast } from "react-toastify"

const CustomFieldModal = ({
  fileId,
  setisLoading,
  setCustomFields,
  CustomFieldREISiftSelect,
  sourceFieldOptions,
  onChangeSourceField,
  removeSourceField,
  listCustomFields,
  setListCustomFields,
  mapListCustomFields,
  setMapCustomFields,
  options,
  setOptions,
  ...props
}) => {
  const addCustomFields = () => {
    const fields = listCustomFields.map((item) => item.sourceField)
    const mappingfields = listCustomFields.map((item) => item.mappingField)

    const copyOptions = [...options]
    if (fields.includes("Select") || mappingfields.includes("Select")) {
      toast.error("Field name could not be 'Select'")
    } else {
      postRequest({ fields }, "create-custom-fields").then(function (response) {
        if (response?.status) {
          toast.success("custom fields are added successfully")
          setMapCustomFields([...mapListCustomFields, ...listCustomFields])
          props.onClose()
          listCustomFields.map((item) => {
            const indexOfOptions = copyOptions.findIndex((object) => {
              return object.label == item.mappingField
            })

            copyOptions.splice(indexOfOptions, 1)
          })

          setOptions(copyOptions)
          setListCustomFields([])
          let mappedData1 = JSON.parse(localStorage.getItem("mappingData"))
          const mappedCustomFieldsValues = {}
          const mergeArray = [...mapListCustomFields, ...listCustomFields]

          mergeArray.map((item) => {
            mappedCustomFieldsValues[item.mappingField] = item.sourceField
          })
          mappedData1.mappedCustomFieldsValues = mappedCustomFieldsValues

          localStorage.setItem("mappingData", JSON.stringify(mappedData1))
        } else {
          toast.error(response?.errors)
        }
      })
    }
  }

  const filteredSourceValue = sourceFieldOptions.filter(function (objFromA) {
    return !mapListCustomFields.find(function (objFromB) {
      return objFromA.label === objFromB.sourceField
    })
  })

  const addCustomField = () => {
    setListCustomFields((pre) => [
      ...pre,
      {
        id: listCustomFields.length,
        sourceField: "Select",
        mappingField: "Select",
      },
    ])
  }

  return (
    <WrapperModal>
      <button
        _ngcontent-hex-c78=''
        type='button'
        data-dismiss='modal'
        className='manipulator-close close-btn1'
        onClick={props.onClose}>
        x
      </button>
      <WrapperContent>
        <WrapperHeader className='manipulator-phone-modal'>
          <div className='flex heading-title'>
            <h1 className='popupModalHeader'>
              <span className='float-left'>{/* {`0 Total Records`} */}</span>
              Custom Fields
            </h1>
          </div>
          <div className='text-center msg-pop mt-2'>
            Any custom fields selected here will be added to the "Property
            Address" section inside of all contacts
          </div>
          <WrapperBody>
            {listCustomFields.map((cus, index) => (
              <div className='row mb-20 relative w-100 overflow-hidden'>
                <div className='col-md-6'>
                  <LabelWrapper className='mb-2'>
                    <Label>Source Field</Label>
                  </LabelWrapper>
                  <CreatableSelect
                    onChange={(e) => onChangeSourceField(e.value, index)}
                    options={[
                      { label: "Select", value: "Select" },
                      ...filteredSourceValue,
                    ]}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={{
                      label: listCustomFields[index]?.sourceField,
                      value: listCustomFields[index]?.sourceField,
                    }}
                  />
                </div>
                <div className='w-47per'>
                  <div className='fields'>
                    <LabelWrapper className='mb-2'>
                      <Label>Mapping</Label>
                    </LabelWrapper>

                    <CustomFieldREISiftSelect index={index} className={false} />
                  </div>
                </div>
                <div className='col-md-2 w-auto flex justify-end top-20 right-_20 absolute'>
                  <svg
                    className='icon-delete'
                    xmlns='http://www.w3.org/2000/svg'
                    width='46'
                    height='46'
                    viewBox='0 0 16 16'>
                    <path
                      onClick={() => removeSourceField(index)}
                      fill='#b34242'
                      cursor='pointer'
                      d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
                    />
                  </svg>
                </div>
              </div>
            ))}
            <div className='flex justify-center'>
              <svg
                className='icon-delete'
                xmlns='http://www.w3.org/2000/svg'
                width='40'
                height='40'
                viewBox='0 0 16 16'>
                <path
                  fill='#362e2e'
                  cursor='pointer'
                  onClick={addCustomField}
                  d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z'
                />
              </svg>
            </div>
            <div className='flex justify-center'>
              <button
                onClick={addCustomFields}
                className='custom-btn ml-auto mr-4 justify-center w-28'>
                Save
              </button>
            </div>
          </WrapperBody>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default CustomFieldModal

const WrapperModal = styled.div`
  position: fixed;
  width: 968px;
  min-height: 200px;
  left: 280px;
  top: 40px;
  z-index: 1;
  display: flex;
  z-index: 9999;
  top: 10px;
  max-height: 76vh;

  border-radius: 0;
  width: calc(98% - 245px);
  left: 260px;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0px 0px 20px 0px;
  border: #d1d1d1 solid 1px;
  overflow-y: hidden;
  border-radius: 20px;
  overflow-x: hidden;
  height: fit-content;
`

const WrapperBody = styled.div`
  padding: 5px;
  max-height: 57vh;
  overflow: auto;
  border-radius: 10px;
  margin: 1% 2%;
`
const MainButton = styled.div`
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height: 35px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
`
const Label = styled.label`
  font-size: 12px;
  display: table;
  cursor: pointer;
  color: black;
  font-family: "Montserrat";
  text-align: center;
  font-weight: bold;
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Upload = styled.input`
  display: none;
  cursor: pointer;
`
