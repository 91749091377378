import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { ProgressBar as Bar } from "react-bootstrap";
import COLORS from "utils/colors";

const ProgressBar = ({ percentage }) => {
  const chunks = Math.floor(percentage / 5);

  const [copyPercentage, setCopyPercentage] = useState(0);
  // if(percentage<100){

  // }

  const handleChunk = () => {
    if (copyPercentage < percentage)
      setTimeout(() => {
        let copyChunks = copyPercentage;
        copyChunks = copyChunks + chunks;
        setCopyPercentage(copyChunks);
      }, [1]);
  };

  useEffect(() => {
    handleChunk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyPercentage]);
  //console.log(percentage);
  if (percentage == "100") {
    return (
      <Wrapper>
        <Text className="ready_status">Ready</Text>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Text>Status : {Math.floor(percentage)}%</Text>
        <Bar
          className="status-bar"
          now={percentage !== undefined ? percentage : 0}
          height={"5px"}
        />
      </Wrapper>
    );
  }
};

const Wrapper = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const Text = styled.label`
  font-size: 12px;
  color: ${COLORS.grey};
`;

export default memo(ProgressBar);
