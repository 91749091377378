export function getAuthorizationToken() {
  let userObject = localStorage.getItem("userData");
  if (userObject) {
    var obj = JSON.parse(userObject);
    // console.log(obj['api_token'])
    let apiToken = "Bearer " + obj["api_token"];
    return apiToken;
  }
  return null;
}

export function getUserID() {
  var userObject = localStorage.getItem("userData");
  if (userObject) {
    var obj = JSON.parse(userObject);
    return obj._id;
  }
  return null;
}

// useEffect(() => {
//   if (searchTerm) {
//     const delayDebounceFn = setTimeout(() => {
//       getActionData({
//         file_id: fileID,
//         action: "get-dropdown",
//         dropdown_type:
//           selectedFilterValue.option || selectedFilterValue.value,
//         search: searchTerm.search,
//       }).then(function (response) {
//         if (response.data) {
//           searchTerm?.callback(response?.data?.option)
//         }
//       })
//     }, 2000)

//     return () => clearTimeout(delayDebounceFn)
//   }
// }, [searchTerm])
