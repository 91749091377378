import React, { useEffect, useState } from "react"
import { getUsersTransactionData } from "services/paymentDeatil"
import { makeStripeDefultCard } from "services/paymentDeatil"
import { getUserCreditCardsAPI } from "services/paymentDeatil"
import { deleteStripeCardAPI } from "services/paymentDeatil"
import TopNavProfile from "components/navbar/TopNavProfile"
import styled from "styled-components"
import { Link } from "react-router-dom"
import Cardone from "assets/images/card1.png"
import visa from "assets/images/visa.png"
import "assets/css/style.css"
import { STRIPE_TEST_KEY,STRIPE_LIVE_KEY,STRIPE_MODE } from "config"
// import { IMAGE_DIR } from "config"
import { getUserID } from "utils/helper"
import { loadStripe } from "@stripe/stripe-js"
import CheckoutForm from "./CheckoutForm"
import { Elements } from "@stripe/react-stripe-js"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ReactPaginate from "react-paginate"
import Loading from "components/loading"
import ReactTooltip from "react-tooltip"

const stripeKey=STRIPE_MODE?STRIPE_LIVE_KEY:STRIPE_TEST_KEY

const stripePromise = loadStripe(stripeKey)
const Payments = (props) => {
  const [, setLoading] = useState(false)
  // const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [getPayment, setPaymentData] = useState([])
  const [getMyCardData, setMyCardData] = useState([])
  const [, setTotalItemsCount] = useState()

  const [pageNumber, setPageNumber] = useState(0)
  const usersPerPage = 5
  const pageVisited = pageNumber * usersPerPage
  let currentUserPageData = []
  if (getPayment.length >= 1) {
    currentUserPageData = getPayment.slice(
      pageVisited,
      pageVisited + usersPerPage
    )
  }
  const pageCount = Math.ceil(getPayment.length / usersPerPage)

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Credits = (x) => {
    return numberWithCommas(x)
    //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const amount = (x) => {
    return numberWithCommas(x)
    //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  useEffect(() => {
    setLoading(true)
    getUserCreditCardsDetails()
    getUserTransactionsHistoryData()
  }, [])

  function getUserCreditCardsDetails() {
    setisLoading(true)
    const userId = getUserID()
    getUserCreditCardsAPI({
      user_id: userId,
    })
      .then(function (response) {
        setisLoading(false)
      
        if (response.status !== false) {
          setMyCardData(response.data)
        }else{
          setMyCardData([])
        }

      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function getUserTransactionsHistoryData() {
    setisLoading(true)
    const userId = getUserID()
    getUsersTransactionData({
      user_id: userId,
    })
      .then(function (response) {
        setisLoading(false)
       
        if(response.message){
          toast.error("We are using stripe live account keys. We need to update stripe test keys. ")
        }else{
        setPaymentData(response.data)
        setTotalItemsCount(response.data.length)
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function makeDefaultCardAPI(id) {
    setisLoading(true)
    const userId = getUserID()
    makeStripeDefultCard({
      stripe_card_id: id,
      user_id: userId,
    })
      .then(function (response) {
        if (response.status === true) {
          setisLoading(false)
          toast.success(response["message"])
          getUserCreditCardsDetails()
        } else {
          toast.error(response["message"])
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function deleteStripeCardData(id) {
    setisLoading(true)
    const userId = getUserID()
    deleteStripeCardAPI({
      stripe_card_id: id,
      user_id: userId,
    })
      .then(function (response) {
        if (response.status === true) {
          setisLoading(false)
          toast.success(response["message"])
          getUserCreditCardsDetails()
          
        } else {
         
          toast.error(response["message"])
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }
  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNavProfile />
      <div className='payments-method-page width-inpayment-set'>
        <div className='wrapper-payments-method-page'>
          <div className='container-fluid'>
            <div className='MainDownloadTabs'>
              <ul>
                <li className='active'>
                  <Link to='/payments'>Payments</Link>
                </li>
                <li>
                  {" "}
                  <Link to='/credits'> Credits</Link>
                </li>
                  <li >
                    <Link to='/plans'> Plans</Link>
                  </li>
              </ul>
            </div>
            <div className='main-div-payments-card'>
              <div className='second-div-for-option'>
                <div className='my-card-payments'>
                  <div className='card'>
                    <div className='card-headding-payment'>
                      <h3>Add Card</h3>
                    </div>
                    <div className='row'>
                      <div>
                        <Elements stripe={stripePromise} >
                          <CheckoutForm />
                        </Elements>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='my-card-payments my-radio-cards'>
                  <div className='card'>
                    <div className='card-headding-payment'>
                      <h3>My Cards</h3>
                      <p>Select card for all Purchases</p>
                    </div>
                    <div className='row'>
                      {getMyCardData.map((paymentData) => (
                        <div className='col-md-6'>
                          <div className='my-radio-button-payment'>
                            <div className='card'>
                              {paymentData["default_card"] === true ? (
                                <input
                                  type='radio'
                                  id='card1'
                                  name='fav_language'
                                  value='card1'
                                  checked
                                />
                              ) : (
                                <input
                                  type='radio'
                                  id='card1'
                                  name='fav_language'
                                  value='card1'
                                  disabled
                                />
                              )}

                              <label htmlFor='card1'>
                                {paymentData["type"] === "Visa" ? (
                                  <span>
                                    {" "}
                                    <img
                                      src={visa}
                                      className='img-hw-payment'
                                      alt='img'
                                    />
                                  </span>
                                ) : (
                                  <span>
                                    {" "}
                                    <img
                                      src={Cardone}
                                      className='img-hw-payment'
                                      alt='img'
                                    />
                                  </span>
                                )}

                                <span className='card-name-text-paym'>
                                  <p id='getCardlast4'>
                                    {paymentData.card_number}
                                  </p>
                                </span>
                                <span className='d-flex links-for-default-card'>
                                  <button
                                    type='submit'
                                    className='defaule-card-payment'
                                    onClick={() =>
                                      makeDefaultCardAPI(paymentData.id)
                                    }>
                                    Make default
                                  </button>
                                  <button
                                    type='submit'
                                    className='remove-card-payment'
                                    onClick={() =>
                                      deleteStripeCardData(paymentData.id)
                                    }>
                                    Remove
                                  </button>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='table-for-payment-div my-card-payments'>
              <div className='card table-for-payment-detail-width'>
                <div className='card-headding-payment table-payment-text'>
                  <h3>Payment History</h3>
                </div>
                <div className='table-for-payment-detail'>
                  <div className='main-th-header-payment'>
                    <div className='row first-th-payment'>
                      <div className='col'>
                        {" "}
                        <th>ID</th>
                      </div>
                      <div className='col'>
                        <th>CREDITS</th>
                      </div>
                      <div className='col'>
                        {" "}
                        <th>AMOUNT</th>
                      </div>
                      <div className='col'>
                        {" "}
                        <th>STATUS</th>
                      </div>
                      <div className='col'>
                        {" "}
                        <th>DATE</th>
                      </div>
                    </div>
                  </div>
                  {currentUserPageData.map((newPaymentData) => (
                    <div className='main-tr-data-payment'>
                      <div className='row tr-inner'>
                        <div className='col'>
                          {" "}
                          <td data-tip data-for={newPaymentData.id}>
                            {newPaymentData.id}
                          </td>
                          <ReactTooltip
                            id={newPaymentData.id}
                            aria-haspopup='true'>
                            {newPaymentData.id}
                          </ReactTooltip>
                        </div>
                        <div className='col'>
                          {" "}
                          <td>{Credits(newPaymentData.description)} </td>{" "}
                        </div>
                        <div className='col'>
                          {" "}
                          <td className='without-otp'>
                            ${amount(newPaymentData.amount / 100)}
                          </td>{" "}
                        </div>
                        {newPaymentData["refunded"] === true ? (
                          <div className='col'>
                            <td style={{ color: "red" }}>Refunded</td>{" "}
                          </div>
                        ) : (
                          <div className='col'>
                            <td style={{ color: "green" }}>Succeded</td>{" "}
                          </div>
                        )}
                        <div className='col'>
                          <td>{newPaymentData.created}</td>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='main-div-for-payment-pagination'>
                  <div className='pagination-for-table-div-payment'>
                    <ul className='pagination'>
                      {/* <Pagination
                                                        itemClass="page-item" 
                                                        linkClass="page-link" 
                                                        previousLabel={"Prev"}
                                                        nextLable={"Nexxt"}
                                                        activePage={currentPage}
                                                        itemsCountPerPage={recordPerPage}
                                                        totalItemsCount={totalRecords}
                                                        pageRangeDisplayed={pageRange}
                                                        onChange={handlePageChange}
                                                    /> */}
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLable={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        itemClassName={"page-item"}
                        linkClassName={"page-link"}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`
export default Payments
