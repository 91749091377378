import React, { useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserID } from "utils/helper";
import "./App.css";
import Login from "./pages/login";
import ResetPassword from "./pages/resetPassword";
import NoComponent from "./pages/noComponent";

import NavbarRoutes from "./Router";
import IdleTimer from "react-idle-timer";
import IdleTimeOutModal from "./components/modal/idleTimeoutModal";

toast.configure();

function App() {
  const userID = getUserID();

  const loggedIn = userID > 0 ? true : false;
  const [timeout] = useState(1000 * 60 * 25);
  const [showModal, setShowModal] = useState(false);

  var idleTimerRef = useRef(null);
  const _onAction = (e) => {};

  const _onActive = (e) => {};

  const _onIdle = (e) => {
    if (loggedIn) {
      setShowModal(true);
      idleTimerRef.reset();
    }
  };
  const handleClose = () => {
    setShowModal(false);
    idleTimerRef.reset();
  };

  const handleLogout = () => {
    setShowModal(false);
    window.location.href = "/";
    localStorage.clear();
  };
  return (
    <>
      <IdleTimer
        ref={(ref) => {
          idleTimerRef = ref;
        }}
        element={document}
        onActive={_onActive}
        onIdle={_onIdle}
        onAction={_onAction}
        debounce={250}
        timeout={timeout}
      />
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              loggedIn ? (
                <Redirect to="/data-validation" />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/login" component={Login} />

          <NavbarRoutes />
          <Redirect to="/" />
          <Route path="*" exact component={NoComponent} />
        </Switch>
      </Router>
      <IdleTimeOutModal
        showModal={showModal}
        handleClose={handleClose}
        handleLogout={handleLogout}
      />
    </>
  );
}

export default App;
