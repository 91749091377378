import Tabs from "pages/revampUpload/report/Tabs"
import React, { useEffect, useState } from "react"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
// import COLORS from "utils/colors"
import {
  getTotalCountPhone,
  getPhoneValidationSummary
} from "services/dataValidation"

const Modal = ({ id, show, onClose }) => {

  const [report, setReport] = useState()
  const [totalCount, setTotalCount] = useState()

  const TotalCount = () => {
    getTotalCountPhone({ filelist_id: id })
      .then(function (response) {
        setTotalCount(response)
      })
      .catch((error) => {
        alert("Some error occured")
        console.error("file upload error: ", error)
      })

    getPhoneValidationSummary({ file_id: id })
      .then(function (response) {
        setReport(response)
      })
      .catch((error) => {
        alert("Some error occured")
        console.error("file upload error: ", error)
      })
  }
  useEffect(() => {
    if (show) {
      TotalCount()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  if (!show) {
    return null
  }
  return (
    <WrapperModal>
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <button
              _ngcontent-hex-c78=''
              type='button'
              data-dismiss='modal'
              className='report-close'
              onClick={onClose}>
              ×
            </button>
          </ModalTitle>
          <WrapperBody>
            <Tabs label="Total CSV File Count:" contents={report?.reportLogs || []} />
            <Tabs label="Total Phone Count:" contents={
              report?.mapReport
            } />
            <Tabs label="Total Phone Validation Count:" contents={
              [
                { lable: "Caller ID Names", value: totalCount?.['Total Caller ID Names'] },
                { lable: "Carriers", value: totalCount?.['Total Carriers'] },
                { lable: "Caller ID Mobile Yes", value: totalCount?.['Total Caller ID Mobile Yes'], percent: totalCount?.['Total Caller ID Mobile Yes %'] },
                { lable: "Caller ID Mobile No", value: totalCount?.['Total Caller ID Mobile No'], percent: totalCount?.['Total Caller ID Mobile No %'] },
                { lable: "Caller ID Landline Yes", value: totalCount?.['Total Caller ID Landline Yes'], percent: totalCount?.['Total Caller ID Landline Yes %'] },
                { lable: "Caller ID Landline No", value: totalCount?.['Total Caller ID Landline No'], percent: totalCount?.['Total Caller ID Landline No %'] },
                { lable: "Disconnected Mobile ", value: totalCount?.['Total Disconnected Mobile'], percent: totalCount?.['Total Disconnected Mobile %'] },
                { lable: "Disconnected Landline", value: totalCount?.['Total Disconnected Landline'], percent: totalCount?.['Total Disconnected Landline %'] },
                { lable: "Mobile", value: totalCount?.['Total Mobile'], percent: totalCount?.['Total Mobile %'] },
                { lable: "Landline", value: totalCount?.['Total Landline'], percent: totalCount?.['Total Landline %'] },
                { lable: "Disconnected", value: totalCount?.['Total Disconnected'], percent: totalCount?.['Total Disconnected %'] },
              ]
            } />
          </WrapperBody>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal

const WrapperModal = styled.div`
  position: fixed;
  width: 58%;
  height:469px;
  overflow-y:hidden;
  left: 420px;
  top: 40px;
  z-index: 111;
  display: flex;
`
const WrapperHeader = styled.div``
const WrapperContent = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 20px 20px;
  border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 520px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;
  overflow-y:auto;
  height:364px;
`
// const WrapperFooter = styled.div`
//   padding: 10px;
//   text-align: center;
// `

// const ModalButtonSubmit = styled.button`
//   width: 222px;
//   height: 40px;
//   color: blue;
//   font-size: 15px;
//   font-weight: bold;
//   background-color: #89de58;
//   color: #fff;
//   margin-top: 30px;
//   box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
//   &:hover {
//     background-color: ${COLORS.green};
//     color: ${COLORS.white};
//   }
//   border-radius: 5px;
//   margin-left: 10px;
// `

// const ModalButton = styled.button`
//   width: 222px;
//   height: 40px;
//   color: blue;
//   font-size: 15px;
//   font-weight: bold;
//   background-color: #d4d4d4;
//   color: #6d6e6e;
//   margin-top: 30px;
//   box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
//   &:hover {
//     background-color: ${COLORS.green};
//     color: ${COLORS.white};
//   }
//   border-radius: 5px;
// `

// const Para2 = styled.p`
//   color: 000000;
//   font-size: 10px;
//   font-weight: bold;
//   position: relative;
//   top: 28px;
//   width: 88%;
//   text-align: center;`