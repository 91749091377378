import React from 'react'
import Select from "react-select"

function ContactFields({onChange,options,step,relative}) {

  const getSelectedValue=(field,index)=>{
       
    if(index){
      return `${step}${index}_${field}`
    }else{
      return `${step}_${field}`
    }
  }

      const Name = ({index}) => (<>
  <label>Relative{index} Name </label>
    <Select
      name={getSelectedValue("name",index)}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue("name",index)]!=''?{label:relative[getSelectedValue("name",index)],value:relative[getSelectedValue("name",index)]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

    const Age = ({index}) => (<>
    <label>{step.includes('relative')&&`Relative${index}`} Age  </label>
    <Select
      name={getSelectedValue("age",index)}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue("age",index)]!=''?{label:relative[getSelectedValue("age",index)],value:relative[getSelectedValue("age",index)]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

  const FirstName = () => (<>
    <label>First Name </label>
    <Select
      name={getSelectedValue("first_name")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue("first_name")]!=''?{label:relative[getSelectedValue("first_name")],value:relative[getSelectedValue("first_name")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

    const LastName = () => (<>
    <label>Last Name </label>
    <Select
      name={getSelectedValue("last_name")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue("last_name")]!=''?{label:relative[getSelectedValue("last_name")],value:relative[getSelectedValue("last_name")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

    const Deceased = () => (<>
    <label>Deceased </label>
    <Select
      name={getSelectedValue("deceased")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue("deceased")]!=''?{label:relative[getSelectedValue("deceased")],value:relative[getSelectedValue("deceased")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

    const MailingAddress = () => (<>
    <label>Mailing Address </label>
    <Select
      name={getSelectedValue("mailing_address")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue("mailing_address")]!=''?{label:relative[getSelectedValue("mailing_address")],value:relative[getSelectedValue("mailing_address")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

    const  MailingCity = () => (<>
    <label>Mailing City  </label>
    <Select
      name={getSelectedValue("mailing_city")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue("mailing_city")]!=''?{label:relative[getSelectedValue("mailing_city")],value:relative[getSelectedValue("mailing_city")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

    const MailingZip = () => (<>
    <label>Mailing Zip  </label>
    <Select
      name={getSelectedValue("mailing_zip")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue("mailing_zip")]!=''?{label:relative[getSelectedValue("mailing_zip")],value:relative[getSelectedValue("mailing_zip")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )

     const MailingState = () => (<>
    <label>Mailing State   </label>
    <Select
      name={getSelectedValue("mailing_state")}
      options={[{label:'Select',value:''},...options]}
      className='basic-multi-select'
      classNamePrefix='select'
      value={relative?.[getSelectedValue("mailing_state")]!=''?{label:relative[getSelectedValue("mailing_state")],value:relative[getSelectedValue("mailing_state")]}:{label:"Select",value:""}}
      onChange={onChange}
    />
    </>
  )
  
  return (<>
      {step.includes("relative")?
              <> 
              {step.includes('relative')&&
              Array.from(Array(6).keys())
                  .slice(1)
                  .map((index) => (<>         
              < div className='col-md-6'>
                <div className='fields max-w-fit'>
                    <Name index={index}/>
                </div>
              </div>
              < div className='col-md-6'>
                <div className='fields max-w-fit'>
                    <Age index={index}/>
                </div>
              </div>
              </>))}
              </>
                        :
                        <>
                        < div className='col-md-6'>
                <div className='fields max-w-fit'>
                    <FirstName/>
                </div>
              </div>
              < div className='col-md-6'>
                <div className='fields max-w-fit'>
                    <LastName/>
                </div>
              </div>
              < div className='col-md-6'>
                <div className='fields max-w-fit'>
                    <Age/>
                </div>
              </div>
              < div className='col-md-6'>
                <div className='fields max-w-fit'>
                    <Deceased/>
                </div>
              </div>
              < div className='col-md-6'>
                <div className='fields max-w-fit'>
                    <MailingAddress/>
                </div>
              </div>
              < div className='col-md-6'>
                <div className='fields max-w-fit'>
                    <MailingCity/>
                </div>
              </div>
              < div className='col-md-6'>
                <div className='fields max-w-fit'>
                  <MailingState/>
                </div>
              </div>  
              < div className='col-md-6'>
                <div className='fields max-w-fit'>
                    <MailingZip/>
                </div>
              </div>
                  
                        </>}
        
  </>)
}

export default ContactFields